var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.travellersRows),function(traveller,index){return _c('div',{key:index,class:`traveller traveller-${traveller.id}`},[_c('div',{staticClass:"product-card",on:{"click":function($event){return _vm.onClickOpenCollapse(traveller.id)}}},[_c('a-row',[_c('a-col',{attrs:{"span":22}},[_c('a-row',{attrs:{"type":"flex","justify":"start","gutter":20}},[_c('a-col',{staticClass:"id cprimary"},[_c('span',{staticClass:"ico"},[_c('a-icon',{attrs:{"type":"user"}}),_vm._v(" "),_c('font',{staticClass:"num"},[_vm._v(_vm._s(traveller.id)+" ")])],1)]),_vm._v(" "),_c('a-col',{staticClass:"id-name"},[_vm._v(" "+_vm._s(_vm.theTraveller(traveller.id)))])],1)],1),_vm._v(" "),_c('a-col',{staticClass:"a-right",attrs:{"span":2}},[(traveller.id == _vm.travellersRows.length && traveller.id != 1)?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete","ghost":""},on:{"click":function($event){return _vm.removeTraveller(_vm.hotelId, _vm.roomId, traveller.id)}}}):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.openCollapse == traveller.id)?_c('div',{staticClass:"product-card-content"},[_c('a-row',{staticClass:"travellers-rows",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{class:'filled'},[_vm._v("Hóspede ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `hotel_${_vm.hotelId}_accommodation_${_vm.roomId}_traveller_${traveller.id}`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `hotel_${hotelId}_accommodation_${roomId}_traveller_${traveller.id}`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Selecione"}},_vm._l((_vm.travellers),function(traveller,index){return _c('a-select-option',{key:index,attrs:{"value":traveller.id}},[_vm._v("\n                "+_vm._s(traveller.name)+"\n                "+_vm._s(traveller.lastName)+"\n              ")])}),1)],1)],1)],1)],1):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }