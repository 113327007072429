<template>
  <div>
    <a-row
      v-for="(traveller, index) in travellers"
      :key="index"
      :class="`traveller traveller-${traveller.id}`"
    >
      <a-col :span="1"
        ><font class="user">
          <a-icon type="user" />
          <font class="number"> {{ traveller.id }} </font>
        </font>
      </a-col>

      <a-col :span="22">
        <a-row :gutter="[20, 0]">
          <a-col :span="4">
            <a-form-item class="travel-input">
              <label :class="'filled'">Nome </label>
              <a-input
                class="upper"
                placeholder="Escreva..."
                v-decorator="[
                  `traveller_${traveller.id}_first_name`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="5">
            <a-form-item class="travel-input">
              <label :class="'filled'">Sobrenome</label>
              <a-input
                class="upper"
                placeholder="Escreva..."
                v-decorator="[
                  `traveller_${traveller.id}_last_name`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="3">
            <a-form-item>
              <span class="travel-input">
                <label :class="'filled'">Sexo</label>
                <a-select
                  class="travel-input"
                  placeholder="Selecione"
                  v-decorator="[
                    `traveller_${traveller.id}_gender`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                  style="width: 100%; height: 30px"
                >
                  <a-select-option value="Masculino">
                    Masculino
                  </a-select-option>
                  <a-select-option value="Feminino">
                    Feminino
                  </a-select-option>
                </a-select>
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item class="travel-input">
              <label :class="'filled'">Nascimento</label>
              <a-date-picker
                class="travel-input"
                placeholder="Selecione"
                format="DD/MM/YYYY"
                v-mask="'##/##/####'"
                :disabled-date="maxDateAge"
                :showToday="false"
                @change="checkAdultHood(traveller.id)"
                v-decorator="[
                  `traveller_${traveller.id}_birthday`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
              </a-date-picker>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item>
              <span class="travel-input">
                <label :class="'filled'">CPF</label>
                <a-input
                  class="travel-input"
                  placeholder="Escreva..."
                  v-mask="'###.###.###-##'"
                  v-decorator="[
                    `traveller_${traveller.id}_CPF`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item>
              <span class="travel-input">
                <label :class="'filled'">RG (opcional)</label>
                <a-input
                  class="travel-input"
                  placeholder="Escreva..."
                  v-mask="['##.###.###', '#.###.###', '###.###.###-##']"
                  v-decorator="[`traveller_${traveller.id}_rg`]"
                >
                </a-input>
              </span>
            </a-form-item>

            <a-form-item style="display: none">
              <a-input
                placeholder="Criança"
                v-decorator="[`traveller_${traveller.id}_age`]"
                readonly
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>

      <a-col class="a-right" :span="1">
        <a-tooltip
          v-if="traveller.id == 1"
          placement="bottomRight"
          title="Recuperar dados do contratante"
        >
          <a-button
            class="mt-10"
            size="small"
            type="primary"
            shape="circle"
            icon="user"
            @click="getCustomerData(traveller.id)"
          >
          </a-button>
        </a-tooltip>
        <a-button
          v-if="traveller.id === travellers.length && travellers.length > 1"
          size="small"
          type="danger"
          shape="circle"
          icon="delete"
          @click="removeRow(traveller.id)"
        >
        </a-button>
      </a-col>
    </a-row>

    <a-row>
      <a-col class="a-center mt-30" :span="24">
        <a-button
          class="add-traveller"
          type="primary"
          @click="addRow(travellers.length)"
        >
          ADICIONAR VIAJANTE
        </a-button>
      </a-col>
    </a-row>

    <a-form-item style="display: none">
      <span class="travel-input">
        <a-input
          v-decorator="[
            `travellers_rows`,
            {
              initialValue: JSON.stringify(travellers),
            },
          ]"
        >
        </a-input>
      </span>
    </a-form-item>
  </div>
</template>

<script>
import subYears from "date-fns/subYears";

export default {
  name: "TravellersModal",
  props: {
    data: Object,
    form: Object,
    customerForm: Object,
  },
  data() {
    return {
      travellers: [{ id: 1 }],
    };
  },
  beforeMount() {
    let travellers =
      this.data.travellers_rows != undefined
        ? JSON.parse(this.data.travellers_rows)
        : this.travellers;

    this.travellers = travellers;

    this.form.setFieldsValue({
      [`travellers_rows`]: JSON.stringify(this.travellers),
    });

    if (travellers.length > 0) {
      travellers.forEach((traveller) => {
        setTimeout(() => {
          this.form.setFieldsValue({
            [`traveller_${traveller.id}_first_name`]: this.data[
              `traveller_${traveller.id}_first_name`
            ],
            [`traveller_${traveller.id}_last_name`]: this.data[
              `traveller_${traveller.id}_last_name`
            ],
            [`traveller_${traveller.id}_birthday`]: this.data[
              `traveller_${traveller.id}_birthday`
            ],
            [`traveller_${traveller.id}_age`]: this.data[
              `traveller_${traveller.id}_age`
            ],
            [`traveller_${traveller.id}_rg`]: this.data[
              `traveller_${traveller.id}_rg`
            ],
            [`traveller_${traveller.id}_CPF`]: this.data[
              `traveller_${traveller.id}_CPF`
            ],
            [`traveller_${traveller.id}_gender`]: this.data[
              `traveller_${traveller.id}_gender`
            ],
          });
        }, 1);
      });
    }
  },
  methods: {
    checkAdultHood(id) {
      let flag = "adult";
      let theDate = "";
      let birthday = "";

      setTimeout(() => {
        birthday = this.data[`traveller_${id}_birthday`];
        if (birthday != undefined) {
          if (typeof birthday == "object") {
            theDate = birthday._d;
          }

          if (typeof birthday == "string") {
            theDate = new Date(birthday);
          }

          if (theDate) {
            if (theDate.getTime() > subYears(new Date(), 12).getTime()) {
              flag = "child";
            }
          }

          this.form.setFieldsValue({
            [`traveller_${id}_age`]: flag,
          });
        }
      }, 10);
    },
    maxDateAge(current) {
      return current && current > new Date();
    },
    addRow(id) {
      this.travellers.push({
        id: id + 1,
      });

      this.form.setFieldsValue({
        [`travellers_rows`]: JSON.stringify(this.travellers),
      });

      this.data.travellers_rows = JSON.stringify(this.travellers);
    },
    removeRow(id) {
      for (var i in this.travellers) {
        if (this.travellers[i].id == id) {
          this.travellers.splice(i, 1);
          break;
        }
      }

      this.form.setFieldsValue({
        [`travellers_rows`]: JSON.stringify(this.travellers),
      });

      this.data.travellers_rows = JSON.stringify(this.travellers);
    },
    getCustomerData(id) {
      this.form.setFieldsValue({
        [`traveller_1_first_name`]: this.data.the_customer.first_name.toUpperCase(),
      });

      this.form.setFieldsValue({
        [`traveller_1_last_name`]: this.data.the_customer.last_name.toUpperCase(),
      });

      this.form.setFieldsValue({
        [`traveller_1_birthday`]: this.data.the_customer.birthday,
      });

      this.form.setFieldsValue({
        [`traveller_1_CPF`]: this.data.the_customer.cpf,
      });

      this.form.setFieldsValue({
        [`traveller_1_rg`]: this.data.the_customer.rg,
      });

      this.form.setFieldsValue({
        [`traveller_1_gender`]: undefined,
      });

      this.data.travellers_rows = JSON.stringify(this.travellers);

      this.checkAdultHood(id);
    },
  },
};
</script>

<style lang="sass">
.traveller
    .ant-form-explain
        position: absolute
        right: 0
        font-size: 9px !important
</style>

<style lang="sass" scoped>
.traveller
  margin-bottom: 10px
  .ant-btn-danger
    position: relative
    top: 10px
  .ant-form-explain
    position: absolute
    right: 0
    font-size: 9px !important
  .user
    position: relative
    top: 10px
    font-size: 18px
    font-weight: 600
    color: #be4178
    .number
      position: absolute
      right: -8px
      top: 0
      font-size: 11px
      color: #333
.add-traveller
    font-weight: 500
.ant-form-item
    margin-bottom: 14px !important
.has-error
  .ant-form-explain
    position: absolute
    right: 0
    font-size: 9px !important
    bottom: -16px
  .ant-form-split
    position: absolute
    right: 0
    font-size: 9px !important
    bottom: -16px
</style>
