<template>
  <div>
    <aRow :gutter="20">
      <aCol
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 620,
              span: 24,
            },
            {
              min: 621,
              max: 1300,
              span: 24,
            },
            {
              min: 1300,
              max: 9999,
              span: 12,
            },
          ])
        "
      >
        <a-form-item>
          <label :class="'filled'"
            >Hotel / Resort
            <font
              v-if="
                tempContract[`hotel_${hotelID}_name_infotera`] &&
                !tempContract[`hotel_${hotelID}_name`]
              "
              class="cblack"
              style="font-size: 9px"
            >
              -
              <b>
                {{ tempContract[`hotel_${hotelID}_name_infotera`] }}
              </b>
            </font>
          </label>

          <a-select
            class="travel-input"
            show-search
            :allow-clear="true"
            placeholder="Selecione o Hotel ou Resort"
            @change="onHotelSelect"
            optionFilterProp="txt"
            v-decorator="[
              `hotel_${hotelID}_name`,
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of removeDuplicatedHotels(
                productSupplier.list
              )"
              :key="index"
              :value="item.trading_name"
              :txt="item.trading_name"
            >
              {{ item.trading_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 620,
              span: 12,
            },
            {
              min: 621,
              max: 1300,
              span: 12,
            },
            {
              min: 1300,
              max: 9999,
              span: 6,
            },
          ])
        "
      >
        <HayaTypeDateField
          label="Check-in"
          :fieldKey="`hotel_${hotelID}_checkin_typed`"
          :fieldToTransferValue="`hotel_${hotelID}_checkin`"
          :form="form"
          :required="true"
          @onChangeDatePicker="onChangeDatePicker"
        />

        <a-form-item class="none">
          <a-input v-decorator="[`hotel_${hotelID}_checkin`]"> </a-input>
        </a-form-item>
      </aCol>

      <aCol
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 620,
              span: 12,
            },
            {
              min: 621,
              max: 1300,
              span: 12,
            },
            {
              min: 1300,
              max: 9999,
              span: 6,
            },
          ])
        "
      >
        <HayaTypeDateField
          label="Check-out"
          :fieldKey="`hotel_${hotelID}_checkout_typed`"
          :fieldToTransferValue="`hotel_${hotelID}_checkout`"
          :form="form"
          :required="true"
          @onBlurHayaTypeDateField="onBlurHayaTypeDateField"
          @onChangeDatePicker="onChangeDatePicker"
        />

        <a-form-item class="none">
          <a-input v-decorator="[`hotel_${hotelID}_checkout`]"> </a-input>
        </a-form-item>
      </aCol>

      <aCol
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 620,
              span: 12,
            },
            {
              min: 621,
              max: 1300,
              span: 12,
            },
            {
              min: 1300,
              max: 9999,
              span: 4,
            },
          ])
        "
      >
        <a-form-item>
          <label :class="'filled'">Nº de dias</label>
          <a-input
            type="number"
            class="travel-input"
            placeholder="Digite"
            v-decorator="[
              'hotel_' + hotelID + '_rates',
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </aCol>

      <aCol
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 620,
              span: 12,
            },
            {
              min: 621,
              max: 1300,
              span: 12,
            },
            {
              min: 1300,
              max: 9999,
              span: 4,
            },
          ])
        "
      >
        <a-form-item>
          <label :class="'filled'">N° de noites </label>
          <a-input
            type="number"
            class="travel-input"
            placeholder="N° noites"
            v-decorator="['hotel_' + hotelID + '_nights']"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </aCol>

      <aCol
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 620,
              span: 12,
            },
            {
              min: 621,
              max: 1300,
              span: 12,
            },
            {
              min: 1300,
              max: 9999,
              span: 8,
            },
          ])
        "
      >
        <a-form-item>
          <label :class="'filled'"
            >Regime alimentar
            <span
              v-if="
                tempContract[`hotel_${hotelID}_diet_infotera`] &&
                !tempContract[`hotel_${hotelID}_diet`]
              "
              class="cgray"
              style="font-size: 9px"
            >
              -
              {{ tempContract[`hotel_${hotelID}_diet_infotera`] }}
            </span>
          </label>

          <a-select
            class="travel-input"
            show-search
            placeholder="Regime alimentar"
            v-decorator="[
              `hotel_${hotelID}_diet`,
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of diets"
              :key="index"
              :value="item.value"
            >
              {{ item.name.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 620,
              span: 12,
            },
            {
              min: 621,
              max: 1300,
              span: 12,
            },
            {
              min: 1300,
              max: 9999,
              span: 8,
            },
          ])
        "
      >
        <a-form-item>
          <label :class="'filled'">Localizador</label>
          <a-input
            class="travel-input"
            placeholder="Localizador"
            v-decorator="[
              'hotel_' + hotelID + '_locator',
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </aCol>
    </aRow>

    <HotelAccommodationForm
      :id="id"
      :hotelID="hotelID"
      :tempContract="tempContract"
      :form="form"
      :hotelRows="hotelRows"
      :hotel="hotel"
      :accommodationRows="hotel.accommodation"
      :theTravellers="theTravellers"
      :travellers="travellers"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { differenceInDays, parse, format, sub } from "date-fns";

import formatThings from "@/mixins/general/formatThings";
import hotelMixins from "@/mixins/hotel/hotelMixins";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import HotelAccommodationForm from "./HotelAccommodationCollapse.vue";
import HayaTypeDateField from "@/components/general/fields/HayaTypeDateField.vue";

export default {
  name: "HotelCollapse",
  props: {
    id: Number,
    add: Boolean,
    totalRows: Number,
    hotelRows: Array,
    tempContract: Object,
    form: Object,
    hotel: Object,
    theTravellers: Array,
    travellers: Array,
  },
  components: { HotelAccommodationForm, HayaTypeDateField },
  mixins: [hotelMixins, productSupplierMixins, formatThings],
  data() {
    return {
      openCollapse: false,
      hotelID: this.hotelRows[this.id - 1].id,
      hotelIndex: this.id - 1,
      accommodationRows: [],
      checkoutOpen: false,
      diets: [
        { name: "ALL INCLUSIVE", value: "ALL INCLUSIVE" },
        { name: "CAFÉ DA MANHÃ", value: "CAFÉ DA MANHÃ" },
        { name: "MEIA PENSAO", value: "MEIA PENSAO" },
        { name: "PENSÃO COMPLETA", value: "PENSÃO COMPLETA" },
        { name: "NÃO TEM", value: "NÃO TEM" },
      ],
    };
  },
  beforeMount() {
    this.productSupplier.filters.order = "ascend";
    this.productSupplier.filters.orderBy = "trading_name";
    this.productSupplier.filters.status.selected = ["Ativo"];
    this.productSupplier.pagination.perPage = 2000;
    this.getProductSuppliers();

    setTimeout(() => {
      this.calcDaysOnLoad();
      this.disabledEndDate();
      this.setFormFields();

      setTimeout(() => {
        this.accommodationRows = this.hotel.accommodation;
      }, 20);
    });
  },
  methods: {
    removeDuplicatedHotels(arr) {
      let unique = [];
      if (arr)
        unique = _.uniqWith(arr, (a, b) => a.trading_name === b.trading_name);

      return unique;
    },
    onChangeDatePicker() {
      setTimeout(() => {
        this.onChangeCalcDays();
      }, 300);
    },
    onChangeCalcDays() {
      const startValue = parse(
        this.form.getFieldValue(`hotel_${this.hotelID}_checkin`),
        "yyyy-MM-dd",
        new Date()
      );

      const endValue = parse(
        this.form.getFieldValue(`hotel_${this.hotelID}_checkout`),
        "yyyy-MM-dd",
        new Date()
      );

      const daysInHotel = differenceInDays(endValue, startValue);

      if (daysInHotel) {
        this.form.setFieldsValue({
          [`hotel_${this.hotelID}_rates`]: daysInHotel,
          [`hotel_${this.hotelID}_nights`]: daysInHotel - 1,
        });
      }
    },
    removeDuplicates(arr, equals) {
      let originalArr = arr.slice(0);
      let i, len, val;
      arr.length = 0;

      for (i = 0, len = originalArr.length; i < len; ++i) {
        val = originalArr[i];
        if (
          !arr.some(function (item) {
            return equals(item, val);
          })
        )
          arr.push(val);
      }
    },
    setFormFields() {
      this.form.setFieldsValue({
        [`hotel_${this.hotelID}_name`]:
          this.tempContract[`hotel_${this.hotelID}_name`],
        [`hotel_${this.hotelID}_checkin`]:
          this.tempContract[`hotel_${this.hotelID}_checkin`],
        [`hotel_${this.hotelID}_checkin_typed`]:
          this.tempContract[`hotel_${this.hotelID}_checkin_typed`] ??
          this.formatMultiDates(
            this.tempContract[`hotel_${this.hotelID}_checkin`]
          ),
        [`hotel_${this.hotelID}_checkout`]:
          this.tempContract[`hotel_${this.hotelID}_checkout`],
        [`hotel_${this.hotelID}_checkout_typed`]:
          this.tempContract[`hotel_${this.hotelID}_checkout_typed`] ??
          this.formatMultiDates(
            this.tempContract[`hotel_${this.hotelID}_checkout`]
          ),
        [`hotel_${this.hotelID}_rates`]:
          this.tempContract[`hotel_${this.hotelID}_rates`],
        [`hotel_${this.hotelID}_nights`]:
          this.tempContract[`hotel_${this.hotelID}_nights`],
        [`hotel_${this.hotelID}_locator`]:
          this.tempContract[`hotel_${this.hotelID}_locator`],
        [`hotel_${this.hotelID}_diet`]:
          this.tempContract[`hotel_${this.hotelID}_diet`],
        [`hotel_${this.hotelID}_total_price`]:
          this.tempContract[`hotel_${this.hotelID}_total_price`],
      });
    },

    getPayingTravellerData() {
      this.form.setFieldsValue({
        [`hotel_${this.hotelID}_accommodation_${1}_person_first_name_${1}`]:
          this.form.getFieldValue(`client_name`).split(" ")[0],
        [`hotel_${this.hotelID}_accommodation_${1}_person_last_name_${1}`]:
          this.form
            .getFieldValue(`client_name`)
            .substr(this.form.getFieldValue(`client_name`).indexOf(" ") + 1),
        [`hotel_${this.hotelID}_accommodation_${1}_person_birthday_${1}`]:
          this.form.getFieldValue(`client_birthday`),
        [`hotel_${this.hotelID}_accommodation_${1}_person_CPF_${1}`]:
          this.form.getFieldValue(`client_CPF`),
      });
    },
    maxDateAge(current) {
      return current && current > new Date();
    },
    minDate(current) {
      return !this.$root.isAdmin()
        ? sub(current, {
            years: 0,
            months: 0,
            weeks: 0,
            days: 1,
            hours: 0,
            minutes: 0,
            seconds: 0,
          }) &&
            current <
              sub(new Date(), {
                years: 0,
                months: 0,
                weeks: 0,
                days: 1,
                hours: 0,
                minutes: 0,
                seconds: 0,
              })
        : false;
    },
    minDateOnLoad() {
      let startValue = parse(
        this.form.getFieldValue(`hotel_${this.hotelID}_checkin`),
        "yyyyMMdd",
        new Date()
      );
      return startValue;
    },
    disabledEndDate(endValue) {
      let startValue = this.form.getFieldValue(`hotel_${this.hotelID}_checkin`);

      if (endValue != undefined) {
        if (endValue["_d"] == undefined) {
          if (!endValue || !startValue) return false;
          return startValue.valueOf() >= endValue.valueOf();
        } else {
          if (!endValue || !startValue) return false;
          return startValue.valueOf() >= endValue.valueOf();
        }
      }
    },
    calcDaysOnLoad() {
      let startValue = parse(
        this.form.getFieldValue(`hotel_${this.hotelID}_checkin`),
        "yyyyMMdd",
        new Date()
      );

      console.log("startValue", startValue);

      let endValue = parse(
        this.form.getFieldValue(`hotel_${this.hotelID}_checkout`),
        "yyyyMMdd",
        new Date()
      );

      let daysInHotel = differenceInDays(endValue, startValue);

      if (daysInHotel) {
        this.form.setFieldsValue({
          [`hotel_${this.hotelID}_rates`]: daysInHotel,
        });
      }
    },
    selectedDate(current) {
      setTimeout(() => {
        this.form.setFieldsValue({
          [`hotel_${this.hotelID}checkin`]: current._d,
          [`hotel_${this.hotelID}_checkout`]: format(current._d, "MM/dd/yyyy"),
        });
        this.checkoutOpen = true;
      }, 100);
    },
    onBlurHayaTypeDateField(checkout) {
      let checkin = this.form.getFieldValue(`hotel_${this.hotelID}_checkin`);
      let daysInHotel = checkout
        ? differenceInDays(
            new Date(checkout + " 10:00"),
            new Date(checkin + " 10:00")
          )
        : "";

      if (checkin.length === 10 && checkout.length) {
        this.form.setFieldsValue({
          [`hotel_${this.hotelID}_rates`]: daysInHotel + 1,
        });

        this.form.setFieldsValue({
          [`hotel_${this.hotelID}_nights`]: daysInHotel,
        });
      }
    },
    checkoutOpenChange(open) {
      let checkout = this.form.getFieldValue(`hotel_${this.hotelID}_checkout`);
      this.disabledEndDate(checkout);
      this.checkoutOpen = open;
    },
    onHotelSelect(val) {
      if (val != undefined) {
        const a = this.productSupplier.list.filter((hotel) => {
          return hotel.trading_name === val;
        });
        this.tempContract[`hotel_${this.hotelID}_id`] = a[0].id;
      } else {
        this.tempContract[`hotel_${this.hotelID}_id`] = "";
      }
    },

    dynamicRequiredField() {
      let flag = this.form.getFieldValue("contracted_services");

      if (flag != undefined) {
        flag = this.form.getFieldValue("contracted_services").includes("hotel");
      } else {
        flag = false;
      }

      return flag;
    },
  },
};
</script>

<style lang="sass" scoped>
.product-card-content
  padding: 30px 20px 20px
  border: 1px solid #eee
  margin-top: -14px
  border-radius: 0 0 6px 6px
  margin-bottom: 11px
  background: #fefefe
.product-card
  cursor: pointer
  clear: both
  padding: 20px
  background: #fff
  border: 1px solid #eee
  margin-bottom: 10px
  border-radius: 6px
  .id
    color: #474747
    font-weight: 600
    .ico
      position: relative
    .num
      position: absolute
      right: -13px
      top: 0
      font-size: 11px
      color: #333
  .id-name
    padding-left: 20px !important
    color: #42413f
    font-weight: 500
</style>
