var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aRow',{staticClass:"gateway",attrs:{"gutter":20}},[_c('aCol',{staticClass:"title",attrs:{"span":24}},[_vm._v(" Gateway de pagamento: REDE ")]),_vm._v(" "),_c('aCol',{staticClass:"subtitle",attrs:{"span":24}},[_vm._v(" Análise de risco ")]),_vm._v(" "),_c('aCol',{staticClass:"fields"},[_c('aRow',{attrs:{"gutter":[20, 0]}},[_c('aCol',{attrs:{"span":3}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Score")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                `payment_methods_payment_link_risk_analysis_score_${_vm.paymentLinkId}`,
              ]),expression:"[\n                `payment_methods_payment_link_risk_analysis_score_${paymentLinkId}`,\n              ]"}],staticClass:"travel-input",attrs:{"placeholder":"","disabled":true}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Status")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                `payment_methods_payment_link_risk_analysis_status_${_vm.paymentLinkId}`,
              ]),expression:"[\n                `payment_methods_payment_link_risk_analysis_status_${paymentLinkId}`,\n              ]"}],staticClass:"travel-input",attrs:{"placeholder":"","disabled":true}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":7}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Status Code")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                `payment_methods_payment_link_risk_analysis_status_code_${_vm.paymentLinkId}`,
              ]),expression:"[\n                `payment_methods_payment_link_risk_analysis_status_code_${paymentLinkId}`,\n              ]"}],staticClass:"travel-input",attrs:{"placeholder":"","disabled":true}})],1)],1),_vm._v(" "),_c('aCol',{staticClass:"actions",attrs:{"span":4}},[_c('a-button',{staticClass:"btn-1 mr-5",attrs:{"type":"primary"}},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/contracts/refresh-link.png"),"alt":"ico"}})]),_vm._v(" "),_c('a-button',{staticClass:"btn-2 mr-5",attrs:{"type":"primary"}},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/contracts/transfer-link.png"),"alt":"ico"}})]),_vm._v(" "),_c('a-button',{staticClass:"btn-3",attrs:{"type":"primary"}},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/contracts/send-link.png"),"alt":"ico"}})])],1),_vm._v(" "),_c('aCol',{staticClass:"obs",attrs:{"span":5}},[_c('b',[_vm._v("Atenção:")]),_vm._v(" Solicitar prioridade terá custo adicional na venda.\n        ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }