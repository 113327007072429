<template>
  <div>
    <div
      v-for="(airline, index) in airlinesRows"
      :key="index"
      :class="`airline airline-${airline.id}`"
    >
      <div class="product-card" @click="onClickOpenCollapse(airline.id)">
        <a-row>
          <a-col :span="22">
            <a-row type="flex" justify="start" :gutter="20">
              <a-col class="id cprimary">
                <span class="ico">
                  <i class="fa fa-plane"></i>
                  <font class="num">{{ airline.id }} </font>
                </span>
              </a-col>
              <a-col class="id-name">
                <div v-html="theAirline(airline.id)"></div>
              </a-col>
            </a-row>
          </a-col>
          <a-col class="a-right" :span="2">
            <a-button
              v-if="airline.id == airlinesRows.length && airline.id != 1"
              size="small"
              type="danger"
              shape="circle"
              icon="delete"
              @click="removeAirline(flightSectionId, airline.id)"
              ghost
            />
          </a-col>
        </a-row>
      </div>

      <div class="product-card-content" v-if="openCollapse == airline.id">
        <a-row :gutter="20">
          <a-col :span="4">
            <a-form-item class="travel-input">
              <label :class="'filled'">Cia aérea </label>
              <a-select
                style="width: 100%"
                placeholder="Selecione"
                show-search
                :allow-clear="true"
                :disabled="milesOrderExists"
                @change="clearMilesProgram(flightSectionId, airline.id)"
                v-decorator="[
                  `flight_${flightSectionId}_airline_${airline.id}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(airline, index) in airlinesList"
                  :key="index"
                  :value="
                    airline.name +
                    '|' +
                    airline.id +
                    '|' +
                    airline.trading_name +
                    '|' +
                    airline.company_name +
                    '|' +
                    airline.cnpj
                  "
                >
                  <img v-if="airline.logo" :src="airline.logo" width="13" />
                  {{ airline.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col
            :span="4"
            v-if="tempContract[`flight_${flightSectionId}_type`] == 'Milhas'"
          >
            <a-form-item class="travel-input">
              <label :class="'filled'">Programa de milhas </label>
              <a-select
                style="width: 100%"
                placeholder="Selecione"
                show-search
                :allow-clear="true"
                :disabled="milesOrderExists"
                v-decorator="[
                  `flight_${flightSectionId}_airline_${airline.id}_miles_program`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(program, index) in filterMilesPrograms(
                    tempContract[
                      `flight_${flightSectionId}_airline_${airline.id}`
                    ]
                  )"
                  :key="index"
                  :value="program.id"
                >
                  {{ program.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col
            :span="16"
            v-if="tempContract[`flight_${flightSectionId}_type`] == 'Milhas'"
          >
            <a-form-item
              class="travel-input travel-select-expandable"
              style="height: auto !important"
            >
              <label :class="'filled'">Passageiros </label>
              <a-select
                class="travellers-selector"
                mode="multiple"
                placeholder="Selecione"
                style="width: 100%; height: auto !important"
                :disabled="milesOrderExists"
                v-decorator="[
                  `flight_${flightSectionId}_airline_${airline.id}_travellers`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(traveller, index) of travellers"
                  :key="index"
                  :value="traveller.id"
                >
                  {{ traveller.name }}
                  {{ traveller.lastName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlightSectionAirlinesCollapse",
  props: {
    flightSectionId: Number,
    tempContract: Object,
    form: Object,
    milesOrderExists: Boolean,
    airlinesRows: Array,
    travellers: Array,
  },
  data() {
    return {
      openCollapse: -1,
      airlinesList: [],
    };
  },
  mounted() {
    this.getAirlines();
  },
  methods: {
    theAirline(airlineId) {
      let theAirline = "CIA AÉREA",
        currentAirline = [];

      if (
        this.tempContract[
          `flight_${this.flightSectionId}_airline_${airlineId}`
        ] != undefined
      ) {
        currentAirline = this.airlinesList.filter((a) => {
          let splitVal = this.tempContract[
            `flight_${this.flightSectionId}_airline_${airlineId}`
          ]
            ? this.tempContract[
                `flight_${this.flightSectionId}_airline_${airlineId}`
              ].split("|")
            : [];
          return a.id == splitVal[1];
        });
      }

      if (currentAirline.length > 0) {
        theAirline = `<img class="mr-5" src='${
          currentAirline[0].logo
        }' height="20" /> ${
          currentAirline[0].name
            ? currentAirline[0].name
            : currentAirline[0].trading_name
        }`;
      }

      return theAirline;
    },
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;

        setTimeout(() => {
          this.setFlightTravellerFields(id);
        }, 5);
      }
    },
    setFlightTravellerFields(id) {
      this.form.setFieldsValue({
        [`flight_${this.flightSectionId}_airline_${id}`]:
          this.tempContract[`flight_${this.flightSectionId}_airline_${id}`] !=
          undefined
            ? this.tempContract[`flight_${this.flightSectionId}_airline_${id}`]
            : undefined,

        [`flight_${this.flightSectionId}_airline_${id}_miles_program`]:
          this.tempContract[
            `flight_${this.flightSectionId}_airline_${id}_miles_program`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_airline_${id}_miles_program`
              ]
            : undefined,

        [`flight_${this.flightSectionId}_airline_${id}_travellers`]:
          this.tempContract[
            `flight_${this.flightSectionId}_airline_${id}_travellers`
          ] != undefined
            ? JSON.parse(
                this.tempContract[
                  `flight_${this.flightSectionId}_airline_${id}_travellers`
                ]
              )
            : undefined,
      });
    },
    clearMilesProgram(flight, airline) {
      this.form.setFieldsValue({
        [`flight_${flight}_airline_${airline}_miles_program`]: undefined,
      });
    },
    getAirlines() {
      this.$http
        .get(`/airline/list?page=1&per_page=200&order=ascend&order-by=name`)
        .then(({ data }) => {
          this.airlinesList = data.data;
        });
    },
    filterMilesPrograms(airline) {
      let filtered = [];

      if (airline != undefined) {
        this.airlinesList.forEach((cia) => {
          if (cia.miles_programs.length > 0) {
            cia.miles_programs.forEach((program) => {
              if (program.airline_id == airline.split("|")[1]) {
                filtered.push(program);
              }
            });
          }
        });
      }

      return filtered;
    },
    removeAirline(flightSectionId, id) {
      this.$emit("removeAirline", flightSectionId, id);
    },
  },
};
</script>

<style lang="sass" scoped>
.product-card-content
  padding: 20px 15px 0px
  border: 1px solid #ddd
  margin-top: -14px
  border-radius: 0 0 6px 6px
  margin-bottom: 11px
  background: #FFF
.product-card
  cursor: pointer
  clear: both
  padding: 10px 20px
  background: #fff
  border: 1px solid #ddd
  margin-bottom: 10px
  border-radius: 6px
  .id
    color: #474747
    font-weight: 600
    .ico
      position: relative
    .num
      position: absolute
      right: -13px
      top: 0
      font-size: 11px
      color: #333
  .id-name
    padding-left: 20px !important
    color: #42413f
    font-weight: 500
</style>
