var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.airlinesRows),function(airline,index){return _c('div',{key:index,class:`airline airline-${airline.id}`},[_c('div',{staticClass:"product-card",on:{"click":function($event){return _vm.onClickOpenCollapse(airline.id)}}},[_c('a-row',[_c('a-col',{attrs:{"span":22}},[_c('a-row',{attrs:{"type":"flex","justify":"start","gutter":20}},[_c('a-col',{staticClass:"id cprimary"},[_c('span',{staticClass:"ico"},[_c('i',{staticClass:"fa fa-plane"}),_vm._v(" "),_c('font',{staticClass:"num"},[_vm._v(_vm._s(airline.id)+" ")])],1)]),_vm._v(" "),_c('a-col',{staticClass:"id-name"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.theAirline(airline.id))}})])],1)],1),_vm._v(" "),_c('a-col',{staticClass:"a-right",attrs:{"span":2}},[(airline.id == _vm.airlinesRows.length && airline.id != 1)?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete","ghost":""},on:{"click":function($event){return _vm.removeAirline(_vm.flightSectionId, airline.id)}}}):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.openCollapse == airline.id)?_c('div',{staticClass:"product-card-content"},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{class:'filled'},[_vm._v("Cia aérea ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionId}_airline_${airline.id}`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionId}_airline_${airline.id}`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","show-search":"","allow-clear":true,"disabled":_vm.milesOrderExists},on:{"change":function($event){return _vm.clearMilesProgram(_vm.flightSectionId, airline.id)}}},_vm._l((_vm.airlinesList),function(airline,index){return _c('a-select-option',{key:index,attrs:{"value":airline.name +
                  '|' +
                  airline.id +
                  '|' +
                  airline.trading_name +
                  '|' +
                  airline.company_name +
                  '|' +
                  airline.cnpj}},[(airline.logo)?_c('img',{attrs:{"src":airline.logo,"width":"13"}}):_vm._e(),_vm._v("\n                "+_vm._s(airline.name)+"\n              ")])}),1)],1)],1),_vm._v(" "),(_vm.tempContract[`flight_${_vm.flightSectionId}_type`] == 'Milhas')?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{class:'filled'},[_vm._v("Programa de milhas ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionId}_airline_${airline.id}_miles_program`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionId}_airline_${airline.id}_miles_program`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","show-search":"","allow-clear":true,"disabled":_vm.milesOrderExists}},_vm._l((_vm.filterMilesPrograms(
                  _vm.tempContract[
                    `flight_${_vm.flightSectionId}_airline_${airline.id}`
                  ]
                )),function(program,index){return _c('a-select-option',{key:index,attrs:{"value":program.id}},[_vm._v("\n                "+_vm._s(program.name)+"\n              ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract[`flight_${_vm.flightSectionId}_type`] == 'Milhas')?_c('a-col',{attrs:{"span":16}},[_c('a-form-item',{staticClass:"travel-input travel-select-expandable",staticStyle:{"height":"auto !important"}},[_c('label',{class:'filled'},[_vm._v("Passageiros ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionId}_airline_${airline.id}_travellers`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionId}_airline_${airline.id}_travellers`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"travellers-selector",staticStyle:{"width":"100%","height":"auto !important"},attrs:{"mode":"multiple","placeholder":"Selecione","disabled":_vm.milesOrderExists}},_vm._l((_vm.travellers),function(traveller,index){return _c('a-select-option',{key:index,attrs:{"value":traveller.id}},[_vm._v("\n                "+_vm._s(traveller.name)+"\n                "+_vm._s(traveller.lastName)+"\n              ")])}),1)],1)],1):_vm._e()],1)],1):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }