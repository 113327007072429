import _ from "lodash";
import { parse } from "vue-currency-input";
import { differenceInMinutes } from "date-fns";
import airports from "@/mixins/internal-apis/airports.json";
import formatThings from "@/mixins/general/formatThings";

export default {
  mixins: [formatThings],
  data() {
    return {
      airports,
      airlinesList: [],
    };
  },
  mounted() {
    this.$http
      .get(`/airline/list?page=1&per_page=200&order=ascend&order-by=name`)
      .then(({ data }) => {
        this.airlinesList = data.data;
      });
  },
  methods: {
    contractEmail() {
      let uniqueId = this.tempContract.created.split("-");

      console.log("contract_email", {
        id: this.tempContract.id,
        unique_id: uniqueId[0] + uniqueId[1] + this.tempContract.id,
        contracted_services: this.tempContract.contracted_services
          ? JSON.parse(this.tempContract.contracted_services)
          : [],
        customer: this.emailCustomer(),
        package_type: this.tempContract.package_type,
        travellers: this.emailTravellers(),
        consultant: this.emailConsultant(),
        company: this.emailCompany(),
        company_branch: this.emailCompanyBranch(),
        payment: this.emailPayments(),
        products: this.emailProducts(),
        marketing: this.emailMarketing(),
        sent_by: {
          id: this.$store.state.userData.id,
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        },
      });

      this.tempContract.contract_email = JSON.stringify({
        id: this.tempContract.id,
        unique_id: uniqueId[0] + uniqueId[1] + this.tempContract.id,
        contracted_services: this.tempContract.contracted_services
          ? JSON.parse(this.tempContract.contracted_services)
          : [],
        customer: this.emailCustomer(),
        package_type: this.tempContract.package_type,
        travellers: this.emailTravellers(),
        consultant: this.emailConsultant(),
        company: this.emailCompany(),
        company_branch: this.emailCompanyBranch(),
        payment: this.emailPayments(),
        products: this.emailProducts(),
        marketing: this.emailMarketing(),
        sent_by: {
          id: this.$store.state.userData.id,
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        },
      }).replace(/<[^>]*>?/gm, "");
    },
    calcLiveloPoints(value) {
      let totalPoints = 0;
      if (value) {
        parse;
        let rule = this.tempContract.livelo_points_rule
          ? parseInt(this.tempContract.livelo_points_rule)
          : 1;
        totalPoints = parseInt(value) * rule;
      }
      return totalPoints;
    },
    emailMarketing() {
      let marketingTypes = this.tempContract.marketing_types
          ? JSON.parse(this.tempContract.marketing_types)
          : [],
        packageType = this.tempContract.package_type
          ? this.tempContract.package_type
          : undefined,
        livelo = {
          is_active: "no",
          products: [],
          rule: this.tempContract.livelo_points_rule
            ? `${this.tempContract.livelo_points_rule} pontos por real gasto`
            : "",
          total: this.tempContract.livelo_amount_to_receive
            ? `${parseInt(this.tempContract.livelo_amount_to_receive)}`
            : "",
        },
        liveloHotels = this.tempContract.livelo_participating_products_hotels
          ? JSON.parse(this.tempContract.livelo_participating_products_hotels)
          : [],
        liveloFlights = this.tempContract.livelo_participating_products_flights
          ? JSON.parse(this.tempContract.livelo_participating_products_flights)
          : [],
        liveloServices = this.tempContract
          .livelo_participating_products_services
          ? JSON.parse(this.tempContract.livelo_participating_products_services)
          : [];

      packageType;

      if (marketingTypes.includes("Livelo")) {
        livelo.is_active = "yes";

        if (packageType == "Serviços" || packageType == "Reserva Online") {
          if (liveloHotels.length > 0) {
            liveloHotels.forEach((hotel) => {
              hotel;
              livelo.products.push({
                name: this.tempContract[`hotel_${hotel}_name`],
                points_to_receive: this.calcLiveloPoints(
                  this.tempContract[`hotel_${hotel}_total_price`]
                ),
              });
            });
          }

          if (liveloFlights.length > 0) {
            liveloFlights.forEach((flight) => {
              flight;
              livelo.products.push({
                name: `Aéreo saindo de ${
                  this.tempContract[`flight_${flight}_section_1_origin`]
                }`,
                points_to_receive: this.calcLiveloPoints(
                  this.tempContract[`flight_${flight}_total_price`]
                ),
              });
            });
          }

          if (liveloServices.length > 0) {
            liveloServices.forEach((service) => {
              service;
              livelo.products.push({
                name: this.tempContract[`service_${service}_type`],
                points_to_receive: this.calcLiveloPoints(
                  this.tempContract[`service_${service}_total_price`]
                ),
              });
            });
          }
        }

        if (packageType == "Pacote Completo Terceiros") {
          livelo.products.push({
            name: "Pacote completo",

            points_to_receive: this.calcLiveloPoints(
              this.tempContract[`package_total_price`]
            ),
          });
        }
      }

      return {
        livelo,
      };
    },
    emailProducts() {
      let productsArr = [],
        contractedServices = this.tempContract.contracted_services
          ? JSON.parse(this.tempContract.contracted_services)
          : [],
        hotelRows = this.tempContract.hotel_rows
          ? JSON.parse(this.tempContract.hotel_rows)
          : [],
        serviceRows = this.tempContract.service_rows
          ? JSON.parse(this.tempContract.service_rows)
          : [];

      if (contractedServices.includes("hotel")) {
        if (hotelRows.length > 0) {
          hotelRows.forEach((hotel) => {
            productsArr.push({
              product: "hotel",
              name: this.tempContract[`hotel_${hotel.id}_name`],
              nameLowerCase: this.tempContract[`hotel_${hotel.id}_name`]
                ? this.tempContract[`hotel_${hotel.id}_name`].toLowerCase()
                : "",
              text: `${this.tempContract[`hotel_${hotel.id}_nights`]} noites, ${
                this.emailProductTravellers(hotel.id, "hotel").txt
              }`,
              textPayment: `${
                this.emailProductTravellers(hotel.id, "hotel").txt
              }`,
              value: this.formatPricePtBr(
                this.tempContract[`hotel_${hotel.id}_total_price`]
              ),
              checkin: this.tempContract[`hotel_${hotel.id}_checkin`]
                ? this.formatDateModel(
                    this.tempContract[`hotel_${hotel.id}_checkin`],
                    "dd MMM yyyy"
                  )
                : "",
              checkout: this.tempContract[`hotel_${hotel.id}_checkout`]
                ? this.formatDateModel(
                    this.tempContract[`hotel_${hotel.id}_checkout`],
                    "dd MMM yyyy"
                  )
                : "",
            });
          });
        }
      }

      if (contractedServices.includes("flight")) {
        productsArr = [...productsArr, ...this.flightDetailsV2()];
      }

      if (contractedServices.includes("service")) {
        if (serviceRows.length > 0) {
          serviceRows.forEach((service) => {
            productsArr.push({
              product: "service",
              type: this.tempContract[`service_${service.id}_type`],
              text: this.tempContract[`service_${service.id}_name`],
            });
          });
        }
      }

      return productsArr;
    },
    flightDetailsV2() {
      let flightSectionsRows = this.tempContract.flight_sections_rows
          ? JSON.parse(this.tempContract.flight_sections_rows)
          : [],
        flightsArr = [],
        allTravellers = [];

      if (this.tempContract.travellers_rows != undefined) {
        JSON.parse(this.tempContract.travellers_rows).forEach((traveller) => {
          allTravellers.push({
            id: traveller.id,
            nome:
              this.tempContract[`traveller_${traveller.id}_first_name`] &&
              this.tempContract[`traveller_${traveller.id}_last_name`]
                ? `${this.tempContract[
                    `traveller_${traveller.id}_first_name`
                  ].toUpperCase()} ${this.tempContract[
                    `traveller_${traveller.id}_last_name`
                  ].toUpperCase()}`
                : "",
            nascimento:
              this.tempContract[`traveller_${traveller.id}_birthday`] !=
              undefined
                ? this.formatMultiDates(
                    this.tempContract[`traveller_${traveller.id}_birthday`]
                  )
                : "",
            cpf: `${this.tempContract[`traveller_${traveller.id}_CPF`]}`,
            idade: `${this.tempContract[`traveller_${traveller.id}_age`]}`,
          });
        });
      }

      flightSectionsRows.forEach((flight) => {
        let theSections = [],
          totalAdults = 0,
          totalChilds = 0,
          txtAdts = "",
          txtChds = "";

        flight.sections.forEach((section) => {
          theSections.push({
            type: this.tempContract[
              `flight_${flight.id}_section_${section.id}_type`
            ],
            origin:
              this.tempContract[
                `flight_${flight.id}_section_${section.id}_origin`
              ],
            originName: this.airportInitialsToName(
              this.tempContract[
                `flight_${flight.id}_section_${section.id}_origin`
              ]
            ),
            destination:
              this.tempContract[
                `flight_${flight.id}_section_${section.id}_destination`
              ],
            destinationName: this.airportInitialsToName(
              this.tempContract[
                `flight_${flight.id}_section_${section.id}_destination`
              ]
            ),
            departure_date: this.formatDateModel(
              this.tempContract[
                `flight_${flight.id}_section_${section.id}_departure_date`
              ],
              "dd MMM yyyy"
            ),
            departure_time:
              this.tempContract[
                `flight_${flight.id}_section_${section.id}_departure_time`
              ],
            arrival_date: this.formatDateModel(
              this.tempContract[
                `flight_${flight.id}_section_${section.id}_arrival_date`
              ],
              "dd MMM yyyy"
            ),
            arrival_time:
              this.tempContract[
                `flight_${flight.id}_section_${section.id}_arrival_time`
              ],
            duration:
              this.tempContract[
                `flight_${flight.id}_section_${section.id}_duration`
              ],
            airline: {
              name: this.tempContract[
                `flight_${flight.id}_section_${section.id}_airline`
              ],
              logo: this.airlineNameToLogo(
                this.tempContract[
                  `flight_${flight.id}_section_${section.id}_airline`
                ]
              )?.logo,
            },
          });
        });

        flight.travellers.forEach((traveller) => {
          allTravellers.forEach((travellerObj) => {
            if (
              travellerObj.id ==
              this.tempContract[`flight_${flight.id}_traveller_${traveller.id}`]
            ) {
              if (travellerObj.idade == "adult") {
                totalAdults = totalAdults + 1;
              }

              if (travellerObj.idade == "child") {
                totalChilds = totalChilds + 1;
              }
            }
          });
        });

        if (totalAdults == 1) {
          txtAdts = "1 adulto";
        }

        if (totalAdults > 1) {
          txtAdts = totalAdults + " adultos";
        }

        if (totalChilds == 1) {
          txtChds = " + 1 criança";
        }

        if (totalChilds > 1) {
          txtChds = ` + ${totalChilds} crianças`;
        }

        flightsArr.push({
          product: "flight",
          type: this.tempContract[`flight_${flight.id}_type`],
          sections: theSections,
          travellers: txtAdts + txtChds,
        });
      });

      return flightsArr;
    },
    flightDetails() {
      let flightSectionsRows = this.tempContract.flight_sections_rows
          ? JSON.parse(this.tempContract.flight_sections_rows)
          : [],
        origin = "",
        destination = "",
        totalAdults = 0,
        totalChilds = 0,
        roundtrip = "Somente ida",
        allTravellers = [],
        flightsArr = [],
        txtAdts = "",
        txtChds = "",
        allAirlines = [],
        totalStopsGoing = 0,
        totalStopsBack = 0;

      if (this.tempContract.travellers_rows != undefined) {
        JSON.parse(this.tempContract.travellers_rows).forEach((traveller) => {
          allTravellers.push({
            id: traveller.id,
            nome:
              this.tempContract[`traveller_${traveller.id}_first_name`] &&
              this.tempContract[`traveller_${traveller.id}_last_name`]
                ? `${this.tempContract[
                    `traveller_${traveller.id}_first_name`
                  ].toUpperCase()} ${this.tempContract[
                    `traveller_${traveller.id}_last_name`
                  ].toUpperCase()}`
                : "",
            nascimento:
              this.tempContract[`traveller_${traveller.id}_birthday`] !=
              undefined
                ? this.formatMultiDates(
                    this.tempContract[`traveller_${traveller.id}_birthday`]
                  )
                : "",
            cpf: `${this.tempContract[`traveller_${traveller.id}_CPF`]}`,
            idade: `${this.tempContract[`traveller_${traveller.id}_age`]}`,
          });
        });
      }

      if (flightSectionsRows.length > 0) {
        flightSectionsRows.forEach((flight) => {
          origin = this.tempContract[`flight_${flight.id}_section_1_origin`];
          destination = "";
          roundtrip = "Somente ida";
          txtAdts = "";
          txtChds = "";
          totalAdults = 0;
          totalChilds = 0;
          allAirlines = [];
          totalStopsGoing = 0;
          let departureDateGoing = "",
            departureDateBack = "",
            flightGoingDates = [],
            flightBackDates = [];

          flight.travellers.forEach((traveller) => {
            allTravellers.forEach((travellerObj) => {
              if (
                travellerObj.id ==
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}`
                ]
              ) {
                if (travellerObj.idade == "adult") {
                  totalAdults = totalAdults + 1;
                }

                if (travellerObj.idade == "child") {
                  totalChilds = totalChilds + 1;
                }
              }
            });
          });

          flight.sections.forEach((section) => {
            allAirlines.push({
              name: this.tempContract[
                `flight_${flight.id}_section_${section.id}_airline`
              ],
              logo: this.airlineNameToLogo(
                this.tempContract[
                  `flight_${flight.id}_section_${section.id}_airline`
                ]
              )?.logo,
            });

            if (
              this.tempContract[
                `flight_${flight.id}_section_${section.id}_type`
              ] == "Ida"
            ) {
              departureDateGoing =
                this.tempContract[
                  `flight_${flight.id}_section_${1}_departure_date`
                ];

              flightGoingDates.push({
                type: this.tempContract[
                  `flight_${flight.id}_section_${section.id}_type`
                ],
                dateDeparture: `${this.formatDateModel(
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_departure_date`
                  ],
                  "yyyy-MM-dd"
                )}`,

                timeDeparture: `${
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_departure_time`
                  ]
                }`,
                dateArrival: `${this.formatDateModel(
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_arrival_date`
                  ],
                  "yyyy-MM-dd"
                )}`,
                timeArrival: `${
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_arrival_time`
                  ]
                }`,
                duration:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_duration`
                  ],
              });

              // totalStopsGoing += parseInt(
              //   this.tempContract[
              //     `flight_${flight.id}_section_${section.id}_stops`
              //   ]
              //     ? this.tempContract[
              //         `flight_${flight.id}_section_${section.id}_stops`
              //       ]
              //     : 0
              // );

              totalStopsGoing += 0;
            }

            if (
              this.tempContract[
                `flight_${flight.id}_section_${section.id}_type`
              ] == "Volta"
            ) {
              if (departureDateBack == "") {
                departureDateBack =
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_departure_date`
                  ];
              }
              flightBackDates.push({
                type: this.tempContract[
                  `flight_${flight.id}_section_${section.id}_type`
                ],
                dateDeparture: `${this.formatDateModel(
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_departure_date`
                  ],
                  "yyyy-MM-dd"
                )}`,
                timeDeparture: `${
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_departure_time`
                  ]
                }`,
                dateArrival: `${this.formatDateModel(
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_arrival_date`
                  ],
                  "yyyy-MM-dd"
                )}`,
                timeArrival: ` ${
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_arrival_time`
                  ]
                }`,
                duration:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_duration`
                  ],
              });

              // totalStopsBack += parseInt(
              //   this.tempContract[
              //     `flight_${flight.id}_section_${section.id}_stops`
              //   ]
              //     ? this.tempContract[
              //         `flight_${flight.id}_section_${section.id}_stops`
              //       ]
              //     : 0
              // );

              totalStopsBack += 0;

              roundtrip = "Ida e Volta";
              destination =
                this.tempContract[
                  `flight_${flight.id}_section_${section.id}_origin`
                ];
            } else {
              destination =
                this.tempContract[
                  `flight_${flight.id}_section_${section.id}_destination`
                ];
            }
          });

          if (totalAdults == 1) {
            txtAdts = "1 adulto";
          }

          if (totalAdults > 1) {
            txtAdts = totalAdults + " adultos";
          }

          if (totalChilds == 1) {
            txtChds = " + 1 criança";
          }

          if (totalChilds > 1) {
            txtChds = ` + ${totalChilds} crianças`;
          }

          flightsArr.push({
            product: "flight",
            type: this.tempContract[`flight_${flight.id}_type`],
            origin,
            destination,
            destinationText: `${this.airportInitialsToName(
              origin
            )} → ${this.airportInitialsToName(destination)}`,
            roundtrip,
            roundtripText: `${roundtrip}`,
            travellersText: `${txtAdts} ${txtChds}`,
            going: {
              date: this.formatDateModel(departureDateGoing, "dd MMM yyyy"),
              times: this.flightDatesGoing(flightGoingDates),
              stops: totalStopsGoing,
              airline: _.uniqBy(allAirlines, "name"),
            },
            back: {
              date: this.formatDateModel(departureDateBack, "dd MMM yyyy"),
              times: this.flightDatesBack(flightBackDates),
              stops: totalStopsBack,
              airline: _.uniqBy(allAirlines, "name"),
            },
          });
        });
      }

      return flightsArr;
    },
    airportInitialsToName(initial) {
      let theName = "";
      if (initial) {
        if (initial.length > 2) {
          theName = this.airports.filter((airport) => {
            return airport.sigla === initial;
          });
          theName = theName.length > 0 ? theName[0].cidade : "";
        }
      }

      return theName;
    },
    airlineNameToLogo(name) {
      let theLogo = "";

      if (this.airlinesList.length > 0 && name != undefined) {
        theLogo = this.airlinesList.filter((airline) => {
          return airline.name == name.toUpperCase();
        });

        theLogo = theLogo[0];
      }

      return theLogo;
    },
    toHoursAndMinutes(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${hours}h${minutes > 0 ? ` ${minutes}m` : ""}`;
    },
    flightDatesGoing(arr) {
      let data = {},
        duration = 0;

      if (arr.length > 0) {
        duration = differenceInMinutes(
          new Date(
            `${arr[arr.length - 1].dateArrival} ${
              arr[arr.length - 1].timeArrival
            }`
          ),
          new Date(`${arr[0].dateDeparture} ${arr[0].timeDeparture}`),
          {
            round: false,
          }
        );

        data = {
          timeDeparture: arr[0].timeDeparture,
          timeArrival: arr[arr.length - 1].timeArrival,
          duration: this.toHoursAndMinutes(duration),
        };
      }

      return data;
    },
    flightDatesBack(arr) {
      let data = {},
        duration = 0;

      if (arr.length > 0) {
        duration = differenceInMinutes(
          new Date(
            `${arr[arr.length - 1].dateArrival} ${
              arr[arr.length - 1].timeArrival
            }`
          ),
          new Date(`${arr[0].dateDeparture} ${arr[0].timeDeparture}`),
          {
            round: false,
          }
        );

        data = {
          timeDeparture: arr[0].timeDeparture,
          timeArrival: arr[arr.length - 1].timeArrival,
          duration: this.toHoursAndMinutes(duration),
        };
      }

      return data;
    },
    emailProductTravellers(id, product) {
      let allTravellers = [];
      let totalAdults = 0;
      let totalChilds = 0;
      let txtAdts = "";
      let txtChds = "";

      if (this.tempContract.travellers_rows != undefined) {
        JSON.parse(this.tempContract.travellers_rows).forEach((traveller) => {
          allTravellers.push({
            id: traveller.id,
            nome:
              this.tempContract[`traveller_${traveller.id}_first_name`] &&
              this.tempContract[`traveller_${traveller.id}_last_name`]
                ? `${this.tempContract[
                    `traveller_${traveller.id}_first_name`
                  ].toUpperCase()} ${this.tempContract[
                    `traveller_${traveller.id}_last_name`
                  ].toUpperCase()}`
                : "",
            nascimento:
              this.tempContract[`traveller_${traveller.id}_birthday`] !=
              undefined
                ? this.formatMultiDates(
                    this.tempContract[`traveller_${traveller.id}_birthday`]
                  )
                : "",
            cpf: `${this.tempContract[`traveller_${traveller.id}_CPF`]}`,
            idade: `${this.tempContract[`traveller_${traveller.id}_age`]}`,
          });
        });
      }

      if (product == "hotel") {
        this.strToJson(this.tempContract.hotel_rows)[
          id - 1
        ].accommodation.forEach((room) => {
          room.travellers.forEach((traveller) => {
            allTravellers.forEach((travellerObj) => {
              if (
                travellerObj.id ==
                this.tempContract[
                  `hotel_${id}_accommodation_${room.id}_traveller_${traveller.id}`
                ]
              ) {
                if (travellerObj.idade == "adult") {
                  totalAdults = totalAdults + 1;
                }

                if (travellerObj.idade == "child") {
                  totalChilds = totalChilds + 1;
                }
              }
            });
          });
        });
      }

      if (totalAdults == 1) {
        txtAdts = "1 adulto";
      }

      if (totalAdults > 1) {
        txtAdts = totalAdults + " adultos";
      }

      if (totalChilds == 1) {
        txtChds = " + 1 criança";
      }

      if (totalChilds > 1) {
        txtChds = ` + ${totalChilds} crianças`;
      }

      return {
        txt: txtAdts + txtChds,
        totalAdults,
        totalChilds,
      };
    },
    service() {
      let arr = this.tempContract.service_travellers
          ? JSON.parse(this.tempContract.service_travellers)
          : [],
        text = "",
        total = 0;

      total += arr.length;

      if (total == 1) {
        text = "1 pessoa";
      } else {
        text = `${total} pessoas`;
      }

      return {
        text,
        total: this.formatPricePtBr(
          this.servicePricingData(this.tempContract).total -
            this.servicePricingData(this.tempContract).totalExtraFees
        ),
        data: this.servicePricingData(this.tempContract),
      };
    },
    flight() {
      let arr = this.tempContract.flight_sections_rows
          ? JSON.parse(this.tempContract.flight_sections_rows)
          : [],
        text = "",
        total = 0;

      arr.forEach((product) => {
        total += product.travellers.length;
      });

      if (total == 1) {
        text = "1 pessoa";
      } else {
        text = `${total} pessoas`;
      }

      return {
        text,
        total: this.formatPricePtBr(
          this.flightPricingData(this.tempContract).total -
            this.flightPricingData(this.tempContract).totalTaxes
        ),
        data: this.flightPricingData(this.tempContract),
      };
    },
    hotel() {
      let arr = this.tempContract.hotel_rows
          ? JSON.parse(this.tempContract.hotel_rows)
          : [],
        text = "",
        total = 0;

      arr.forEach((product) => {
        product.accommodation.forEach((room) => {
          total += room.travellers.length;
        });
      });

      if (total == 1) {
        text = "1 pessoa";
      } else {
        text = `${total} pessoas`;
      }

      return {
        text,
        total: this.formatPricePtBr(
          this.hotelPricingData(this.tempContract).total -
            this.hotelPricingData(this.tempContract).totalExtraFees
        ),
        data: this.hotelPricingData(this.tempContract),
      };
    },
    package() {
      return {
        total: this.formatPricePtBr(
          this.packagePricingData(this.tempContract).total -
            this.packagePricingData(this.tempContract).totalExtraFees
        ),
        data: this.packagePricingData(this.tempContract),
      };
    },
    emailPayments() {
      return {
        products: {
          hotel: this.hotel(),
          flight: this.flight(),
          service: this.service(),
          package: this.package(),
        },
        taxes_total: this.formatPricePtBr(
          this.flight().data.totalTaxes +
            this.hotel().data.totalExtraFees +
            this.service().data.totalExtraFees +
            this.package().data.totalExtraFees
        ),
        discount: this.formatPricePtBr(
          this.hotel().data.totalDiscount + this.service().data.totalDiscount
        ),
        total: this.formatPricePtBr(this.allContractData.total),
        total_without_taxes: this.formatPricePtBr(
          this.allContractData.total -
            (this.flight().data.totalTaxes +
              this.hotel().data.totalExtraFees +
              this.service().data.totalExtraFees +
              this.package().data.totalExtraFees)
        ),
        show_total_value_in_email:
          this.tempContract["show_total_value_in_email_instead_per_product"],
      };
    },
    emailTravellers() {
      let travellers =
          this.tempContract["travellers_rows"] != undefined
            ? JSON.parse(this.tempContract["travellers_rows"])
            : [],
        arr = [];

      travellers.forEach((traveller) => {
        // this.tempContract[`traveller_${traveller.id}_gender`]
        arr.push({
          first_name: this.tempContract[`traveller_${traveller.id}_first_name`],
          last_name: this.tempContract[`traveller_${traveller.id}_last_name`],
          birthday: this.tempContract[`traveller_${traveller.id}_birthday`]
            ? this.formatMultiDates(
                this.tempContract[`traveller_${traveller.id}_birthday`]
              )
            : "",
        });
      });

      return arr;
    },
    emailConsultant() {
      return {
        first_name: this.contract.user.first_name,
        last_name: this.contract.user.last_name,
        email: this.contract.user.email,
        mobile_phone: this.contract.user.mobile_phone,
        avatar: this.contract.user.avatar,
        whatsapp: this.contract.user.mobile_phone
          ? this.contract.user.mobile_phone
              .replace(" ", "")
              .replace(" ", "")
              .replace("-", "")
              .replace("(", "")
              .replace(")", "")
          : "",
      };
    },
    emailCompany() {
      return {
        name: this.contract.company_details.company_name,
        cnpj: this.contract.company_details.cnpj,
      };
    },
    emailCompanyBranch() {
      return {
        name: this.contract.company_branch.name,
        logo: this.contract.company_branch.logo_email,
        from_email: this.contract.company_branch.from_email,
        email_banner_image: this.contract.company_branch.email_banner_image,
        email_banner_url: this.contract.company_branch.email_banner_url,
      };
    },
    emailCustomer() {
      return {
        first_name: this.tempContract.the_customer.first_name,
        last_name: this.tempContract.the_customer.last_name,
        email: this.tempContract.the_customer.email,
        cpf: this.tempContract.the_customer.cpf,
        person_type: this.tempContract.the_customer.person_type
          ? this.tempContract.the_customer.person_type
          : "",
        trading_name: this.tempContract.the_customer.trading_name
          ? this.tempContract.the_customer.trading_name
          : "",
        cnpj: this.tempContract.the_customer.cnpj
          ? this.tempContract.the_customer.cnpj
          : "",
        birthday: this.tempContract.the_customer.birthday
          ? this.formatMultiDates(this.tempContract.the_customer.birthday)
          : "",
        mobile_phone: this.tempContract.the_customer.mobile_phone,
        whatsapp: this.tempContract.the_customer.mobile_phone
          ? this.tempContract.the_customer.mobile_phone
              .replace(" ", "")
              .replace(" ", "")
              .replace("-", "")
              .replace("(", "")
              .replace(")", "")
          : "",
      };
    },
  },
};
