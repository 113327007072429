import _ from "lodash";
export default {
    data() {
        return {
            hotelsList: [],
            hotel: {},
            loadingHotels: false,
            filters: {
                users: {
                    list: [],
                    selected: [],
                },
                period: {
                    selected: [],
                },
                searchTerm: "",
                order: "desc",
                orderBy: "created",
            },
            pagination: {
                page: 1,
                perPage: 10,
                total: 0,
                totalPages: 0,
            },
        };
    },
    methods: {
        searchHotel: _.debounce(function (e) {
            const serachText = e?.target?.value ? e.target.value : e;
            this.searchHotels(1, serachText);
        }, 400),
        searchHotels(page, searchText) {
            this.loadingHotels = true;
            const params = {
                page,
                s: searchText,
                per_page: 10,
            };
            this.$http
                .get("/hotel/list", {
                    params
                })
                .then(({
                    data
                }) => {
                    this.hotelsList = data.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.hotelsList = [];
                })
                .finally(() => {
                    this.loadingHotels = false;
                });
        },
        onHotelSelected(id) {
            this.hotel = null;
            this.getHotel(id);
        },
        changePage(current) {
            this.pagination.page = current;
            this.getHotels();
        },
        changePageSize(current, pageSize) {
            this.pagination.page = current;
            this.pagination.perPage = pageSize;

            this.getHotels();
        },
        getHotels(samePage) {
            this.loadingHotels = true;

            let filters = "";

            if (this.filters.searchTerm) {
                filters += `&s=${this.filters.searchTerm}`;
            }

            filters += `&order=${this.filters.order}&order-by=${this.filters.orderBy}`;

            this.$http
                .get(
                    `/hotel/list?page=${this.pagination.page}&per_page=${this.pagination.perPage}${filters}`
                )
                .then(({
                    data
                }) => {
                    if (!samePage) {
                        this.pagination.page = 1;
                    }
                    this.pagination.total = data.meta.total;
                    this.pagination.totalPages = data.meta.total_pages;

                    this.hotelsList = data.data;
                    this.loadingHotels = false;
                })
                .catch(({
                    response
                }) => {
                    response;
                    this.pagination.total = response.data.meta.total;
                    this.pagination.totalPages = response.data.meta.total_pages;

                    this.hotelsList = [];
                    this.loadingHotels = false;
                });
        },
        getHotel(id) {
            this.$http
                .post("/hotel/details", {
                    id
                })
                .then(({
                    data
                }) => {
                    this.hotel = data;
                    this.hotelForm.setFieldsValue(data)
                    if (this.$route.path.split('/')[1] == "hotels") {
                        document.title = `${data.id} - ${data.first_name}  ${data.last_name}`;
                    }
                })
                .catch(
                    ({
                        response
                    }) => {
                        response
                    }
                )
                .finally(() => {
                    //this.processing = false;
                });
        }

    }

}