<template>
  <div v-if="show">
    <div v-if="requestMilesTableData.length > 0">
      <h4 class="mb-10 cprimary">SOLICITAÇÃO</h4>
      <a-table
        class="travel-table last-itens"
        :pagination="false"
        :data-source="requestMilesTableData"
        :columns="[
          {
            title: 'ID Aéreo',
            dataIndex: 'contract_flight_id',
            key: 'contract_flight_id',
          },
          {
            title: 'Cia',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Programa de Milhas',
            dataIndex: 'miles_program',
            key: 'miles_program',
          },
          {
            title: 'Milhas',
            scopedSlots: {
              customRender: 'miles',
            },
          },
          {
            title: 'Valor Vôo (R$)',
            scopedSlots: {
              customRender: 'value',
            },
          },
          {
            title: 'Moeda Taxas',
            scopedSlots: {
              customRender: 'tax_currency',
            },
          },
          {
            title: 'Taxas',
            scopedSlots: {
              customRender: 'taxes',
            },
          },
          {
            title: '',
            align: 'right',
            fixed: 'right',
            scopedSlots: {
              customRender: 'action',
            },
          },
        ]"
      >
        <div slot="name" slot-scope="record">
          {{ formatInt(record.miles) }}
        </div>
        <div slot="miles" slot-scope="record">
          {{ formatInt(record.miles) }}
        </div>
        <div slot="value" slot-scope="record">
          {{ formatPricePrBr(record.value) }}
        </div>
        <div slot="tax_currency" slot-scope="record">
          {{ record.tax_currency }}
        </div>
        <div slot="taxes" slot-scope="record">
          {{ record.taxes }}
        </div>
        <div slot="action" slot-scope="record">
          <a-popconfirm
            v-if="record.status == 'À Solicitar'"
            ok-text="Sim"
            placement="bottom"
            cancel-text="Não"
            @confirm="
              requestMilesCardRow(
                record.contract_flight_id,
                record.contract_airline_id,
                record
              )
            "
          >
            <template slot="title">
              Tem certeza que deseja solicitar<br />
              as milhas? Esta ação não poderá<br />
              ser cancelada até o retorno <br />da sua solicitação.
            </template>

            <a-tooltip placement="top" title="Solicitar milhas">
              <a
                class="ml-5"
                v-if="!loadingRequest"
                style="font-size: 16px; color: red"
              >
                <a class="cgreen" style="font-size: 16px">
                  <i class="far fa-caret-square-right"></i>
                </a>
              </a>
              <a class="ml-5" v-else>
                <a-icon type="loading" />
              </a>
            </a-tooltip>
          </a-popconfirm>
        </div>
      </a-table>
    </div>

    <div v-if="listMilesOrders.length > 0">
      <h4
        class="mb-10 cprimary"
        :class="requestMilesTableData.length > 0 ? 'mt-20' : ''"
      >
        PEDIDOS ENVIADOS
      </h4>
      <a-table
        class="travel-table last-itens"
        :pagination="false"
        :data-source="listMilesOrders"
        :columns="[
          {
            title: 'ID Pedido',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: 'ID Aéreo',
            dataIndex: 'flight_id',
            key: 'flight_id',
          },
          {
            title: 'Cia',
            scopedSlots: {
              customRender: 'airline',
            },
          },
          {
            title: 'Pgm. Milhas',
            scopedSlots: {
              customRender: 'miles_program',
            },
          },
          {
            title: 'Milhas',
            dataIndex: 'miles_quantity',
            key: 'miles_quantity',
          },
          {
            title: 'Valor ',
            scopedSlots: {
              customRender: 'flight_value',
            },
          },
          {
            title: 'Taxas ',
            scopedSlots: {
              customRender: 'tax_value',
            },
          },
          {
            title: 'ID Cartão',
            scopedSlots: {
              customRender: 'miles_card',
            },
          },

          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
          },
          {
            title: '',
            align: 'right',
            fixed: 'right',
            scopedSlots: {
              customRender: 'action',
            },
          },
        ]"
      >
        <div slot="airline" slot-scope="record">
          <div v-if="record.airline.logo">
            <img :src="record.airline.logo" width="14" alt="logo" />
          </div>

          <div v-if="record.airline.logo == ''">
            {{ record.airline.name }}
          </div>
        </div>

        <div slot="miles_card" slot-scope="record">
          {{ record.miles_card ? record.miles_card.id : "" }}
        </div>

        <div slot="miles_program" slot-scope="record">
          {{ record.miles_program.name }}
        </div>

        <div slot="flight_value" slot-scope="record">
          R$ {{ record.flight_value }}
        </div>

        <div slot="tax_value" slot-scope="record">
          R$ {{ record.tax_value }}
        </div>

        <div slot="action" slot-scope="record">
          <div v-if="record.status == 'Pendente' && showActions">
            <a-popconfirm
              ok-text="Sim"
              placement="bottom"
              cancel-text="Não"
              @confirm="cancelMilesCardEmission(record, 'cancel-miles-order')"
            >
              <template slot="title">
                ATENÇÃO! Se você cancelar a <br />emissão das milhas terá de
                repetir <br />todo o processo novamente. <br />Tem certeza que
                deseja cancelar?
              </template>
              <a-tooltip placement="top" title="Cancelar o pedido">
                <a-button
                  class="red ml-10"
                  :loading="loadingMilesEmissionCancelation"
                  type="link"
                  style="padding: 0"
                >
                  <i class="far fa-times-circle"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>

            <a-tooltip placement="top" title="Aguardando">
              <a>
                <i
                  class="far fa-clock ml-10"
                  style="
                    color: #aaa;
                    font-size: 15px;
                    top: 1px;
                    position: relative;
                  "
                ></i>
              </a>
            </a-tooltip>
          </div>

          <div v-if="record.status == 'Pendente de Confirmação' && showActions">
            <a-popconfirm
              ok-text="Sim"
              placement="bottom"
              cancel-text="Não"
              @confirm="confirmMilesEmission(record)"
            >
              <template slot="title">
                Ao clicar em CONFIRMAR você completará <br />o processo de
                emissão das milhas. <br />Deseja continuar?
              </template>
              <a-tooltip placement="top" title="Confirmar emissão">
                <a-button
                  type="link"
                  :loading="loadingMilesEmissionConfirmation"
                  style="padding: 0"
                  class="cgreen"
                >
                  <i class="far fa-check-circle"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>

            <a-popconfirm
              ok-text="Sim"
              placement="bottom"
              cancel-text="Não"
              @confirm="cancelMilesCardEmission(record)"
            >
              <template slot="title">
                ATENÇÃO! Se você cancelar a <br />emissão das milhas terá de
                repetir <br />todo o processo novamente. <br />Tem certeza que
                deseja cancelar?
              </template>
              <a-tooltip placement="top" title="Cancelar">
                <a-button
                  class="red ml-10"
                  :loading="loadingMilesEmissionCancelation"
                  type="link"
                  style="padding: 0"
                >
                  <i class="far fa-times-circle"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>

            <a-tooltip placement="top" title="Ver o cartão">
              <a
                class="cgreen ml-10"
                @click="viewMilesCard(record.miles_card.id)"
                style="font-size: 14px; position: relative; top: 2px"
              >
                <a-icon slot="prefix" type="eye-svg" />
              </a>
            </a-tooltip>
          </div>

          <div v-if="record.status == 'Concluído'">
            <a-tooltip placement="top" title="Ver o cartão">
              <a
                class="cgreen ml-10"
                @click="viewMilesCard(record.miles_card.id)"
                style="font-size: 14px; position: relative; top: 2px"
              >
                <a-icon slot="prefix" type="eye-svg" style="zoom: 0.035" />
              </a>
            </a-tooltip>
          </div>
        </div>
      </a-table>
    </div>

    <a-modal
      class="miles-card-modal"
      :visible="viewMilesCardDrawer"
      :footer="false"
      width="1000px"
      @cancel="closeMilesCardDrawer"
    >
      <MilesCardsModal
        v-if="viewMilesCardDrawer"
        :milesCard="milesCard"
        :editMilesCard="editMilesCard"
        :editCard="editCard"
        :activeMilesCardTab="activeMilesCardTab"
        :viewOnly="true"
        @updateMilesCardsList="updateMilesCardsList"
      />
    </a-modal>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import MilesCardsModal from "../../miles/modal/MilesCardsModal.vue";
import { parse } from "vue-currency-input";

export default {
  name: "RequestMilesModal",
  components: { MilesCardsModal },
  mixins: [formatThings],
  props: {
    form: Object,
    allowMilesRequest: Boolean,
    tempContract: Object,
    milesCardOrder: Object,
    show: Boolean,
    milesRequestList: Array,
    showActions: Boolean,
  },
  data() {
    return {
      loadingRequest: false,
      loadingMilesEmissionConfirmation: false,
      loadingMilesEmissionCancelation: false,
      listMilesOrders: [],
      requestMilesTableData: [],
      viewMilesCardDrawer: false,
      editMilesCard: false,
      milesCard: {},
      theTravellers: [],
    };
  },
  beforeMount() {
    this.getMilesOrders();
    if (this.tempContract.travellers_rows != undefined) {
      JSON.parse(this.tempContract.travellers_rows).forEach((traveller) => {
        this.theTravellers.push({
          id: traveller.id,
          name: `${this.tempContract[
            `traveller_${traveller.id}_first_name`
          ].toUpperCase()} ${this.tempContract[
            `traveller_${traveller.id}_last_name`
          ].toUpperCase()}`,
          birthday:
            this.tempContract[`traveller_${traveller.id}_birthday`] != undefined
              ? this.tempContract[`traveller_${traveller.id}_birthday`]
              : "",
          cpf: `${
            this.tempContract[`traveller_${traveller.id}_CPF`]
              ? this.tempContract[`traveller_${traveller.id}_CPF`]
              : ""
          }`,
          age: this.tempContract[`traveller_${traveller.id}_age`],
        });
      });
    }

    this.requestMilesTableData = this.listMilesToRequest().list;
  },
  beforeDestroy() {
    if (this.milesCardOrder != undefined) {
      this.milesCardOrder.list = JSON.stringify(this.milesCardOrder.list);
    }
  },
  methods: {
    getMilesOrders() {
      this.$http
        .get(
          `/miles-card-order/list?page=1&per_page=100&contract_id=${this.tempContract.id}&status=Concluído,Pendente,Pendente de Confirmação`
        )
        .then(({ data }) => {
          this.listMilesOrders = data.data;
        })
        .catch(({ response }) => {
          this.listMilesOrders = response.data.data;
        });
    },
    listMilesToRequest() {
      let flag = false;
      let totalGeneralMiles = 0;
      let totalGeneralValue = 0;
      let totalGeneralTaxes = 0;
      let totalMiles = 0;
      let totalValue = 0;
      let totalTax = 0;
      let milesRequestList = [];
      let airlineName = "";
      let milesProgram = "";
      let taxCurrency = "";

      if (this.tempContract.flight_sections_rows != undefined) {
        JSON.parse(this.tempContract.flight_sections_rows).forEach((flight) => {
          totalMiles = 0;
          totalValue = 0;
          totalTax = 0;
          if (this.tempContract[`flight_${flight.id}_type`] == "Milhas") {
            flight.airlines.forEach((airline) => {
              airlineName =
                this.tempContract[`flight_${flight.id}_airline_${airline.id}`];

              milesProgram =
                this.tempContract[
                  `flight_${flight.id}_airline_${airline.id}_miles_program`
                ];

              let milesTravellers =
                this.tempContract[
                  `flight_${flight.id}_airline_${airline.id}_travellers`
                ];

              flight.travellers.forEach((traveller) => {
                if (
                  JSON.parse(milesTravellers).includes(
                    parseInt(
                      this.tempContract[
                        `flight_${flight.id}_traveller_${traveller.id}`
                      ]
                    )
                  )
                ) {
                  totalMiles += this.convertToInt(
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_miles`
                    ]
                  );

                  totalValue += parse(
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_value`
                    ]
                  );

                  totalTax += parse(
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes`
                    ]
                  );

                  taxCurrency =
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                    ];
                }
              });

              totalGeneralMiles += totalMiles;
              totalGeneralValue += totalValue;
              totalGeneralTaxes += totalTax;

              if (
                this.tempContract[
                  `flight_${flight.id}_airline_${airline.id}_miles_order_id`
                ] == undefined ||
                this.tempContract[
                  `flight_${flight.id}_airline_${airline.id}_miles_order_id`
                ] == ""
              ) {
                milesRequestList.push({
                  contract_flight_id: flight.id,
                  contract_airline_id: airline.id,
                  airline_id: airlineName.split("|")[1],
                  name: airlineName.split("|")[0],
                  miles_program: this.milesProgramName(
                    airlineName.split("|")[1],
                    milesProgram
                  ),
                  miles_program_id:
                    this.tempContract[
                      `flight_${flight.id}_airline_${airline.id}_miles_program`
                    ],
                  miles: totalMiles,
                  value: totalValue,
                  taxes: totalTax,
                  tax_currency: taxCurrency,
                  milescard_id: "",
                  transaction_id: "",
                  travellers: this.filterTravellers(
                    flight,
                    this.tempContract[
                      `flight_${flight.id}_airline_${airline.id}_travellers`
                    ]
                  ),
                  status:
                    this.tempContract[
                      `flight_${flight.id}_airline_${airline.id}_status`
                    ] != undefined
                      ? this.tempContract[
                          `flight_${flight.id}_airline_${airline.id}_status`
                        ]
                      : "À Solicitar",
                });
              }

              totalValue = 0;
              totalMiles = 0;
              totalTax = 0;
            });

            flag = true;

            flight.travellers.forEach((traveller) => {
              if (
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}_value`
                ]
              ) {
                totalValue += this.convertToInt(
                  this.tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_value`
                  ]
                );
              } else {
                totalValue += 0;
              }
            });
          }
        });
      }

      return {
        contract_id: this.tempContract.id,
        user_id: this.$store.state.userData.id,
        miles_quantity: totalGeneralMiles,
        value: totalGeneralValue,
        taxes: totalGeneralTaxes,
        status: "Pendente",
        list: milesRequestList,
        show: flag,
      };
    },
    filterTravellers(flight, travellers) {
      let theRequestTravellers = [];

      if (travellers != undefined) {
        JSON.parse(travellers).forEach((id) => {
          let theTraveller = this.theTravellers.filter((traveller) => {
            return traveller.id == id;
          });

          // console.log("filterTravellers", theTraveller);

          theTraveller[0].value = parse(
            this.tempContract[`flight_${flight.id}_traveller_${id}_value`]
          );

          theTraveller[0].taxes = parse(
            this.tempContract[`flight_${flight.id}_traveller_${id}_taxes`]
          );

          theTraveller[0].miles = this.convertToInt(
            this.tempContract[`flight_${flight.id}_traveller_${id}_miles`]
          );

          theRequestTravellers.push(theTraveller[0]);
        });
      }

      return JSON.stringify(theRequestTravellers);
    },
    requestMilesCardRow(flightId, airlineId, request) {
      let theOrder = {};

      request.status = "Pendente";

      // object
      theOrder.contract_id = this.tempContract.id;
      theOrder.user_id = this.$store.state.userData.id;
      theOrder.airline_id = request.airline_id;
      theOrder.miles_program_id = request.miles_program_id;
      theOrder.milescard_id = "";
      theOrder.transaction_id = "";
      theOrder.flight_id = flightId;
      theOrder.travellers = request.travellers;
      theOrder.miles_quantity = request.miles;
      theOrder.tax_currency = request.tax_currency;
      theOrder.tax_value = request.taxes;
      theOrder.flight_value = request.value;
      theOrder.value = request.value + request.taxes;
      theOrder.status = "Pendente";
      theOrder.action = "create-miles-order";
      theOrder.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.loadingRequest = true;
      //this.show = false;

      this.$http
        .post("/miles-card-order/create", theOrder)
        .then(({ data }) => {
          this.tempContract[`flight_${flightId}_airline_${airlineId}_status`] =
            "Aguardando";

          this.tempContract[
            `flight_${flightId}_airline_${airlineId}_miles_order_id`
          ] = data.id;

          this.$message.success("Pedido de milhas enviado com sucesso!");
          this.loadingRequest = false;

          let updateContract = {
            id: this.tempContract.id,
          };

          updateContract[`flight_${flightId}_airline_${airlineId}_status`] =
            "Aguardando";

          updateContract[
            `flight_${flightId}_airline_${airlineId}_miles_order_id`
          ] = data.id;

          updateContract.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/contract/update-multi-meta", updateContract)
            .then(({ data }) => {
              data;
              this.loadingRequest = false;
              this.$message.success("Dados do pedido atualizados no contrato!");
            })
            .catch(({ response }) => {
              response;
              this.loadingRequest = false;
            });

          // this.updateContract();
        })
        .catch(({ res }) => {
          this.$message.error(res.data.message);
          this.loadingRequest = false;
        })
        .finally(() => {
          setTimeout(() => {
            //   this.show = true;
            this.getMilesOrders();
            this.requestMilesTableData = this.listMilesToRequest().list;
            this.loadingRequest = false;
          }, 300);
        });
    },
    confirmMilesEmission(request) {
      let theOrder = {};

      console.log(request);

      request.status = "Concluído";

      // object
      theOrder.id = request.id;
      theOrder.contract_id = this.tempContract.id;
      theOrder.user_id = this.$store.state.userData.id;
      theOrder.airline_id = request.airline.id;
      theOrder.miles_program_id = request.miles_program.id;
      theOrder.milescard_id = request.miles_card.id;
      theOrder.transaction_id = "";
      theOrder.flight_id = request.flight_id;
      theOrder.travellers = request.travellers;
      theOrder.miles_quantity = request.miles_quantity
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");

      theOrder.tax_value = request.raw.tax_value;
      theOrder.tax_currency = request.raw.tax_currency;
      theOrder.flight_value = request.raw.flight_value;
      theOrder.miles_cost = request.raw.miles_cost;
      theOrder.per_miles = request.raw.per_miles;

      theOrder.value = request.value
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");
      theOrder.status = "Concluído";

      theOrder.action = "confirm-miles-emission";
      theOrder.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.loadingMilesEmissionConfirmation = true;

      this.$http
        .post("/miles-card-order/update", theOrder)
        .then(() => {
          this.$message.success("Utilização das milhas confirmado!");
          this.loadingMilesEmissionConfirmation = false;

          let updateContract = {
            id: this.tempContract.id,
          };

          if (this.tempContract.flight_sections_rows != undefined) {
            JSON.parse(this.tempContract.flight_sections_rows).forEach(
              (flight) => {
                flight.airlines.forEach((airline) => {
                  if (
                    this.tempContract[
                      `flight_${flight.id}_airline_${airline.id}_miles_order_id`
                    ] == request.id
                  ) {
                    updateContract[
                      `flight_${flight.id}_airline_${airline.id}_status`
                    ] = "Concluído";

                    this.tempContract[
                      `flight_${flight.id}_airline_${airline.id}_status`
                    ] = "Concluído";
                  }
                });
              }
            );
          }

          updateContract.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/contract/update-multi-meta", updateContract)
            .then(() => {
              this.loadingRequest = false;
              this.$message.success("Dados do pedido atualizados no contrato!");
            })
            .catch(() => {
              this.loadingRequest = false;
            });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loadingMilesEmissionConfirmation = false;
        });
    },
    cancelMilesCardEmission(request, type) {
      let theOrder = {};

      if (type == "cancel-miles-order") {
        request.status = "Pedido Cancelado";
      } else {
        request.status = "Cancelado";
      }

      // object
      theOrder.id = request.id;
      theOrder.contract_id = this.tempContract.id;
      theOrder.user_id = this.$store.state.userData.id;
      theOrder.airline_id = request.airline.id;
      theOrder.miles_program_id = request.miles_program.id;
      theOrder.milescard_id = request.miles_card.id;
      theOrder.transaction_id = request.transaction_id;
      theOrder.flight_id = request.flight_id;
      theOrder.travellers = request.travellers;
      theOrder.miles_quantity = request.miles_quantity
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");
      theOrder.value = request.value
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");

      theOrder.tax_value = request.raw.tax_value;
      theOrder.tax_currency = request.raw.tax_currency;
      theOrder.flight_value = request.raw.flight_value;
      theOrder.miles_cost = request.raw.miles_cost;

      if (type == "cancel-miles-order") {
        theOrder.status = "Pedido Cancelado";
        theOrder.action = type;
      } else {
        theOrder.status = "Cancelado";
        theOrder.action = "cancel-miles-emission";
      }

      theOrder.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.loadingMilesEmissionCancelation = true;

      this.$http
        .post("/miles-card-order/update", theOrder)
        .then(({ data }) => {
          data;

          if (type == "cancel-miles-order") {
            this.$message.success("Pedido Cancelado com sucesso!");
          } else {
            this.$message.success("Pedido Cancelado com sucesso!");
          }

          this.loadingMilesEmissionCancelation = false;

          let updateContract = {
            id: this.tempContract.id,
          };

          if (this.tempContract.flight_sections_rows != undefined) {
            JSON.parse(this.tempContract.flight_sections_rows).forEach(
              (flight) => {
                flight.airlines.forEach((airline) => {
                  if (
                    this.tempContract[
                      `flight_${flight.id}_airline_${airline.id}_miles_order_id`
                    ] == request.id
                  ) {
                    updateContract[
                      `flight_${flight.id}_airline_${airline.id}_miles_order_id`
                    ] = "";

                    updateContract[
                      `flight_${flight.id}_airline_${airline.id}_status`
                    ] = "À Solicitar";

                    updateContract[`miles_order_id`] = "";

                    this.tempContract[
                      `flight_${flight.id}_airline_${airline.id}_miles_order_id`
                    ] = "";

                    this.tempContract[
                      `flight_${flight.id}_airline_${airline.id}_status`
                    ] = "À Solicitar";

                    this.tempContract[`miles_order_id`] = "";
                  }
                });
              }
            );
          }

          updateContract.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/contract/update-multi-meta", updateContract)
            .then(() => {
              this.loadingRequest = false;
              this.$message.success("Dados do pedido atualizados no contrato!");
            })
            .catch(() => {
              this.loadingRequest = false;
            });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loadingMilesEmissionCancelation = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.getMilesOrders();
            this.requestMilesTableData = this.listMilesToRequest().list;
          }, 300);
        });
    },
    checkMilesCardStatus() {
      let flag = false;

      if (this.milesCardOrder != undefined)
        if (
          this.milesCardOrder.status != "Cancelado" &&
          this.milesCardOrder.status != "Concluído"
        )
          flag = true;

      return flag;
    },
    getMilesCard(id) {
      this.$http
        .post("/miles-card/details", {
          id,
          action: "contract-view-miles-card",
          contract_id: this.tempContract.id,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        })
        .then(({ data }) => {
          this.milesCard = data;
          this.viewMilesCardDrawer = true;
          this.activeMilesCardTab = "card";
        });
    },
    viewMilesCard(id) {
      this.getMilesCard(id);
    },
    milesProgramName(airline_id, miles_program_id) {
      let name = "";

      if (this.milesRequestList != undefined) {
        this.milesRequestList.forEach((cia) => {
          if (cia.id == airline_id) {
            if (cia.miles_programs.length > 0) {
              cia.miles_programs.forEach((program) => {
                if (program.id == miles_program_id) name = program.name;
              });
            }
          }
        });
      }

      return name;
    },
    closeMilesCardDrawer() {
      this.viewMilesCardDrawer = false;
      this.editMilesCard = false;
      this.editMilesCardDrawer = false;
    },
    updateContract() {
      setTimeout(() => {
        // UPDATE CONTRACT FIELDS
        this.tempContract.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        this.$http.post("/contract/update", this.tempContract).then(() => {
          this.loadingRequest = false;
        });
      }, 80);
    },
  },
};
</script>
