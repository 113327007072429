<template>
  <div>
    <div
      v-for="(traveller, index) in travellersRows"
      :key="index"
      :class="`traveller traveller-${traveller.id}`"
    >
      <div class="product-card" @click="onClickOpenCollapse(traveller.id)">
        <a-row>
          <a-col :span="22">
            <a-row type="flex" justify="start" :gutter="20">
              <a-col class="id cprimary">
                <span class="ico">
                  <a-icon type="user" />
                  <font class="num">{{ traveller.id }} </font>
                </span>
              </a-col>
              <a-col class="id-name">
                <span class="traveller">{{ theTraveller(traveller.id) }}</span>

                <span class="meta f11">
                  <span
                    v-if="
                      tempContract[`flight_${flightSectionId}_type`] == 'Milhas'
                    "
                    >Milhas:
                    {{
                      tempContract[
                        `flight_${flightSectionId}_traveller_${traveller.id}_miles`
                      ]
                    }}</span
                  >

                  <span
                    class="ml-20"
                    v-if="
                      tempContract[`flight_${flightSectionId}_type`] == 'Milhas'
                    "
                    >Valor:
                    {{
                      tempContract[
                        `flight_${flightSectionId}_traveller_${traveller.id}_value`
                      ]
                    }}
                  </span>

                  <span
                    class="ml-20"
                    v-if="
                      tempContract[`flight_${flightSectionId}_type`] == 'Milhas'
                    "
                    >Taxas:
                    {{
                      tempContract[
                        `flight_${flightSectionId}_traveller_${traveller.id}_taxes`
                      ]
                    }}
                  </span>
                  <span
                    v-if="
                      tempContract[`flight_${flightSectionId}_type`] == 'Milhas'
                    "
                    class="ml-20 mr-20"
                  >
                    Moeda Tx:
                    {{
                      tempContract[
                        `flight_${flightSectionId}_traveller_${traveller.id}_taxes_currency`
                      ]
                    }}
                  </span>
                  <span>
                    Localizador:
                    {{
                      tempContract[
                        `flight_${flightSectionId}_traveller_${traveller.id}_locator`
                      ]
                    }}</span
                  ></span
                >
              </a-col>
            </a-row>
          </a-col>
          <a-col class="a-right" :span="2">
            <a-button
              v-if="traveller.id == travellersRows.length && traveller.id != 1"
              size="small"
              type="danger"
              shape="circle"
              icon="delete"
              @click="removeTraveller(flightSectionId, traveller.id)"
              ghost
            />
          </a-col>
        </a-row>
      </div>

      <div class="product-card-content" v-if="openCollapse == traveller.id">
        <a-row :gutter="20" class="travellers-rows">
          <a-col :span="6">
            <a-form-item class="travel-input">
              <label :class="'filled'">Viajante </label>
              <a-select
                placeholder="Selecione"
                :disabled="milesOrderExists"
                v-decorator="[
                  `flight_${flightSectionId}_traveller_${traveller.id}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(traveller, index) of travellers"
                  :key="index"
                  :value="traveller.id"
                >
                  {{ traveller.name }}
                  {{ traveller.lastName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col
            :span="4"
            v-if="tempContract[`flight_${flightSectionId}_type`] == 'Milhas'"
          >
            <a-form-item>
              <span class="travel-input">
                <label :class="'filled'">Milhas </label>
                <a-input
                  class="travel-input"
                  placeholder="Insira"
                  v-currency="{
                    currency: {
                      prefix: '',
                      suffix: '',
                    },
                    distractionFree: true,
                    valueAsInteger: false,
                    precision: 0,
                    autoDecimalMode: true,
                    allowNegative: false,
                  }"
                  v-decorator="[
                    `flight_${flightSectionId}_traveller_${traveller.id}_miles`,
                  ]"
                  :disabled="milesOrderExists"
                >
                </a-input>
              </span>
            </a-form-item>
          </a-col>

          <a-col
            v-if="tempContract[`flight_${flightSectionId}_type`] == 'Milhas'"
            :span="4"
          >
            <a-form-item>
              <span class="travel-input">
                <label :class="'filled'">Valor (R$) </label>
                <a-input
                  class="travel-input"
                  placeholder="Insira"
                  v-currency
                  @blur="calcFlightTotalValue(flightSectionId)"
                  v-decorator="[
                    `flight_${flightSectionId}_traveller_${traveller.id}_value`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </span>
            </a-form-item>
          </a-col>

          <a-col
            v-if="tempContract[`flight_${flightSectionId}_type`] == 'Milhas'"
            :span="3"
          >
            <a-form-item class="travel-input">
              <label :class="'filled'">Moeda Taxas </label>
              <a-select
                placeholder="Selecione"
                v-decorator="[
                  `flight_${flightSectionId}_traveller_${traveller.id}_taxes_currency`,
                  {
                    rules: [
                      {
                        required:
                          tempContract[`flight_${flightSectionId}_type`] ==
                          'Milhas'
                            ? true
                            : false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option value="BRL"> R$ </a-select-option>

                <a-select-option value="USD"> $ </a-select-option>

                <a-select-option value="EUR"> € </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col
            v-if="tempContract[`flight_${flightSectionId}_type`] == 'Milhas'"
            :span="3"
          >
            <a-form-item>
              <span class="travel-input">
                <label :class="'filled'"
                  >Taxas ({{
                    flightTaxesCurrency(flightSectionId, traveller.id)
                  }})
                </label>
                <a-input
                  class="travel-input"
                  placeholder="Insira"
                  v-currency="{
                    currency: flightTaxesCurrency(
                      flightSectionId,
                      traveller.id
                    ),
                  }"
                  @blur="calcFlightTotalValue(flightSectionId)"
                  v-decorator="[
                    `flight_${flightSectionId}_traveller_${traveller.id}_taxes`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item>
              <span class="travel-input">
                <label :class="'filled'">Localizador </label>
                <a-input
                  class="travel-input"
                  placeholder="Insira"
                  v-decorator="[
                    `flight_${flightSectionId}_traveller_${traveller.id}_locator`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </span>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";

export default {
  name: "FlightSectionTravellersCollapse",
  props: {
    flightSectionId: Number,
    tempContract: Object,
    form: Object,
    milesOrderExists: Boolean,
    travellersRows: Array,
    travellers: Array,
  },
  data() {
    return {
      openCollapse: -1,
    };
  },
  methods: {
    theTraveller(travellerId) {
      let theTraveller = "VIAJANTE",
        currentTraveller = this.travellers.filter((t) => {
          return (
            t.id ==
            this.tempContract[
              `flight_${this.flightSectionId}_traveller_${travellerId}`
            ]
          );
        });

      if (currentTraveller.length > 0)
        theTraveller = `${currentTraveller[0].name} ${currentTraveller[0].lastName}`;

      return theTraveller;
    },
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;

        setTimeout(() => {
          this.setFlightTravellerFields(id);
        }, 5);
      }
    },
    setFlightTravellerFields(id) {
      this.form.setFieldsValue({
        [`flight_${this.flightSectionId}_traveller_${id}`]:
          this.tempContract[`flight_${this.flightSectionId}_traveller_${id}`] !=
          undefined
            ? parseInt(
                this.tempContract[
                  `flight_${this.flightSectionId}_traveller_${id}`
                ]
              )
            : undefined,
        [`flight_${this.flightSectionId}_traveller_${id}_miles`]:
          this.tempContract[
            `flight_${this.flightSectionId}_traveller_${id}_miles`
          ],
        [`flight_${this.flightSectionId}_traveller_${id}_locator`]:
          this.tempContract[
            `flight_${this.flightSectionId}_traveller_${id}_locator`
          ],
        [`flight_${this.flightSectionId}_traveller_${id}_value`]:
          this.tempContract[
            `flight_${this.flightSectionId}_traveller_${id}_value`
          ],
        [`flight_${this.flightSectionId}_traveller_${id}_taxes_currency`]:
          this.tempContract[
            `flight_${this.flightSectionId}_traveller_${id}_taxes_currency`
          ],
        [`flight_${this.flightSectionId}_traveller_${id}_taxes`]:
          this.tempContract[
            `flight_${this.flightSectionId}_traveller_${id}_taxes`
          ],
      });
    },
    calcFlightTotalValue() {
      let totalValue = 0,
        flights =
          this.tempContract["flight_sections_rows"] != undefined
            ? JSON.parse(this.tempContract.flight_sections_rows)
            : [];

      if (flights.length > 0) {
        flights[this.flightSectionId - 1].travellers.forEach((traveller) => {
          let currency =
            this.tempContract[
              `flight_${this.flightSectionId}_traveller_${traveller.id}_taxes_currency`
            ] != undefined
              ? this.tempContract[
                  `flight_${this.flightSectionId}_traveller_${traveller.id}_taxes_currency`
                ]
              : "BRL";

          if (
            this.tempContract[
              `flight_${this.flightSectionId}_traveller_${traveller.id}_value`
            ]
          ) {
            totalValue += parse(
              this.tempContract[
                `flight_${this.flightSectionId}_traveller_${traveller.id}_value`
              ]
            );
          } else {
            totalValue += 0;
          }

          if (currency == "BRL") {
            if (
              this.tempContract[
                `flight_${this.flightSectionId}_traveller_${traveller.id}_taxes`
              ]
            ) {
              totalValue += parse(
                this.tempContract[
                  `flight_${this.flightSectionId}_traveller_${traveller.id}_taxes`
                ]
              );
            } else {
              totalValue += 0;
            }
          } else {
            if (
              this.tempContract[
                `flight_${this.flightSectionId}_traveller_${traveller.id}_taxes`
              ]
            ) {
              let exchange = this.tempContract[`voucher_payments_exchange`];

              if (exchange != undefined) {
                totalValue +=
                  exchange.replace(",", ".") *
                  parse(
                    this.tempContract[
                      `flight_${this.flightSectionId}_traveller_${traveller.id}_taxes`
                    ]
                  );
              } else {
                totalValue += 0;
              }
            } else {
              totalValue += 0;
            }
          }
        });
      }

      this.form.setFieldsValue({
        [`flight_${this.flightSectionId}_total_price`]: `${totalValue
          .toFixed(2)
          .replace(".", ",")}`,
      });
    },
    removeTraveller(flightSectionId, id) {
      this.$emit("removeTraveller", flightSectionId, id);
    },
    flightTaxesCurrency(flightId, travellerId) {
      let theCurrency =
        this.tempContract[
          `flight_${flightId}_traveller_${travellerId}_taxes_currency`
        ];

      if (!theCurrency && theCurrency == undefined) theCurrency = "BRL";

      return theCurrency;
    },
  },
};
</script>

<style lang="sass" scoped>
.product-card-content
  padding: 20px 15px 0px
  border: 1px solid #ddd
  margin-top: -14px
  border-radius: 0 0 6px 6px
  margin-bottom: 11px
  background: #FFF
.product-card
  cursor: pointer
  clear: both
  padding: 10px 20px
  background: #fff
  border: 1px solid #ddd
  margin-bottom: 10px
  border-radius: 6px
  line-height: 1
  .id
    color: #474747
    font-weight: 600
    .ico
      position: relative
    .num
      position: absolute
      right: -13px
      top: 0
      font-size: 11px
      color: #333
  .id-name
    padding-left: 20px !important
    color: #42413f
    font-weight: 500
  .traveller
    display: block
  .meta
    margin-top: 7px
    color: #999
    display: block
</style>
