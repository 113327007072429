import { parse } from "vue-currency-input";

export default {
  data() {
    return {
      performFraudAnalysis: true,
    };
  },
  methods: {
    onChangePerformFraudAnalysis(val) {
      this.tempContract[
        `payment_methods_payment_link_perform_fraud_analysis_${this.paymentLinkId}`
      ] = val.target.checked;
    },
    setPaymentLinkFieldsValue() {
      this.form.setFieldsValue({
        [`payment_methods_payment_link_total_value_${this.paymentLinkId}`]:
          this.tempContract[
            `payment_methods_payment_link_total_value_${this.paymentLinkId}`
          ],
        [`payment_methods_payment_link_installments_${this.paymentLinkId}`]:
          this.tempContract[
            `payment_methods_payment_link_installments_${this.paymentLinkId}`
          ]
            ? parseInt(
                this.tempContract[
                  `payment_methods_payment_link_installments_${this.paymentLinkId}`
                ]
              )
            : undefined,
        [`payment_methods_payment_link_first_installment_${this.paymentLinkId}`]:
          this.tempContract[
            `payment_methods_payment_link_first_installment_${this.paymentLinkId}`
          ],
        [`payment_methods_payment_link_installments_value_${this.paymentLinkId}`]:
          this.tempContract[
            `payment_methods_payment_link_installments_value_${this.paymentLinkId}`
          ],
        [`payment_methods_payment_link_advance_fee_value_${this.paymentLinkId}`]:
          this.tempContract[
            `payment_methods_payment_link_advance_fee_value_${this.paymentLinkId}`
          ],
        [`payment_methods_payment_link_tax_value_${this.paymentLinkId}`]:
          this.tempContract[
            `payment_methods_payment_link_tax_value_${this.paymentLinkId}`
          ],
        [`payment_methods_payment_link_total_taxes_${this.paymentLinkId}`]:
          this.tempContract[
            `payment_methods_payment_link_total_taxes_${this.paymentLinkId}`
          ],
      });

      let performFraudAnalysis =
        this.tempContract[
          `payment_methods_payment_link_perform_fraud_analysis_${this.paymentLinkId}`
        ];

      if (performFraudAnalysis === undefined) {
        this.performFraudAnalysis = true;
      } else {
        this.performFraudAnalysis = performFraudAnalysis == 1 ? true : false;
      }
    },
    updatePaymentLinkValue() {
      this.tempContract[
        `payment_methods_payment_link_installments_${this.paymentLinkId}`
      ] = "";
      this.tempContract[
        `payment_methods_payment_link_first_installment_${this.paymentLinkId}`
      ] = "";
      this.tempContract[
        `payment_methods_payment_link_installments_value_${this.paymentLinkId}`
      ] = "";

      this.form.setFieldsValue({
        [`payment_methods_payment_link_total_value_${this.paymentLinkId}`]: "",
        [`payment_methods_payment_link_installments_${this.paymentLinkId}`]:
          undefined,
        [`payment_methods_payment_link_first_installment_${this.paymentLinkId}`]:
          "",
        [`payment_methods_payment_link_installments_value_${this.paymentLinkId}`]:
          "",
      });

      setTimeout(() => {
        this.$emit("updatePaymentLinkValue");
      }, 50);
    },
    onBlurVerifyPaymentLinkValue(e) {
      if (
        parse(e.target.value) >
        parseFloat(this.tempContract.contract_total_value.toFixed(2))
      ) {
        setTimeout(() => {
          this.form.setFieldsValue({
            [`payment_methods_payment_link_total_value_${this.paymentLinkId}`]:
              undefined,
            [`payment_methods_payment_link_tax_value_${this.paymentLinkId}`]:
              undefined,
            [`payment_methods_payment_link_advance_fee_value_${this.paymentLinkId}`]:
              undefined,
            [`payment_methods_payment_link_total_taxes_${this.paymentLinkId}`]:
              undefined,
          });
        }, 100);

        this.$message.warning(
          "Atenção: O valor do Link de Pagamento não pode ser maior do que o valor do contrato."
        );
      }

      this.$emit("updatePaymentLinkValue");
    },
    onChangePaymentLinkInstallments(installments) {
      let value = this.tempContract[
          `payment_methods_payment_link_total_value_${this.paymentLinkId}`
        ]
          ? parse(
              this.tempContract[
                `payment_methods_payment_link_total_value_${this.paymentLinkId}`
              ]
            )
          : 0,
        installmentsValue = 0;

      installmentsValue = value / installments;

      setTimeout(() => {
        this.form.setFieldsValue({
          [`payment_methods_payment_link_first_installment_${this.paymentLinkId}`]:
            this.parseFloatToStr(installmentsValue),
        });

        setTimeout(() => {
          this.form.setFieldsValue({
            [`payment_methods_payment_link_installments_value_${this.paymentLinkId}`]:
              this.parseFloatToStr(installmentsValue),
          });
        });
      }, 50);

      this.calcMachineTaxes();
    },
    updateOtherInstallments(firstInstallment) {
      let theFirstInstallment = firstInstallment.target._value
          ? parse(firstInstallment.target._value)
          : 0,
        value = parse(
          this.tempContract[
            `payment_methods_payment_link_total_value_${this.paymentLinkId}`
          ]
        ),
        installments = parseInt(
          this.tempContract[
            `payment_methods_payment_link_installments_${this.paymentLinkId}`
          ]
        ),
        newInstallments = 0;

      if (theFirstInstallment)
        newInstallments = (value - theFirstInstallment) / installments;

      this.form.setFieldsValue({
        payment_methods_payment_link_installments_value: newInstallments,
      });
    },
    calcMachineTaxes() {
      const paymentLinkValue = parse(
        this.tempContract[
          `payment_methods_payment_link_total_value_${this.paymentLinkId}`
        ]
      );
      const installments = this.tempContract[
        `payment_methods_payment_link_installments_${this.paymentLinkId}`
      ]
        ? parseInt(
            this.tempContract[
              `payment_methods_payment_link_installments_${this.paymentLinkId}`
            ]
          )
        : 0;
      const taxesRange =
        this.tempContract[
          `payment_methods_payment_link_taxes_range_${this.paymentLinkId}`
        ];
      const advanceFeeRange =
        this.tempContract[
          `payment_methods_payment_link_advance_fee_range_${this.paymentLinkId}`
        ];

      setTimeout(() => {
        if (paymentLinkValue) {
          const { totalAdvanceFee } = this.calcAdvancedFee(
            advanceFeeRange,
            paymentLinkValue,
            installments
          );
          const { totalTax } = this.calcTaxes(
            taxesRange,
            paymentLinkValue,
            installments
          );

          this.form.setFieldsValue({
            [`payment_methods_payment_link_total_with_tax_percentage_discount_${this.paymentLinkId}`]:
              (paymentLinkValue - (totalTax + totalAdvanceFee))
                .toFixed(2)
                .replace(".", ","),
            [`payment_methods_payment_link_total_taxes_${this.paymentLinkId}`]:
              (totalTax + totalAdvanceFee).toFixed(2).replace(".", ","),
          });

          const monthlyInstallments = [];
          for (let i = 1; i < installments + 1; i++) {
            monthlyInstallments.push(paymentLinkValue / installments);
          }
        }
      }, 200);
    },
    calcAdvancedFee(advanceFeeRange, paymentLinkValue, installments) {
      let selectedAdvanceFee = "";
      let totalAdvanceFee = 0;
      let totalPercentage = 0;

      if (advanceFeeRange && advanceFeeRange != "[]") {
        advanceFeeRange = JSON.parse(advanceFeeRange);
        if (advanceFeeRange.length > 0) {
          selectedAdvanceFee = advanceFeeRange.filter((e) => {
            return e.id == installments;
          });
          let thePercentage = selectedAdvanceFee[0].value
            .replace("%", "")
            .replace(",", ".");

          let theTaxPercentageDiscount = 0;
          totalPercentage = parseFloat(thePercentage);
          theTaxPercentageDiscount = (totalPercentage / 100) * paymentLinkValue;

          this.form.setFieldsValue({
            [`payment_methods_payment_link_advance_fee_percentage_${this.paymentLinkId}`]:
              totalPercentage,
            [`payment_methods_payment_link_advance_fee_value_${this.paymentLinkId}`]:
              theTaxPercentageDiscount.toFixed(2).replace(".", ","),
          });

          totalAdvanceFee = theTaxPercentageDiscount;
        }
      } else {
        this.form.setFieldsValue({
          [`payment_methods_payment_link_advance_fee_percentage_${this.paymentLinkId}`]: 0,
          [`payment_methods_payment_link_advance_fee_value_${this.paymentLinkId}`]: 0,
        });
      }

      return { totalAdvanceFee };
    },
    calcTaxes(taxesRange, paymentLinkValue, installments) {
      let selectedTax = "";
      let totalTax = 0;
      let totalPercentage = 0;
      let theTaxPercentageDiscount = 0;

      if (taxesRange && taxesRange != "[]") {
        taxesRange = JSON.parse(taxesRange);
        if (taxesRange.length > 0) {
          selectedTax = taxesRange.filter((e) => {
            return e.id == installments;
          });
          let thePercentage = selectedTax[0].value
            .replace("%", "")
            .replace(",", ".");

          totalPercentage = parseFloat(thePercentage);
          theTaxPercentageDiscount = (totalPercentage / 100) * paymentLinkValue;

          this.form.setFieldsValue({
            [`payment_methods_payment_link_tax_percentage_${this.paymentLinkId}`]:
              totalPercentage,
            [`payment_methods_payment_link_tax_value_${this.paymentLinkId}`]:
              theTaxPercentageDiscount.toFixed(2).replace(".", ","),
          });

          totalTax = theTaxPercentageDiscount;
        }
      } else {
        this.form.setFieldsValue({
          [`payment_methods_payment_link_tax_percentage_${this.paymentLinkId}`]: 0,
          [`payment_methods_payment_link_tax_value_${this.paymentLinkId}`]: 0,
        });
      }

      return { totalTax };
    },
  },
};
