<template>
  <div>
    <a-row :gutter="20">
      <a-col :span="12">
        <a-form-item>
          <label class="filled">Valor total do pagamento</label>
          <a-input
            class="travel-input"
            placeholder="Valor total do pagamento"
            v-currency
            @keyup="verifyTotal"
            v-decorator="[
              `payment_methods_custom_payment_total_value`,
              {
                rules: [
                  {
                    required: dynamicRequiredFieldCustomPayment(),
                    message: 'Preencha todos números.',
                  },
                  {
                    validator: verifyValue,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item>
          <label class="filled">Nome da forma de Pagamento</label>
          <a-input
            class="travel-input"
            placeholder="Nome da forma de Pagamento"
            v-decorator="[
              `payment_methods_custom_payment_name`,
              {
                rules: [
                  {
                    required: dynamicRequiredFieldCustomPayment(),
                    message: 'Preencha o nome da forma de pagamento.',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="textarea">
          <label class="filled">Observações (opcional)</label>

          <a-input
            class="travel-textarea"
            placeholder="Observações (opcional)"
            type="textarea"
            v-decorator="[`payment_methods_custom_payment_observation`]"
          >
          </a-input>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";

export default {
  name: "CustomPaymentTabV1",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    reservation_total_price: Number,
  },
  mixins: [formatThings],
  data() {
    return {
      paymentMethods: undefined,
    };
  },
  mounted() {
    this.paymentMethods = this.tempContract["payment_methods"]
      ? JSON.parse(this.tempContract["payment_methods"])
      : undefined;

    this.form.setFieldsValue({
      payment_methods_custom_payment_total_value:
        this.tempContract.payment_methods_custom_payment_total_value,
      payment_methods_custom_payment_name:
        this.tempContract.payment_methods_custom_payment_name,
      payment_methods_custom_payment_observation:
        this.tempContract.payment_methods_custom_payment_observation,
    });

    this.$emit("verifyTotal");
  },
  methods: {
    verifyTotal() {
      this.$emit("verifyTotal");
    },
    dynamicRequiredFieldCustomPayment() {
      let flag = this.paymentMethods;

      if (flag != undefined) {
        flag = this.paymentMethods.includes("custom-payment");
      } else {
        flag = false;
      }

      return flag;
    },
  },
};
</script>

<style lang="sass" scoped></style>
