<template>
  <div>
    <a-form-item class="none">
      <a-input
        v-decorator="[
          `credit_card_rows`,
          {
            initialValue: JSON.stringify(credit_card_rows),
          },
        ]"
      >
      </a-input>
    </a-form-item>

    <a-row>
      <a-col
        class="credit-card"
        v-for="card in credit_card_rows"
        :key="card.id"
        :span="24"
      >
        <div class="head" @click="onClickOpenCollapse(card.id)">
          <a-row :gutter="20">
            <a-col class="id cprimary" :span="1">
              <span class="ico">
                <img
                  src="@/assets/images/dashboard/contracts/credit-card.png"
                  width="20" alt
                />
                <span class="num">{{ card.id }} </span>
              </span>
            </a-col>

            <a-col class="id-name" :span="11">
              {{ creditCardOwner(card.id) }}
            </a-col>

            <a-col class="value" :span="9">
              <a-tag :color="'blue'">
                {{
                  formatPricePtBr(
                    tempContract[
                      `payment_methods_credit_card_total_value_${card.id}`
                    ]
                  )
                }}
              </a-tag>
            </a-col>

            <a-col class="a-right" :span="3">
              <a-button
                class="duplicate"
                size="small"
                shape="circle"
                type="danger"
                icon="copy"
                ghost
                @click="duplicateCreditCard(card.id)"
              >
              </a-button>

              <a-button
                class="ml-5"
                v-if="card.id > 1 && credit_card_rows.length == card.id"
                size="small"
                type="danger"
                shape="circle"
                icon="delete"
                @click="removeCreditCard(card.id - 1)"
                ghost
              >
              </a-button>
            </a-col>
          </a-row>
        </div>

        <div class="content" v-if="openCollapse == card.id">
          <CreditCardCollapse
            :contract="contract"
            :tempContract="tempContract"
            :form="form"
            :customerForm="customerForm"
            :cardID="card.id"
            :travellers="travellers"
            @creditCardTotal="creditCardTotal"
          />
        </div>
      </a-col>
    </a-row>

    <div class="a-center mb-40 mt-20">
      <a-button
        type="primary"
        @click="addCreditCard(credit_card_rows.length)"
        ghost
      >
        Adicionar cartão
      </a-button>
    </div>
  </div>
</template>

<script>
import CreditCardCollapse from "../collapses/CreditCardCollapse.vue";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "CreditCardTab",
  components: { CreditCardCollapse },
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    customerForm: Object,
    travellers: Array,
  },
  mixins: [formatThings],
  data() {
    return {
      credit_card_rows: [],
      openCollapse: 0,
    };
  },
  mounted() {
    if (this.tempContract["credit_card_rows"]) {
      this.credit_card_rows = JSON.parse(this.tempContract["credit_card_rows"]);
    }
  },
  methods: {
    creditCardTotal(val) {
      this.$emit("creditCardTotal", val);
      this.$emit("verifyTotal");
    },
    creditCardOwner(id) {
      let theName = `NOME`;

      if (
        this.tempContract[`payment_methods_credit_card_owner_${id}`] !=
        undefined
      ) {
        if (
          this.tempContract[`payment_methods_credit_card_owner_${id}`] ==
          "client"
        ) {
          //
          theName = `${this.tempContract[`the_customer`].first_name} ${
            this.tempContract[`the_customer`].last_name
          }`;
        } else {
          if (
            this.tempContract[`payment_methods_credit_card_owner_${id}_name`] !=
            undefined
          ) {
            theName = `${
              this.tempContract[`payment_methods_credit_card_owner_${id}_name`]
            } `;
          }
        }
      }

      return theName;
    },
    duplicateCreditCard(id) {
      id;
      this.credit_card_rows.push({
        id: this.credit_card_rows.length + 1,
      });

      this.tempContract["credit_card_rows"] = JSON.stringify(
        this.credit_card_rows
      );

      setTimeout(() => {
        this.tempContract[
          `payment_methods_credit_card_owner_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_owner_${id}`];

        this.tempContract[
          `payment_methods_credit_card_total_value_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_total_value_${id}`];

        this.tempContract[
          `payment_methods_credit_card_total_with_tax_percentage_discount_${this.credit_card_rows.length}`
        ] =
          this.tempContract[
            `payment_methods_credit_card_total_with_tax_percentage_discount_${id}`
          ];

        this.tempContract[
          `payment_methods_credit_card_advance_fee_value_${this.credit_card_rows.length}`
        ] =
          this.tempContract[
            `payment_methods_credit_card_advance_fee_value_${id}`
          ];

        this.tempContract[
          `payment_methods_credit_card_total_tax_discount_${this.credit_card_rows.length}`
        ] =
          this.tempContract[
            `payment_methods_credit_card_total_tax_discount_${id}`
          ];

        this.tempContract[
          `payment_methods_credit_card_machine_id_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_machine_id_${id}`];
        this.tempContract[
          `payment_methods_credit_card_installments_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_installments_${id}`];
        this.tempContract[
          `payment_methods_credit_card_first_installment_value_${this.credit_card_rows.length}`
        ] =
          this.tempContract[
            `payment_methods_credit_card_first_installment_value_${id}`
          ];
        this.tempContract[
          `payment_methods_credit_card_installments_value_${this.credit_card_rows.length}`
        ] =
          this.tempContract[
            `payment_methods_credit_card_installments_value_${id}`
          ];
        this.tempContract[
          `payment_methods_credit_card_tax_value_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_tax_value_${id}`];

        this.tempContract[
          `payment_methods_credit_card_masked_name_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_masked_name_${id}`];
        this.tempContract[
          `payment_methods_credit_card_masked_number_${this.credit_card_rows.length}`
        ] =
          this.tempContract[`payment_methods_credit_card_masked_number_${id}`];

        this.tempContract[
          `payment_methods_credit_card_masked_flag_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_masked_flag_${id}`];

        this.tempContract[
          `payment_methods_credit_card_masked_month_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_masked_month_${id}`];
        this.tempContract[
          `payment_methods_credit_card_masked_year_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_masked_year_${id}`];
        this.tempContract[
          `payment_methods_credit_card_masked_cvv_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_masked_cvv_${id}`];

        this.tempContract[
          `payment_methods_credit_card_machine_supplier_id_${this.credit_card_rows.length}`
        ] =
          this.tempContract[
            `payment_methods_credit_card_machine_supplier_id_${id}`
          ];

        this.tempContract[
          `payment_methods_credit_card_obs_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_obs_${id}`];
        this.tempContract[
          `payment_methods_credit_card_have_cvv_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_have_cvv_${id}`];

        this.tempContract[
          `payment_methods_credit_card_masked_name_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_masked_name_${id}`];

        this.tempContract[
          `payment_methods_credit_card_masked_number_${this.credit_card_rows.length}`
        ] =
          this.tempContract[`payment_methods_credit_card_masked_number_${id}`];

        this.tempContract[
          `payment_methods_credit_card_masked_month_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_masked_month_${id}`];

        this.tempContract[
          `payment_methods_credit_card_masked_year_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_masked_year_${id}`];

        this.tempContract[
          `payment_methods_credit_card_masked_cvv_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_masked_cvv_${id}`];

        this.tempContract[
          `payment_methods_credit_card_masked_flag_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_masked_flag_${id}`];

        ////////////////////////////////

        this.tempContract[
          `payment_methods_credit_card_client_name_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_client_name_${id}`];

        this.tempContract[
          `payment_methods_credit_card_client_number_${this.credit_card_rows.length}`
        ] =
          this.tempContract[`payment_methods_credit_card_client_number_${id}`];

        this.tempContract[
          `payment_methods_credit_card_client_flag_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_client_flag_${id}`];

        this.tempContract[
          `payment_methods_credit_card_client_month_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_client_month_${id}`];

        this.tempContract[
          `payment_methods_credit_card_client_year_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_client_year_${id}`];

        this.tempContract[
          `payment_methods_credit_card_client_cvv_${this.credit_card_rows.length}`
        ] = this.tempContract[`payment_methods_credit_card_client_cvv_${id}`];

        ////////////////////////////////

        if (
          this.tempContract[`payment_methods_credit_card_owner_${id}`] ==
          "credit-card-owner"
        ) {
          this.tempContract[
            `payment_methods_credit_card_owner_${this.credit_card_rows.length}_name`
          ] = this.tempContract[`payment_methods_credit_card_owner_${id}_name`];

          this.tempContract[
            `payment_methods_credit_card_owner_${this.credit_card_rows.length}_CPF`
          ] = this.tempContract[`payment_methods_credit_card_owner_${id}_CPF`];

          this.tempContract[
            `payment_methods_credit_card_owner_${this.credit_card_rows.length}_mobile_phone`
          ] =
            this.tempContract[
              `payment_methods_credit_card_owner_${id}_mobile_phone`
            ];

          this.tempContract[
            `payment_methods_credit_card_owner_${this.credit_card_rows.length}_phone`
          ] =
            this.tempContract[`payment_methods_credit_card_owner_${id}_phone`];

          this.tempContract[
            `payment_methods_credit_card_owner_${this.credit_card_rows.length}_CEP`
          ] = this.tempContract[`payment_methods_credit_card_owner_${id}_CEP`];

          this.tempContract[
            `payment_methods_credit_card_owner_${this.credit_card_rows.length}_location_street`
          ] =
            this.tempContract[
              `payment_methods_credit_card_owner_${id}_location_street`
            ];

          this.tempContract[
            `payment_methods_credit_card_owner_${this.credit_card_rows.length}_location_number`
          ] =
            this.tempContract[
              `payment_methods_credit_card_owner_${id}_location_number`
            ];

          this.tempContract[
            `payment_methods_credit_card_owner_${this.credit_card_rows.length}_location_complement`
          ] =
            this.tempContract[
              `payment_methods_credit_card_owner_${id}_location_complement`
            ];

          this.tempContract[
            `payment_methods_credit_card_owner_${this.credit_card_rows.length}_location_neighborhood`
          ] =
            this.tempContract[
              `payment_methods_credit_card_owner_${id}_location_neighborhood`
            ];

          this.tempContract[
            `payment_methods_credit_card_owner_${this.credit_card_rows.length}_location_city`
          ] =
            this.tempContract[
              `payment_methods_credit_card_owner_${id}_location_city`
            ];

          this.tempContract[
            `payment_methods_credit_card_owner_${this.credit_card_rows.length}_location_state`
          ] =
            this.tempContract[
              `payment_methods_credit_card_owner_${id}_location_state`
            ];
        }

        this.$emit("creditCardTotal", true);

        this.openCollapse = 0;
      }, 20);
    },
    addCreditCard(id) {
      this.credit_card_rows.push({
        id: id + 1,
      });

      this.tempContract["credit_card_rows"] = JSON.stringify(
        this.credit_card_rows
      );

      this.$emit("creditCardTotal", true);
    },
    removeCreditCard(index) {
      for (var i in this.credit_card_rows) {
        if (i == index) {
          this.credit_card_rows.splice(i, 1);
          break;
        }
      }

      this.tempContract["credit_card_rows"] = JSON.stringify(
        this.credit_card_rows
      );

      this.$emit("creditCardTotal", true);
    },
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.ant-btn-danger
  border:  0 !important
  font-size: 17px !important
  box-shadow: none !important
.duplicate
  border:  0 !important
  color: #4B8AD6 !important
  box-shadow: none !important
  font-size: 17px !important
.credit-card
  .content
    padding: 20px 20px 0px
    border: 1px solid #ccc
    margin-top: -14px
    border-radius: 0 0 6px 6px
    margin-bottom: 11px
    background: #fff
  .head
    cursor: pointer
    clear: both
    padding:  20px
    background: #fff
    border: 1px solid #ccc
    margin-bottom: 10px
    border-radius: 6px
    .id
      color: #474747
      font-weight: 600
      .ico
        position: relative
      .num
        position: absolute
        right: -13px
        top: 0
        font-size: 11px
        color: #333
    .id-name
      padding-left: 20px !important
      color: #42413f
      font-weight: 500
      text-transform: uppercase
    .value
      font-size: 10px
      color: #42413f
      font-weight: 500
      text-transform: uppercase
</style>
