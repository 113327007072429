<template>
  <div>
    <a-row :gutter="20">
      <a-col :span="12">
        <a-form-item>
          <label class="filled">Valor total do pagamento</label>
          <a-input
            class="travel-input"
            placeholder="Valor total do pagamento"
            v-currency
            @keyup="updateCustomPaymentValue"
            v-decorator="[
              `payment_methods_custom_payment_total_value_${customPaymentId}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha todos números.',
                  },
                  {
                    validator: verifyValue,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item>
          <label class="filled">Nome da forma de Pagamento</label>
          <a-input
            class="travel-input"
            placeholder="Nome da forma de Pagamento"
            v-decorator="[
              `payment_methods_custom_payment_name_${customPaymentId}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha o nome da forma de pagamento.',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="textarea">
          <label class="filled">Observações (opcional)</label>

          <a-input
            class="travel-textarea"
            placeholder="Observações (opcional)"
            type="textarea"
            v-decorator="[
              `payment_methods_custom_payment_observation_${customPaymentId}`,
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";

export default {
  name: "CustomPaymentCollapse",
  props: {
    form: Object,
    customPaymentId: Number,
    tempContract: Object,
  },
  data() {
    return {
      banksList: [],
    };
  },
  mounted() {
    this.form.setFieldsValue({
      [`payment_methods_custom_payment_total_value_${this.customPaymentId}`]:
        this.tempContract[
          `payment_methods_custom_payment_total_value_${this.customPaymentId}`
        ],
      [`payment_methods_custom_payment_name_${this.customPaymentId}`]:
        this.tempContract[
          `payment_methods_custom_payment_name_${this.customPaymentId}`
        ],
      [`payment_methods_custom_payment_observation_${this.customPaymentId}`]:
        this.tempContract[
          `payment_methods_custom_payment_observation_${this.customPaymentId}`
        ],
    });

    this.$emit("updateCustomPaymentValue");
  },
  methods: {
    updateCustomPaymentValue() {
      this.$emit("updateCustomPaymentValue");
    },
    async verifyValue(rule, value, callback) {
      rule;
      let theValue = parse(value),
        totalValue = parseFloat(this.reservation_total_price);
      if (isNaN(theValue) || theValue == undefined) {
        theValue = 0;
      } else {
        if (parse(value) > totalValue) {
          callback(
            `O valor preenchido ultrapassa o total da reserva (R$ ${this.reservation_total_price}).`
          );
        } else {
          callback();
        }
      }
    },
  },
};
</script>
