import { parse } from "vue-currency-input";

export default {
  data() {
    return {
      totalValue: {
        status: false,
        total: 0,
      },
      paymentMethodsOptions: [
        {
          label: "Boleto",
          value: "billing-ticket",
          show: false,
        },
        {
          label: "Carta Crédito",
          value: "payment-card",
          show: false,
        },
        {
          label: "Cartão Crédito",
          value: "credit-card",
          show: false,
        },
        {
          label: "Link Pag.",
          value: "payment-link",
          show: this.$root.isAdmin() ? false : true,
        },
        {
          label: "Personalizado",
          value: "custom-payment",
          show: this.$root.isAdmin() ? false : true,
        },
        {
          label: "Transf. Banc.",
          value: "bank-transfer",
          show: false,
        },
        {
          label: "Terceiros",
          value: "third-party",
          show: false,
        },
      ],
    };
  },
  filters: {
    formatPrice: function (price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString()}`;
    },
  },
  methods: {
    async verifyPaymentCardProfitValue(rule, value, callback) {
      rule;
      let theValue = parseFloat(value);
      if (isNaN(theValue) || theValue == undefined) {
        theValue = 0;
      } else {
        if (parseFloat(value) < 1000) {
          callback(
            `O valor da lucratividade está baixo, insira um valor maior que R$ 1.000,00.`
          );
        } else {
          callback();
        }
      }
    },
    formatPrice(price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString()}`;
    },
    onPaymentChange(paymentMethods) {
      setTimeout(() => {
        this.updatePaymentMethods = paymentMethods;
        this.paymentMethods = paymentMethods;

        this.activeTab = this.paymentMethods.slice(-1)[0];

        this.tempContract["payment_methods"] = JSON.stringify(paymentMethods);

        if (paymentMethods.includes("third-party")) {
          if (this.third_party_payment_rows.length == 0) {
            this.third_party_payment_rows.push({
              id: 1,
            });
          }
          this.tempContract["third_party_payment_rows"] = JSON.stringify(
            this.third_party_payment_rows
          );
        }

        this.verifyTotal(paymentMethods);
      }, 30);
    },
    paymentLinkTotal() {
      let total = 0;

      total += parse(
        this.tempContract[`payment_methods_payment_link_total_value`]
      );
      if (isNaN(total) || total == undefined || !total) {
        total = 0;
      }

      return this.formatPrice(total);
    },
    paymentCardTotal() {
      let total = 0,
        totalProfitDiscount = 0,
        paymentCardRows = this.tempContract["payment_card_rows"]
          ? JSON.parse(this.tempContract["payment_card_rows"])
          : [];

      if (this.contractId > 2786 || this.contractId == 2294) {
        if (paymentCardRows.length > 0) {
          paymentCardRows.forEach((pCard) => {
            total += parse(
              this.tempContract[
                `payment_methods_payment_card_total_value_${pCard.id}`
              ]
                ? this.tempContract[
                    `payment_methods_payment_card_total_value_${pCard.id}`
                  ]
                : 0
            );

            totalProfitDiscount += parse(
              this.tempContract[
                `payment_methods_payment_card_contract_profit_discount_value_${pCard.id}`
              ]
                ? this.tempContract[
                    `payment_methods_payment_card_contract_profit_discount_value_${pCard.id}`
                  ]
                : 0
            );
          });

          if (isNaN(total) || total == undefined || !total) {
            total = 0;
          }

          this.tempContract["payment_methods_payment_card_total_value"] =
            `${total}`.replace(".", ",");
          this.tempContract[
            "payment_methods_payment_card_contract_profit_discount_value"
          ] = `${totalProfitDiscount}`.replace(".", ",");
        }
      } else {
        total = this.tempContract[`payment_methods_payment_card_total_value`]
          ? parse(this.tempContract[`payment_methods_payment_card_total_value`])
          : 0;
      }

      return this.formatPrice(total);
    },
    creditCardTotal() {
      let total = 0,
        creditCardRows = this.tempContract["credit_card_rows"]
          ? JSON.parse(this.tempContract["credit_card_rows"])
          : [];

      if (creditCardRows.length > 0) {
        creditCardRows.forEach((card) => {
          total += parse(
            this.tempContract[
              `payment_methods_credit_card_total_value_${card.id}`
            ]
          );
        });
        if (isNaN(total) || total == undefined || !total) {
          total = 0;
        }
      }

      return this.formatPrice(total);
    },
    thirdPartyTotal() {
      let thirdParty = 0;

      this.third_party_payment_rows.forEach((person) => {
        thirdParty += parseFloat(
          this.form.getFieldValue(`third_party_client_paid_value_${person.id}`)
        );

        if (isNaN(thirdParty) || thirdParty == undefined) {
          thirdParty = 0;
        }
      });

      if (isNaN(thirdParty) || thirdParty == undefined) {
        thirdParty = 0;
      }

      return this.formatPrice(thirdParty);
    },
    verifyTotal() {
      const paymentMethods = this.tempContract["payment_methods"]
        ? JSON.parse(this.tempContract["payment_methods"])
        : [];

      if (paymentMethods.length > 0) {
        this.paymentCardTotal();

        let creditCard = 0,
          bankTransfer = 0,
          billingTicket = 0,
          paymentCard = 0,
          paymentLink = 0,
          customPayment = 0,
          creditCardRows = this.tempContract["credit_card_rows"]
            ? JSON.parse(this.tempContract["credit_card_rows"])
            : [];

        if (paymentMethods.includes("credit-card")) {
          if (creditCardRows.length > 0) {
            creditCardRows.forEach((card) => {
              creditCard += parse(
                this.tempContract[
                  `payment_methods_credit_card_total_value_${card.id}`
                ]
              );
            });

            if (isNaN(creditCard) || creditCard == undefined || !creditCard) {
              creditCard = 0;
            }
          }
        }

        if (this.paymentMethods.includes("bank-transfer")) {
          bankTransfer = this.tempContract[
            `payment_methods_bank_transfer_total_value`
          ]
            ? parse(
                this.tempContract[`payment_methods_bank_transfer_total_value`]
              )
            : 0;

          if (
            isNaN(bankTransfer) ||
            bankTransfer == undefined ||
            !bankTransfer
          ) {
            bankTransfer = 0;
          }
        }

        if (this.paymentMethods.includes("billing-ticket")) {
          billingTicket = this.tempContract[
            `payment_methods_billing_ticket_total_value`
          ]
            ? parse(
                this.tempContract[`payment_methods_billing_ticket_total_value`]
              )
            : 0;

          if (
            isNaN(billingTicket) ||
            billingTicket == undefined ||
            !billingTicket
          ) {
            billingTicket = 0;
          }
        }

        if (this.paymentMethods.includes("custom-payment")) {
          customPayment =
            this.tempContract[`payment_methods_custom_payment_total_value`];

          customPayment = parse(customPayment);

          if (
            isNaN(customPayment) ||
            customPayment == undefined ||
            !customPayment
          ) {
            customPayment = 0;
          }
        }

        if (this.paymentMethods.includes("payment-card")) {
          paymentCard = this.tempContract[
            `payment_methods_payment_card_total_value`
          ]
            ? parse(
                this.tempContract[`payment_methods_payment_card_total_value`]
              )
            : 0;

          if (isNaN(paymentCard) || paymentCard == undefined || !paymentCard) {
            paymentCard = 0;
          }
        }

        if (paymentMethods.includes("payment-link")) {
          paymentLink = this.tempContract[
            `payment_methods_payment_link_total_value`
          ]
            ? parse(
                this.tempContract[`payment_methods_payment_link_total_value`]
              )
            : 0;

          if (isNaN(paymentLink) || paymentLink == undefined || !paymentLink) {
            paymentLink = 0;
          }
        }

        let total =
          creditCard +
          bankTransfer +
          billingTicket +
          paymentCard +
          customPayment +
          paymentLink;

        if (
          parseFloat(this.reservation_total_price).toFixed(2) !=
          total.toFixed(2)
        ) {
          this.$emit("onPriceChange", false);
          this.totalValue = {
            status: false,
            total: total.toFixed(2),
          };
        } else {
          this.$emit("onPriceChange", true);
          this.totalValue = { status: true, total: total.toFixed(2) };
        }
      } else {
        this.totalValue = { status: false, total: 0 };
      }
    },
    updateThirdParty(id) {
      this.third_party_payment_rows.push({
        id: id + 1,
      });
    },
    addThirdParty(id) {
      this.third_party_payment_rows.push({
        id: id + 1,
      });

      this.tempContract["third_party_payment_rows"] = JSON.stringify(
        this.third_party_payment_rows
      );
    },
    removeThirdParty(id) {
      for (var i in this.third_party_payment_rows) {
        if (this.third_party_payment_rows[i].id == id) {
          this.third_party_payment_rows.splice(i, 1);
          break;
        }
      }

      this.tempContract["third_party_payment_rows"] = JSON.stringify(
        this.third_party_payment_rows
      );
    },
    portalActiveTab(active) {
      this.activeTab = active;
    },
    dynamicRequiredFieldPaymentCard() {
      let flag = this.paymentMethods;

      if (flag != undefined) {
        flag = this.paymentMethods.includes("payment-card");
      } else {
        flag = false;
      }

      return flag;
    },
    async verifyValue(rule, value, callback) {
      rule;
      let theValue = parse(value);
      if (isNaN(theValue) || theValue == undefined) {
        theValue = 0;
      } else {
        if (parse(value) > this.reservation_total_price) {
          callback(
            `O valor preenchido ultrapassa o total da reserva (R$ ${this.reservation_total_price}).`
          );
        } else {
          callback();
        }
      }
    },
  },
};
