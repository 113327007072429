<template>
  <div>
    <a-row>
      <a-col v-for="(hotel, index) in hotelRows" :key="hotel.id" :span="24">
        <div class="product-card" @click="onClickOpenCollapse(hotel.id)">
          <a-row :gutter="20">
            <a-col class="id cprimary" :span="1">
              <span class="ico">
                <i class="fas fa-hotel"></i>
                <font class="num">{{ hotel.id }} </font>
              </span>
            </a-col>

            <a-col class="id-name" :span="21">
              {{
                tempContract[`hotel_${hotel.id}_name`]
                  ? tempContract[`hotel_${hotel.id}_name`]
                  : "HOTEL / RESORT"
              }}
            </a-col>

            <a-col class="a-right" :span="2">
              <a-button
                v-if="hotelRows.length == hotel.id && hotel.id != 1"
                size="small"
                type="danger"
                shape="circle"
                icon="delete"
                @click="removeRows(hotel.id)"
                ghost
              >
              </a-button>
            </a-col>
          </a-row>
        </div>

        <div class="product-card-content" v-if="openCollapse == hotel.id">
          <HotelForm
            :totalRows="hotelRows.length"
            :hotelRows="hotelRows"
            :travellers="travellers"
            :theTravellers="travellers"
            :index="index"
            :add="index === 0 ? true : false"
            :class="`hotel-row hotel-${hotel.id}`"
            :id="hotel.id"
            :hotel="hotel"
            :tempContract="tempContract"
            :form="form"
            @updateHotelRows="updateHotelRows"
          />
        </div>

        <div v-if="hotelRows.length == hotel.id" class="a-center">
          <a-button class="cprimary mt-10" @click="updateRows(hotel.id)">
            Adicionar hotel
          </a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import HotelForm from "../collapses/HotelCollapse.vue";

export default {
  name: "HotelTab",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    travellers: Array,
  },
  components: {
    HotelForm,
  },
  data() {
    return {
      openCollapse: 0,
      hotelRows: [],
    };
  },
  mounted() {
    if (this.tempContract.hotel_rows == undefined) {
      this.tempContract.hotel_rows = JSON.stringify([
        {
          id: 1,
          accommodation: [
            {
              id: 1,
              travellers: [
                {
                  id: 1,
                },
              ],
            },
          ],
        },
      ]);
    }

    this.hotelRows =
      this.tempContract.hotel_rows != undefined
        ? JSON.parse(this.tempContract.hotel_rows)
        : [];
  },
  methods: {
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;
      }
    },
    updateHotelRows(hotelRowsUpdated) {
      this.hotelRows = hotelRowsUpdated;
      this.tempContract["hotel_rows"] = JSON.stringify(this.hotelRows);
    },
    updateRows(id) {
      this.hotelRows.push({
        id: id + 1,
        accommodation: [
          {
            id: 1,
            travellers: [
              {
                id: 1,
              },
            ],
          },
        ],
      });

      this.tempContract[`hotel_${id + 1}_name`] = undefined;
      this.tempContract[`hotel_${id + 1}_checkin`] = "";
      this.tempContract[`hotel_${id + 1}_checkout`] = "";
      this.tempContract[`hotel_${id + 1}_rates`] = "";
      this.tempContract[`hotel_${id + 1}_nights`] = "";
      this.tempContract[`hotel_${id + 1}_diet`] = undefined;
      this.tempContract[`hotel_${id + 1}_total_price`] = "";
      this.tempContract[`hotel_${id + 1}_accommodation_1`] = "";
      this.tempContract[`hotel_${id + 1}_accommodation_1_type`] = undefined;
      this.tempContract["hotel_rows"] = JSON.stringify(this.hotelRows);
    },
    removeRows(id) {
      for (var i in this.hotelRows) {
        if (this.hotelRows[i].id == id) {
          this.hotelRows.splice(i, 1);
          break;
        }
      }

      this.tempContract["hotel_rows"] = JSON.stringify(this.hotelRows);

      Object.keys(this.tempContract).forEach((key) => {
        if (key.startsWith(`hotel_${id}`)) {
          setTimeout(() => {
            this.tempContract[key] = "";
          }, 20);
        }

        if (key.startsWith(`contract_finances_hotel_${id}`)) {
          if (
            key == `contract_finances_hotel_${id}_net_value` ||
            key == `contract_finances_hotel_${id}_profit_value`
          ) {
            setTimeout(() => {
              this.tempContract[key] = 0;
            }, 20);
          }
        }
      });
    },
    updateFlightRows(id, flightSectionID) {
      this.flightSectionRows[flightSectionID - 1].sections.push({
        id: id + 1,
      });

      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.product-card-content
  padding: 30px 20px 20px
  border: 1px solid #ccc
  margin-top: -14px
  border-radius: 0 0 6px 6px
  margin-bottom: 11px
  background: #fff
.product-card
  cursor: pointer
  clear: both
  padding:  20px
  background: #fff
  border: 1px solid #ccc
  margin-bottom: 10px
  border-radius: 6px
  .id
    color: #474747
    font-weight: 600
    .ico
      position: relative
    .num
      position: absolute
      right: -13px
      top: 0
      font-size: 11px
      color: #333
  .id-name
    padding-left: 20px !important
    color: #42413f
    font-weight: 500
</style>
