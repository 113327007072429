var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.flightSectionsRows),function(flight,index){return _c('div',{key:index,staticClass:"supplier-accordeon"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"name",on:{"click":function($event){return _vm.activeServiceAccordeon(index, flight)}}},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',{staticClass:"upper"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.theProduct(flight))}})]),_vm._v(" "),_c('a-col',[_c('a-button',{attrs:{"type":"link"}},[_c('i',{staticClass:"fas fa-caret-down"})])],1)],1)],1),_vm._v(" "),(index == _vm.activeAccordeon)?_c('div',{staticClass:"content"},[(_vm.tempContract[`flight_${flight.id}_type`] == 'Regular')?_c('ContractFinancesRegularFlight',{staticStyle:{"padding":"25px 15px"},attrs:{"contract":_vm.contract,"form":_vm.form,"tempContract":_vm.tempContract,"flight":flight}}):_vm._e(),_vm._v(" "),(_vm.tempContract[`flight_${flight.id}_type`] == 'Milhas')?_c('a-row',{staticClass:"fields",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Valor total do aéreo (R$) ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `flight_${flight.id}_total_price`,
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `flight_${flight.id}_total_price`,\n                  {\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor total"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":18}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Valor total por extenso ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`flight_${flight.id}_total_price_written`]),expression:"[`flight_${flight.id}_total_price_written`]"}],staticClass:"travel-input upper",attrs:{"placeholder":"Valor total por extenso"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),(_vm.tempContract[`flight_${flight.id}_type`] == 'Regular')?_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer travel-multiselector"},[_c('label',{class:'filled'},[_vm._v(" Fornecedor ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `contract_finances_flight_${flight.id}_supplier_id`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `contract_finances_flight_${flight.id}_supplier_id`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",staticStyle:{"width":"100%","height":"30px"},attrs:{"show-search":"","allow-clear":true,"optionFilterProp":"txt","placeholder":"Selecione um fornecedor"},on:{"change":function($event){return _vm.onSelectSupplier(flight.id)}}},_vm._l((_vm.productSupplier.list),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n                  "+_vm._s(item.id)+" -\n                  "+_vm._s(item.trading_name.toUpperCase())+"\n                ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract[`flight_${flight.id}_type`] == 'Regular')?_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Valor Neto")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `contract_finances_flight_${flight.id}_net_value`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `contract_finances_flight_${flight.id}_net_value`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor Neto"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract[`flight_${flight.id}_type`] == 'Regular')?_c('a-col',{staticClass:"none",attrs:{"span":4}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Markup")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                  currency: {
                    prefix: '',
                    suffix: '',
                  },
                  distractionFree: false,
                  valueAsInteger: false,
                  precision: 2,
                  autoDecimalMode: false,
                  allowNegative: false,
                }),expression:"{\n                  currency: {\n                    prefix: '',\n                    suffix: '',\n                  },\n                  distractionFree: false,\n                  valueAsInteger: false,\n                  precision: 2,\n                  autoDecimalMode: false,\n                  allowNegative: false,\n                }"},{name:"decorator",rawName:"v-decorator",value:([
                  `contract_finances_flight_${flight.id}_markup_value`,
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `contract_finances_flight_${flight.id}_markup_value`,\n                  {\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Markup"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract[`flight_${flight.id}_type`] == 'Regular')?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Desconto")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `contract_finances_flight_${flight.id}_discount_value`,
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `contract_finances_flight_${flight.id}_discount_value`,\n                  {\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Desconto"},on:{"blur":function($event){return _vm.discountValue(flight.id)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract[`flight_${flight.id}_type`] == 'Milhas')?_c('a-col',{staticClass:"flight-table",attrs:{"span":24}},[_c('a-row',[_c('a-col',{staticClass:"line",staticStyle:{"padding":"0","border":"0"},attrs:{"span":14}},[_c('a-row',{staticClass:"head"},[_c('a-col',{attrs:{"span":9}},[_vm._v("Pax ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v(" Milhas ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v(" Ticket ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v(" Taxas ")])],1),_vm._v(" "),_c('a-row',[_c('a-col',{attrs:{"span":9}},[_vm._v(_vm._s(_vm.flightTravellers(flight.id).adults.qtd)+"x\n                    Adulto(s)")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v("\n                    "+_vm._s(_vm.flightTravellers(flight.id).adults.totalMiles)+"\n                  ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v("\n                    "+_vm._s(_vm.formatPricePtBr(
                        _vm.flightTravellers(flight.id).adults.totalTicket
                      ))+"\n                  ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v("\n                    "+_vm._s(_vm.formatPricePtBr(
                        _vm.flightTravellers(flight.id).adults.totalTaxes
                      ))+"\n                  ")])],1),_vm._v(" "),(_vm.flightTravellers(flight.id).children.qtd > 0)?_c('a-row',{staticClass:"line"},[_c('a-col',{attrs:{"span":9}},[_vm._v(_vm._s(_vm.flightTravellers(flight.id).children.qtd)+"x\n                    Criança(s)")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v("\n                    "+_vm._s(_vm.flightTravellers(flight.id).children.totalMiles)+"\n                  ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v(_vm._s(_vm.formatPricePtBr(
                        _vm.flightTravellers(flight.id).children.totalTicket
                      ))+"\n                  ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v(_vm._s(_vm.formatPricePtBr(
                        _vm.flightTravellers(flight.id).children.totalTaxes
                      ))+"\n                  ")])],1):_vm._e()],1),_vm._v(" "),_c('a-col',{staticClass:"line",staticStyle:{"padding":"0","border":"0"},attrs:{"span":10}},[_c('a-row',{staticClass:"head"},[_c('a-col',{attrs:{"span":6}},[_vm._v(" Custo ")]),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_vm._v(" Fornecedores ")]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_vm._v(" Pedidos ")])],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_vm._v("\n                  "+_vm._s(_vm.formatPricePtBr(
                      _vm.tempContract[
                        `contract_finances_flight_${flight.id}_sale_value`
                      ]
                    ))+"\n                ")]),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_vm._v("\n                  "+_vm._s(_vm.tempContract[
                      `contract_finances_flight_${flight.id}_suppliers`
                    ])+"\n                ")]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_vm._v("\n                  "+_vm._s(_vm.tempContract[
                      `contract_finances_flight_${flight.id}_miles_order_ids`
                    ])+"\n                ")])],1)],1)],1):_vm._e()],1):_vm._e(),_vm._v(" "),_c('a-row',{staticClass:"fields",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":24}},[_c('h4',{staticClass:"subtitile"},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_vm._v("Taxas Extras")]),_vm._v(" "),_c('a-col',[_c('a-button',{staticClass:"add-button color",attrs:{"size":"small"},on:{"click":function($event){return _vm.addTaxRow(flight.id)}}},[_vm._v("\n                    Adicionar\n                  ")])],1)],1)],1),_vm._v(" "),_vm._l((_vm.taxesRows),function(tax,index){return _c('a-row',{key:index,attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Taxas (R$)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                      `contract_finances_flight_${flight.id}_tax_${tax.id}_value`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]),expression:"[\n                      `contract_finances_flight_${flight.id}_tax_${tax.id}_value`,\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Obrigatório',\n                          },\n                        ],\n                      },\n                    ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer travel-multiselector"},[_c('label',{class:'filled'},[_vm._v(" Tipo ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      `contract_finances_flight_${flight.id}_tax_${tax.id}_type`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]),expression:"[\n                      `contract_finances_flight_${flight.id}_tax_${tax.id}_type`,\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Obrigatório',\n                          },\n                        ],\n                      },\n                    ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"show-search":"","allow-clear":true,"optionFilterProp":"txt","placeholder":"Selecione um tipo"}},_vm._l((_vm.taxesType),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.name}},[_vm._v("\n                      "+_vm._s(item.name)+"\n                    ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":7}},[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      `contract_finances_flight_${flight.id}_tax_${tax.id}_add_in_total`,
                    ]),expression:"[\n                      `contract_finances_flight_${flight.id}_tax_${tax.id}_add_in_total`,\n                    ]"}],attrs:{"checked":_vm.tempContract[
                        `contract_finances_flight_${flight.id}_tax_${tax.id}_add_in_total`
                      ] == 1
                        ? true
                        : false}},[_vm._v("Somar no valor total")])],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":1}},[(tax.id == _vm.taxesRows.length)?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeTaxRow(flight.id, tax.id)}}}):_vm._e()],1)],1)})],2),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('h4',{staticClass:"subtitile"},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_vm._v("Incentivos")]),_vm._v(" "),_c('a-col',[_c('a-button',{staticClass:"add-button color",attrs:{"size":"small"},on:{"click":function($event){return _vm.addIncentiveRow(flight.id)}}},[_vm._v("\n                    Adicionar\n                  ")])],1)],1)],1),_vm._v(" "),_vm._l((_vm.incentivesRows),function(incentive,index){return _c('a-row',{key:index,attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer travel-multiselector"},[_c('label',{class:'filled'},[_vm._v(" Tipo ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      `contract_finances_flight_${flight.id}_incentive_${incentive.id}_type`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]),expression:"[\n                      `contract_finances_flight_${flight.id}_incentive_${incentive.id}_type`,\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Obrigatório',\n                          },\n                        ],\n                      },\n                    ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"show-search":"","allow-clear":true,"optionFilterProp":"txt","placeholder":"% ou R$"}},[_c('a-select-option',{attrs:{"value":"%"}},[_vm._v(" % ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"R$"}},[_vm._v(" R$ ")])],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Valor incentivo")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                          currency: {
                            prefix: '',
                            suffix: '',
                          },
                          distractionFree: true,
                          valueAsInteger: false,
                          precision: 2,
                          autoDecimalMode: true,
                          allowNegative: false,
                        }),expression:"{\n                          currency: {\n                            prefix: '',\n                            suffix: '',\n                          },\n                          distractionFree: true,\n                          valueAsInteger: false,\n                          precision: 2,\n                          autoDecimalMode: true,\n                          allowNegative: false,\n                        }"},{name:"decorator",rawName:"v-decorator",value:([
                          `contract_finances_flight_${flight.id}_incentive_${incentive.id}_value`,
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]),expression:"[\n                          `contract_finances_flight_${flight.id}_incentive_${incentive.id}_value`,\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: 'Obrigatório',\n                              },\n                            ],\n                          },\n                        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor incentivo","disabled":_vm.form.getFieldValue(
                            `contract_finances_flight_${flight.id}_incentive_${incentive.id}_type`
                          )
                            ? false
                            : true},on:{"blur":function($event){return _vm.calcIncetivesPercentage(flight.id, incentive.id)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      `contract_finances_flight_${flight.id}_incentive_${incentive.id}_is_invoiced`,
                    ]),expression:"[\n                      `contract_finances_flight_${flight.id}_incentive_${incentive.id}_is_invoiced`,\n                    ]"}],attrs:{"checked":_vm.tempContract[
                        `contract_finances_flight_${flight.id}_incentive_${incentive.id}_is_invoiced`
                      ] == 1
                        ? true
                        : false}},[_vm._v("Faturado")])],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":7}},[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      `contract_finances_flight_${flight.id}_incentive_${incentive.id}_add_in_total`,
                    ]),expression:"[\n                      `contract_finances_flight_${flight.id}_incentive_${incentive.id}_add_in_total`,\n                    ]"}],attrs:{"checked":_vm.tempContract[
                        `contract_finances_flight_${flight.id}_incentive_${incentive.id}_add_in_total`
                      ] == 1
                        ? true
                        : false}},[_vm._v("Somar no valor total")])],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":1}},[(incentive.id == _vm.incentivesRows.length)?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeIncentiveRow(flight.id, incentive.id)}}}):_vm._e()],1)],1)})],2)],1)],1):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }