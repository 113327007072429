<template>
  <div>
    <div
      class="package-finances"
      v-if="tempContract['package_type'] == 'Pacote Completo Terceiros'"
    >
      <ContractFinancesThirdPartyPackageTab
        :form="form"
        :tempContract="tempContract"
      />
    </div>

    <a-tabs
      class="finances-tabs"
      :tab-position="'left'"
      v-if="
        tempContract['package_type'] == 'Serviços' ||
        tempContract['package_type'] == 'Reserva Online'
      "
      @change="onChangeTabs"
    >
      <a-tab-pane v-if="contractedServices.includes('hotel')" key="hotel">
        <span slot="tab">
          <img
            class="relative"
            src="@/assets/images/dashboard/contracts/hotel-ico.png"
            alt="hotel"
          />
        </span>
        <ContractFinancesHotelTab
          v-if="activeTab == 'hotel'"
          :form="form"
          :tempContract="tempContract"
        />
      </a-tab-pane>

      <a-tab-pane v-if="contractedServices.includes('flight')" key="flight">
        <span slot="tab">
          <img
            class="relative"
            src="@/assets/images/dashboard/contracts/flight-ico.png"
            alt="flight"
          />
        </span>
        <ContractFinancesFlightTab
          v-if="activeTab == 'flight'"
          :form="form"
          :tempContract="tempContract"
        />
      </a-tab-pane>

      <a-tab-pane v-if="contractedServices.includes('service')" key="service">
        <span slot="tab">
          <img
            class="relative"
            src="@/assets/images/dashboard/contracts/services-ico.png"
            alt="services"
          />
        </span>

        <ContractFinancesServiceTab
          v-if="activeTab == 'service'"
          :form="form"
          :tempContract="tempContract"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import ContractFinancesFlightTab from "../tabs/ContractFinancesFlightTab.vue";
import ContractFinancesHotelTab from "../tabs/ContractFinancesHotelTab.vue";
import ContractFinancesServiceTab from "../tabs/ContractFinancesServiceTab.vue";
import ContractFinancesThirdPartyPackageTab from "../tabs/ContractFinancesThirdPartyPackageTab.vue";

export default {
  name: "ContractFinancesModal",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
  },
  components: {
    ContractFinancesHotelTab,
    ContractFinancesServiceTab,
    ContractFinancesFlightTab,
    ContractFinancesThirdPartyPackageTab,
  },
  data() {
    return {
      activeTab: 0,
      contractedServices: [],
    };
  },
  mounted() {
    this.contractedServices = this.tempContract.contracted_services
      ? JSON.parse(this.tempContract.contracted_services)
      : [];

    if (this.contractedServices.includes("service")) {
      this.activeTab = "service";
    }

    if (this.contractedServices.includes("flight")) {
      this.activeTab = "flight";
    }

    if (this.contractedServices.includes("hotel")) {
      this.activeTab = "hotel";
    }
  },
  methods: {
    onChangeTabs(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style lang="css">
.finances-tabs {
  border-top: 1px solid #eee;
}
.finances-tabs .ant-tabs-left-content {
  padding-left: 0 !important;
}

.finances-tabs .ant-tabs-bar {
  height: 100vh !important;
  padding-top: 10px;
}

.finances-tabs .ant-tabs-tab-active.ant-tabs-tab {
  opacity: 1;
}

.finances-tabs .ant-tabs-tab {
  opacity: 0.5;
}

.finances-tabs .ant-tabs-tab {
  padding: 8px 0 !important;
}

.finances-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none !important;
}
</style>
