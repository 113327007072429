<template>
  <div>
    <div
      class="supplier-accordeon"
      v-for="(flight, index) in flightSectionsRows"
      :key="index"
    >
      <div class="wrapper">
        <div class="name" @click="activeServiceAccordeon(index, flight)">
          <a-row type="flex" justify="space-between">
            <a-col class="upper">
              <div v-html="theProduct(flight)"></div>
            </a-col>

            <a-col>
              <a-button type="link">
                <i class="fas fa-caret-down"></i>
              </a-button>
            </a-col>
          </a-row>
        </div>

        <div v-if="index == activeAccordeon" class="content">
          <ContractFinancesRegularFlight
            v-if="tempContract[`flight_${flight.id}_type`] == 'Regular'"
            :contract="contract"
            :form="form"
            :tempContract="tempContract"
            :flight="flight"
            style="padding: 25px 15px"
          />

          <a-row
            class="fields"
            v-if="tempContract[`flight_${flight.id}_type`] == 'Milhas'"
            :gutter="20"
          >
            <a-col :span="6">
              <a-form-item class="travel-input-outer">
                <label for :class="'filled'"> Valor total do aéreo (R$) </label>

                <a-input
                  class="travel-input"
                  placeholder="Valor total"
                  v-currency
                  v-decorator="[
                    `flight_${flight.id}_total_price`,
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="18">
              <a-form-item class="travel-input-outer">
                <label :class="'filled'"> Valor total por extenso </label>

                <a-input
                  class="travel-input upper"
                  placeholder="Valor total por extenso"
                  v-decorator="[`flight_${flight.id}_total_price_written`]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col
              v-if="tempContract[`flight_${flight.id}_type`] == 'Regular'"
              :span="8"
            >
              <a-form-item class="travel-input-outer travel-multiselector">
                <label :class="'filled'"> Fornecedor </label>

                <a-select
                  class="travel-input"
                  show-search
                  :allow-clear="true"
                  optionFilterProp="txt"
                  placeholder="Selecione um fornecedor"
                  v-decorator="[
                    `contract_finances_flight_${flight.id}_supplier_id`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                  style="width: 100%; height: 30px"
                  @change="onSelectSupplier(flight.id)"
                >
                  <a-select-option
                    v-for="(item, index) of productSupplier.list"
                    :key="index"
                    :value="item.id"
                    :txt="item.trading_name"
                  >
                    {{ item.id }} -
                    {{ item.trading_name.toUpperCase() }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col
              v-if="tempContract[`flight_${flight.id}_type`] == 'Regular'"
              :span="5"
            >
              <a-form-item class="travel-input-outer">
                <label class="filled">Valor Neto</label>
                <a-input
                  class="travel-input"
                  placeholder="Valor Neto"
                  v-currency
                  v-decorator="[
                    `contract_finances_flight_${flight.id}_net_value`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col
              class="none"
              v-if="tempContract[`flight_${flight.id}_type`] == 'Regular'"
              :span="4"
            >
              <a-form-item class="travel-input-outer">
                <label class="filled">Markup</label>
                <a-input
                  class="travel-input"
                  placeholder="Markup"
                  v-currency="{
                    currency: {
                      prefix: '',
                      suffix: '',
                    },
                    distractionFree: false,
                    valueAsInteger: false,
                    precision: 2,
                    autoDecimalMode: false,
                    allowNegative: false,
                  }"
                  v-decorator="[
                    `contract_finances_flight_${flight.id}_markup_value`,
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col
              v-if="tempContract[`flight_${flight.id}_type`] == 'Regular'"
              :span="4"
            >
              <a-form-item class="travel-input-outer">
                <label class="filled">Desconto</label>
                <a-input
                  class="travel-input"
                  placeholder="Desconto"
                  v-currency
                  v-decorator="[
                    `contract_finances_flight_${flight.id}_discount_value`,
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                  @blur="discountValue(flight.id)"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col
              v-if="tempContract[`flight_${flight.id}_type`] == 'Milhas'"
              class="flight-table"
              :span="24"
            >
              <a-row>
                <a-col class="line" :span="14" style="padding: 0; border: 0">
                  <a-row class="head">
                    <a-col :span="9">Pax </a-col>
                    <a-col :span="5"> Milhas </a-col>
                    <a-col :span="5"> Ticket </a-col>
                    <a-col :span="5"> Taxas </a-col>
                  </a-row>

                  <a-row>
                    <a-col :span="9"
                      >{{ flightTravellers(flight.id).adults.qtd }}x
                      Adulto(s)</a-col
                    >
                    <a-col :span="5">
                      {{ flightTravellers(flight.id).adults.totalMiles }}
                    </a-col>
                    <a-col :span="5">
                      {{
                        formatPricePtBr(
                          flightTravellers(flight.id).adults.totalTicket
                        )
                      }}
                    </a-col>

                    <a-col :span="5">
                      {{
                        formatPricePtBr(
                          flightTravellers(flight.id).adults.totalTaxes
                        )
                      }}
                    </a-col>
                  </a-row>

                  <a-row
                    class="line"
                    v-if="flightTravellers(flight.id).children.qtd > 0"
                  >
                    <a-col :span="9"
                      >{{ flightTravellers(flight.id).children.qtd }}x
                      Criança(s)</a-col
                    >
                    <a-col :span="5">
                      {{ flightTravellers(flight.id).children.totalMiles }}
                    </a-col>
                    <a-col :span="5"
                      >{{
                        formatPricePtBr(
                          flightTravellers(flight.id).children.totalTicket
                        )
                      }}
                    </a-col>

                    <a-col :span="5"
                      >{{
                        formatPricePtBr(
                          flightTravellers(flight.id).children.totalTaxes
                        )
                      }}
                    </a-col>
                  </a-row>
                </a-col>

                <a-col class="line" :span="10" style="padding: 0; border: 0">
                  <a-row class="head">
                    <a-col :span="6"> Custo </a-col>
                    <a-col :span="12"> Fornecedores </a-col>
                    <a-col :span="6"> Pedidos </a-col>
                  </a-row>

                  <a-col :span="6">
                    {{
                      formatPricePtBr(
                        tempContract[
                          `contract_finances_flight_${flight.id}_sale_value`
                        ]
                      )
                    }}
                  </a-col>

                  <a-col :span="12">
                    {{
                      tempContract[
                        `contract_finances_flight_${flight.id}_suppliers`
                      ]
                    }}
                  </a-col>
                  <a-col :span="6">
                    {{
                      tempContract[
                        `contract_finances_flight_${flight.id}_miles_order_ids`
                      ]
                    }}
                  </a-col>
                </a-col>
              </a-row>
            </a-col>
          </a-row>

          <a-row class="fields" :gutter="20">
            <a-col :span="24">
              <h4 class="subtitile">
                <a-row type="flex" justify="space-between">
                  <a-col>Taxas Extras</a-col>
                  <a-col>
                    <a-button
                      class="add-button color"
                      size="small"
                      @click="addTaxRow(flight.id)"
                    >
                      Adicionar
                    </a-button></a-col
                  >
                </a-row>
              </h4>

              <a-row
                v-for="(tax, index) in taxesRows"
                :gutter="20"
                :key="index"
              >
                <a-col :span="5">
                  <a-form-item class="travel-input-outer">
                    <label class="filled">Taxas (R$)</label>
                    <a-input
                      class="travel-input"
                      placeholder="Insira"
                      v-currency
                      v-decorator="[
                        `contract_finances_flight_${flight.id}_tax_${tax.id}_value`,
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                      <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item class="travel-input-outer travel-multiselector">
                    <label :class="'filled'"> Tipo </label>

                    <a-select
                      class="travel-input"
                      show-search
                      :allow-clear="true"
                      optionFilterProp="txt"
                      placeholder="Selecione um tipo"
                      v-decorator="[
                        `contract_finances_flight_${flight.id}_tax_${tax.id}_type`,
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                      style="width: 100%"
                    >
                      <a-select-option
                        v-for="(item, index) of taxesType"
                        :key="index"
                        :value="item.name"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :span="7">
                  <a-form-item>
                    <a-checkbox
                      :checked="
                        tempContract[
                          `contract_finances_flight_${flight.id}_tax_${tax.id}_add_in_total`
                        ] == 1
                          ? true
                          : false
                      "
                      v-decorator="[
                        `contract_finances_flight_${flight.id}_tax_${tax.id}_add_in_total`,
                      ]"
                      >Somar no valor total</a-checkbox
                    >
                  </a-form-item>
                </a-col>

                <a-col :span="1">
                  <a-button
                    v-if="tax.id == taxesRows.length"
                    size="small"
                    type="danger"
                    shape="circle"
                    icon="delete"
                    @click="removeTaxRow(flight.id, tax.id)"
                  >
                  </a-button>
                </a-col>
              </a-row>
            </a-col>

            <a-col :span="24">
              <h4 class="subtitile">
                <a-row type="flex" justify="space-between">
                  <a-col>Incentivos</a-col>
                  <a-col>
                    <a-button
                      class="add-button color"
                      size="small"
                      @click="addIncentiveRow(flight.id)"
                    >
                      Adicionar
                    </a-button></a-col
                  >
                </a-row>
              </h4>

              <a-row
                v-for="(incentive, index) in incentivesRows"
                :gutter="20"
                :key="index"
              >
                <a-col :span="5">
                  <a-form-item class="travel-input-outer travel-multiselector">
                    <label :class="'filled'"> Tipo </label>

                    <a-select
                      class="travel-input"
                      show-search
                      :allow-clear="true"
                      optionFilterProp="txt"
                      placeholder="% ou R$"
                      v-decorator="[
                        `contract_finances_flight_${flight.id}_incentive_${incentive.id}_type`,
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                      style="width: 100%"
                    >
                      <a-select-option value="%"> % </a-select-option>
                      <a-select-option value="R$"> R$ </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :span="6">
                  <a-row :gutter="8">
                    <a-col :span="24">
                      <a-form-item class="travel-input-outer">
                        <label class="filled">Valor incentivo</label>
                        <a-input
                          class="travel-input"
                          placeholder="Valor incentivo"
                          v-currency="{
                            currency: {
                              prefix: '',
                              suffix: '',
                            },
                            distractionFree: true,
                            valueAsInteger: false,
                            precision: 2,
                            autoDecimalMode: true,
                            allowNegative: false,
                          }"
                          v-decorator="[
                            `contract_finances_flight_${flight.id}_incentive_${incentive.id}_value`,
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                          :disabled="
                            form.getFieldValue(
                              `contract_finances_flight_${flight.id}_incentive_${incentive.id}_type`
                            )
                              ? false
                              : true
                          "
                          @blur="
                            calcIncetivesPercentage(flight.id, incentive.id)
                          "
                        >
                          <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-col>

                <a-col :span="4">
                  <a-form-item>
                    <a-checkbox
                      :checked="
                        tempContract[
                          `contract_finances_flight_${flight.id}_incentive_${incentive.id}_is_invoiced`
                        ] == 1
                          ? true
                          : false
                      "
                      v-decorator="[
                        `contract_finances_flight_${flight.id}_incentive_${incentive.id}_is_invoiced`,
                      ]"
                      >Faturado</a-checkbox
                    >
                  </a-form-item>
                </a-col>

                <a-col :span="7">
                  <a-form-item>
                    <a-checkbox
                      :checked="
                        tempContract[
                          `contract_finances_flight_${flight.id}_incentive_${incentive.id}_add_in_total`
                        ] == 1
                          ? true
                          : false
                      "
                      v-decorator="[
                        `contract_finances_flight_${flight.id}_incentive_${incentive.id}_add_in_total`,
                      ]"
                      >Somar no valor total</a-checkbox
                    >
                  </a-form-item>
                </a-col>

                <a-col :span="1">
                  <a-button
                    v-if="incentive.id == incentivesRows.length"
                    size="small"
                    type="danger"
                    shape="circle"
                    icon="delete"
                    @click="removeIncentiveRow(flight.id, incentive.id)"
                  >
                  </a-button>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import formatThings from "@/mixins/general/formatThings";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import ContractFinancesRegularFlight from "../collapses/ContractFinancesRegularFlight.vue";

export default {
  name: "ContractFinancesFlightTab",
  props: {
    tempContract: Object,
    contract: Object,
    form: Object,
  },
  components: { ContractFinancesRegularFlight },
  mixins: [formatThings, productSupplierMixins],
  data() {
    return {
      flightSectionsRows: [],
      activeAccordeon: -1,
      taxesType: [
        {
          label: "Taxa de Embarque",
          value: "Taxa de Embarque",
        },
        {
          label: "Taxa do Hotel",
          value: "Taxa do Hotel",
        },
        {
          label: "Taxa de Serviço",
          value: "Taxa de Serviço",
        },
        {
          label: "Taxa de Limpeza",
          value: "Taxa de Limpeza",
        },
      ],
      taxesRows: [],
      incentivesRows: [],
      flightExtraData: [],
    };
  },
  mounted() {
    this.productSupplier.pagination.perPage = 300;
    this.productSupplier.filters.only_specific_categories = 1;
    this.getProductSuppliers();

    this.$http
      .get(
        `/category-entry/list?page=1&per_page=10&category_id=3&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.taxesType = data.data;
      });

    if (this.tempContract.flight_sections_rows) {
      this.flightSectionsRows = JSON.parse(
        this.tempContract.flight_sections_rows
      );
    }
  },
  methods: {
    discountValue(id) {
      let discount = 0;
      let totalValue = 0;

      if (this.tempContract[`contract_finances_flight_${id}_discount_value`]) {
        discount =
          this.tempContract[`contract_finances_flight_${id}_discount_value`];
      }

      totalValue = this.tempContract[`flight_${id}_total_price`];

      if (totalValue) {
        totalValue = totalValue.replace(",", ".");
        totalValue = parseFloat(totalValue);
      }

      if (discount) {
        discount = discount.replace(",", ".");
        discount = parseFloat(discount);
      }

      let newTotalPrice = totalValue - discount;

      this.form.setFieldsValue({
        [`flight_${id}_total_price`]: newTotalPrice
          .toString()
          .replace(".", ","),
      });
    },
    calcIncetivesPercentage(id, incentiveId) {
      let percentage = 0,
        totalValue = 0,
        incentiveType =
          this.tempContract[
            `contract_finances_flight_${id}_incentive_${incentiveId}_type`
          ];

      if (incentiveType == "%") {
        percentage =
          this.tempContract[
            `contract_finances_flight_${id}_incentive_${incentiveId}_value`
          ];

        if (percentage) {
          percentage = parseFloat(percentage.replace(",", "."));
          percentage = percentage / 100;
        } else {
          percentage = 0;
        }

        totalValue = this.tempContract[`flight_${id}_total_price`];

        if (totalValue) {
          totalValue = parseFloat(totalValue);
        } else {
          totalValue = 0;
        }

        if (totalValue && percentage != 0) {
          this.tempContract[
            `contract_finances_flight_${id}_incentive_${incentiveId}_percentage_value`
          ] = (percentage * totalValue).toFixed(2);
        } else {
          this.tempContract[
            `contract_finances_flight_${id}_incentive_${incentiveId}_percentage_value`
          ] = 0;
        }
      }
    },
    theProduct(flight) {
      let name = "";
      name = `AÉREO ${flight.id} -  ${
        this.tempContract[`flight_${flight.id}_section_${1}_class`] != undefined
          ? this.tempContract[`flight_${flight.id}_section_${1}_class`]
          : "CLASSE"
      } - ${
        this.tempContract[`flight_${flight.id}_section_${1}_origin`] !=
        undefined
          ? this.tempContract[`flight_${flight.id}_section_${1}_origin`]
          : "ORIGEM"
      }  <i class="fas fa-plane" style="margin: 0 2px; font-size: 8px; top:-1px; position: relative;"></i> ${
        this.tempContract[`flight_${flight.id}_section_${1}_destination`] !=
        undefined
          ? this.tempContract[`flight_${flight.id}_section_${1}_destination`]
          : "DESTINO"
      }`;

      return name;
    },
    activeServiceAccordeon(current, flight) {
      if (current == this.activeAccordeon) {
        this.activeAccordeon = -1;
      } else {
        this.activeAccordeon = current;
      }

      this.setFlightFields(flight);
    },
    setFlightFields(flight) {
      setTimeout(() => {
        this.flightSectionsRows.forEach((flight) => {
          let allSuppliers = "",
            allMilesOrdersIds = "",
            totalProfit = 0,
            totalSale = 0,
            totalMiles = 0;

          this.form.setFieldsValue({
            [`contract_finances_flight_${flight.id}_discount_value`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_discount_value`
              ],
            [`flight_${flight.id}_total_price_written`]:
              this.tempContract[`flight_${flight.id}_total_price_written`],
            [`flight_${flight.id}_total_price`]:
              this.tempContract[`flight_${flight.id}_total_price`],
            [`contract_finances_flight_${flight.id}_supplier_id`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_supplier_id`
              ],
            [`contract_finances_flight_${flight.id}_net_value`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_net_value`
              ],
            [`contract_finances_flight_${flight.id}_markup_value`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_markup_value`
              ],

            [`contract_finances_flight_${flight.id}_taxes_value`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_taxes_value`
              ],
            [`contract_finances_flight_${flight.id}_taxes_type`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_taxes_type`
              ],
            [`contract_finances_flight_${flight.id}_incentives_type`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_incentives_type`
              ],
            [`contract_finances_flight_${flight.id}_incentives_value`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_incentives_value`
              ],
          });

          if (flight.id) {
            this.$http
              .get(
                `/miles-card-order/list?page=1&per_page=20&status=Concluído&contract_id=${this.tempContract.id}&flight_id=${flight.id}&order=desc&order-by=created`
              )
              .then(({ data }) => {
                data.data.forEach((milesOrder) => {
                  allSuppliers += `${milesOrder.miles_card.supplier.first_name} ${milesOrder.miles_card.supplier.last_name} (ID ${milesOrder.miles_card.supplier.id}), `;
                  allMilesOrdersIds += `${milesOrder.id}, `;

                  totalProfit += parseFloat(milesOrder.raw.profit);
                  totalSale += parseFloat(milesOrder.raw.miles_cost);
                  totalMiles += parseInt(milesOrder.raw.miles_quantity);

                  this.tempContract[
                    `contract_finances_flight_${milesOrder.raw.flight_id}_miles`
                  ] = totalMiles;

                  this.tempContract[
                    `contract_finances_flight_${milesOrder.raw.flight_id}_sale_value`
                  ] = totalSale;

                  totalProfit;

                  this.tempContract[
                    `contract_finances_flight_${milesOrder.raw.flight_id}_suppliers`
                  ] = allSuppliers.slice(0, -2);

                  this.tempContract[
                    `contract_finances_flight_${milesOrder.raw.flight_id}_miles_order_ids`
                  ] = allMilesOrdersIds.slice(0, -2);
                });
              });
          }
        });
      }, 100);

      this.incentivesRows = this.tempContract[
        `contract_finances_flight_${flight.id}_incentives_rows`
      ]
        ? JSON.parse(
            this.tempContract[
              `contract_finances_flight_${flight.id}_incentives_rows`
            ]
          )
        : [];

      this.taxesRows = this.tempContract[
        `contract_finances_flight_${flight.id}_taxes_rows`
      ]
        ? JSON.parse(
            this.tempContract[
              `contract_finances_flight_${flight.id}_taxes_rows`
            ]
          )
        : [];

      setTimeout(() => {
        this.taxesRows.forEach((tax) => {
          this.form.setFieldsValue({
            [`contract_finances_flight_${flight.id}_tax_${tax.id}_value`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_tax_${tax.id}_value`
              ],
            [`contract_finances_flight_${flight.id}_tax_${tax.id}_type`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_tax_${tax.id}_type`
              ],
          });
        });

        this.incentivesRows.forEach((incentive) => {
          this.form.setFieldsValue({
            [`contract_finances_flight_${flight.id}_incentive_${incentive.id}_value`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_incentive_${incentive.id}_value`
              ],
            [`contract_finances_flight_${flight.id}_incentive_${incentive.id}_type`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_incentive_${incentive.id}_type`
              ],
            [`contract_finances_flight_${flight.id}_incentive_${incentive.id}_add_in_total`]:
              this.tempContract[
                `contract_finances_flight_${flight.id}_incentive_${incentive.id}_add_in_total`
              ] == true &&
              this.tempContract[
                `contract_finances_flight_${flight.id}_incentive_${incentive.id}_add_in_total`
              ] == 1
                ? true
                : false,
          });
        });
      }, 50);
    },
    onSelectSupplier(id) {
      setTimeout(() => {
        let supplierId =
            this.tempContract[`contract_finances_flight_${id}_supplier_id`],
          theSupplier = this.productSupplier.list.filter((supplier) => {
            return supplier.id == supplierId;
          });

        this.tempContract[`contract_finances_flight_${id}_supplier`] =
          theSupplier[0].trading_name;
      }, 200);
    },
    addIncentiveRow(flightId) {
      setTimeout(() => {
        this.incentivesRows.push({
          id: this.incentivesRows.length + 1,
        });

        this.tempContract[
          `contract_finances_flight_${flightId}_incentives_rows`
        ] = JSON.stringify(this.incentivesRows);

        this.form.setFieldsValue({
          [`contract_finances_flight_${flightId}_incentive_${this.incentivesRows.length}_is_invoiced`]: 1,
        });
      }, 100);
    },
    removeIncentiveRow(flightId, id) {
      for (var i in this.incentivesRows) {
        if (this.incentivesRows[i].id == id) {
          this.incentivesRows.splice(i, 1);
          break;
        }
      }

      this.tempContract[
        `contract_finances_flight_${flightId}_incentives_rows`
      ] = JSON.stringify(this.incentivesRows);
    },
    addTaxRow(flightId) {
      setTimeout(() => {
        this.taxesRows.push({
          id: this.taxesRows.length + 1,
        });

        this.tempContract[`contract_finances_flight_${flightId}_taxes_rows`] =
          JSON.stringify(this.taxesRows);
      }, 100);
    },
    removeTaxRow(flightId, id) {
      for (var i in this.taxesRows) {
        if (this.taxesRows[i].id == id) {
          this.taxesRows.splice(i, 1);
          break;
        }
      }

      this.tempContract[`contract_finances_flight_${flightId}_taxes_rows`] =
        JSON.stringify(this.taxesRows);
    },
    getTravellerAge(travellerId) {
      let travellers = JSON.parse(this.tempContract[`travellers_rows`]),
        theAge = "adult";

      travellers.forEach((traveller) => {
        if (traveller.id == travellerId) {
          theAge = this.tempContract[`traveller_${traveller.id}_age`];
        }
      });

      return theAge;
    },
    flightTravellers(flightId) {
      let allTravellers = [];

      this.flightSectionsRows.forEach((flight) => {
        if (flightId == flight.id) {
          flight.travellers.forEach((traveller) => {
            allTravellers.push({
              id: `${
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}`
                ]
              }`,
              age: this.getTravellerAge(
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}`
                ]
              ),
              value: `${
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}_value`
                ]
              }`,
              miles: `${
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}_miles`
                ]
              }`,
              tax: `${
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}_taxes`
                ]
              }`,
              tax_currency: `${
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                ] != undefined
                  ? this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                    ]
                  : "BRL"
              }`,
            });
          });
        }
      });

      let all = _.groupBy(allTravellers, (item) => item.age),
        totalTicketAdults = 0,
        totalTaxesAdults = 0,
        totalMilesAdults = 0;

      all.adult.forEach((adt) => {
        totalTicketAdults += parseFloat(adt.value.replace(",", "."));
        totalTaxesAdults += parseFloat(adt.tax.replace(",", "."));
        totalMilesAdults += parseInt(adt.miles);
      });

      let qtdChds = 0,
        Chds = [],
        totalTicketChds = 0,
        totalTaxesChds = 0,
        totalMilesChds = 0;

      if (all.child != undefined) {
        all.child.forEach((chd) => {
          totalTicketChds += parseFloat(chd.value.replace(",", "."));
          totalTaxesChds += parseFloat(chd.tax.replace(",", "."));
          totalMilesChds += parseInt(chd.miles);
        });

        Chds = all.child;
        qtdChds = all.child.length;
      }

      return {
        adults: {
          qtd: all.adult.length,
          data: all.adult,
          supplier: "",
          totalTicket: totalTicketAdults,
          totalTaxes: totalTaxesAdults,
          totalMiles: totalMilesAdults,
        },
        children: {
          qtd: qtdChds,
          data: Chds,
          supplier: "",
          totalTicket: totalTicketChds,
          totalTaxes: totalTaxesChds,
          totalMiles: totalMilesChds,
        },
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.add-button
  font-size: 11px
  font-weight: 600
  color: #bbb
  border-color: #ddd
  padding: 2px 5px !important
  line-height: 4px
  height: 22px
  top: -3px
  position: relative
.add-button.color
  border-color: #be4178
  color: #be4178
.remove-button
  font-size: 11px
  font-weight: 600
  color: red
  border-color: red
  padding: 2px 5px !important
  line-height: 4px
  height: 20px
  top: -3px
  position: relative
.flight-table
  border: 1px solid #ddd
  border-width: 1px 0
  padding: 0 !important
  margin: 5px 10px 20px
  font-size: 12px
  .head
    font-weight: 600
    background: #f9f9f9
    color: #666
    border: 1px solid #ddd
    border-width: 1px 0
    .ant-col
      padding: 5px
      border-right: 1px solid #ddd
  .line
    border-top: 0px solid #ddd
    .ant-col
      padding: 5px
      border-right: 0px solid #ddd

.supplier-accordeon
  .name
    background: #6c7a89
    border-bottom: 1px solid #fff
    text-align: center
    color: #fff
    padding: 7px 10px 7px 25px
    font-size: 13px
    line-height: 28px
    cursor: pointer
    button
      color: #fff
      height: auto
    .anticon
      color: #fff
  .fields
    padding: 30px 25px
    .add-row
      position: relative
      top: 10px
      padding: 0
      width: 25px
      height: 25px
      line-height: 24px
      font-size: 12px
      color: #bbb
      text-align: center
      &:hover
        color: #be4178
    .subtitile
      text-transform: uppercase
      font-size: 11px
      padding: 5px 0
      border-bottom: 1px solid #ddd
      margin-bottom: 20px
  .content
    .infos
      font-size: 12px
      padding: 10px 25px
      border-bottom: 1px solid #ddd
      .line
        padding: 3px 0
        .label
          color: #808183
        .value
          font-weight: 500
          color: #59595b
</style>
