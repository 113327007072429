<template>
  <div>
    <a-row :gutter="[20, 0]">
      <a-col :span="12">
        <a-form-item>
          <label class="filled">Valor total da Transferência</label>

          <a-input
            class="travel-input"
            placeholder="Valor da Transferência"
            v-currency
            @keyup="verifyTotal"
            v-decorator="[
              `payment_methods_bank_transfer_total_value`,
              {
                rules: [
                  {
                    required: dynamicRequiredFieldTransfer(),
                    message: 'Preencha todos números.',
                  },
                  {
                    validator: verifyValue,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="10">
        <a-form-item class="travel-input-outer">
          <label class="filled">Data de recebimento transferência</label>
          <a-date-picker
            class="travel-input"
            placeholder="Data de recebimento"
            format="DD/MM/YYYY"
            :showToday="false"
            :allowClear="true"
            v-mask="'##/##/####'"
            v-decorator="[
              `payment_methods_bank_transfer_receipt_date`,
              {
                rules: [
                  {
                    required: dynamicRequiredFieldTransfer(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-date-picker>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="[20, 0]">
      <a-col :span="12">
        <a-form-item class="show-label mt-20">
          <a-radio-group
            name="radioGroup"
            v-decorator="[
              `payment_methods_bank_transfer_bank`,
              {
                rules: [
                  {
                    required: dynamicRequiredFieldTransfer(),
                    message: 'Selecione um banco.',
                  },
                ],
              },
            ]"
          >
            <a-radio
              v-for="(item, index) of banksList"
              :key="index"
              :value="item.account"
            >
              <strong style="font-weight: 600">
                {{ item.company_name }}
              </strong>
              <a-divider type="vertical" />
              <strong style="font-weight: 600">CNPJ/PIX:</strong>
              {{ item.cnpj }}
              <br />
              <div style="padding: 8px 0px 10px 28px">
                Agência:
                <strong style="font-weight: 600"> {{ item.agency }}</strong>
                <a-divider type="vertical" />
                Conta:
                <strong style="font-weight: 600"> {{ item.account }}</strong>
                <a-divider type="vertical" />
                {{ item.number }}
                -
                {{ item.name }}
              </div>
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";

export default {
  name: "BankTransferTab",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    reservation_total_price: Number,
  },
  data() {
    return {
      paymentMethods: undefined,
      banksList: [],
    };
  },
  mounted() {
    this.$http
      .get(
        `/company-bank-accounts/list?page=1&per_page=200&status=Ativo&allow_payments=1`
      )
      .then(({ data }) => {
        this.banksList = data.data;
      });

    this.form.setFieldsValue({
      payment_methods_bank_transfer_total_value:
        this.tempContract.payment_methods_bank_transfer_total_value,
      payment_methods_bank_transfer_receipt_date:
        this.tempContract.payment_methods_bank_transfer_receipt_date,
    });

    setTimeout(() => {
      this.form.setFieldsValue({
        [`payment_methods_bank_transfer_bank`]:
          this.tempContract["payment_methods_bank_transfer_bank"] ==
            undefined ||
          this.tempContract["payment_methods_bank_transfer_bank"] == ""
            ? ""
            : this.tempContract["payment_methods_bank_transfer_bank"],
      });

      this.verifyTotal();
    }, 200);
  },
  methods: {
    verifyTotal() {
      this.$emit("verifyTotal");
    },
    dynamicRequiredFieldTransfer() {
      let flag = this.paymentMethods;

      if (flag != undefined) {
        flag = this.paymentMethods.includes("bank-transfer");
      } else {
        flag = false;
      }

      return flag;
    },
    async verifyValue(rule, value, callback) {
      rule;
      let theValue = parse(value),
        totalValue = parseFloat(this.reservation_total_price);
      if (isNaN(theValue) || theValue == undefined) {
        theValue = 0;
      } else {
        if (parse(value) > totalValue) {
          callback(
            `O valor preenchido ultrapassa o total da reserva (R$ ${this.reservation_total_price}).`
          );
        } else {
          callback();
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped></style>
