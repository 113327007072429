var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.sectionsRows),function(section,index){return _c('div',{key:index,class:`section section-${section.id}`},[_c('div',{staticClass:"product-card",on:{"click":function($event){return _vm.onClickOpenCollapse(section.id)}}},[_c('aRow',[_c('aCol',{attrs:{"span":22}},[_c('aRow',{attrs:{"type":"flex","justify":"start","gutter":20}},[_c('aCol',{staticClass:"id cprimary"},[_c('span',{staticClass:"ico"},[_c('i',{staticClass:"fas fa-plane-departure"}),_vm._v(" "),_c('span',{staticClass:"num"},[_vm._v(_vm._s(section.id)+" ")])])]),_vm._v(" "),_c('aCol',{staticClass:"id-name"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.theSection(section.id))}})])],1)],1),_vm._v(" "),_c('aCol',{staticClass:"a-right",attrs:{"span":2}},[(section.id == _vm.sectionsRows.length && section.id != 1)?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete","ghost":""},on:{"click":function($event){return _vm.removeSection(_vm.flightSectionId, section.id)}}}):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.openCollapse == section.id)?_c('div',{staticClass:"product-card-content"},[_c('aRow',{attrs:{"gutter":[20, 0]}},[_c('aCol',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"section-type"},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionId}_section_${section.id}_type`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionId}_section_${section.id}_type`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"options":[
                { label: 'Ida', value: 'Ida' },
                { label: 'Volta', value: 'Volta' },
                {
                  label: 'Conexão',
                  value: 'Conexão',
                },
              ]}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Origem")]),_vm._v(" "),_c('a-auto-complete',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionId}_section_${section.id}_origin`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionId}_section_${section.id}_origin`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Insira","option-label-prop":"value","dropdownMenuStyle":{ width: '300px' },"allowClear":""},on:{"change":_vm.searchOriginAiports}},[_c('template',{slot:"dataSource"},_vm._l((_vm.originAirportsList),function(airport,index){return _c('a-select-option',{key:index,staticClass:"dropdown-airports",attrs:{"value":airport.name}},[_c('div',{staticStyle:{"width":"500px"}},[_vm._v("\n                    "+_vm._s(airport.name)+"\n                    "),_c('a-divider',{attrs:{"type":"vertical"}}),_vm._v("\n                    "+_vm._s(airport.meta.airport_name)+"\n                  ")],1)])}),1),_vm._v(" "),_c('a-input',{staticClass:"travel-input",attrs:{"maxLength":"3","placeholder":"Origem"}},[_c('a-icon',{attrs:{"slot":"prefix","type":_vm.loadingOriginAirports ? 'loading' : 'field-svg'},slot:"prefix"})],1)],2)],1),_vm._v(" "),_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Destino")]),_vm._v(" "),_c('a-auto-complete',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionId}_section_${section.id}_destination`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionId}_section_${section.id}_destination`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Insira","option-label-prop":"value","dropdownMenuStyle":{ width: '300px' },"allowClear":""},on:{"change":_vm.searchDestinationAiports}},[_c('template',{slot:"dataSource"},_vm._l((_vm.destinationAirportsList),function(airport,index){return _c('a-select-option',{key:index,staticClass:"dropdown-airports",attrs:{"value":airport.name}},[_vm._v("\n                  "+_vm._s(airport.name)+"\n                  "),_c('a-divider',{attrs:{"type":"vertical"}}),_vm._v("\n                  "+_vm._s(airport.meta.airport_name)+"\n                ")],1)}),1),_vm._v(" "),_c('a-input',{staticClass:"travel-input",attrs:{"maxLength":"3","placeholder":"Destino"}},[_c('a-icon',{attrs:{"slot":"prefix","type":_vm.loadingDestinationAirports ? 'loading' : 'field-svg'},slot:"prefix"})],1)],2)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":8}},[_c('aRow',{attrs:{"gutter":10}},[_c('aCol',{attrs:{"span":14}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Data de embarque")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    `flight_${_vm.flightSectionId}_section_${section.id}_departure_date`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `flight_${flightSectionId}_section_${section.id}_departure_date`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira","format":"DD/MM/YYYY","valueFormat":"YYYY-MM-DD","showToday":false},on:{"change":function($event){return _vm.calcDuration(section.id)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":10}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Horário embarque")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"},{name:"decorator",rawName:"v-decorator",value:([
                    `flight_${_vm.flightSectionId}_section_${section.id}_departure_time`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `flight_${flightSectionId}_section_${section.id}_departure_time`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira"},on:{"change":function($event){return _vm.calcDuration(section.id)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1),_vm._v(" "),_c('aRow',{attrs:{"gutter":10}},[_c('aCol',{attrs:{"span":14}},[_c('a-form-item',[_c('label',{staticClass:"filled"},[_vm._v("Data de desembarque")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    `flight_${_vm.flightSectionId}_section_${section.id}_arrival_date`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `flight_${flightSectionId}_section_${section.id}_arrival_date`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira","format":"DD/MM/YYYY","valueFormat":"YYYY-MM-DD","showToday":false},on:{"change":function($event){return _vm.calcDuration(section.id)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":10}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Horário desembarque")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"},{name:"decorator",rawName:"v-decorator",value:([
                    `flight_${_vm.flightSectionId}_section_${section.id}_arrival_time`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `flight_${flightSectionId}_section_${section.id}_arrival_time`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira"},on:{"change":function($event){return _vm.calcDuration(section.id)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Classe")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionId}_section_${section.id}_class`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionId}_section_${section.id}_class`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"travel-input",attrs:{"show-search":"","placeholder":"Selecione"}},_vm._l((_vm.flightClasses),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n                "+_vm._s(item.name.toUpperCase())+"\n              ")])}),1)],1),_vm._v(" "),_c('a-form-item',{staticClass:"travel-input"},[_c('label',{class:'filled'},[_vm._v("Duração do vôo")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"},{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionId}_section_${section.id}_duration`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Modifique as datas',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionId}_section_${section.id}_duration`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Modifique as datas',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"readonly",attrs:{"placeholder":"Insira"}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":7}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Companhia aérea")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionId}_section_${section.id}_airline`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionId}_section_${section.id}_airline`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","show-search":"","allow-clear":true}},_vm._l((_vm.airlinesList),function(airline){return _c('a-select-option',{key:airline.id,attrs:{"value":airline.name}},[(airline.logo)?_c('img',{attrs:{"src":airline.logo,"width":"13"}}):_vm._e(),_vm._v("\n                "+_vm._s(airline.name)+"\n              ")])}),1)],1),_vm._v(" "),_c('aRow',{attrs:{"gutter":10}},[_c('aCol',{attrs:{"span":12}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Nº do vôo")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `flight_${_vm.flightSectionId}_section_${section.id}_number`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `flight_${flightSectionId}_section_${section.id}_number`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"type":"number","placeholder":"Insira"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1)],1)],1):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }