var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":_vm.$root.reponsive([
          {
            min: 0,
            max: 620,
            span: 24,
          },
          {
            min: 621,
            max: 1300,
            span: 24,
          },
          {
            min: 1300,
            max: 9999,
            span: 12,
          },
        ])}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Hotel / Resort\n          "),(
              _vm.tempContract[`hotel_${_vm.hotelID}_name_infotera`] &&
              !_vm.tempContract[`hotel_${_vm.hotelID}_name`]
            )?_c('font',{staticClass:"cblack",staticStyle:{"font-size":"9px"}},[_vm._v("\n            -\n            "),_c('b',[_vm._v("\n              "+_vm._s(_vm.tempContract[`hotel_${_vm.hotelID}_name_infotera`])+"\n            ")])]):_vm._e()],1),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `hotel_${_vm.hotelID}_name`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `hotel_${hotelID}_name`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"show-search":"","allow-clear":true,"placeholder":"Selecione o Hotel ou Resort","optionFilterProp":"txt"},on:{"change":_vm.onHotelSelect}},_vm._l((_vm.removeDuplicatedHotels(
              _vm.productSupplier.list
            )),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.trading_name,"txt":item.trading_name}},[_vm._v("\n            "+_vm._s(item.trading_name)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.$root.reponsive([
          {
            min: 0,
            max: 620,
            span: 12,
          },
          {
            min: 621,
            max: 1300,
            span: 12,
          },
          {
            min: 1300,
            max: 9999,
            span: 6,
          },
        ])}},[_c('HayaTypeDateField',{attrs:{"label":"Check-in","fieldKey":`hotel_${_vm.hotelID}_checkin_typed`,"fieldToTransferValue":`hotel_${_vm.hotelID}_checkin`,"form":_vm.form,"required":true},on:{"onChangeDatePicker":_vm.onChangeDatePicker}}),_vm._v(" "),_c('a-form-item',{staticClass:"none"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`hotel_${_vm.hotelID}_checkin`]),expression:"[`hotel_${hotelID}_checkin`]"}]})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.$root.reponsive([
          {
            min: 0,
            max: 620,
            span: 12,
          },
          {
            min: 621,
            max: 1300,
            span: 12,
          },
          {
            min: 1300,
            max: 9999,
            span: 6,
          },
        ])}},[_c('HayaTypeDateField',{attrs:{"label":"Check-out","fieldKey":`hotel_${_vm.hotelID}_checkout_typed`,"fieldToTransferValue":`hotel_${_vm.hotelID}_checkout`,"form":_vm.form,"required":true},on:{"onBlurHayaTypeDateField":_vm.onBlurHayaTypeDateField,"onChangeDatePicker":_vm.onChangeDatePicker}}),_vm._v(" "),_c('a-form-item',{staticClass:"none"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`hotel_${_vm.hotelID}_checkout`]),expression:"[`hotel_${hotelID}_checkout`]"}]})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.$root.reponsive([
          {
            min: 0,
            max: 620,
            span: 12,
          },
          {
            min: 621,
            max: 1300,
            span: 12,
          },
          {
            min: 1300,
            max: 9999,
            span: 4,
          },
        ])}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Nº de dias")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'hotel_' + _vm.hotelID + '_rates',
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'hotel_' + hotelID + '_rates',\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"type":"number","placeholder":"Digite"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.$root.reponsive([
          {
            min: 0,
            max: 620,
            span: 12,
          },
          {
            min: 621,
            max: 1300,
            span: 12,
          },
          {
            min: 1300,
            max: 9999,
            span: 4,
          },
        ])}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("N° de noites ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['hotel_' + _vm.hotelID + '_nights']),expression:"['hotel_' + hotelID + '_nights']"}],staticClass:"travel-input",attrs:{"type":"number","placeholder":"N° noites"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.$root.reponsive([
          {
            min: 0,
            max: 620,
            span: 12,
          },
          {
            min: 621,
            max: 1300,
            span: 12,
          },
          {
            min: 1300,
            max: 9999,
            span: 8,
          },
        ])}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Regime alimentar\n          "),(
              _vm.tempContract[`hotel_${_vm.hotelID}_diet_infotera`] &&
              !_vm.tempContract[`hotel_${_vm.hotelID}_diet`]
            )?_c('span',{staticClass:"cgray",staticStyle:{"font-size":"9px"}},[_vm._v("\n            -\n            "+_vm._s(_vm.tempContract[`hotel_${_vm.hotelID}_diet_infotera`])+"\n          ")]):_vm._e()]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `hotel_${_vm.hotelID}_diet`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `hotel_${hotelID}_diet`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"show-search":"","placeholder":"Regime alimentar"}},_vm._l((_vm.diets),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n            "+_vm._s(item.name.toUpperCase())+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.$root.reponsive([
          {
            min: 0,
            max: 620,
            span: 12,
          },
          {
            min: 621,
            max: 1300,
            span: 12,
          },
          {
            min: 1300,
            max: 9999,
            span: 8,
          },
        ])}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Localizador")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'hotel_' + _vm.hotelID + '_locator',
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'hotel_' + hotelID + '_locator',\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Localizador"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1),_vm._v(" "),_c('HotelAccommodationForm',{attrs:{"id":_vm.id,"hotelID":_vm.hotelID,"tempContract":_vm.tempContract,"form":_vm.form,"hotelRows":_vm.hotelRows,"hotel":_vm.hotel,"accommodationRows":_vm.hotel.accommodation,"theTravellers":_vm.theTravellers,"travellers":_vm.travellers}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }