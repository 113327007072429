<template>
  <div>
    <a-row>
      <a-col
        v-for="(flightSection, index) in flightSectionRows"
        :key="index"
        :span="24"
      >
        <div
          class="product-card"
          @click="onClickOpenCollapse(flightSection.id)"
        >
          <a-row :gutter="20">
            <a-col class="id cprimary" :span="1">
              <span class="ico">
                <i class="fas fa-plane"></i>
                <font class="num">{{ flightSection.id }}</font>
              </span>
            </a-col>

            <a-col class="id-name upper" :span="21">
              AÉREO
              {{
                tempContract[`flight_${flightSection.id}_type`]
                  ? tempContract[`flight_${flightSection.id}_type`]
                  : ""
              }}
            </a-col>

            <a-col class="a-right" :span="2">
              <a-button
                v-if="
                  flightSectionRows.length == flightSection.id &&
                    flightSection.id != 1
                "
                size="small"
                type="danger"
                shape="circle"
                icon="delete"
                @click="removeFlightSectionRow(flightSection.id)"
                ghost
              >
              </a-button>
            </a-col>
          </a-row>
        </div>

        <div
          class="product-card-content"
          v-if="openCollapse == flightSection.id"
        >
          <a-form-item class="flight-type">
            <a-radio-group
              :options="[
                {
                  label: 'Pagamento Regular',
                  value: 'Regular',
                  disabled: milesOrderExists(flightSection.id),
                },
                {
                  label:
                    tempContract.status == 'new'
                      ? 'Pagamento com Milhas (salve o contrato para liberar)'
                      : 'Pagamento com Milhas',
                  value: 'Milhas',
                  disabled: tempContract.status != 'new' ? false : true,
                },
              ]"
              v-decorator="[
                `flight_${flightSection.id}_type`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
                ,
              ]"
            />
          </a-form-item>


          <div :class="`travellers`">

            <div
              v-if="tempContract[`flight_${flightSection.id}_type`] == 'Milhas'"
            >
              <FlightSectionAirlinesCollapse
                :flightSectionId="flightSection.id"
                :airlinesRows="flightSection.airlines"
                :travellers="travellers"
                :milesOrderExists="milesOrderExists(flightSection.id)"
                :tempContract="tempContract"
                :form="form"
                @removeAirline="removeAirline"
              />

              <div class="a-center mt-20 mb-20">
                <a-button
                  class="f12"
                  size="small"
                  @click="
                    addAirline(flightSection.id, flightSection.airlines.length)
                  "
                >
                  Adicionar cia
                </a-button>
              </div>
            </div>

            <FlightSectionTravellersCollapse
              :flightSectionId="flightSection.id"
              :travellersRows="flightSection.travellers"
              :travellers="travellers"
              :milesOrderExists="milesOrderExists(flightSection.id)"
              :tempContract="tempContract"
              :form="form"
              @removeTraveller="removeTraveller"
            />

            <div class="a-center mt-20 mb-20">
              <a-row>
                <a-col :span="4"> </a-col>
                <a-col :span="16">
                  <a-button
                    class="f12"
                    size="small"
                    @click="
                      addTraveller(
                        flightSection.id,
                        flightSection.travellers.length
                      )
                    "
                  >
                    Adicionar viajante
                  </a-button>
                </a-col>
                <a-col class="a-right" :span="4">
                  <a-tooltip
                    placement="topRight"
                    title="Adicionar todos viajantes"
                  >
                    <a-button
                      class="f10"
                      size="small"
                      type="primary"
                      shape="circle"
                      @click="addAllTravellers(flightSection.id, 'flight')"
                    >
                      <a-icon type="usergroup-add" style="font-size: 17px" />
                    </a-button>
                  </a-tooltip>
                </a-col>
              </a-row>
            </div>
          </div>

          <div :class="`sections`">
            <div
              class="product-card internal"
              @click="onClickOpenInternalCollapse(`s-${flightSection.id}`)"
            >
              <a-row>
                <a-col :span="20">
                  <a-row type="flex" justify="start">
                    <a-col class="id cprimary">
                      <span class="ico">
                        <i class="fas fa-globe-americas"></i>
                      </span>
                    </a-col>

                    <a-col class="id-name">
                      TRECHOS
                      <font class="count f8"
                        >({{ flightSection.sections.length }})</font
                      >
                    </a-col>
                  </a-row></a-col
                >
              </a-row>
            </div>

            <div
              class="product-card-content internal"
              v-if="openInternalCollapse == `s-${flightSection.id}`"
            >
              <FlightSectionSectionsCollapse
                :flightSectionId="flightSection.id"
                :sectionsRows="flightSection.sections"
                :tempContract="tempContract"
                :form="form"
                @removeSection="removeSection"
              />

              <div class="a-center mt-20">
                <a-button
                  class="f12"
                  size="small"
                  @click="
                    addSection(flightSection.id, flightSection.sections.length)
                  "
                >
                  Adicionar trecho
                </a-button>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="flightSectionRows.length == flightSection.id"
          class="a-center"
        >
          <a-row>
            <a-col :span="4"> </a-col>
            <a-col :span="16">
              <a-button
                class="cprimary mt-10"
                @click="addFlightSectionRow(flightSection.id)"
              >
                Adicionar aéreo
              </a-button>
            </a-col>
            <a-col class="a-right" :span="4">
              <span
                class="ticketed-btn"
                v-if="tempContract.request_miles_status == true"
              >
                <a-popconfirm
                  ok-text="Sim"
                  cancel-text="Não"
                  @confirm="confirmTicketed"
                  @cancel="cancelTicketed"
                >
                  <template slot="title">
                    Tem certeza que deseja marcar o ticket
                    <br />
                    aéreo como bilhetado?
                  </template>
                  <a-checkbox
                    v-model="ticketed"
                    style="
                      font-size: 13px;
                      font-weight: 500;
                      position: relative;
                  "
                  >
                    Bilhetado
                  </a-checkbox>
                </a-popconfirm>
              </span>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import FlightSectionAirlinesCollapse from "../collapses/FlightSectionAirlinesCollapse.vue";
import FlightSectionSectionsCollapse from "../collapses/FlightSectionSectionsCollapse.vue";
import FlightSectionTravellersCollapse from "../collapses/FlightSectionTravellersCollapse.vue";

export default {
  name: "FlightTab",
  components: {
    FlightSectionTravellersCollapse,
    FlightSectionSectionsCollapse,
    FlightSectionAirlinesCollapse,
  },
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    travellers: Array,
  },
  data() {
    return {
      flightSectionRows: [],
      openCollapse: -1,
      openInternalCollapse: -1,
    };
  },
  beforeMount() {
    if (this.tempContract.flight_sections_rows == undefined) {
      this.tempContract.flight_sections_rows = JSON.stringify([
        {
          id: 1,
          travellers: [{ id: 1 }],
          airlines: [{ id: 1 }],
          sections: [{ id: 1 }],
          ticketed: false,
        },
      ]);
    }

    this.flightSectionRows =
      this.tempContract.flight_sections_rows != undefined
        ? JSON.parse(this.tempContract.flight_sections_rows)
        : [];

    if (this.flightSectionRows.length > 0) {
      this.flightSectionRows.forEach((flightSection, index) => {
        index;
        setTimeout(() => {
          this.form.setFieldsValue({
            [`flight_${flightSection.id}_type`]: this.tempContract[
              `flight_${flightSection.id}_type`
            ],
          });
        });
      });
    }

    this.ticketed = this.tempContract.flight_is_ticketed == "1" ? true : false;
  },
  methods: {
    onClickOpenInternalCollapse(id) {
      if (id == this.openInternalCollapse) {
        this.openInternalCollapse = -1;
      } else {
        this.openInternalCollapse = id;
      }
    },
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = -1;
      } else {
        this.openCollapse = id;
      }

      this.openInternalCollapse = -1;

      setTimeout(() => {
        this.form.setFieldsValue({
          [`flight_${id}_type`]: this.tempContract[`flight_${id}_type`],
        });
      }, 1);
    },
    confirmTicketed() {
      this.ticketed = true;
      setTimeout(() => {
        this.tempContract.flight_is_ticketed = true;

        this.$http.post("/log/create", {
          user_id: this.$store.state.userData.id,
          module_id: this.tempContract.id,
          module: "contract",
          action: "flight-ticketed-contract",
          description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} marcou o ticket aéreo como Bilhetado no contrato ID ${this.tempContract.id}.`,
        });
      }, 20);
    },
    cancelTicketed() {
      this.ticketed = false;
      setTimeout(() => {
        this.tempContract.flight_is_ticketed = false;
      }, 20);
    },

    addAllTravellers(id, type) {
      let travellers = [];

      this.travellers.forEach((traveller) => {
        if (traveller.name && traveller.lastName) {
          travellers.push({ id: traveller.id });
        }
      });

      if (travellers.length > 0) {
        if (type == "flight") {
          for (let i in this.flightSectionRows) {
            if (this.flightSectionRows[i].id == id) {
              this.flightSectionRows[i].travellers = travellers;
              break;
            }
          }

          setTimeout(() => {
            travellers.forEach((traveller) => {
              this.form.setFieldsValue({
                [`flight_${id}_traveller_${traveller.id}`]: traveller.id,
              });

              setTimeout(() => {
                this.tempContract[
                  `flight_${id}_traveller_${traveller.id}_age`
                ] = this.tempContract[`traveller_${traveller.id}_age`];
              }, 20);
            });
          }, 10);

          this.tempContract.flight_sections_rows = JSON.stringify(
            this.flightSectionRows
          );
        }
      } else {
        ///
        this.$message.warning("Preencha os viajantes.");
      }

      setTimeout(() => {
        this.openInternalCollapse = `t-${id}`;
      }, 1);
    },

    addSection(flightSectionID, id) {
      this.flightSectionRows[flightSectionID - 1].sections.push({
        id: id + 1,
      });

      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
    removeSection(flightSectionID, id) {
      for (var i in this.flightSectionRows[flightSectionID - 1].sections) {
        if (this.flightSectionRows[flightSectionID - 1].sections[i].id == id) {
          this.flightSectionRows[flightSectionID - 1].sections.splice(i, 1);
          break;
        }
      }
      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
    addAirline(flightSectionID, id) {
      this.flightSectionRows[flightSectionID - 1].airlines.push({
        id: id + 1,
      });

      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
    removeAirline(flightSectionID, id) {
      for (var i in this.flightSectionRows[flightSectionID - 1].airlines) {
        if (this.flightSectionRows[flightSectionID - 1].airlines[i].id == id) {
          this.flightSectionRows[flightSectionID - 1].airlines.splice(i, 1);
          break;
        }
      }
      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
    addTraveller(flightSectionID, id) {
      this.flightSectionRows[flightSectionID - 1].travellers.push({
        id: id + 1,
      });

      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
    removeTraveller(flightSectionID, id) {
      for (var i in this.flightSectionRows[flightSectionID - 1].travellers) {
        if (
          this.flightSectionRows[flightSectionID - 1].travellers[i].id == id
        ) {
          this.flightSectionRows[flightSectionID - 1].travellers.splice(i, 1);
          break;
        }
      }
      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
    milesOrderExists(flightId) {
      let flag = false;

      if (!this.$root.isAdmin()) {
        if (this.flightSectionRows.length > 0) {
          this.flightSectionRows[flightId - 1].airlines.forEach((airline) => {
            console.log(
              airline.id,
              this.tempContract[
                `flight_${flightId}_airline_${airline.id}_miles_order_id`
              ]
            );
            if (
              this.tempContract[
                `flight_${flightId}_airline_${airline.id}_miles_order_id`
              ] != undefined
            ) {
              flag = true;
            }
          });
        }
      }

      return flag;
    },
    addFlightSectionRow(id) {
      this.flightSectionRows.push({
        id: id + 1,
        travellers: [{ id: 1 }],
        airlines: [{ id: 1 }],
        sections: [{ id: 1 }],
      });

      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
    removeFlightSectionRow(id) {
      for (var i in this.flightSectionRows) {
        if (this.flightSectionRows[i].id == id) {
          this.flightSectionRows.splice(i, 1);
          break;
        }
      }

      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.ticketed-btn
  font-weight: 600
  font-size: 14px
  border-radius: 10px
  margin-bottom: 14px
  position: relative
  border-color: #ddd !important
  .ant-checkbox
    top: 0.1px !important
  label
    overflow: visible !important
    font-weight: 500 !important
    color: #666 !important
    transition: .3s !important
    opacity: 1 !important
    top: 6px !important


.product-card-content
  padding: 20px 15px 10px
  border: 1px solid #ccc
  margin-top: -14px
  border-radius: 0 0 6px 6px
  margin-bottom: 11px
  background: #fff
  .flight-type
    max-height: 34px !important

.product-card-content.internal
  padding: 15px

.product-card.internal
  padding:  10px 20px
.product-card
  cursor: pointer
  clear: both
  padding:  20px
  background: #fff
  border: 1px solid #ccc
  margin-bottom: 10px
  border-radius: 6px
  .id
    color: #474747
    font-weight: 600
    .ico
      position: relative
    .num
      position: absolute
      right: -13px
      top: 0
      font-size: 11px
      color: #333
  .id-name
    padding-left: 20px !important
    color: #42413f
    font-weight: 500
</style>

<style lang="css">
.flight-type label {
  position: relative !important;
  opacity: 1 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #333 !important;
  top: -10px !important;
  left: unset !important;
  width: unset !important;
}
</style>
