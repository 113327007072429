<template>
  <div>
    <div class="payments">
      <a-form-item class="options payments-options">
        <a-checkbox-group
          v-decorator="[
            'voucher_payments_method',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
          @change="onVoucherPaymentChange"
        >
          <a-checkbox class="mr-20" value="voucher-payment">
            Voucher
          </a-checkbox>
          <a-checkbox
            value="credit-card"
            :disabled="creditCardFieldsDisabled()"
          >
            Cartão de Crédito
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>

      <a-row class="status-bar">
        <a-col :span="12">
          <strong>TOTAL TAXAS </strong>
          <a-tag class="ml-10" :color="'green'">
            {{
              formatPriceCurrency(
                milesTaxesPayments.totalTaxesWithCurrency,
                milesTaxesPayments.currency
              )
            }}
          </a-tag>

          <font
            class="equality"
            style="
              font-size: 19px;
              margin-bottom: 0;
              position: relative;
              top: 4px;
              font-weight: 500;
              color: rgb(76, 56, 141);
            "
          >
            <span v-if="calculateVoucherTotalValue().status">
              <a-icon type="swap" />
            </span>

            <span v-if="!calculateVoucherTotalValue().status">
              <a-icon type="swap-right" />
            </span>
          </font>

          <a-tag
            class="ml-10"
            :color="!calculateVoucherTotalValue().status ? 'red' : 'blue'"
          >
            {{
              formatPriceCurrency(
                calculateVoucherTotalValue().total,
                milesTaxesPayments.currency
              )
            }}
          </a-tag>
        </a-col>

        <a-col :span="4">
          <div
            style="
              font-size: 11px;
              font-weight: 600;
              letter-spacing: -0.4px;
              color: rgb(68, 68, 68);
              position: relative;
              top: 7px;
            "
          >
            Câmbio ({{ formatCurrency(milesTaxesPayments.currency) }}
            para R$)
          </div>
        </a-col>

        <a-col :span="5">
          <a-form-item style="margin: -6px 0">
            <a-input
              class="travel-input"
              placeholder="Câmbio"
              v-currency="{
                currency: 'BRL',
              }"
              v-decorator="[
                `voucher_payments_exchange`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório.',
                    },
                  ],
                },
              ]"
              @blur="calcExchange"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <div v-if="form.getFieldValue('voucher_payments_method') != undefined">
        <div
          v-show="form.getFieldValue('voucher_payments_method').length"
          class="portal-tabs horizontal payment-tabs"
        >
          <div class="tabs">
            <a-form-item style="display: none">
              <span class="travel-input">
                <a-input
                  v-decorator="[
                    `voucher_rows`,
                    {
                      initialValue: JSON.stringify(voucherRows),
                    },
                  ]"
                >
                </a-input>
              </span>
            </a-form-item>

            <a-row :gutter="10">
              <a-col
                v-show="
                  form
                    .getFieldValue('voucher_payments_method')
                    .includes('voucher-payment')
                "
                @click="portalActiveTab('voucher-payment')"
                :span="6"
              >
                <div
                  class="tab"
                  :class="activeTab == 'voucher-payment' ? 'active' : ''"
                >
                  <div>Voucher</div>
                  <a-tag class="ml-10" color="blue">
                    {{ totalVoucher() }}
                  </a-tag>
                </div>
              </a-col>

              <a-col
                v-show="
                  form
                    .getFieldValue('voucher_payments_method')
                    .includes('credit-card')
                "
                @click="portalActiveTab('credit-card')"
                :span="6"
              >
                <div
                  class="tab"
                  :class="activeTab == 'credit-card' ? 'active' : ''"
                >
                  <div>Cartão de Crédito</div>
                  <a-tag class="ml-10" color="blue">
                    {{
                      formatPriceCurrency(
                        form.getFieldValue(
                          `voucher_payments_credit_card_value`
                        ),
                        milesTaxesPayments.currency
                      )
                    }}
                  </a-tag>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>

      <div
        class="tabs-content payment-tabs"
        v-show="activeTab == 'voucher-payment'"
      >
        <a-row
          v-for="(voucher, index) in voucherRows"
          :key="index"
          :gutter="20"
        >
          <a-col :span="8">
            <a-form-item>
              <label
                :class="
                  form.getFieldValue(
                    `voucher_payments_voucher_${voucher.id}_value`
                  )
                    ? 'filled'
                    : ''
                "
                >Valor do Voucher
              </label>
              <a-input
                class="travel-input"
                placeholder="Valor do Voucher"
                :disabled="!verifyCodeStatus(voucher.id)"
                v-currency="{
                  currency: milesTaxesPayments.currency,
                }"
                @keyup="verifyTotal"
                v-decorator="[
                  `voucher_payments_voucher_${voucher.id}_value`,
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório.',
                      },
                      {
                        validator: verifyValue,
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item>
              <label
                :class="
                  form.getFieldValue(
                    `voucher_payments_voucher_${voucher.id}_code`
                  )
                    ? 'filled'
                    : ''
                "
                >Código do Voucher</label
              >
              <a-input
                class="travel-input"
                placeholder="Código do Voucher"
                :disabled="!verifyCodeStatus(voucher.id)"
                v-decorator="[
                  `voucher_payments_voucher_${voucher.id}_code`,
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório.',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="3">
            <span
              v-if="verifyCodeStatus(voucher.id)"
              class="relative"
              style="top: 10px"
            >
              <a-popconfirm
                ok-text="Sim"
                placement="bottom"
                cancel-text="Não"
                @confirm="verifyVoucherCode(voucher.id)"
                :disabled="disableSearch(voucher.id)"
              >
                <template slot="title">
                  Tem certeza que deseja utilizar <br />
                  o voucher?
                </template>
                <a-button
                  type="primary"
                  shape="circle"
                  size="small"
                  icon="search"
                  :disabled="disableSearch(voucher.id)"
                  :loading="codeVerifyLoading"
                />
              </a-popconfirm>
            </span>
            <div v-else class="relative" style="top: 10px">
              <a-tooltip>
                <template slot="title"> Voucher Utilizado! </template>
                <a-button
                  class="btn-green"
                  type="primary"
                  shape="circle"
                  size="small"
                  icon="check"
                />
              </a-tooltip>

              <a-popconfirm
                ok-text="Sim"
                placement="bottom"
                cancel-text="Não"
                @confirm="cancelVoucher(voucher)"
              >
                <template slot="title">
                  Tem certeza que deseja cancelar o <br />
                  valor emitido do voucher?
                </template>

                <a-tooltip>
                  <template slot="title"> Cancelar </template>
                  <a-button
                    class="ml-10"
                    type="danger"
                    shape="circle"
                    icon="close"
                    size="small"
                  />
                </a-tooltip>
              </a-popconfirm>
            </div>

            <span
              class="relative"
              style="top: 10px"
              v-if="verifyCodeStatus(voucher.id)"
            >
              <a-button
                class="ml-10"
                type="danger"
                shape="circle"
                size="small"
                icon="delete"
                v-if="
                  voucherRows.length == voucher.id && voucherRows.length != 1
                "
                @click="removeVoucherRow(voucher.id)"
              />
            </span>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col class="a-center mt-20" :span="24">
            <a-button @click="addVoucherRow(voucherRows.length)" type="primary">
              Adicionar Voucher
            </a-button>
          </a-col>
        </a-row>
      </div>

      <div
        class="tabs-content payment-tabs"
        v-show="activeTab == 'credit-card'"
      >
        <a-row :gutter="20">
          <a-col v-if="!creditCardId" class="mb-20" :span="24">
            <div class="mb-20">
              <a-alert
                style="font-size: 12px"
                type="info"
                message="Selecione um cartão de crédito na lista abaixo:"
                banner
              />
            </div>
            <a-table
              class="travel-table last-itens"
              :data-source="companyCreditCards.list"
              :pagination="false"
              :loading="companyCreditCards.loading"
              :columns="[
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  class: 'id',
                  width: 40,
                },
                {
                  title: 'Proprietário do cartão',
                  scopedSlots: { customRender: 'customer' },
                },
                {
                  title: 'Número do cartão',
                  dataIndex: 'card_number',
                  key: 'card_number',
                  class: 'card_number',
                },
                {
                  title: 'Disponível',
                  scopedSlots: {
                    customRender: 'available_value',
                  },
                },
                {
                  title: '',
                  align: 'right',
                  fixed: 'right',
                  scopedSlots: {
                    customRender: 'action',
                  },
                },
              ]"
              @change="companyCreditCardsTableChange"
            >
              <template slot="id" slot-scope="record">
                {{ record.id }}
              </template>

              <div slot="customer" slot-scope="record">
                {{ record.customer.first_name }}
                {{ record.customer.last_name }}
              </div>

              <template slot="available_value" slot-scope="record">
                <div class="cgreen">
                  {{ formatPricePtBr(record.available_value) }}
                </div>
              </template>

              <div slot="action" slot-scope="record">
                <a-popconfirm
                  ok-text="Sim"
                  placement="left"
                  cancel-text="Não"
                  @confirm="selectCreditCard(record)"
                >
                  <template slot="title">
                    Tem certeza que deseja selecionar<br />
                    este cartão de crédito?
                  </template>
                  <a-button
                    type="link"
                    :loading="loadingMilesEmission"
                    style="padding: 0"
                  >
                    <i class="cgreen far fa-caret-square-right"></i>
                  </a-button>
                </a-popconfirm>
              </div>
            </a-table>

            <div class="a-center mt-30">
              <a-pagination
                :default-current="companyCreditCards.pagination.page"
                :total="companyCreditCards.pagination.total"
                @change="changeCompanyCreditCardsPage"
                @showSizeChange="changeCompanyCreditCardsPageSize"
              />
            </div>
          </a-col>

          <!-- <a-divider v-if="!creditCardId" /> -->

          <a-col v-if="creditCardId" class="mt-20" :span="24">
            <a-row :gutter="20">
              <a-col :span="9">
                <a-form-item class="travel-input-outer">
                  <label
                    :class="
                      form.getFieldValue(`voucher_payments_credit_card_id`)
                        ? 'filled'
                        : ''
                    "
                    >Cartão de Crédito</label
                  >
                  <a-select
                    class="travel-input"
                    show-search
                    placeholder="Cartão de Crédito"
                    :disabled="true"
                    v-decorator="[
                      'voucher_payments_credit_card_id',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-select-option
                      v-for="(item, index) of companyCreditCards.list"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.card_name }} -
                      {{ item.card_number }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="1" style="padding: 0">
                <a-tooltip placement="top" title="Ver cartão">
                  <a class="edit cgray" @click="viewCreditCard()">
                    <a-icon
                      class="relative cgreen"
                      type="credit-card"
                      style="font-size: 20px; top: 11px"
                    />
                    <!-- <a-icon type="eye" /> -->
                  </a>
                </a-tooltip>

                <a-modal
                  class="miles-card-modal"
                  v-model="viewCompanyCreditCard"
                  width="1000px"
                  :footer="false"
                  @cancel="closeCreditCardModal"
                >
                  <div v-show="loadingModal">
                    <a-skeleton active />
                    <a-skeleton active />
                    <a-skeleton active />
                    <a-skeleton active />
                  </div>
                  <CompanyCreditCardsModal
                    v-show="!loadingModal"
                    :viewOnly="true"
                    :creditCardId="creditCardId"
                    @loadStatus="loadStatus"
                    v-if="viewCompanyCreditCard"
                  />
                </a-modal>
              </a-col>

              <a-col :span="6">
                <a-form-item>
                  <label
                    :class="
                      form.getFieldValue(
                        `voucher_payments_credit_card_exchange`
                      )
                        ? 'filled'
                        : 'filled'
                    "
                    >Valor a ser debitado em
                    {{ formatCurrency(milesTaxesPayments.currency) }}
                  </label>
                  <a-input
                    class="travel-input"
                    :placeholder="`Valor a ser debitado em ${formatCurrency(
                      milesTaxesPayments.currency
                    )}`"
                    :disabled="creditCardFieldsDisabled()"
                    v-currency="{
                      currency: milesTaxesPayments.currency,
                    }"
                    @keyup="verifyTotal"
                    @blur="calcExchange"
                    v-decorator="[
                      `voucher_payments_credit_card_value`,
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório.',
                          },
                          {
                            validator: verifyValue,
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-input>
                </a-form-item>
              </a-col>

              <a-col :span="6">
                <a-form-item>
                  <label
                    :class="
                      form.getFieldValue(
                        `voucher_payments_credit_card_value_brl`
                      )
                        ? 'filled'
                        : 'filled'
                    "
                    >Valor a ser debitado em R$
                  </label>
                  <a-input
                    class="travel-input"
                    placeholder="Valor a ser debitado em R$ "
                    :disabled="true"
                    v-decorator="[
                      `voucher_payments_credit_card_value_brl`,
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório.',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-input>
                </a-form-item>
              </a-col>

              <a-col v-if="!creditCardFieldsDisabled()" :span="2">
                <a-popconfirm
                  ok-text="Sim"
                  placement="bottom"
                  cancel-text="Não"
                  @confirm="removeCreditCard()"
                >
                  <template slot="title">
                    Tem certeza que deseja remover o <br />
                    cartão de crédito?
                  </template>

                  <a-tooltip>
                    <template slot="title"> Remover cartão </template>
                    <a-button
                      class="ml-10 relative"
                      type="danger"
                      shape="circle"
                      icon="close"
                      size="small"
                      style="top: 6px"
                    />
                  </a-tooltip>
                </a-popconfirm>
              </a-col>
            </a-row>
          </a-col>
          <a-col v-if="creditCardId" class="a-center" :span="24">
            <a-popconfirm
              v-if="!creditCardFieldsDisabled()"
              ok-text="Sim"
              cancel-text="Não"
              placement="bottom"
              @confirm="confirmCreditCardDebit"
              :loading="loadingCreditCardDebit"
            >
              <template slot="title">
                Tem certeza que deseja debitar <br />
                R$
                {{ tempContract["voucher_payments_credit_card_value_brl"] }}
                no cartão de crédito?
              </template>
              <a-button :disabled="loadingCreditCardDebit" type="primary">
                Confirmar débito no cartão
              </a-button>
            </a-popconfirm>

            <a-popconfirm
              v-if="creditCardFieldsDisabled()"
              ok-text="Sim"
              cancel-text="Não"
              placement="bottom"
              @confirm="confirmCreditCardExtortion"
              :loading="loadingCreditCardExtortion"
            >
              <template slot="title">
                Tem certeza que deseja estornar <br />
                R$
                {{ tempContract["voucher_payments_credit_card_value_brl"] }}
                no cartão de crédito?
              </template>
              <a-button :disabled="loadingCreditCardExtortion" type="danger">
                Estornar valor no cartão
              </a-button>
            </a-popconfirm>
          </a-col>
        </a-row>
      </div>
    </div>

    <pre
      id="live-debug"
      class="mt-40"
      style="display: none; height: 300px; width: 100%; overflow: auto"
      >{{ tempContract }}</pre
    >
  </div>
</template>

<script>
import axios from "axios";
import { parse } from "vue-currency-input";

import formatThings from "@/mixins/general/formatThings.js";
import companyCreditCardMixins from "@/mixins/company-credit-cards/companyCreditCardMixins";
import CompanyCreditCardsModal from "../../company-credit-cards/modal/CompanyCreditCardsModal.vue";

export default {
  name: "VoucherPaymentsModal",
  mixins: [formatThings, companyCreditCardMixins],
  props: {
    form: Object,
    tempContract: Object,
    milesTaxesPayments: Object,
  },
  components: {
    CompanyCreditCardsModal,
  },
  data() {
    return {
      voucherRows: [{ id: 1 }],
      codeVerifyLoading: false,
      creditCardsList: [],
      creditCardId: "",
      loadingCreditCardDebit: false,
      loadingCreditCardExtortion: false,
      activeTab: null,
      viewCompanyCreditCard: false,
      loadingModal: true,
    };
  },
  mounted() {
    this.form.setFieldsValue({
      voucher_payments_method:
        this.tempContract["voucher_payments_method"] != undefined
          ? JSON.parse(this.tempContract["voucher_payments_method"])
          : ["voucher-payment"],
    });

    //this.getCreditCards();
    this.companyCreditCards.filters.showInContract = 1;
    this.getCompanyCreditCards();

    this.voucherRows =
      this.tempContract["voucher_rows"] != undefined
        ? JSON.parse(this.tempContract["voucher_rows"])
        : [{ id: 1 }];

    this.form.setFieldsValue({
      [`voucher_rows`]: JSON.stringify(this.voucherRows),
    });

    setTimeout(() => {
      this.setFieldsValue();
    }, 100);

    if (this.tempContract["voucher_payments_credit_card_id"]) {
      this.creditCardId = this.tempContract["voucher_payments_credit_card_id"];
    }

    if (this.form.getFieldValue("voucher_payments_method") != undefined) {
      if (
        this.form
          .getFieldValue("voucher_payments_method")
          .includes("voucher-payment")
      ) {
        this.activeTab = "voucher-payment";
      } else {
        if (
          this.form
            .getFieldValue("voucher_payments_method")
            .includes("credit-card")
        ) {
          this.activeTab = "credit-card";
        }
      }
    }

    axios
      .get("https://economia.awesomeapi.com.br/last/USD-BRL,EUR-BRL")
      .then(({ data }) => {
        if (this.tempContract["voucher_payments_exchange"] == undefined) {
          this.form.setFieldsValue({
            [`voucher_payments_exchange`]: "1,00",
          });

          // DOLAR
          if (this.milesTaxesPayments.currency == "USD") {
            this.form.setFieldsValue({
              [`voucher_payments_exchange`]: data.USDBRL.high.replace(".", ","),
            });
          }

          // EURO
          if (this.milesTaxesPayments.currency == "EUR") {
            this.form.setFieldsValue({
              [`voucher_payments_exchange`]: data.EURBRL.high.replace(".", ","),
            });
          }

          this.calcExchange();
        }
      });
  },
  methods: {
    viewCreditCard() {
      this.viewCompanyCreditCard = true;

      this.$http
        .post("/log/create", {
          user_id: this.$store.state.userData.id,
          module_id: this.tempContract.id,
          module: "contract",
          action: "view-credit-card-contract",
          description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} visualizou dados do cartão de crédito da empresa ID ${this.creditCardId}.`,
        })
        .then(({ data }) => {
          data;
        });

      this.$http
        .post("/log/create", {
          user_id: this.$store.state.userData.id,
          module_id: this.creditCardId,
          module: "company-credit-card",
          action: "view-credit-card-contract",
          description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} visualizou dados do cartão no contrato ID ${this.tempContract.id}.`,
        })
        .then(({ data }) => {
          data;
        });
    },
    removeCreditCard() {
      this.creditCardId = "";

      this.form.setFieldsValue({
        [`voucher_payments_credit_card_id`]: "",
      });

      this.form.setFieldsValue({
        [`voucher_payments_credit_card_value`]: "",
      });

      this.form.setFieldsValue({
        [`voucher_payments_credit_card_value_brl`]: "",
      });
    },
    loadStatus(val) {
      this.loadingModal = val;
    },
    closeCreditCardModal() {
      this.loadingModal = true;
    },
    portalActiveTab(active) {
      this.activeTab = active;
    },
    selectCreditCard(creditCard) {
      this.creditCardId = creditCard.id;
      setTimeout(() => {
        this.form.setFieldsValue({
          [`voucher_payments_credit_card_id`]: creditCard.id,
        });
      }, 100);
    },
    totalVoucher() {
      let total = parseFloat(0);
      let voucherPayments = this.tempContract["voucher_payments_method"]
        ? JSON.parse(this.tempContract["voucher_payments_method"])
        : [];

      if (voucherPayments.includes("voucher-payment")) {
        if (this.tempContract["voucher_rows"] != undefined) {
          JSON.parse(this.tempContract["voucher_rows"]).forEach((voucher) => {
            total += parseFloat(
              this.tempContract[`voucher_payments_voucher_${voucher.id}_value`]
                ? this.tempContract[
                    `voucher_payments_voucher_${voucher.id}_value`
                  ].replace(",", ".")
                : 0
            );
          });
        }
      }

      return this.formatPriceCurrency(total, this.milesTaxesPayments.currency);
    },
    getCreditCards() {
      this.$http
        .get(
          "/company-credit-card/list?page=1&per_page=100&status=Ativo&show_in_contract=1"
        )
        .then(({ data }) => {
          this.creditCardsList = data.data;
        });
    },
    setFieldsValue() {
      this.voucherRows.forEach((voucher) => {
        this.form.setFieldsValue({
          [`voucher_payments_voucher_${voucher.id}_value`]:
            this.tempContract[`voucher_payments_voucher_${voucher.id}_value`],
          [`voucher_payments_voucher_${voucher.id}_code`]:
            this.tempContract[`voucher_payments_voucher_${voucher.id}_code`],
          [`voucher_payments_voucher_${voucher.id}_status`]:
            this.tempContract[`voucher_payments_voucher_${voucher.id}_status`],
        });
      });

      this.form.setFieldsValue({
        [`voucher_payments_exchange`]:
          this.tempContract["voucher_payments_exchange"],
        [`voucher_payments_credit_card_id`]:
          this.tempContract[`voucher_payments_credit_card_id`],
        [`voucher_payments_credit_card_exchange`]:
          this.tempContract[`voucher_payments_credit_card_exchange`],
        [`voucher_payments_credit_card_value`]:
          this.tempContract[`voucher_payments_credit_card_value`],
        [`voucher_payments_credit_card_value_brl`]:
          this.tempContract[`voucher_payments_credit_card_value_brl`],
      });
    },
    updateContract() {
      this.tempContract.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };
      // UPDATE CONTRACT FIELDS
      this.$http
        .post("/contract/update", this.tempContract)
        .then(({ data }) => {
          data;
          this.loadingCreditCardExtortion = false;
          this.loadingCreditCardDebit = false;
        })
        .catch(({ response }) => {
          response;
        });
    },
    confirmCreditCardExtortion() {
      let value = this.tempContract[`voucher_payments_credit_card_value_brl`];

      let creditCardId = this.tempContract[`voucher_payments_credit_card_id`];

      let transactionId =
        this.tempContract[`voucher_payments_credit_card_transaction_id`];

      this.loadingCreditCardExtortion = true;

      this.$http
        .post("/company-credit-card-transactions/create", {
          company_credit_card_id: creditCardId,
          contract_id: this.tempContract.id,
          customer_id: this.tempContract.customer_id,
          is_canceled: 0,
          voyage_id: 0,
          user_id: this.$store.state.userData.id,
          value: this.convertNumberToDatabase(value),
          operation: "extortion",
          extra_info: `Extorno da transação ${
            this.tempContract[
              `voucher_payments_credit_card_company_credit_card_transaction_id`
            ]
          }`,
          action: "cancel-credit-card-debit",
          transaction_id: transactionId,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        })
        .then(({ data }) => {
          data;
          this.$message.success("Valor extornado com sucesso!");
          this.tempContract[`voucher_payments_credit_card_transaction_id`] = "";

          this.tempContract[
            `voucher_payments_credit_card_company_credit_card_transaction_id`
          ] = "";

          this.updateContract();
          this.getCreditCards();
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loadingCreditCardExtortion = false;
        });
    },
    confirmCreditCardDebit() {
      let value = this.tempContract[`voucher_payments_credit_card_value_brl`],
        creditCardId = this.tempContract[`voucher_payments_credit_card_id`];

      if (this.calculateVoucherTotalValue().status) {
        this.loadingCreditCardDebit = true;
        this.$http
          .post("/company-credit-card-transactions/create", {
            company_credit_card_id: creditCardId,
            contract_id: this.tempContract.id,
            customer_id: this.tempContract.customer_id,
            is_canceled: 0,
            voyage_id: 0,
            user_id: this.$store.state.userData.id,
            value: this.convertNumberToDatabase(value),
            operation: "subtract",
            extra_info: "",
          })
          .then(({ data }) => {
            this.$message.success(
              "Débito no cartão de crédito confirmado com sucesso!"
            );
            this.tempContract[`voucher_payments_credit_card_transaction_id`] =
              data.id;

            this.tempContract[
              `voucher_payments_credit_card_company_credit_card_transaction_id`
            ] = data.company_credit_card_transaction_id;

            this.updateContract();
            this.getCreditCards();
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.loadingCreditCardDebit = false;
          });
      } else {
        this.$message.warning(
          "Os valores somados não se equivalem ao total. Verique os valores preenchidos."
        );
      }
    },
    creditCardFieldsDisabled() {
      let flag = false;
      let creditCardDebitId =
        this.tempContract[`voucher_payments_credit_card_transaction_id`];

      if (creditCardDebitId != undefined && creditCardDebitId) {
        flag = true;
      }

      return flag;
    },
    cancelVoucher(voucher) {
      this.$http
        .post("/voucher-transactions/details", {
          id: this.tempContract[`voucher_payments_voucher_${voucher.id}_id`],
        })
        .then(({ data }) => {
          let transaction = data;
          transaction.raw.is_canceled = 1;
          transaction.raw.operation = "canceled";
          transaction.raw.action = "cancel-voucher-emission";
          transaction.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/voucher-transactions/update", transaction.raw)
            .then(({ data }) => {
              this.$message.success(data.message);

              this.tempContract[`voucher_payments_voucher_${voucher.id}_id`] =
                "";
              this.tempContract[
                `voucher_payments_voucher_${voucher.id}_transaction_id`
              ] = "";
              this.tempContract[
                `voucher_payments_voucher_${voucher.id}_status`
              ] = "";

              this.updateContract();

              this.verifyCodeStatus(voucher.id);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    onVoucherPaymentChange(payment) {
      this.activeTab = 0;

      if (payment.includes("voucher-payment")) {
        this.activeTab = "voucher-payment";
      }

      if (payment.includes("credit-card")) {
        this.activeTab = "credit-card";
      }

      if (payment == "voucher-payment") {
        setTimeout(() => {
          this.setFieldsValue();
        }, 100);
      }

      setTimeout(() => {
        this.setFieldsValue();
      }, 100);
    },
    calculateVoucherTotalValue() {
      let total = parseFloat(0),
        voucherPayments = this.tempContract["voucher_payments_method"]
          ? JSON.parse(this.tempContract["voucher_payments_method"])
          : [];

      if (voucherPayments.includes("voucher-payment")) {
        if (this.tempContract["voucher_rows"] != undefined) {
          JSON.parse(this.tempContract["voucher_rows"]).forEach((voucher) => {
            total += parseFloat(
              this.tempContract[`voucher_payments_voucher_${voucher.id}_value`]
                ? this.tempContract[
                    `voucher_payments_voucher_${voucher.id}_value`
                  ].replace(",", ".")
                : 0
            );
          });
        }
      }

      if (
        this.tempContract["voucher_payments_credit_card_value"] != undefined &&
        this.form
          .getFieldValue("voucher_payments_method")
          .includes("credit-card")
      ) {
        total += parseFloat(
          this.tempContract[`voucher_payments_credit_card_value`]
            ? this.tempContract[`voucher_payments_credit_card_value`].replace(
                ",",
                "."
              )
            : 0
        );
      }

      return {
        status:
          total.toFixed(2) ==
          this.milesTaxesPayments.totalTaxesWithCurrency.toFixed(2)
            ? true
            : false,
        total: total.toFixed(2),
      };
    },
    verifyVoucherCode(id) {
      let code = this.tempContract[`voucher_payments_voucher_${id}_code`],
        voucherValue =
          this.tempContract[`voucher_payments_voucher_${id}_value`];

      //  if (this.calculateVoucherTotalValue().status) {
      if (voucherValue) {
        if (code.length > 2) {
          this.codeVerifyLoading = true;

          this.$http
            .get(
              `/miles-card-voucher/list?page=1&per_page=10&status=Ativo&check_availability=true&voucher_code=${code}&currency=${
                this.milesTaxesPayments.currency
              }&request_value=${voucherValue.replace(",", ".")}`
            )
            .then(({ data }) => {
              data.meta.total;
              this.$message.success("Voucher utilizado com sucesso!");

              if (data.meta.total > 0) {
                this.$http
                  .post("/voucher-transactions/create", {
                    voucher_id: data.data[0].id,
                    contract_id: this.tempContract.id,
                    customer_id: 0,
                    user_id: this.$store.state.userData.id,
                    voucher_code: code,
                    currency: this.milesTaxesPayments.currency,
                    value: voucherValue
                      .replace(".", "")
                      .replace(".", "")
                      .replace(".", "")
                      .replace(",", "."),
                    operation: "subtract",
                    extra_info: "",
                    is_canceled: "0",
                    action: "emit-voucher-value",
                    modified_by: {
                      name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                      id: this.$store.state.userData.id,
                    },
                    voucher: data.data[0].raw,
                  })
                  .then(({ data }) => {
                    this.tempContract[`voucher_payments_voucher_${id}_id`] =
                      data.id;
                    this.tempContract[
                      `voucher_payments_voucher_${id}_transaction_id`
                    ] = data.voucher_transaction_id;
                    this.updateContract();
                  })
                  .catch(({ res }) => {
                    this.$message.error(res.data.message);
                  });

                this.tempContract[`voucher_payments_voucher_${id}_status`] =
                  "Verificado";
              }
              this.codeVerifyLoading = false;
              this.updateContract();
            })
            .catch(({ response }) => {
              response;
              this.$message.error(response.data.message);
              this.codeVerifyLoading = false;
            });
        }
      } else {
        this.$message.warning("Insira o valor a ser retirado do Voucher.");
      }
      // } else {
      //   this.$message.warning(
      //     "Os valores somados não se equivalem ao total. Verique os valores preenchidos."
      //   );
      // }
    },
    verifyCodeStatus(id) {
      let flag = true,
        status = this.tempContract[`voucher_payments_voucher_${id}_status`];
      if (status != undefined) {
        if (status) {
          flag = false;
        }
      }

      return flag;
    },
    disableSearch(id) {
      let flag = true;
      let code = this.tempContract[`voucher_payments_voucher_${id}_code`];
      let value = this.tempContract[`voucher_payments_voucher_${id}_value`];
      if (code != undefined && value != undefined) {
        if (code.length > 1 && value) {
          flag = false;
        }
      }
      return flag;
    },
    removeVoucherRow(id) {
      for (var i in this.voucherRows) {
        if (this.voucherRows[i].id == id) {
          this.voucherRows.splice(i, 1);
          break;
        }
      }

      this.tempContract["voucher_rows"] = JSON.stringify(this.voucherRows);
    },
    addVoucherRow(id) {
      this.voucherRows.push({
        id: id + 1,
      });

      this.tempContract["voucher_rows"] = JSON.stringify(this.voucherRows);
    },
    calcExchange() {
      let theValue = this.tempContract[`voucher_payments_credit_card_value`];

      let exchange = this.tempContract[`voucher_payments_exchange`];

      if (theValue) {
        theValue = theValue.replace(",", ".");
        theValue = parseFloat(theValue);
      } else {
        theValue = 0;
      }

      let theExchange = exchange.replace(",", ".");

      if (!theExchange) {
        theExchange = 1;
      }

      let convertedValue = 0;

      convertedValue = parseFloat(theExchange) * theValue;
      convertedValue = Number(convertedValue.toFixed(2));

      this.form.setFieldsValue({
        [`voucher_payments_credit_card_value_brl`]: convertedValue
          .toString()
          .replace(".", ","),
      });

      this.$emit("updateFlightValue", true);
    },
    verifyTotal() {
      let flag = this.form.getFieldValue("voucher_payments_method");
      let total = 0;
      if (flag != undefined) {
        parse;

        if (this.tempContract["voucher_rows"] != undefined) {
          JSON.parse(this.tempContract["voucher_rows"]).forEach((voucher) => {
            total += parseFloat(
              this.tempContract[`voucher_payments_voucher_${voucher.id}_value`]
                ? this.tempContract[
                    `voucher_payments_voucher_${voucher.id}_value`
                  ].replace(",", ".")
                : 0
            );
          });
        }

        if (
          parseFloat(this.milesTaxesPayments.totalTaxesWithCurrency).toFixed(
            2
          ) != total.toFixed(2)
        ) {
          this.totalValue = {
            status: false,
            total: total.toFixed(2),
          };
        } else {
          this.totalValue = { status: true, total: total.toFixed(2) };
        }
      } else {
        this.totalValue = { status: false, total: 0 };
      }
    },
    async verifyValue(rule, value, callback) {
      rule;
      let theValue = parse(value);
      if (isNaN(theValue) || theValue == undefined) {
        theValue = 0;
      } else {
        if (parse(value) > this.milesTaxesPayments.totalTaxesWithCurrency) {
          callback(
            `O valor preenchido ultrapassa o total da reserva (${this.milesTaxesPayments.currency} ${this.milesTaxesPayments.totalTaxes}).`
          );
        } else {
          callback();
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped></style>
