<template>
  <div>
    <a-row :gutter="[20, 0]">
      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Valor total da carta de crédito</label>
          <a-input
            class="travel-input"
            placeholder="Insira um valor"
            v-currency
            @blur="updtPaymentCardValue"
            v-decorator="[
              `payment_methods_payment_card_total_value`,
              {
                rules: [
                  {
                    required: dynamicRequiredFieldPaymentCard(),
                    message: 'Preencha todos números.',
                  },
                  {
                    validator: verifyValue,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Valor da Lucratividade</label>

          <a-input
            class="travel-input"
            placeholder="Insira um valor"
            v-currency
            @blur="calcConstractualFine"
            v-decorator="[
              `payment_methods_payment_card_total_profit_value`,
              {
                rules: [
                  {
                    required: dynamicRequiredFieldPaymentCard(),
                    message: 'Preencha todos números.',
                  },
                  {
                    validator: !this.$root.isAdmin()
                      ? verifyPaymentCardProfitValue
                      : false,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Multa contratual (%)</label>

          <a-input
            class="travel-input"
            placeholder="Insira um valor"
            @blur="calcConstractualFine"
            v-currency="{
              currency: {
                prefix: '',
                suffix: '',
              },
              distractionFree: true,
              valueAsInteger: false,
              precision: 2,
              autoDecimalMode: false,
              allowNegative: false,
            }"
            v-decorator="[
              `payment_methods_payment_card_contractual_fine_percentage`,
              {
                rules: [
                  {
                    validator: verifyPercentage,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Multa (R$)</label>

          <a-input
            class="travel-input readonly"
            v-currency
            v-decorator="[
              `payment_methods_payment_card_contractual_fine_value`,
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Valor desconto lucratividade (R$)</label>

          <a-input
            class="travel-input readonly"
            v-currency
            v-decorator="[
              `payment_methods_payment_card_contract_profit_discount_value`,
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Número do voyage</label>

          <a-input
            class="travel-input"
            type="number"
            placeholder="Insira um valor"
            v-decorator="[
              `payment_methods_payment_card_voyage_id`,
              {
                rules: [
                  {
                    required: dynamicRequiredFieldPaymentCard(),
                    message: 'Obrigatório.',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";
import formatThings from "@/mixins/general/formatThings.js";

export default {
  name: "PaymentCardCollapse",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    customerForm: Object,
    reservation_total_price: Number,
    paymentCardId: Number,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  mounted() {
    if (
      this.tempContract
        .payment_methods_payment_card_contractual_fine_percentage == undefined
    )
      this.tempContract.payment_methods_payment_card_contractual_fine_percentage =
        "100";

    this.form.setFieldsValue({
      payment_methods_payment_card_voyage_id:
        this.tempContract.payment_methods_payment_card_voyage_id,
      payment_methods_payment_card_total_value:
        this.tempContract.payment_methods_payment_card_total_value,
      payment_methods_payment_card_total_profit_value:
        this.tempContract.payment_methods_payment_card_total_profit_value,
      payment_methods_payment_card_contractual_fine_percentage:
        this.tempContract
          .payment_methods_payment_card_contractual_fine_percentage,
      payment_methods_payment_card_contractual_fine_value:
        this.tempContract.payment_methods_payment_card_contractual_fine_value,

      payment_methods_payment_card_contract_profit_discount_value:
        this.tempContract
          .payment_methods_payment_card_contract_profit_discount_value,
    });

    setTimeout(() => {
      this.calcConstractualFine();
    }, 200);
  },
  methods: {
    updtPaymentCardValue() {
      this.$emit("verifyTotal", true);
    },
    calcConstractualFine() {
      let percentage = this.tempContract
          .payment_methods_payment_card_contractual_fine_percentage
          ? parse(
              this.tempContract
                .payment_methods_payment_card_contractual_fine_percentage
            )
          : 0,
        profitDiscount = this.tempContract
          .payment_methods_payment_card_total_profit_value
          ? parse(
              this.tempContract.payment_methods_payment_card_total_profit_value
            )
          : 0,
        profitFee = 0,
        profit = 0;

      profitFee = (percentage / 100) * profitDiscount;

      profit = profitDiscount - profitFee;

      this.form.setFieldsValue({
        payment_methods_payment_card_contractual_fine_value: profitFee
          ? `${profitFee.toFixed(2)}`.replace(".", ",")
          : 0,
      });

      this.form.setFieldsValue({
        payment_methods_payment_card_contract_profit_discount_value: profit
          ? `${profit.toFixed(2)}`.replace(".", ",")
          : 0,
      });
    },
    dynamicRequiredFieldPaymentCard() {
      let flag = this.form.getFieldValue("payment_methods");

      if (flag != undefined) {
        flag = this.form
          .getFieldValue("payment_methods")
          .includes("payment-card");
      } else {
        flag = false;
      }

      return flag;
    },
    async verifyValue(rule, value, callback) {
      let theValue = parse(value);

      if (isNaN(theValue) || theValue == undefined) {
        theValue = 0;
      } else {
        if (parse(value) > parseFloat(this.reservation_total_price)) {
          callback(
            `O valor preenchido ultrapassa o total da reserva (R$ ${this.reservation_total_price}).`
          );
        } else {
          callback();
        }
      }
    },
    async verifyPercentage(rule, value, callback) {
      let theValue = parse(value);
      if (isNaN(theValue) || theValue == undefined) {
        theValue = 0;
      } else {
        if (parse(value) > 100) {
          callback(`Preencha um valor entre 0 a 100.`);
        } else {
          callback();
        }
      }
    },
  },
};
</script>
