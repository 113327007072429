var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('label',{staticClass:"filled"},[_vm._v("Valor total do pagamento")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_custom_payment_total_value_${_vm.customPaymentId}`,
            {
              rules: [
                {
                  required: true,
                  message: 'Preencha todos números.',
                },
                {
                  validator: _vm.verifyValue,
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_custom_payment_total_value_${customPaymentId}`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Preencha todos números.',\n                },\n                {\n                  validator: verifyValue,\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor total do pagamento"},on:{"keyup":_vm.updateCustomPaymentValue}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('label',{staticClass:"filled"},[_vm._v("Nome da forma de Pagamento")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_custom_payment_name_${_vm.customPaymentId}`,
            {
              rules: [
                {
                  required: true,
                  message: 'Preencha o nome da forma de pagamento.',
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_custom_payment_name_${customPaymentId}`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Preencha o nome da forma de pagamento.',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Nome da forma de Pagamento"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"textarea"},[_c('label',{staticClass:"filled"},[_vm._v("Observações (opcional)")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_custom_payment_observation_${_vm.customPaymentId}`,
          ]),expression:"[\n            `payment_methods_custom_payment_observation_${customPaymentId}`,\n          ]"}],staticClass:"travel-textarea",attrs:{"placeholder":"Observações (opcional)","type":"textarea"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }