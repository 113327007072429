<template>
  <div>
    <aRow class="gateway" :gutter="20">
      <aCol :span="24" class="title"> Gateway de pagamento: REDE </aCol>
      <aCol :span="24" class="subtitle"> Análise de risco </aCol>
      <aCol class="fields">
        <aRow :gutter="[20, 0]">
          <aCol :span="3">
            <a-form-item>
              <label :class="'filled'">Score</label>
              <a-input
                class="travel-input"
                placeholder=""
                v-currency
                v-decorator="[
                  `payment_methods_payment_link_risk_analysis_score_${paymentLinkId}`,
                ]"
                :disabled="true"
              >
              </a-input>
            </a-form-item>
          </aCol>

          <aCol :span="5">
            <a-form-item>
              <label :class="'filled'">Status</label>
              <a-input
                class="travel-input"
                placeholder=""
                v-currency
                v-decorator="[
                  `payment_methods_payment_link_risk_analysis_status_${paymentLinkId}`,
                ]"
                :disabled="true"
              >
              </a-input>
            </a-form-item>
          </aCol>

          <aCol :span="7">
            <a-form-item>
              <label :class="'filled'">Status Code</label>
              <a-input
                class="travel-input"
                placeholder=""
                v-currency
                v-decorator="[
                  `payment_methods_payment_link_risk_analysis_status_code_${paymentLinkId}`,
                ]"
                :disabled="true"
              >
              </a-input>
            </a-form-item>
          </aCol>

          <aCol class="actions" :span="4">
            <a-button class="btn-1 mr-5" type="primary">
              <img
                src="@/assets/images/dashboard/contracts/refresh-link.png"
                alt="ico"
              />
            </a-button>
            <a-button class="btn-2 mr-5" type="primary">
              <img
                src="@/assets/images/dashboard/contracts/transfer-link.png"
                alt="ico"
              />
            </a-button>
            <a-button class="btn-3" type="primary">
              <img
                src="@/assets/images/dashboard/contracts/send-link.png"
                alt="ico"
              />
            </a-button>
          </aCol>

          <aCol class="obs" :span="5">
            <b>Atenção:</b> Solicitar prioridade terá custo adicional na venda.
          </aCol>
        </aRow>
      </aCol>
    </aRow>
  </div>
</template>

<script>
export default {
  name: "PaymentLinkClearSale",
  props: {
    form: Object,
    paymentLinkId: Number,
    tempContract: Object,
    contract: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass" scoped>
$green: #00aa21
.gateway
  .title
    color: #aab2bd
    border-bottom: 1px solid #ddd
    padding: 4px 0
    font-size: 13px
    margin: 0 0 20px
  .fields
    border-bottom: 1px solid #ccc
    padding-bottom: 20px
  .actions
    button
      padding: 0 !important
      width: 32px
      border: 1px solid rgba(0,0,0,0.2)
    & .btn-1
      background: #6dd9bd !important
    & .btn-2
      background: #fd8b74 !important
    & .btn-3
      background: #e05c6e !important
  .obs
    font-size: 12px
    color: #333
    line-height: 1
    b
      display: block
      color: #f00
      margin-bottom: 5px
  .subtitle
    color: #ff4b00
    font-weight: 600
    font-size: 13px
    margin: 0px 0 15px
</style>
