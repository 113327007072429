var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.haveMachine)?[_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":4}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Valor")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
              `payment_methods_payment_link_total_value_${_vm.paymentLinkId}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha todos números.',
                  },
                ],
              },
            ]),expression:"[\n              `payment_methods_payment_link_total_value_${paymentLinkId}`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Preencha todos números.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor"},on:{"change":_vm.updatePaymentLinkValue,"blur":_vm.onBlurVerifyPaymentLinkValue}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.form.getFieldValue(
            `payment_methods_payment_link_total_value_${_vm.paymentLinkId}`
          )
        ),expression:"\n          form.getFieldValue(\n            `payment_methods_payment_link_total_value_${paymentLinkId}`\n          )\n        "}],attrs:{"span":3}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Parcelas")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `payment_methods_payment_link_installments_${_vm.paymentLinkId}`,

              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `payment_methods_payment_link_installments_${paymentLinkId}`,\n\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input select",attrs:{"placeholder":"Selecione"},on:{"change":_vm.onChangePaymentLinkInstallments}},_vm._l((_vm.installments),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item}},[_vm._v("\n              "+_vm._s(item)+"\n            ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.form.getFieldValue(
            `payment_methods_payment_link_total_value_${_vm.paymentLinkId}`
          ) &&
          _vm.form.getFieldValue(
            `payment_methods_payment_link_installments_${_vm.paymentLinkId}`
          )
        ),expression:"\n          form.getFieldValue(\n            `payment_methods_payment_link_total_value_${paymentLinkId}`\n          ) &&\n          form.getFieldValue(\n            `payment_methods_payment_link_installments_${paymentLinkId}`\n          )\n        "}],attrs:{"span":4}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Primeira parcela ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
              `payment_methods_payment_link_first_installment_${_vm.paymentLinkId}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha todos números.',
                  },
                ],
              },
            ]),expression:"[\n              `payment_methods_payment_link_first_installment_${paymentLinkId}`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Preencha todos números.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira o valor"},on:{"blur":_vm.updateOtherInstallments}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.form.getFieldValue(
            `payment_methods_payment_link_total_value_${_vm.paymentLinkId}`
          ) &&
          _vm.form.getFieldValue(
            `payment_methods_payment_link_installments_${_vm.paymentLinkId}`
          ) != 1
        ),expression:"\n          form.getFieldValue(\n            `payment_methods_payment_link_total_value_${paymentLinkId}`\n          ) &&\n          form.getFieldValue(\n            `payment_methods_payment_link_installments_${paymentLinkId}`\n          ) != 1\n        "}],attrs:{"span":4}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Demais parcelas")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
              `payment_methods_payment_link_installments_value_${_vm.paymentLinkId}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha todos números.',
                  },
                ],
              },
            ]),expression:"[\n              `payment_methods_payment_link_installments_value_${paymentLinkId}`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Preencha todos números.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input readonly",attrs:{"placeholder":""}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Taxa PDQ (R$)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
              `payment_methods_payment_link_tax_value_${_vm.paymentLinkId}`,
            ]),expression:"[\n              `payment_methods_payment_link_tax_value_${paymentLinkId}`,\n            ]"}],staticClass:"travel-input readonly",attrs:{"placeholder":""}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Taxa Adm (R$)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
              `payment_methods_payment_link_advance_fee_value_${_vm.paymentLinkId}`,
            ]),expression:"[\n              `payment_methods_payment_link_advance_fee_value_${paymentLinkId}`,\n            ]"}],staticClass:"travel-input readonly",attrs:{"placeholder":""}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Total Taxas (R$)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
              `payment_methods_payment_link_total_taxes_${_vm.paymentLinkId}`,
            ]),expression:"[\n              `payment_methods_payment_link_total_taxes_${paymentLinkId}`,\n            ]"}],staticClass:"travel-input readonly",attrs:{"placeholder":""}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1),_vm._v(" "),(
        _vm.form.getFieldValue(
          `payment_methods_payment_link_total_value_${_vm.paymentLinkId}`
        ) &&
        _vm.form.getFieldValue(
          `payment_methods_payment_link_installments_${_vm.paymentLinkId}`
        ) &&
        _vm.form.getFieldValue(
          `payment_methods_payment_link_first_installment_${_vm.paymentLinkId}`
        )
      )?_c('PaymentLinkGenerateLink',{attrs:{"form":_vm.form,"paymentLinkId":_vm.paymentLinkId,"tempContract":_vm.tempContract,"contract":_vm.contract}}):_vm._e(),_vm._v(" "),(
        ![undefined, ''].includes(
          _vm.tempContract[`payment_link_3ds_tid_${_vm.paymentLinkId}`]
        )
      )?_c('PaymentLinkTransactionActions',{attrs:{"form":_vm.form,"paymentLinkId":_vm.paymentLinkId,"tempContract":_vm.tempContract,"contract":_vm.contract}}):_vm._e(),_vm._v(" "),_c('aCheckbox',{staticClass:"f12",on:{"change":_vm.onChangePerformFraudAnalysis},model:{value:(_vm.performFraudAnalysis),callback:function ($$v) {_vm.performFraudAnalysis=$$v},expression:"performFraudAnalysis"}},[_vm._v("\n      Marque para realizar "),_c('b',[_vm._v("análise de fraude")]),_vm._v(" no pagamento\n    ")]),_vm._v(" "),(_vm.performFraudAnalysis)?_c('PaymentLinkClearSale',{staticClass:"mt-20",attrs:{"form":_vm.form,"paymentLinkId":_vm.paymentLinkId,"tempContract":_vm.tempContract,"contract":_vm.contract}}):_vm._e()]:_vm._e(),_vm._v(" "),(!_vm.haveMachine)?[_c('a-alert',{staticClass:"mb-20 f12",attrs:{"message":"ATENÇÃO: Nenhuma máquina de pagamento configurada para empresa do contrato. Vá na lista de PDQs e ative para link de pagamento. Esta etapa é necessária para cálculos de lucratividade e taxas.","banner":"","type":"warning"}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }