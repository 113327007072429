import policyMixins from "@/mixins/policies/policyMixins";

export default {
  mixins: [policyMixins],
  data() {
    return {
      validateAllContractFields: {
        customerStatus: {
          alert: false,
        },
        hotelStatus: {
          alert: false,
          financesAlert: false,
        },
        serviceStatus: {
          alert: false,
          financesAlert: false,
        },
        flightStatus: {
          alert: false,
          financesAlert: false,
        },
        cruisesStatus: {
          alert: false,
          financesAlert: false,
        },
        voucherStatus: {
          alert: false,
        },
        policyStatus: {
          alert: false,
        },
        passengersStatus: {
          alert: false,
        },
        paymentsStatus: {
          alert: false,
        },
      },
    };
  },
  mounted() {
    this.policy.filters.module = "contract";
    this.policy.filters.id = this.$route.params.id;
    this.getPolicies();
  },
  methods: {
    validateFields() {
      let allFieldsIsFilled = false,
        customerStatus = {
          alert: false,
        },
        hotelStatus = {
          alert: false,
          financesAlert: false,
        },
        serviceStatus = {
          alert: false,
          financesAlert: false,
        },
        flightStatus = {
          alert: false,
          financesAlert: false,
        },
        cruisesStatus = {
          alert: false,
          financesAlert: false,
        },
        voucherStatus = {
          alert: false,
        },
        policyStatus = {
          alert: false,
        },
        passengersStatus = {
          alert: false,
        },
        voucherMethods =
          this.tempContract["voucher_payments_method"] != undefined
            ? JSON.parse(this.tempContract["voucher_payments_method"])
            : [],
        contractedServices = this.tempContract["contracted_services"]
          ? JSON.parse(this.tempContract["contracted_services"])
          : [],
        hotelRows = this.tempContract["hotel_rows"]
          ? JSON.parse(this.tempContract["hotel_rows"])
          : [],
        serviceRows = this.tempContract["service_rows"]
          ? JSON.parse(this.tempContract["service_rows"])
          : [],
        flightSectionRows = this.tempContract["flight_sections_rows"]
          ? JSON.parse(this.tempContract["flight_sections_rows"])
          : [],
        haveMiles = [],
        voucherRows = this.tempContract["voucher_rows"]
          ? JSON.parse(this.tempContract["voucher_rows"])
          : [],
        packageType = this.tempContract["package_type"],
        travellersRows =
          this.tempContract["travellers_rows"] != undefined
            ? JSON.parse(this.tempContract["travellers_rows"])
            : [];

      if (this.tempContract[`the_customer`] != undefined) {
        if (
          ["1", true].includes(
            this.tempContract[`the_customer`].is_international
          )
        ) {
          if (
            this.tempContract[`the_customer`].person_type &&
            this.tempContract[`the_customer`].mobile_phone &&
            this.tempContract[`the_customer`].email
          ) {
            allFieldsIsFilled = true;
          } else {
            allFieldsIsFilled = false;
            customerStatus.alert = true;
          }
        } else {
          if (
            this.tempContract[`the_customer`].person_type &&
            this.tempContract[`the_customer`].mobile_phone &&
            this.tempContract[`the_customer`].cep &&
            this.tempContract[`the_customer`].location_street &&
            this.tempContract[`the_customer`].location_state &&
            this.tempContract[`the_customer`].location_number &&
            this.tempContract[`the_customer`].location_neighborhood &&
            this.tempContract[`the_customer`].location_country &&
            this.tempContract[`the_customer`].location_city &&
            this.tempContract[`the_customer`].email
          ) {
            allFieldsIsFilled = true;
          } else {
            allFieldsIsFilled = false;
            customerStatus.alert = true;
          }
        }
      }

      // SERVIÇOS CONTRATADOS
      if (contractedServices.length > 0) {
        contractedServices.forEach((service) => {
          if (service == "hotel") {
            hotelRows.forEach((hotel) => {
              if (hotel.id) {
                let hotelValue, hotelNetValue;
                if (packageType == "Pacote Completo Terceiros") {
                  hotelValue = this.tempContract[`package_total_price`];
                  hotelNetValue =
                    this.tempContract[`contract_finances_package_net_value`];
                } else {
                  hotelValue =
                    this.tempContract[`hotel_${hotel.id}_total_price`];
                  hotelNetValue =
                    this.tempContract[
                      `contract_finances_hotel_${hotel.id}_net_value`
                    ];
                }

                if (
                  this.tempContract[`hotel_${hotel.id}_name`] &&
                  this.tempContract[`hotel_${hotel.id}_checkin`] &&
                  this.tempContract[`hotel_${hotel.id}_checkout`] &&
                  this.tempContract[`hotel_${hotel.id}_rates`] &&
                  this.tempContract[`hotel_${hotel.id}_nights`] &&
                  this.tempContract[`hotel_${hotel.id}_diet`]
                ) {
                  allFieldsIsFilled = true;
                } else {
                  allFieldsIsFilled = false;
                  hotelStatus.alert = true;
                }

                if (packageType == "Pacote Completo Terceiros") {
                  //
                } else {
                  if (
                    hotelValue &&
                    hotelNetValue &&
                    this.tempContract[
                      `contract_finances_hotel_${hotel.id}_supplier_id`
                    ]
                  ) {
                    allFieldsIsFilled = true;
                  } else {
                    allFieldsIsFilled = false;
                    hotelStatus.financesAlert = true;
                  }
                }

                hotel.accommodation.forEach((room) => {
                  if (
                    this.tempContract[
                      `hotel_${hotel.id}_accommodation_${room.id}`
                    ] != undefined &&
                    this.tempContract[
                      `hotel_${hotel.id}_accommodation_${room.id}_type`
                    ] != undefined &&
                    this.tempContract[
                      `hotel_${hotel.id}_accommodation_${room.id}`
                    ] &&
                    this.tempContract[
                      `hotel_${hotel.id}_accommodation_${room.id}_type`
                    ]
                  ) {
                    allFieldsIsFilled = true;
                  } else {
                    allFieldsIsFilled = false;
                    hotelStatus.alert = true;
                  }

                  room.travellers.forEach((traveller) => {
                    if (
                      this.tempContract[
                        `hotel_${hotel.id}_accommodation_${room.id}_traveller_${traveller.id}`
                      ] != undefined &&
                      this.tempContract[
                        `hotel_${hotel.id}_accommodation_${room.id}_traveller_${traveller.id}`
                      ]
                    ) {
                      allFieldsIsFilled = true;
                    } else {
                      allFieldsIsFilled = false;
                      hotelStatus.alert = true;
                    }
                  });

                  if (
                    this.tempContract[
                      `contract_finances_hotel_${hotel.id}_taxes_rows`
                    ] != undefined
                  ) {
                    JSON.parse(
                      this.tempContract[
                        `contract_finances_hotel_${hotel.id}_taxes_rows`
                      ]
                    ).forEach((tax) => {
                      if (
                        this.tempContract[
                          `contract_finances_hotel_${hotel.id}_tax_${tax.id}_type`
                        ] &&
                        this.tempContract[
                          `contract_finances_hotel_${hotel.id}_tax_${tax.id}_value`
                        ]
                      ) {
                        allFieldsIsFilled = true;
                      } else {
                        allFieldsIsFilled = false;
                        hotelStatus.financesAlert = true;
                      }
                    });
                  }

                  if (
                    this.tempContract[
                      `contract_finances_hotel_${hotel.id}_incentives_rows`
                    ] != undefined
                  ) {
                    JSON.parse(
                      this.tempContract[
                        `contract_finances_hotel_${hotel.id}_incentives_rows`
                      ]
                    ).forEach((tax) => {
                      if (
                        this.tempContract[
                          `contract_finances_hotel_${hotel.id}_incentive_${tax.id}_type`
                        ] &&
                        this.tempContract[
                          `contract_finances_hotel_${hotel.id}_incentive_${tax.id}_value`
                        ]
                      ) {
                        allFieldsIsFilled = true;
                      } else {
                        allFieldsIsFilled = false;
                        hotelStatus.financesAlert = true;
                      }
                    });
                  }
                });
              }
            });
          }

          if (service == "service") {
            allFieldsIsFilled = false;
            serviceStatus.alert = true;

            if (packageType != "Pacote Completo Terceiros") {
              serviceStatus.financesAlert = true;
            }

            serviceRows.forEach((s) => {
              if (
                this.validateEachField(
                  this.tempContract[`service_${s.id}_type`]
                ) &&
                this.validateEachField(
                  this.tempContract[`service_${s.id}_checkin`]
                ) &&
                this.validateEachField(
                  this.tempContract[`service_${s.id}_checkout`]
                ) &&
                this.validateEachField(
                  this.tempContract[`service_${s.id}_name`]
                )
              ) {
                allFieldsIsFilled = true;
                serviceStatus.alert = false;
              } else {
                allFieldsIsFilled = false;
                serviceStatus.alert = true;
              }

              if (s.travellers != undefined) {
                s.travellers.forEach((traveller) => {
                  if (
                    this.tempContract[
                      `service_${s.id}_traveller_${traveller.id}`
                    ]
                  ) {
                    allFieldsIsFilled = true;
                    serviceStatus.alert = false;
                  } else {
                    allFieldsIsFilled = false;
                    serviceStatus.alert = true;
                  }
                });
              }

              if (packageType == "Pacote Completo Terceiros") {
                //
              } else {
                if (
                  this.tempContract[
                    `contract_finances_service_${s.id}_supplier_id`
                  ] &&
                  this.tempContract[`service_${s.id}_total_price`] &&
                  this.tempContract[
                    `contract_finances_service_${s.id}_net_value`
                  ]
                ) {
                  allFieldsIsFilled = true;
                  serviceStatus.financesAlert = false;
                } else {
                  allFieldsIsFilled = false;
                  serviceStatus.financesAlert = true;
                }

                if (
                  this.tempContract[
                    `contract_finances_service_${s.id}_taxes_rows`
                  ] != undefined
                ) {
                  JSON.parse(
                    this.tempContract[
                      `contract_finances_service_${s.id}_taxes_rows`
                    ]
                  ).forEach((tax) => {
                    if (
                      this.tempContract[
                        `contract_finances_service_${s.id}_tax_${tax.id}_type`
                      ] &&
                      this.tempContract[
                        `contract_finances_service_${s.id}_tax_${tax.id}_value`
                      ]
                    ) {
                      allFieldsIsFilled = true;
                      serviceStatus.financesAlert = false;
                    } else {
                      allFieldsIsFilled = false;
                      serviceStatus.financesAlert = true;
                    }
                  });
                }

                if (
                  this.tempContract[
                    `contract_finances_service_${s.id}_incentives_rows`
                  ] != undefined
                ) {
                  JSON.parse(
                    this.tempContract[
                      `contract_finances_service_${s.id}_incentives_rows`
                    ]
                  ).forEach((tax) => {
                    if (
                      this.tempContract[
                        `contract_finances_service_${s.id}_incentive_${tax.id}_type`
                      ] &&
                      this.tempContract[
                        `contract_finances_service_${s.id}_incentive_${tax.id}_value`
                      ]
                    ) {
                      allFieldsIsFilled = true;
                      serviceStatus.financesAlert = false;
                    } else {
                      allFieldsIsFilled = false;
                      serviceStatus.financesAlert = true;
                    }
                  });
                }
              }
            });

            //   console.log('serrrr 2', allFieldsIsFilled,  serviceStatus.alert, serviceStatus.financesAlert);
          }

          if (service == "flight") {
            flightSectionRows.forEach((flight) => {
              if (
                flight.id &&
                this.tempContract[`flight_${flight.id}_type`] == "Regular"
              ) {
                if (packageType == "Pacote Completo Terceiros") {
                  //
                } else {
                  if (
                    this.tempContract[
                      `contract_finances_flight_${flight.id}_supplier_id`
                    ] &&
                    this.tempContract[
                      `contract_finances_flight_${flight.id}_supplier_id`
                    ] != undefined
                  ) {
                    allFieldsIsFilled = true;
                  } else {
                    allFieldsIsFilled = false;
                    flightStatus.financesAlert = true;
                  }
                }

                flight.travellers.forEach((traveller) => {
                  if (
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}`
                    ] &&
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_locator`
                    ]
                  ) {
                    allFieldsIsFilled = true;
                  } else {
                    allFieldsIsFilled = false;
                    flightStatus.alert = true;
                  }
                });
              }

              if (
                flight.id &&
                this.tempContract[`flight_${flight.id}_type`] == "Milhas"
              ) {
                flight.travellers.forEach((traveller) => {
                  if (
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}`
                    ] &&
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_locator`
                    ] &&
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_miles`
                    ] &&
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_value`
                    ] &&
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                    ] &&
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes`
                    ]
                  ) {
                    allFieldsIsFilled = true;
                  } else {
                    allFieldsIsFilled = false;
                    flightStatus.alert = true;
                  }
                });

                flight.airlines.forEach((airline) => {
                  if (
                    this.tempContract[
                      `flight_${flight.id}_airline_${airline.id}`
                    ] &&
                    this.tempContract[
                      `flight_${flight.id}_airline_${airline.id}_miles_program`
                    ] &&
                    this.tempContract[
                      `flight_${flight.id}_airline_${airline.id}_travellers`
                    ]
                  ) {
                    allFieldsIsFilled = true;
                  } else {
                    allFieldsIsFilled = false;
                    flightStatus.alert = true;
                  }
                });
              }

              flight.sections.forEach((section) => {
                if (
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_origin`
                  ] &&
                  this.tempContract[`flight_${flight.id}_type`] &&
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_type`
                  ] &&
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_departure_date`
                  ] &&
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_departure_time`
                  ] &&
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_class`
                  ] &&
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_airline`
                  ] &&
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_destination`
                  ] &&
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_arrival_date`
                  ] &&
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_arrival_time`
                  ] &&
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_duration`
                  ] &&
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_number`
                  ]
                ) {
                  allFieldsIsFilled = true;
                } else {
                  allFieldsIsFilled = false;
                  flightStatus.alert = true;
                }
              });

              if (packageType == "Pacote Completo Terceiros") {
                //
              } else {
                if (
                  this.tempContract[
                    `contract_finances_flight_${flight.id}_taxes_rows`
                  ] != undefined
                ) {
                  JSON.parse(
                    this.tempContract[
                      `contract_finances_flight_${flight.id}_taxes_rows`
                    ]
                  ).forEach((tax) => {
                    if (
                      this.tempContract[
                        `contract_finances_flight_${flight.id}_tax_${tax.id}_type`
                      ] &&
                      this.tempContract[
                        `contract_finances_flight_${flight.id}_tax_${tax.id}_value`
                      ]
                    ) {
                      allFieldsIsFilled = true;
                    } else {
                      allFieldsIsFilled = false;
                      flightStatus.financesAlert = true;
                    }
                  });
                }

                if (
                  this.tempContract[
                    `contract_finances_flight_${flight.id}_incentives_rows`
                  ] != undefined
                ) {
                  JSON.parse(
                    this.tempContract[
                      `contract_finances_flight_${flight.id}_incentives_rows`
                    ]
                  ).forEach((tax) => {
                    if (
                      this.tempContract[
                        `contract_finances_flight_${flight.id}_incentive_${tax.id}_type`
                      ] &&
                      this.tempContract[
                        `contract_finances_flight_${flight.id}_incentive_${tax.id}_value`
                      ]
                    ) {
                      allFieldsIsFilled = true;
                    } else {
                      allFieldsIsFilled = false;
                      flightStatus.financesAlert = true;
                    }
                  });
                }
              }
            });
          }
        });
      }

      if (flightSectionRows.length > 0) {
        haveMiles = flightSectionRows.filter((flight) => {
          return this.tempContract[`flight_${flight.id}_type`] == "Milhas";
        });
      }

      // PAGAMENTOS VOUCHERS
      if (voucherMethods.length > 0 && haveMiles.length > 0) {
        voucherMethods.forEach((method) => {
          if (method == "voucher-payment") {
            voucherRows.forEach((payment) => {
              if (payment.id) {
                if (
                  this.tempContract[
                    `voucher_payments_voucher_${payment.id}_value`
                  ] &&
                  this.tempContract[
                    `voucher_payments_voucher_${payment.id}_code`
                  ] &&
                  this.tempContract[
                    `voucher_payments_voucher_${payment.id}_status`
                  ]
                ) {
                  if (
                    voucherMethods.includes("credit-card") ||
                    voucherMethods.includes("voucher-payment")
                  ) {
                    if (
                      this.tempContract[
                        "voucher_payments_voucher_1_transaction_id"
                      ] ||
                      this.tempContract[
                        "voucher_payments_credit_card_transaction_id"
                      ]
                    ) {
                      allFieldsIsFilled = true;
                    }
                  } else {
                    allFieldsIsFilled = false;
                    voucherStatus.alert = true;
                  }
                } else {
                  allFieldsIsFilled = false;
                  voucherStatus.alert = true;
                }
              }
            });
          }
          if (method == "credit-card") {
            ///
            // allFieldsIsFilled = false;
            // policyStatus.alert = true;
          }
        });
      }

      // PASSAGEIROS
      if (travellersRows.length > 0) {
        travellersRows.forEach((traveller) => {
          if (traveller.id) {
            if (this.$root.isAdmin()) {
              if (
                this.tempContract[`traveller_${traveller.id}_first_name`] &&
                this.tempContract[`traveller_${traveller.id}_last_name`] &&
                this.tempContract[`traveller_${traveller.id}_gender`] &&
                this.tempContract[`traveller_${traveller.id}_birthday`]
              ) {
                allFieldsIsFilled = true;
              } else {
                allFieldsIsFilled = false;
                passengersStatus.alert = true;
              }
            } else {
              if (
                this.tempContract[`traveller_${traveller.id}_first_name`] &&
                this.tempContract[`traveller_${traveller.id}_last_name`] &&
                this.tempContract[`traveller_${traveller.id}_gender`] &&
                this.tempContract[`traveller_${traveller.id}_birthday`] &&
                this.tempContract[`traveller_${traveller.id}_CPF`]
              ) {
                allFieldsIsFilled = true;
              } else {
                allFieldsIsFilled = false;
                passengersStatus.alert = true;
              }
            }
          }
        });
      } else {
        allFieldsIsFilled = false;
        passengersStatus.alert = true;
      }

      // POLÌTICAS
      if (
        this.policy.list.length > 0 &&
        this.policy.list[0].title != "" &&
        this.policy.list[0].content != ""
      ) {
        allFieldsIsFilled = true;
      } else {
        allFieldsIsFilled = false;
        policyStatus.alert = true;
      }

      if (this.$store.state.userData.id == 2) {
        allFieldsIsFilled = true;
        policyStatus.alert = false;
      }

      this.validateAllContractFields = {
        allFieldsFilled:
          this.validatePaymenFields().paymentsStatus.alert ||
          serviceStatus.alert ||
          serviceStatus.financesAlert ||
          customerStatus.alert ||
          policyStatus.alert ||
          flightStatus.alert ||
          flightStatus.financesAlert ||
          hotelStatus.alert ||
          hotelStatus.financesAlert ||
          passengersStatus.alert ||
          cruisesStatus.alert ||
          cruisesStatus.financesAlert ||
          voucherStatus.alert
            ? false
            : allFieldsIsFilled,
        hotelStatus,
        customerStatus,
        serviceStatus,
        flightStatus,
        cruisesStatus,
        voucherStatus,
        policyStatus,
        passengersStatus,
        paymentsStatus: this.validatePaymenFields().paymentsStatus,
      };
    },
    validateEachField(field) {
      let flag = false;
      if (field != undefined && field != null && field != "") flag = true;
      return flag;
    },
    validatePaymenFields() {
      const contractId = this.tempContract["id"]
        ? parseInt(this.tempContract.id)
        : 0;
      let allFieldsIsFilled = false,
        paymentMethods =
          this.tempContract["payment_methods"] != undefined
            ? JSON.parse(this.tempContract["payment_methods"])
            : [],
        bankTransferRows =
          this.tempContract["bank_transfer_rows"] != undefined
            ? JSON.parse(this.tempContract["bank_transfer_rows"])
            : [],
        customPaymentRows =
          this.tempContract["custom_payment_rows"] != undefined
            ? JSON.parse(this.tempContract["custom_payment_rows"])
            : [],
        creditCardRows =
          this.tempContract["credit_card_rows"] != undefined
            ? JSON.parse(this.tempContract["credit_card_rows"])
            : [],
        paymentCardRows =
          this.tempContract["payment_card_rows"] != undefined
            ? JSON.parse(this.tempContract["payment_card_rows"])
            : [],
        billingTicketInstallmentsRows =
          this.tempContract[
            "payment_methods_billing_ticket_installments_rows"
          ] != undefined
            ? JSON.parse(
                this.tempContract[
                  "payment_methods_billing_ticket_installments_rows"
                ]
              )
            : [],
        thirdPartyPaymentRows =
          this.tempContract["third_party_payment_rows"] != undefined
            ? JSON.parse(this.tempContract["third_party_payment_rows"])
            : [],
        paymentsStatus = {
          alert: false,
        };

      // RECEBIMENTOS
      if (paymentMethods.length > 0) {
        if (paymentMethods.includes("credit-card")) {
          if (creditCardRows.length > 0) {
            creditCardRows.forEach((cCard) => {
              setTimeout(() => {
                if (!this.$root.isAdmin()) {
                  if (
                    (this.validateEachField(
                      this.tempContract[
                        `payment_methods_credit_card_machine_id_${cCard.id}`
                      ]
                    ) &&
                      this.validateEachField(
                        this.tempContract[
                          `payment_methods_credit_card_owner_${cCard.id}`
                        ]
                      ) &&
                      this.validateEachField(
                        this.tempContract[
                          `payment_methods_credit_card_total_value_${cCard.id}`
                        ]
                      ) &&
                      this.validateEachField(
                        this.tempContract[
                          `payment_methods_credit_card_client_name_${cCard.id}`
                        ]
                      ) &&
                      this.validateEachField(
                        this.tempContract[
                          `payment_methods_credit_card_client_number_${cCard.id}`
                        ]
                      ) &&
                      this.validateEachField(
                        this.tempContract[
                          `payment_methods_credit_card_client_month_${cCard.id}`
                        ]
                      ) &&
                      this.validateEachField(
                        this.tempContract[
                          `payment_methods_credit_card_client_year_${cCard.id}`
                        ]
                      )) ||
                    (this.validateEachField(
                      this.tempContract[
                        `payment_methods_credit_card_machine_id_${cCard.id}`
                      ]
                    ) &&
                      this.validateEachField(
                        this.tempContract[
                          `payment_methods_credit_card_owner_${cCard.id}`
                        ]
                      ) &&
                      this.validateEachField(
                        this.tempContract[
                          `payment_methods_credit_card_total_value_${cCard.id}`
                        ]
                      ) &&
                      this.validateEachField(
                        this.tempContract[
                          `payment_methods_credit_card_masked_name_${cCard.id}`
                        ]
                      ) &&
                      this.validateEachField(
                        this.tempContract[
                          `payment_methods_credit_card_masked_month_${cCard.id}`
                        ]
                      ) &&
                      this.validateEachField(
                        this.tempContract[
                          `payment_methods_credit_card_masked_year_${cCard.id}`
                        ]
                      ) &&
                      this.validateEachField(
                        this.tempContract[
                          `payment_methods_credit_card_masked_number_${cCard.id}`
                        ]
                      ))
                  ) {
                    if (
                      this.tempContract[
                        `payment_methods_credit_card_machine_id_${cCard.id}`
                      ] == "14"
                    ) {
                      if (
                        this.validateEachField(
                          this.tempContract[
                            `payment_methods_credit_card_payment_type_${cCard.id}`
                          ]
                        ) &&
                        this.validateEachField(
                          this.tempContract[
                            `payment_methods_credit_card_machine_supplier_id_${cCard.id}`
                          ]
                        )
                      ) {
                        allFieldsIsFilled = true;
                      } else {
                        allFieldsIsFilled = false;
                        paymentsStatus.alert = true;
                      }
                    }

                    if (
                      this.tempContract[
                        `payment_methods_credit_card_owner_${cCard.id}`
                      ] == "credit-card-owner"
                    ) {
                      if (
                        this.validateEachField(
                          this.tempContract[
                            `payment_methods_credit_card_owner_${cCard.id}_name`
                          ]
                        ) &&
                        this.validateEachField(
                          this.tempContract[
                            `payment_methods_credit_card_owner_${cCard.id}_mobile_phone`
                          ]
                        ) &&
                        this.validateEachField(
                          this.tempContract[
                            `payment_methods_credit_card_owner_${cCard.id}_location_street`
                          ]
                        ) &&
                        this.validateEachField(
                          this.tempContract[
                            `payment_methods_credit_card_owner_${cCard.id}_location_number`
                          ]
                        ) &&
                        this.validateEachField(
                          this.tempContract[
                            `payment_methods_credit_card_owner_${cCard.id}_CEP`
                          ]
                        ) &&
                        this.validateEachField(
                          this.tempContract[
                            `payment_methods_credit_card_owner_${cCard.id}_location_city`
                          ]
                        ) &&
                        this.validateEachField(
                          this.tempContract[
                            `payment_methods_credit_card_owner_${cCard.id}_location_neighborhood`
                          ]
                        ) &&
                        this.validateEachField(
                          this.tempContract[
                            `payment_methods_credit_card_owner_${cCard.id}_location_state`
                          ]
                        ) &&
                        this.validateEachField(
                          this.tempContract[
                            `payment_methods_credit_card_owner_${cCard.id}_CPF`
                          ]
                        )
                      ) {
                        allFieldsIsFilled = true;
                      } else {
                        allFieldsIsFilled = false;
                        paymentsStatus.alert = true;
                      }
                    } else {
                      allFieldsIsFilled = true;
                    }
                  } else {
                    allFieldsIsFilled = false;
                    paymentsStatus.alert = true;
                  }
                }
              }, 100);
            });
          } else {
            allFieldsIsFilled = false;
            paymentsStatus.alert = true;
          }
        }

        if (paymentMethods.includes("custom-payment")) {
          if (customPaymentRows.length > 0) {
            customPaymentRows.forEach(({ id }) => {
              if (
                this.validateEachField(
                  this.tempContract[
                    `payment_methods_custom_payment_total_value_${id}`
                  ]
                ) &&
                this.validateEachField(
                  this.tempContract[`payment_methods_custom_payment_name_${id}`]
                )
              ) {
                allFieldsIsFilled = true;
              } else {
                allFieldsIsFilled = false;
                paymentsStatus.alert = true;
              }
            });
          } else {
            if (
              this.validateEachField(
                this.tempContract[`payment_methods_custom_payment_total_value`]
              ) &&
              this.validateEachField(
                this.tempContract[`payment_methods_custom_payment_name`]
              )
            ) {
              allFieldsIsFilled = true;
            } else {
              allFieldsIsFilled = false;
              paymentsStatus.alert = true;
            }
          }
        }

        if (paymentMethods.includes("billing-ticket")) {
          if (
            this.tempContract[`payment_methods_billing_ticket_total_value`] &&
            this.tempContract[`payment_methods_billing_ticket_tax_id`] &&
            this.tempContract[`payment_methods_billing_ticket_installments`]
          ) {
            if (billingTicketInstallmentsRows.length > 0) {
              billingTicketInstallmentsRows.forEach((installment) => {
                if (
                  this.tempContract[
                    `payment_methods_billing_ticket_installment_${installment.id}`
                  ] &&
                  this.tempContract[
                    `payment_methods_billing_ticket_installment_${installment.id}_expiration_date`
                  ]
                ) {
                  //
                } else {
                  allFieldsIsFilled = false;
                  paymentsStatus.alert = true;
                }
              });
            }
          } else {
            allFieldsIsFilled = false;
            paymentsStatus.alert = true;
          }
        }

        if (paymentMethods.includes("bank-transfer")) {
          if (bankTransferRows.length > 0) {
            bankTransferRows.forEach(({ id }) => {
              if (
                this.validateEachField(
                  this.tempContract[
                    `payment_methods_bank_transfer_total_value_${id}`
                  ]
                ) &&
                this.validateEachField(
                  this.tempContract[
                    `payment_methods_bank_transfer_receipt_date_${id}`
                  ]
                ) &&
                this.validateEachField(
                  this.tempContract[`payment_methods_bank_transfer_bank_${id}`]
                )
              ) {
                allFieldsIsFilled = true;
              } else {
                allFieldsIsFilled = false;
                paymentsStatus.alert = true;
              }
            });
          } else {
            if (
              this.tempContract[`payment_methods_bank_transfer_total_value`] &&
              this.tempContract[`payment_methods_bank_transfer_receipt_date`] &&
              this.tempContract[`payment_methods_bank_transfer_bank`]
            ) {
              //
            } else {
              allFieldsIsFilled = false;
              paymentsStatus.alert = true;
            }
          }
        }

        if (paymentMethods.includes("payment-card")) {
          if (contractId > 2786) {
            // new version payment card
            if (paymentCardRows.length > 0) {
              paymentCardRows.forEach((pCard) => {
                if (
                  this.tempContract[
                    `payment_methods_payment_card_total_value_${pCard.id}`
                  ] &&
                  this.tempContract[
                    `payment_methods_payment_card_total_profit_value_${pCard.id}`
                  ] &&
                  this.tempContract[
                    `payment_methods_payment_card_contractual_fine_percentage_${pCard.id}`
                  ]
                ) {
                  //
                } else {
                  allFieldsIsFilled = false;
                  paymentsStatus.alert = true;
                }
              });
            }
          } else {
            if (
              this.tempContract[`payment_methods_payment_card_total_value`] &&
              this.tempContract[
                `payment_methods_payment_card_total_profit_value`
              ] &&
              this.tempContract[
                `payment_methods_payment_card_contractual_fine_percentage`
              ]
            ) {
              //
            } else {
              allFieldsIsFilled = false;
              paymentsStatus.alert = true;
            }
          }
        }

        if (paymentMethods.includes("third-party")) {
          if (thirdPartyPaymentRows.length > 0) {
            thirdPartyPaymentRows.forEach((thirdParty) => {
              if (
                (this.tempContract[
                  `third_party_client_first_name_${thirdParty.id}`
                ] &&
                  this.tempContract[
                    `third_party_client_last_name_${thirdParty.id}`
                  ] &&
                  this.tempContract[
                    `third_party_client_email_${thirdParty.id}`
                  ] &&
                  this.tempContract[
                    `third_party_client_birthday_${thirdParty.id}`
                  ] &&
                  this.tempContract[
                    `third_party_client_phone_${thirdParty.id}`
                  ] &&
                  this.tempContract[
                    `third_party_client_location_neighborhood_${thirdParty.id}`
                  ] &&
                  this.tempContract[
                    `third_party_client_location_neighborhood_${thirdParty.id}`
                  ] != "" &&
                  this.tempContract[
                    `third_party_client_location_state_${thirdParty.id}`
                  ] &&
                  this.tempContract[
                    `third_party_client_location_state_${thirdParty.id}`
                  ] != "") ||
                (this.tempContract[
                  `third_party_client_mobile_phone_${thirdParty.id}`
                ] &&
                  this.tempContract[
                    `third_party_client_CEP_${thirdParty.id}`
                  ] &&
                  this.tempContract[
                    `third_party_client_location_number_${thirdParty.id}`
                  ] &&
                  this.tempContract[
                    `third_party_client_location_city_${thirdParty.id}`
                  ] &&
                  this.tempContract[
                    `third_party_client_location_street_${thirdParty.id}`
                  ] &&
                  this.tempContract[`third_party_client_RG_${thirdParty.id}`] &&
                  this.tempContract[
                    `third_party_client_doc_emission_${thirdParty.id}`
                  ] &&
                  this.tempContract[
                    `third_party_client_doc_emission_state_${thirdParty.id}`
                  ] &&
                  this.tempContract[
                    `third_party_client_CPF_${thirdParty.id}`
                  ] &&
                  this.tempContract[
                    `third_party_client_paid_value_${thirdParty.id}`
                  ])
              ) {
                allFieldsIsFilled = true;
              } else {
                allFieldsIsFilled = false;
                paymentsStatus.alert = true;
              }
            });
          } else {
            allFieldsIsFilled = false;
            paymentsStatus.alert = true;
          }
        }
      } else {
        allFieldsIsFilled = false;
        paymentsStatus.alert = true;
      }

      return {
        allFieldsIsFilled,
        paymentsStatus,
      };
    },
  },
};
