<template>
  <div class="person" :class="`person-${clientId}`">
    <h3 class="title mb-30 cgray f13">
      <a-row>
        <a-col :span="12">
          Terceiro - Nº {{ clientId }}
          {{
            this.form.getFieldValue(`third_party_client_first_name_${clientId}`)
              ? " - " +
                this.form.getFieldValue(
                  `third_party_client_first_name_${clientId}`
                )
              : ""
          }}
        </a-col>
        <a-col class="a-right" :span="12">
          <a-tooltip
            v-if="clientId == 1"
            placement="left"
            title="Recuperar dados do contratante"
          >
            <a-button
              size="small"
              type="primary"
              shape="circle"
              icon="user"
              @click="getCustomerData(clientId)"
            >
            </a-button>
          </a-tooltip>

          <a-button
            v-if="clientId > 1"
            size="small"
            type="danger"
            shape="circle"
            icon="delete"
            @click="removeRow(clientId)"
          >
          </a-button>
        </a-col>
      </a-row>
    </h3>

    <a-row :gutter="[20, 0]">
      <a-col :span="24">
        <a-row :gutter="[20, 0]">
          <a-col :span="7">
            <a-form-item>
              <label :class="'filled'">Nome</label>
              <a-input
                class="travel-input"
                placeholder="Nome"
                v-decorator="[
                  `third_party_client_first_name_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="7">
            <a-form-item>
              <label :class="'filled'">Sobrenome</label>
              <a-input
                class="travel-input"
                placeholder="Sobrenome"
                v-decorator="[
                  `third_party_client_last_name_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="10">
            <a-form-item>
              <label :class="'filled'">Email</label>
              <a-input
                class="travel-input"
                placeholder="Email"
                v-decorator="[
                  `third_party_client_email_${clientId}`,
                  {
                    rules: [
                      {
                        type: 'email',
                        message: 'Escreva um e-mail válido',
                      },
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="5">
            <a-form-item>
              <label :class="'filled'">Nascimento</label>
              <a-date-picker
                class="travel-input"
                placeholder="Nascimento"
                format="DD/MM/YYYY"
                v-mask="'##/##/####'"
                :showToday="false"
                :disabled-date="adultHoodDate"
                @openChange="defaultDate"
                v-decorator="[
                  `third_party_client_birthday_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
              </a-date-picker>
            </a-form-item>
          </a-col>

          <a-col :span="5">
            <a-form-item>
              <label :class="'filled'">CPF</label>
              <a-input
                class="travel-input"
                placeholder="CPF"
                v-mask="'###.###.###-##'"
                v-decorator="[
                  `third_party_client_CPF_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        min: 9,
                        message: 'Preencha todos números.',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="5">
            <a-form-item>
              <label :class="'filled'">RG</label>
              <a-input
                class="travel-input"
                placeholder="RG"
                v-mask="['##.###.###', '#.###.###', '###.###.###-##']"
                v-decorator="[
                  `third_party_client_RG_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                      {
                        min: 9,
                        message: 'Preencha todos números',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item>
              <label :class="'filled'">Órgão Emissor</label>
              <a-input
                class="travel-input"
                placeholder="Órgão Emissor"
                v-decorator="[
                  `third_party_client_doc_emission_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item>
              <label :class="'filled'">ES</label>
              <a-select
                class="travel-input"
                show-search
                placeholder="ES"
                v-decorator="[
                  `third_party_client_doc_emission_state_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of states"
                  :key="index"
                  :value="item.initials"
                >
                  {{ item.initials.toUpperCase() }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <label :class="'filled'">Telefone fixo</label>
              <a-input
                class="travel-input"
                placeholder="Telefone fixo"
                v-mask="'(##) ####-####'"
                v-decorator="[
                  `third_party_client_phone_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <label :class="'filled'">Celular</label>
              <a-input
                class="travel-input"
                placeholder="Celular"
                v-mask="'(##) # ####-####'"
                v-decorator="[`third_party_client_mobile_phone_${clientId}`]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <label :class="'filled'">CEP</label>
              <a-input
                class="travel-input"
                placeholder="CEP"
                v-mask="'#####-###'"
                v-decorator="[
                  `third_party_client_CEP_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                @change="getLocation()"
              >
                <a-icon slot="prefix" type="field-svg" />
                <a-icon v-if="cepLoading" slot="suffix" type="loading" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <label :class="'filled'">Logradouro</label>
              <a-input
                class="travel-input"
                placeholder="Logradouro"
                v-decorator="[
                  `third_party_client_location_street_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item>
              <label :class="'filled'">Número</label>
              <a-input
                type="number"
                class="travel-input"
                placeholder="Número"
                v-decorator="[
                  `third_party_client_location_number_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <label :class="'filled'">Complemento</label>
              <a-input
                class="travel-input"
                placeholder="Complemento"
                v-decorator="[
                  `third_party_client_location_complement_${clientId}`,
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <label :class="'filled'">Bairro</label>
              <a-input
                class="travel-input"
                placeholder="Bairro"
                v-decorator="[
                  `third_party_client_location_neighborhood_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <label :class="'filled'">Cidade</label>
              <a-input
                class="travel-input"
                placeholder="Cidade"
                v-decorator="[
                  `third_party_client_location_city_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <label :class="'filled'">Estado</label>
              <a-select
                class="travel-input"
                show-search
                placeholder="ES"
                v-decorator="[
                  `third_party_client_location_state_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of states"
                  :key="index"
                  :value="item.initials"
                >
                  {{ item.initials.toUpperCase() }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" :offset="6">
            <a-form-item>
              <label :class="'filled'">Valor pago (R$)</label>
              <a-input
                class="travel-input"
                placeholder="Valor pago (R$)"
                v-currency
                v-decorator="[
                  `third_party_client_paid_value_${clientId}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import axios from "axios";
import subYears from "date-fns/subYears";
import { format } from "date-fns";

export default {
  name: "ClientThirdPartyForm",
  props: {
    form: Object,
    contract: Object,
    tempContract: Object,
    clientId: Number,
    totalThirdParty: Number,
  },
  data() {
    return {
      states: [
        { name: "Acre", initials: "AC" },
        { name: "Alagoas", initials: "AL" },
        { name: "Amapá", initials: "AP" },
        { name: "Amazonas", initials: "AM" },
        { name: "Bahia", initials: "BA" },
        { name: "Ceará", initials: "CE" },
        { name: "Distrito Federal", initials: "DF" },
        { name: "Espírito Santo", initials: "ES" },
        { name: "Goiás", initials: "GO" },
        { name: "Maranhão", initials: "MA" },
        { name: "Mato Grosso", initials: "MT" },
        { name: "Mato Grosso do Sul", initials: "MS" },
        { name: "Minas Gerais", initials: "MG" },
        { name: "Pará", initials: "PA" },
        { name: "Paraíba", initials: "PB" },
        { name: "Paraná", initials: "PR" },
        { name: "Pernambuco", initials: "PE" },
        { name: "Piauí", initials: "PI" },
        { name: "Rio de Janeiro", initials: "RJ" },
        { name: "Rio Grande do Norte", initials: "RN" },
        { name: "Rio Grande do Sul", initials: "RS" },
        { name: "Rondônia", initials: "RO" },
        { name: "Roraima", initials: "RR" },
        { name: "Santa Catarina", initials: "SC" },
        { name: "São Paulo", initials: "SP" },
        { name: "Sergipe", initials: "SE" },
        { name: "Tocantins", initials: "TO" },
      ],
      cepLoading: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.form.setFieldsValue({
        [`third_party_client_first_name_${this.clientId}`]:
          this.tempContract[`third_party_client_first_name_${this.clientId}`],
        [`third_party_client_last_name_${this.clientId}`]:
          this.tempContract[`third_party_client_last_name_${this.clientId}`],
        [`third_party_client_email_${this.clientId}`]:
          this.tempContract[`third_party_client_email_${this.clientId}`],
        [`third_party_client_birthday_${this.clientId}`]:
          this.tempContract[`third_party_client_birthday_${this.clientId}`],
        [`third_party_client_phone_${this.clientId}`]:
          this.tempContract[`third_party_client_phone_${this.clientId}`],
        [`third_party_client_mobile_phone_${this.clientId}`]:
          this.tempContract[`third_party_client_mobile_phone_${this.clientId}`],
        [`third_party_client_CEP_${this.clientId}`]:
          this.tempContract[`third_party_client_CEP_${this.clientId}`],
        [`third_party_client_location_neighborhood_${this.clientId}`]:
          this.tempContract[
            `third_party_client_location_neighborhood_${this.clientId}`
          ],
        [`third_party_client_location_number_${this.clientId}`]:
          this.tempContract[
            `third_party_client_location_number_${this.clientId}`
          ],
        [`third_party_client_location_complement_${this.clientId}`]:
          this.tempContract[
            `third_party_client_location_complement_${this.clientId}`
          ],
        [`third_party_client_location_street_${this.clientId}`]:
          this.tempContract[
            `third_party_client_location_street_${this.clientId}`
          ],
        [`third_party_client_location_city_${this.clientId}`]:
          this.tempContract[
            `third_party_client_location_city_${this.clientId}`
          ],
        [`third_party_client_location_state_${this.clientId}`]:
          this.tempContract[
            `third_party_client_location_state_${this.clientId}`
          ],
        [`third_party_client_RG_${this.clientId}`]:
          this.tempContract[`third_party_client_RG_${this.clientId}`],
        [`third_party_client_doc_emission_${this.clientId}`]:
          this.tempContract[`third_party_client_doc_emission_${this.clientId}`],
        [`third_party_client_doc_emission_state_${this.clientId}`]:
          this.tempContract[
            `third_party_client_doc_emission_state_${this.clientId}`
          ],
        [`third_party_client_CPF_${this.clientId}`]:
          this.tempContract[`third_party_client_CPF_${this.clientId}`],
        [`third_party_client_paid_value_${this.clientId}`]:
          this.tempContract[`third_party_client_paid_value_${this.clientId}`],
      });
    }, 50);
  },
  methods: {
    getCustomerData() {

      this.form.setFieldsValue({
        [`third_party_client_first_name_${this.clientId}`]:
          this.tempContract.the_customer.first_name.toUpperCase(),
      });

      this.form.setFieldsValue({
        [`third_party_client_last_name_${this.clientId}`]:
          this.tempContract.the_customer.last_name.toUpperCase(),
      });

      this.form.setFieldsValue({
        [`third_party_client_email_${this.clientId}`]:
          this.tempContract.the_customer.email.toUpperCase(),
      });

      this.form.setFieldsValue({
        [`third_party_client_birthday_${this.clientId}`]:
          this.tempContract.the_customer.birthday,
      });

      this.form.setFieldsValue({
        [`third_party_client_phone_${this.clientId}`]:
          this.tempContract.the_customer.phone,
      });

      this.form.setFieldsValue({
        [`third_party_client_location_neighborhood_${this.clientId}`]:
          this.tempContract.the_customer.location_neighborhood.toUpperCase(),
      });

      this.form.setFieldsValue({
        [`third_party_client_location_state_${this.clientId}`]:
          this.tempContract.the_customer.location_state,
      });

      this.form.setFieldsValue({
        [`third_party_client_mobile_phone_${this.clientId}`]:
          this.tempContract.the_customer.mobile_phone.toUpperCase(),
      });

      this.form.setFieldsValue({
        [`third_party_client_CEP_${this.clientId}`]:
          this.tempContract.the_customer.cep.toUpperCase(),
      });

      this.form.setFieldsValue({
        [`third_party_client_location_number_${this.clientId}`]:
          this.tempContract.the_customer.location_number,
      });

      this.form.setFieldsValue({
        [`third_party_client_location_complement_${this.clientId}`]:
          this.tempContract.the_customer.location_complement.toUpperCase(),
      });

      this.form.setFieldsValue({
        [`third_party_client_location_city_${this.clientId}`]:
          this.tempContract.the_customer.location_city.toUpperCase(),
      });

      this.form.setFieldsValue({
        [`third_party_client_location_street_${this.clientId}`]:
          this.tempContract.the_customer.location_street.toUpperCase(),
      });

      this.form.setFieldsValue({
        [`third_party_client_RG_${this.clientId}`]:
          this.tempContract.the_customer.rg,
      });

      this.form.setFieldsValue({
        [`third_party_client_doc_emission_${this.clientId}`]:
          this.tempContract.the_customer.rg_emissor,
      });

      this.form.setFieldsValue({
        [`third_party_client_doc_emission_state_${this.clientId}`]:
          this.tempContract.the_customer.rg_state,
      });

      this.form.setFieldsValue({
        [`third_party_client_CPF_${this.clientId}`]:
          this.tempContract.the_customer.cpf,
      });
    },
    getLocation() {
      setTimeout(() => {
        if (
          this.form.getFieldValue(`third_party_client_CEP_${this.clientId}`)
        ) {
          if (
            this.form
              .getFieldValue(`third_party_client_CEP_${this.clientId}`)
              .replace("-", "").length == 8
          ) {
            this.cepLoading = true;
            axios
              .get(
                "https://viacep.com.br/ws/" +
                  this.form.getFieldValue(
                    `third_party_client_CEP_${this.clientId}`
                  ) +
                  "/json/"
              )
              .then(({ data }) => {
                this.cepLoading = false;

                setTimeout(() => {
                  this.form.setFieldsValue({
                    [`third_party_client_location_street_${this.clientId}`]:
                      data.logradouro.toUpperCase(),
                    [`third_party_client_location_state_${this.clientId}`]:
                      data.uf,
                  });

                  this.form.setFieldsValue({
                    [`third_party_client_location_city_${this.clientId}`]:
                      data.localidade.toUpperCase(),
                    [`third_party_client_location_neighborhood_${this.clientId}`]:
                      data.bairro.toUpperCase(),
                  });

                  this.tempContract[
                    `third_party_client_location_street_${this.clientId}`
                  ] = data.logradouro.toUpperCase();
                  this.tempContract[
                    `third_party_client_location_state_${this.clientId}`
                  ] = data.uf;
                  this.tempContract[
                    `third_party_client_location_city_${this.clientId}`
                  ] = data.localidade.toUpperCase();
                  this.tempContract[
                    `third_party_client_location_neighborhood_${this.clientId}`
                  ] = data.bairro.toUpperCase();
                }, 50);
              })
              .catch(({ response }) => {
                response;
                this.$message.error(
                  "Houve um problema ao recuperar a localização."
                );
                this.cepLoading = false;
              });
          }
        }
      }, 110);
    },
    defaultDate() {
      setTimeout(() => {
        this.form.setFieldsValue({
          [`third_party_client_birthday_${this.clientId}`]:
            this.form.getFieldValue(
              `third_party_client_birthday_${this.clientId}`
            )
              ? this.form.getFieldValue(
                  `third_party_client_birthday_${this.clientId}`
                )
              : format(subYears(new Date(), 18), "MM/dd/yyyy"),
        });
      }, 1);
    },
    adultHoodDate(current) {
      return current && current > subYears(new Date(), 18);
    },
    removeRow(id) {
      this.$emit("removeThirdParty", id);
    },
  },
};
</script>