var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.travellersRows),function(traveller,index){return _c('div',{key:index,class:`traveller traveller-${traveller.id}`},[_c('div',{staticClass:"product-card",on:{"click":function($event){return _vm.onClickOpenCollapse(traveller.id)}}},[_c('a-row',[_c('a-col',{attrs:{"span":22}},[_c('a-row',{attrs:{"type":"flex","justify":"start","gutter":20}},[_c('a-col',{staticClass:"id cprimary"},[_c('span',{staticClass:"ico"},[_c('a-icon',{attrs:{"type":"user"}}),_vm._v(" "),_c('font',{staticClass:"num"},[_vm._v(_vm._s(traveller.id)+" ")])],1)]),_vm._v(" "),_c('a-col',{staticClass:"id-name"},[_c('span',{staticClass:"traveller"},[_vm._v(_vm._s(_vm.theTraveller(traveller.id)))]),_vm._v(" "),_c('span',{staticClass:"meta f11"},[(
                    _vm.tempContract[`flight_${_vm.flightSectionId}_type`] == 'Milhas'
                  )?_c('span',[_vm._v("Milhas:\n                  "+_vm._s(_vm.tempContract[
                      `flight_${_vm.flightSectionId}_traveller_${traveller.id}_miles`
                    ]))]):_vm._e(),_vm._v(" "),(
                    _vm.tempContract[`flight_${_vm.flightSectionId}_type`] == 'Milhas'
                  )?_c('span',{staticClass:"ml-20"},[_vm._v("Valor:\n                  "+_vm._s(_vm.tempContract[
                      `flight_${_vm.flightSectionId}_traveller_${traveller.id}_value`
                    ])+"\n                ")]):_vm._e(),_vm._v(" "),(
                    _vm.tempContract[`flight_${_vm.flightSectionId}_type`] == 'Milhas'
                  )?_c('span',{staticClass:"ml-20"},[_vm._v("Taxas:\n                  "+_vm._s(_vm.tempContract[
                      `flight_${_vm.flightSectionId}_traveller_${traveller.id}_taxes`
                    ])+"\n                ")]):_vm._e(),_vm._v(" "),(
                    _vm.tempContract[`flight_${_vm.flightSectionId}_type`] == 'Milhas'
                  )?_c('span',{staticClass:"ml-20 mr-20"},[_vm._v("\n                  Moeda Tx:\n                  "+_vm._s(_vm.tempContract[
                      `flight_${_vm.flightSectionId}_traveller_${traveller.id}_taxes_currency`
                    ])+"\n                ")]):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n                  Localizador:\n                  "+_vm._s(_vm.tempContract[
                      `flight_${_vm.flightSectionId}_traveller_${traveller.id}_locator`
                    ]))])])])],1)],1),_vm._v(" "),_c('a-col',{staticClass:"a-right",attrs:{"span":2}},[(traveller.id == _vm.travellersRows.length && traveller.id != 1)?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete","ghost":""},on:{"click":function($event){return _vm.removeTraveller(_vm.flightSectionId, traveller.id)}}}):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.openCollapse == traveller.id)?_c('div',{staticClass:"product-card-content"},[_c('a-row',{staticClass:"travellers-rows",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{class:'filled'},[_vm._v("Viajante ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionId}_traveller_${traveller.id}`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionId}_traveller_${traveller.id}`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Selecione","disabled":_vm.milesOrderExists}},_vm._l((_vm.travellers),function(traveller,index){return _c('a-select-option',{key:index,attrs:{"value":traveller.id}},[_vm._v("\n                "+_vm._s(traveller.name)+"\n                "+_vm._s(traveller.lastName)+"\n              ")])}),1)],1)],1),_vm._v(" "),(_vm.tempContract[`flight_${_vm.flightSectionId}_type`] == 'Milhas')?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('span',{staticClass:"travel-input"},[_c('label',{class:'filled'},[_vm._v("Milhas ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                  currency: {
                    prefix: '',
                    suffix: '',
                  },
                  distractionFree: true,
                  valueAsInteger: false,
                  precision: 0,
                  autoDecimalMode: true,
                  allowNegative: false,
                }),expression:"{\n                  currency: {\n                    prefix: '',\n                    suffix: '',\n                  },\n                  distractionFree: true,\n                  valueAsInteger: false,\n                  precision: 0,\n                  autoDecimalMode: true,\n                  allowNegative: false,\n                }"},{name:"decorator",rawName:"v-decorator",value:([
                  `flight_${_vm.flightSectionId}_traveller_${traveller.id}_miles`,
                ]),expression:"[\n                  `flight_${flightSectionId}_traveller_${traveller.id}_miles`,\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira","disabled":_vm.milesOrderExists}})],1)])],1):_vm._e(),_vm._v(" "),(_vm.tempContract[`flight_${_vm.flightSectionId}_type`] == 'Milhas')?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('span',{staticClass:"travel-input"},[_c('label',{class:'filled'},[_vm._v("Valor (R$) ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `flight_${_vm.flightSectionId}_traveller_${traveller.id}_value`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `flight_${flightSectionId}_traveller_${traveller.id}_value`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira"},on:{"blur":function($event){return _vm.calcFlightTotalValue(_vm.flightSectionId)}}})],1)])],1):_vm._e(),_vm._v(" "),(_vm.tempContract[`flight_${_vm.flightSectionId}_type`] == 'Milhas')?_c('a-col',{attrs:{"span":3}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{class:'filled'},[_vm._v("Moeda Taxas ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionId}_traveller_${traveller.id}_taxes_currency`,
                {
                  rules: [
                    {
                      required:
                        _vm.tempContract[`flight_${_vm.flightSectionId}_type`] ==
                        'Milhas'
                          ? true
                          : false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionId}_traveller_${traveller.id}_taxes_currency`,\n                {\n                  rules: [\n                    {\n                      required:\n                        tempContract[`flight_${flightSectionId}_type`] ==\n                        'Milhas'\n                          ? true\n                          : false,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Selecione"}},[_c('a-select-option',{attrs:{"value":"BRL"}},[_vm._v(" R$ ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"USD"}},[_vm._v(" $ ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"EUR"}},[_vm._v(" € ")])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract[`flight_${_vm.flightSectionId}_type`] == 'Milhas')?_c('a-col',{attrs:{"span":3}},[_c('a-form-item',[_c('span',{staticClass:"travel-input"},[_c('label',{class:'filled'},[_vm._v("Taxas ("+_vm._s(_vm.flightTaxesCurrency(_vm.flightSectionId, traveller.id))+")\n              ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                  currency: _vm.flightTaxesCurrency(
                    _vm.flightSectionId,
                    traveller.id
                  ),
                }),expression:"{\n                  currency: flightTaxesCurrency(\n                    flightSectionId,\n                    traveller.id\n                  ),\n                }"},{name:"decorator",rawName:"v-decorator",value:([
                  `flight_${_vm.flightSectionId}_traveller_${traveller.id}_taxes`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `flight_${flightSectionId}_traveller_${traveller.id}_taxes`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira"},on:{"blur":function($event){return _vm.calcFlightTotalValue(_vm.flightSectionId)}}})],1)])],1):_vm._e(),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('span',{staticClass:"travel-input"},[_c('label',{class:'filled'},[_vm._v("Localizador ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `flight_${_vm.flightSectionId}_traveller_${traveller.id}_locator`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `flight_${flightSectionId}_traveller_${traveller.id}_locator`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira"}})],1)])],1)],1)],1):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }