<template>
  <div>
    <template v-if="haveMachine">
      <aRow :gutter="20">
        <aCol :span="4">
          <a-form-item>
            <label :class="'filled'">Valor</label>
            <a-input
              class="travel-input"
              placeholder="Valor"
              v-currency
              v-decorator="[
                `payment_methods_payment_link_total_value_${paymentLinkId}`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Preencha todos números.',
                    },
                  ],
                },
              ]"
              @change="updatePaymentLinkValue"
              @blur="onBlurVerifyPaymentLinkValue"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </aCol>

        <aCol
          v-show="
            form.getFieldValue(
              `payment_methods_payment_link_total_value_${paymentLinkId}`
            )
          "
          :span="3"
        >
          <a-form-item>
            <label :class="'filled'">Parcelas</label>

            <a-select
              class="travel-input select"
              placeholder="Selecione"
              @change="onChangePaymentLinkInstallments"
              v-decorator="[
                `payment_methods_payment_link_installments_${paymentLinkId}`,

                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="(item, index) of installments"
                :key="index"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </aCol>

        <aCol
          v-show="
            form.getFieldValue(
              `payment_methods_payment_link_total_value_${paymentLinkId}`
            ) &&
            form.getFieldValue(
              `payment_methods_payment_link_installments_${paymentLinkId}`
            )
          "
          :span="4"
        >
          <a-form-item>
            <label :class="'filled'">Primeira parcela </label>

            <a-input
              class="travel-input"
              placeholder="Insira o valor"
              v-currency
              v-decorator="[
                `payment_methods_payment_link_first_installment_${paymentLinkId}`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Preencha todos números.',
                    },
                  ],
                },
              ]"
              @blur="updateOtherInstallments"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </aCol>

        <aCol
          v-show="
            form.getFieldValue(
              `payment_methods_payment_link_total_value_${paymentLinkId}`
            ) &&
            form.getFieldValue(
              `payment_methods_payment_link_installments_${paymentLinkId}`
            ) != 1
          "
          :span="4"
        >
          <a-form-item>
            <label :class="'filled'">Demais parcelas</label>

            <a-input
              class="travel-input readonly"
              placeholder=""
              v-currency
              v-decorator="[
                `payment_methods_payment_link_installments_value_${paymentLinkId}`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Preencha todos números.',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </aCol>

        <aCol :span="3">
          <a-form-item>
            <label :class="'filled'">Taxa PDQ (R$)</label>
            <a-input
              class="travel-input readonly"
              placeholder=""
              v-currency
              v-decorator="[
                `payment_methods_payment_link_tax_value_${paymentLinkId}`,
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </aCol>

        <aCol :span="3">
          <a-form-item>
            <label :class="'filled'">Taxa Adm (R$)</label>
            <a-input
              class="travel-input readonly"
              placeholder=""
              v-currency
              v-decorator="[
                `payment_methods_payment_link_advance_fee_value_${paymentLinkId}`,
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </aCol>

        <aCol :span="3">
          <a-form-item>
            <label :class="'filled'">Total Taxas (R$)</label>
            <a-input
              class="travel-input readonly"
              placeholder=""
              v-currency
              v-decorator="[
                `payment_methods_payment_link_total_taxes_${paymentLinkId}`,
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </aCol>
      </aRow>

      <PaymentLinkGenerateLink
        v-if="
          form.getFieldValue(
            `payment_methods_payment_link_total_value_${paymentLinkId}`
          ) &&
          form.getFieldValue(
            `payment_methods_payment_link_installments_${paymentLinkId}`
          ) &&
          form.getFieldValue(
            `payment_methods_payment_link_first_installment_${paymentLinkId}`
          )
        "
        :form="form"
        :paymentLinkId="paymentLinkId"
        :tempContract="tempContract"
        :contract="contract"
      />

      <PaymentLinkTransactionActions
        v-if="
          ![undefined, ''].includes(
            tempContract[`payment_link_3ds_tid_${paymentLinkId}`]
          )
        "
        :form="form"
        :paymentLinkId="paymentLinkId"
        :tempContract="tempContract"
        :contract="contract"
      />

      <aCheckbox
        v-model="performFraudAnalysis"
        class="f12"
        @change="onChangePerformFraudAnalysis"
      >
        Marque para realizar <b>análise de fraude</b> no pagamento
      </aCheckbox>

      <PaymentLinkClearSale
        class="mt-20"
        v-if="performFraudAnalysis"
        :form="form"
        :paymentLinkId="paymentLinkId"
        :tempContract="tempContract"
        :contract="contract"
      />
    </template>

    <template v-if="!haveMachine">
      <a-alert
        class="mb-20 f12"
        message="ATENÇÃO: Nenhuma máquina de pagamento configurada para empresa do contrato. Vá na lista de PDQs e ative para link de pagamento. Esta etapa é necessária para cálculos de lucratividade e taxas."
        banner
        type="warning"
      />
    </template>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import cardMachinesMixins from "@/mixins/card-machines/cardMachinesMixins.js";
import paymentLinkMixins from "@/components/contracts/mixins/paymentLinkMixins.js";
import PaymentLinkGenerateLink from "../sections/PaymentLinkGenerateLink.vue";
import PaymentLinkClearSale from "../sections/PaymentLinkClearSale.vue";
import PaymentLinkTransactionActions from "../sections/PaymentLinkTransactionActions.vue";

export default {
  name: "PaymentLinkCollapse",
  props: {
    form: Object,
    tempContract: Object,
    contract: Object,
    paymentLinkId: Number,
  },
  mixins: [cardMachinesMixins, paymentLinkMixins, formatThings],
  components: {
    PaymentLinkGenerateLink,
    PaymentLinkClearSale,
    PaymentLinkTransactionActions,
  },
  data() {
    return {
      installments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      haveMachine: false,
      onlinePayment: false,
    };
  },
  mounted() {
    if (
      this.tempContract[
        `payment_methods_payment_link_machine_id_${this.paymentLinkId}`
      ] === undefined
    ) {
      this.cardMachines.filters.status.selected = "Ativo";
      this.cardMachines.filters.paymentLinkStatus.selected = "Ativo";
      this.cardMachines.filters.paymentLinkActiveMachine.selected = ["1"];
      this.cardMachines.filters.company.selected = [
        this.tempContract.company_id,
      ];

      this.getCardMachines();

      setTimeout(() => {
        this.tempContract[
          `payment_methods_payment_link_taxes_range_${this.paymentLinkId}`
        ] = this.cardMachines.list[0].taxes_range;

        this.tempContract[
          `payment_methods_payment_link_advance_fee_range_${this.paymentLinkId}`
        ] = this.cardMachines.list[0].advance_fee;

        this.tempContract[
          `payment_methods_payment_link_machine_id_${this.paymentLinkId}`
        ] = this.cardMachines.list[0].id;

        this.cardMachines.list.length > 0
          ? (this.haveMachine = true)
          : (this.haveMachine = false);
      }, 500);
    } else {
      this.haveMachine = true;
    }

    setTimeout(() => {
      this.setPaymentLinkFieldsValue();
    }, 100);
  },
};
</script>
