<template>
  <div>
    <div v-if="contractedServices.length > 0" class="travel-tabs">
      <div class="tab-icons">
        <div
          v-if="contractedServices.includes('hotel')"
          class="tab grayscale"
          :class="activeTab == 'hotel' ? 'active' : ''"
          @click="portalActiveTab('hotel')"
        >
          <img
            src="@/assets/images/dashboard/contracts/hotel-ico.png"
            alt="img"
          />

          <a-tooltip v-if="validateAllContractFields.hotelStatus.alert">
            <template slot="title"> Preencha todos os campos </template>
            <img
              class="c-pointer alert"
              src="@/assets/images/dashboard/contracts/danger.png"
              alt="img"
              width="15"
            />
          </a-tooltip>
        </div>

        <div
          v-if="contractedServices.includes('flight')"
          class="tab grayscale"
          :class="activeTab == 'flight' ? 'active' : ''"
          @click="portalActiveTab('flight')"
        >
          <img
            src="@/assets/images/dashboard/contracts/flight-ico.png"
            alt="img"
          />

          <a-tooltip v-if="validateAllContractFields.flightStatus.alert">
            <template slot="title"> Preencha todos os campos </template>
            <img
              class="c-pointer alert"
              src="@/assets/images/dashboard/contracts/danger.png"
              alt="img"
              width="15"
            />
          </a-tooltip>
        </div>

        <div
          v-show="contractedServices.includes('service')"
          class="tab grayscale"
          :class="activeTab == 'service' ? 'active' : ''"
          @click="portalActiveTab('service')"
        >
          <img
            src="@/assets/images/dashboard/contracts/services-ico.png"
            alt="img"
          />

          <a-tooltip v-if="validateAllContractFields.serviceStatus.alert">
            <template slot="title"> Preencha todos os campos </template>
            <img
              class="c-pointer alert"
              src="@/assets/images/dashboard/contracts/danger.png"
              alt="img"
              width="15"
            />
            <!-- <a-icon class="c-pointer alert" type="warning"  theme="twoTone" two-tone-color="#ffe000"  /> -->
          </a-tooltip>
        </div>
      </div>

      <div class="tab-content">
        <div class="tabs-content" v-if="activeTab == 'hotel'">
          <HotelTab
            v-if="contractedServices.includes('hotel')"
            :contract="contract"
            :form="form"
            :tempContract="tempContract"
            :travellers="travellers"
          />
        </div>

        <div class="tabs-content" v-if="activeTab == 'flight'">
          <FlightTab
            v-if="contractedServices.includes('flight')"
            :contract="contract"
            :form="form"
            :tempContract="tempContract"
            :travellers="travellers"
          />
        </div>

        <div class="tabs-content" v-if="activeTab == 'service'">
          <ServiceTab
            v-if="contractedServices.includes('service')"
            :contract="contract"
            :form="form"
            :tempContract="tempContract"
            :travellers="travellers"
          />
        </div>

        <div class="tabs-content" v-if="activeTab == 'cruise'">
          <CruiseTab
            :contract="contract"
            :form="form"
            :tempContract="tempContract"
            :travellers="travellers"
          />
        </div>
      </div>
    </div>

    <div v-if="contractedServices.length == 0" style="height: 510px">
      <NoData
        text="Nenhum produto cadastrado"
        :img="{ src: noProductsIco, width: 200 }"
      />
    </div>

    <div id="live-debug">
      <json-viewer
        :value="tempContract"
        :show-array-index="false"
        :expand-depth="1"
      ></json-viewer>
    </div>
  </div>
</template>

<script>
import NoData from "../../general/NoData.vue";
import noProductsIco from "@/assets/images/dashboard/contracts/no-products.png";
import contractFieldsValidation from "../mixins/contractFieldsValidation";
import FlightTab from "../tabs/FlightTab.vue";
import HotelTab from "../tabs/HotelTab.vue";
import ServiceTab from "../tabs/ServiceTab.vue";
import CruiseTab from "../tabs/CruiseTab.vue";

export default {
  name: "ContractPageContent",
  components: { NoData, HotelTab, FlightTab, ServiceTab, CruiseTab },
  mixins: [contractFieldsValidation],
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    travellers: Array,
    activeTab: String,
    contractedServices: Array,
    validateAllContractFields: Object,
  },
  data() {
    return {
      noProductsIco,
    };
  },
  mounted() {},
  methods: {
    portalActiveTab(active) {
      setTimeout(() => {
        this.activeTab = active;
        this.$emit("activeTabEvent", active);
        this.tempContract.active_tab = active;
      }, 100);
    },
  },
};
</script>

<style lang="sass" scoped>
.travel-tabs
  .tab-icons
    float: left
    border-right: 1px solid #e0e0e0
    width: 75px
    height: 74vh
    text-align: center
    padding-right: 14px
    .tab
      float: left
      height: 60px
      width: 100%
      line-height: 56px
      margin-bottom: 8px
      cursor: pointer
      border-radius: 6px
      transition: .6s
      opacity: 0.6
      .alert
        position: absolute
        z-index: 10
        top: 39px
        right: 3px
        font-size: 21px
        &:hover
          background: none !important

    & :hover, .active
      background: #fafafa
      filter: none
      -webkit-filter: grayscale(0)
      -webkit-filter: grayscale(0%)
      opacity: 1
  .tab-content
      margin-left: 74px
      border-left: 1px solid #e0e0e0
      padding-left: 15px
      .title
        display: block
        border-bottom: 1px solid #efefef
        font-size: 14px
        font-weight: 500
        color: #434244 !important
        padding: 5px 8px
.anticon svg
  background: none !important
.anticon svg:hover
  background: none !important
</style>
