<template>
  <div>
    <a-row :class="`service-row service-${serviceId}`" :gutter="20">
      <a-col :span="6">
        <a-form-item>
          <span class="travel-input">
            <label :class="'filled'">Tipo de serviço</label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              :showSearch="true"
              v-decorator="[
                `service_${serviceId}_type`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%; height: 30px"
            >
              <a-select-option
                v-for="({ name }, i) in servicesList"
                :key="i"
                :value="name"
                :txt="name"
              >
                {{ name }}
              </a-select-option>
            </a-select>
          </span>
        </a-form-item>
      </a-col>

      <a-col :span="11">
        <a-form-item>
          <label :class="'filled'">Descrição/Nome do serviço</label>
          <a-input
            class="travel-input upper"
            placeholder="Escreva um pequeno texto sobre o serviço"
            v-decorator="[
              'service_' + serviceId + '_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Localizador</label>
          <a-input
            class="travel-input"
            placeholder="Insira (opcional)"
            v-decorator="['service_' + serviceId + '_locator']"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 620,
              span: 12,
            },
            {
              min: 621,
              max: 1300,
              span: 12,
            },
            {
              min: 1300,
              max: 9999,
              span: 6,
            },
          ])
        "
      >
        <a-form-item>
          <label :class="'filled'">Check-in</label>

          <a-date-picker
            class="travel-input"
            placeholder="Selecione"
            format="DD/MM/YYYY"
            valueFormat="YYYY-MM-DD"
            :showToday="false"
            :disabled-date="minDate"
            :allowClear="false"
            v-mask="'##/##/####'"
            @change="selectedDate"
            v-decorator="[
              'service_' + serviceId + '_checkin',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-date-picker>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 620,
              span: 12,
            },
            {
              min: 621,
              max: 1300,
              span: 12,
            },
            {
              min: 1300,
              max: 9999,
              span: 6,
            },
          ])
        "
      >
        <a-form-item>
          <label :class="'filled'">Check-out</label>
          <a-date-picker
            class="travel-input"
            placeholder="Selecione"
            format="DD/MM/YYYY"
            valueFormat="YYYY-MM-DD"
            :showToday="false"
            :allowClear="false"
            :disabled-date="disabledEndDate && minDateCheckout"
            :open="checkoutOpen"
            @openChange="checkoutOpenChange"
            v-mask="'##/##/####'"
            v-decorator="[
              'service_' + serviceId + '_checkout',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-date-picker>
        </a-form-item>
      </a-col>

      <a-col :span="1" style="text-align: center">
        <a-button
          v-if="serviceId == totalRows && totalRows > 1"
          size="small"
          @click="removeServiceRow"
          type="danger"
          shape="circle"
          icon="delete"
        >
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { format, sub } from "date-fns";

export default {
  name: "ServiceCollapse",
  props: {
    totalRows: Number,
    serviceId: Number,
    serviceIndex: Number,
    tempContract: Object,
    form: Object,
  },
  data() {
    return {
      checkoutOpen: false,
      servicesList: [],
    };
  },
  beforeMount() {
    setTimeout(() => {
      this.form.setFieldsValue({
        [`service_${this.serviceId}_type`]:
          this.tempContract[`service_${this.serviceId}_type`],
        [`service_${this.serviceId}_name`]:
          this.tempContract[`service_${this.serviceId}_name`],
        [`service_${this.serviceId}_checkin`]:
          this.tempContract[`service_${this.serviceId}_checkin`],
        [`service_${this.serviceId}_checkout`]:
          this.tempContract[`service_${this.serviceId}_checkout`],
        [`service_${this.serviceId}_locator`]:
          this.tempContract[`service_${this.serviceId}_locator`],
        [`service_${this.serviceId}_total_price`]:
          this.tempContract[`service_${this.serviceId}_total_price`],
      });
    }, 20);

    this.$http
      .get(`/category-entry/list?page=1&category_id=11&per_page=300`)
      .then(({ data }) => {
        this.servicesList = data.data;
      });
  },
  methods: {
    removeServiceRow() {
      this.$emit("removeTraveller", this.serviceId);
    },
    emitValueUpdate() {
      this.$emit("emitValueUpdate");
    },
    minDateCheckout(current) {
      return (
        sub(current, {
          years: 0,
          months: 0,
          weeks: 0,
          days: 1,
          hours: 0,
          minutes: 0,
          seconds: 0,
        }) &&
        current <
          sub(
            new Date(this.tempContract[`service_${this.serviceId}_checkin`]),
            {
              years: 0,
              months: 0,
              weeks: 0,
              days: 1,
              hours: 0,
              minutes: 0,
              seconds: 0,
            }
          )
      );
    },
    minDate(current) {
      return (
        sub(current, {
          years: 0,
          months: 0,
          weeks: 0,
          days: 1,
          hours: 0,
          minutes: 0,
          seconds: 0,
        }) &&
        current <
          sub(new Date(), {
            years: 0,
            months: 0,
            weeks: 0,
            days: 1,
            hours: 0,
            minutes: 0,
            seconds: 0,
          })
      );
    },
    selectedDate(current) {
      setTimeout(() => {
        this.form.setFieldsValue({
          [`service_${this.serviceId}_checkin`]: current._d,
          [`service_${this.serviceId}_checkout`]: format(
            current._d,
            "MM/dd/yyyy"
          ),
        });
        this.checkoutOpen = true;
      }, 100);
    },
    checkoutOpenChange(open) {
      let checkout = this.form.getFieldValue(
        `service_${this.serviceId}_checkout`
      );
      this.disabledEndDate(checkout);
      this.checkoutOpen = open;
    },
    disabledEndDate(endValue) {
      let startValue = this.form.getFieldValue(
        `service_${this.serviceId}_checkin`
      );

      if (endValue != undefined) {
        if (endValue["_d"] == undefined) {
          if (!endValue || !startValue) {
            return false;
          }

          return startValue.valueOf() >= endValue.valueOf();
        } else {
          if (!endValue || !startValue) {
            return false;
          }

          return startValue.valueOf() >= endValue.valueOf();
        }
      }
    },
  },
};
</script>
