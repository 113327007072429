<template>
  <div>
    <aRow :gutter="20" v-if="documents.documentsSelector.length > 0">
      <aCol :span="24">
        <a-alert
          class="f12 mb-20"
          type="info"
          message="Selecione os documentos para gerar o contrato abaixo:"
          banner
        />
        <a-form-item class="mt-0 f12">
          <a-transfer
            class="doc-selector"
            :dataSource="formatDataToAntdTransfer(documents.documentsSelector)"
            :titles="['Documentos p/ selecionar', 'Documentos p/ gerar']"
            :render="(item) => item.name"
            :filter-option="
              (inputValue, item) => item.filterName.indexOf(inputValue) !== -1
            "
            :target-keys="selectedDocs"
            :list-style="{
              width: '355px',
              height: '250px',
            }"
            show-search
            @change="onSelectDocs"
          >
            <template slot="children"> </template>
          </a-transfer>
        </a-form-item>

        <a-divider dashed />
      </aCol>

      <aCol :span="12">
        <div>
          <div class="f12 cblack">Qual nota você dá para o <b>CLIENTE</b>?</div>
          <a-rate
            class="mt-5"
            v-model="tempContract.rate_customer"
            @change="onRateCustomer"
          />
        </div>
      </aCol>

      <aCol :span="12">
        <div>
          <div class="f12 cblack">
            Qual nota você dá para esta <b>VENDA</b>?

            <a-popover title="Regras para avaliação">
              <template slot="content">
                <div class="f12 cblack">
                  Classifique esta venda baseada nos seguintes critérios:<br />
                  - O processo de venda foi suave e eficiente, garantindo uma
                  transação sem complicações.<br />
                  - A lucratividade foi boa, demonstrando que o negócio foi
                  benéfico para o vendedor.<br />
                  - A comunicação com o cliente foi eficiente, permitindo uma
                  troca clara e eficaz de informações.<br />
                  - O tempo de maturação da venda foi curto, indicando que o
                  ciclo de vendas foi concluído rapidamente
                </div>
              </template>
              <a-icon style="color: #2196f3" type="info-circle" />
            </a-popover>
          </div>
          <a-rate
            class="mt-5"
            v-model="tempContract.rate"
            @change="onRateContract"
          />
        </div>
      </aCol>

      <aCol v-if="tempContract.package_type === 'Serviços'" :span="12">
        <a-divider dashed />
        <div class="f12 mb-10 cblack">
          Informar o valor total dos produtos ao invés de por serviço no
          <b>E-MAIL para o cliente</b> (opcional)?
        </div>
        <a-checkbox
          v-model="showTotalValueInsteadPerProduct"
          @change="onChangeShowTotalValueInEmail"
          class="f12 relative fw600"
        >
          Sim, desejo exibir o valor total
        </a-checkbox>
      </aCol>

      <aCol
        v-if="['0', 0, null].includes(tempContract.opportunity_id)"
        :span="12"
      >
        <a-divider dashed />
        <div class="f12 mb-10 cblack">
          Este contrato não tem uma <b>oportunidade</b>, deseja criar uma
          oportunidade ao gerar o contrato?
        </div>
        <a-checkbox
          v-model="generateOpportunity"
          @change="onAllowGenerateOpportunity"
          class="f12 relative fw600"
        >
          Sim, desejo criar a oportunidade
        </a-checkbox>
      </aCol>

      <aCol
        v-show="
          ['0', 0, null].includes(tempContract.opportunity_id) &&
          generateOpportunity
        "
        :span="12"
      >
        <a-divider dashed />
        <HayaSelectField
          label="Etapa da oportunidade"
          :initialValue="tempContract.opportunity_step"
          :list="mapOpportunitiesFunnelStepsList(opportunitiesFunnelSteps.list)"
          fieldKey="opportunity_step"
        />
      </aCol>

      <aCol
        v-show="
          ['0', 0, null].includes(tempContract.opportunity_id) &&
          generateOpportunity
        "
        :span="12"
      >
        <a-divider dashed />
        <HayaRadioField
          label="Status da oportunidade"
          fieldKey="opportunity_status"
          :initialValue="tempContract.opportunity_status"
          :options="[
            { label: 'Aberto', value: 'Aberto' },
            { label: 'Ganho', value: 'Ganho' },
          ]"
        />
      </aCol>

      <aCol
        v-if="tempContract.status !== 'concluded'"
        class="a-center"
        :span="24"
      >
        <a-divider />

        <a-button
          class="button-green"
          id="generate-contract"
          size="large"
          type="primary"
          :disabled="disableGenerateContract()"
          :loading="generateContractLoading"
          @click="$emit('onClickGenerateContract')"
        >
          GERAR CONTRATO
        </a-button>
      </aCol>
    </aRow>
    <aRow v-else>
      <aCol :span="24">
        <a-skeleton active />
        <a-skeleton active />
        <a-skeleton active />
      </aCol>
    </aRow>
  </div>
</template>

<script>
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaRadioField from "@/components/general/fields/HayaRadioField.vue";
import opportunitiesFunnelStepsMixins from "@/components/pipeline/mixins/opportunitiesFunnelStepsMixins";

export default {
  name: "GenerateContractModal",
  props: {
    tempContract: Object,
    form: Object,
    generateContractLoading: Boolean,
  },
  mixins: [opportunitiesFunnelStepsMixins],
  data() {
    return {
      generateOpportunity: false,
      showTotalValueInsteadPerProduct: false,
      selectedDocs: [],
      documents: {
        documentsSelector: [],
      },
    };
  },
  mounted() {
    this.getDocuments();

    this.opportunitiesFunnelSteps.filters.funnelId = 1;
    this.opportunitiesFunnelSteps.filters.status.selected = ["Ativado"];
    this.getOpportunitiesFunnelSteps();

    this.selectedDocs =
      this.tempContract["contract_documents"] != undefined
        ? JSON.parse(this.tempContract["contract_documents"])
        : [];
    this.generateOpportunity = ["1", true].includes(
      this.tempContract["create_opportunity"]
    )
      ? true
      : false;
    this.showTotalValueInsteadPerProduct =
      this.tempContract["show_total_value_in_email_instead_per_product"] == 1 ||
      this.tempContract["show_total_value_in_email_instead_per_product"] == true
        ? true
        : false;

    this.form.setFieldsValue({
      opportunity_status: this.tempContract.opportunity_status,
      opportunity_step: this.tempContract.opportunity_step,
    });
  },
  methods: {
    disableGenerateContract() {
      let flag = true;

      if (this.generateOpportunity) {
        if (
          this.tempContract.rate &&
          this.tempContract.rate_customer &&
          this.tempContract.opportunity_status != undefined &&
          this.tempContract.opportunity_step != undefined &&
          this.selectedDocs.length > 0
        )
          flag = false;
      } else {
        if (
          this.tempContract.rate &&
          this.tempContract.rate_customer &&
          this.selectedDocs.length > 0
        )
          flag = false;
      }

      return flag;
    },
    formatDataToAntdTransfer(array) {
      const formattedArr = [];
      if (array.length > 0)
        array.forEach((item) => {
          console.log(item);
          formattedArr.push({
            key: item.id,
            name: `${item.id} - ${item.name}`,
            filterName: item.name ? item.name.toLowerCase() : item.name,
            description: "",
            disabled: 0,
          });
        });
      return formattedArr;
    },
    onSelectDocs(nextselectedDocs) {
      this.selectedDocs = nextselectedDocs;
      this.tempContract["contract_documents"] =
        JSON.stringify(nextselectedDocs);
    },
    onAllowGenerateOpportunity(opportunity) {
      this.tempContract["create_opportunity"] = opportunity.target.checked;
    },
    onChangeShowTotalValueInEmail(e) {
      this.tempContract["show_total_value_in_email_instead_per_product"] =
        e.target.checked;
    },
    onRateCustomer(val) {
      let valuesToUpdate = {};
      valuesToUpdate.id = this.tempContract.id;
      valuesToUpdate.rate_customer = val;
      valuesToUpdate.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };
      this.$http
        .post("/contract/update-multi-meta", valuesToUpdate)
        .then(() => {
          this.tempContract.rate_customer = val;
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempContract.id,
            module: "contract",
            action: "rate-customer",
            description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> avaliou o cliente <font class="id">ID ${this.tempContract.the_customer.id}</font> com ${val} estrelas.`,
          });
          this.$http
            .post("/customer/update-multi-meta", {
              id: this.tempContract.the_customer.id,
              rate: val,
              modified_by: {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
              },
            })
            .then(() => {
              this.$http.post("/log/create", {
                user_id: this.$store.state.userData.id,
                module_id: this.tempContract.the_customer.id,
                module: "customer",
                action: "rate-customer",
                description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> avaliou o cliente <font class="id">ID ${this.tempContract.the_customer.id}</font> com ${val} estrelas.`,
              });
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    onRateContract(val) {
      let valuesToUpdate = {};
      valuesToUpdate.id = this.tempContract.id;
      valuesToUpdate.rate = val;
      valuesToUpdate.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };
      this.$http
        .post("/contract/update-multi-meta", valuesToUpdate)
        .then(() => {
          this.tempContract.rate = val;
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempContract.id,
            module: "contract",
            action: "rate-contract",
            description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> avaliou o contrato <font class="id">ID ${this.tempContract.id}</font> com ${val} estrelas.`,
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    getDocuments() {
      this.$http
        .get(
          `/document/list?page=1&per_page=100&company_branch_id=${this.tempContract["company_branch_id"]}&status=1&is_general=1&html=no`
        )
        .then(({ data }) => {
          this.documents.documentsSelector = data.data;
          this.documents.documentsSelector.push({
            id: "10",
            title: `Anexo II - Terceiros `,
            name: `Anexo II - Terceiros `,
            company: "all",
            is_general: 1,
          });
          this.documents.documentsSelector.push({
            id: "9",
            title: `(SISTEMA) Autorização de Débito`,
            name: `(SISTEMA) Autorização de Débito`,
            company: "all",
            is_general: 1,
          });
        });
    },
  },
  components: { HayaSelectField, HayaRadioField },
};
</script>

<style lang="sass">
.doc-selector
  .ant-checkbox-inner
    z-index: 3
  .ant-transfer-list-search-action
    pointer-events: none
    top: 8px
  .ant-transfer-list-body-with-search
    padding-top: 43px
  .ant-transfer-list-body-search-wrapper
    top: -1px
    padding: 6px
    background: #eee
    .ant-transfer-list-search
      border-radius: 3px
      font-size: 12px

  .ant-transfer-list-content > div
    height: auto
  .ant-transfer-list-content-item
    min-height: 9px
    padding: 3px 8px
    font-size: 11px
    font-weight: 500
</style>

<style lang="sass" scoped>
.doc-selector
  font-size: 12px
.button-green
  background: #2ecc71
  border-color: #2ecc71
  font-weight: 600
.button-green[disabled]
  pointer-events: none
  background: #f6f6f6
  opacity: 0.7
  border-color: #ddd !important
</style>
