<template>
  <div>
    <a-row class="meta-wrapper" type="flex" :gutter="15">
      <a-col class="sidebar" flex="360px">
        <a-row>
          <a-col
            class="w-box mb-10"
            :span="24"
            style="padding: 11px 15px !important"
          >
            <h1>
              <a-row type="flex" justify="space-between">
                <a-col v-if="$route.name != 'new'">
                  <img src="@/assets/images/agreement.png" alt="img" />
                  CONTRATO
                  {{ contract.id != undefined ? " #" + contract.id : "" }}
                </a-col>

                <a-col v-if="$route.name == 'new'">
                  <img src="@/assets/images/agreement.png" alt="img" />
                  NOVO CONTRATO
                </a-col>
                <a-col v-if="tempContract.status == 'concluded'">
                  <a
                    v-if="contract.id != undefined"
                    class="view"
                    @click="$router.push(`/contracts/view/${contract.id}`)"
                  >
                    <a-icon type="eye" />
                  </a>
                </a-col>
              </a-row>
            </h1>

            <a-row
              v-if="contract.created"
              class="meta"
              align="middle"
              type="flex"
              justify="start"
            >
              <a-col>
                <a
                  class="user dotted-phrase"
                  @click="$router.push(`/users/view/${contract.user.id}`)"
                  v-if="contract.user != undefined"
                  target="_blank"
                  style=""
                >
                  <a-tooltip placement="right">
                    <template slot="title">
                      {{ contract.user.first_name }}
                      {{ contract.user.last_name }}
                    </template>
                    <a-avatar
                      v-if="contract.user.avatar"
                      :src="contract.user.avatar"
                      :size="20"
                    />
                    <a-avatar
                      v-else
                      :size="20"
                      class="upper"
                      style="color: #f56a00; background-color: #fde3cf"
                    >
                      {{ contract.user.first_name.substring(0, 1)
                      }}{{ contract.user.last_name.substring(0, 1) }}
                    </a-avatar>
                  </a-tooltip>
                </a>
              </a-col>
              <a-col>
                <a-divider type="vertical" />
                <a-icon class="cprimary" type="calendar" />
                {{
                  contract.created != undefined
                    ? formatDateTime(contract.created)
                    : ""
                }}
              </a-col>

              <a-col>
                <a-divider type="vertical" />
                <a-icon class="cprimary" type="tag" />
                {{ formatContractStatus(contract.status) }}</a-col
              >

              <a-col v-if="contract.id_infotera != 0">
                <a-divider type="vertical" />
                Infotera:
                {{ contract.id_infotera }}</a-col
              >
            </a-row>
            <div v-else class="meta">
              <a
                class="user"
                :href="`/users/view/${contract.user}`"
                v-if="contract.user != undefined"
                target="_blank"
              >
                <a-icon class="cprimary" type="user" />
                {{ this.$store.state.userData.first_name }}
                {{ this.$store.state.userData.last_name }}
              </a>
            </div>
          </a-col>
        </a-row>
      </a-col>

      <a-col class="data-panels" flex="auto">
        <a-row :gutter="15">
          <a-col class="mb-10" :span="6">
            <div class="w-box">
              <h3>A RECEBER</h3>
              <div class="value">
                <font class="ml-20 relative" style="top: 2px">
                  {{ formatPricePtBr(allContractData.total) }}
                </font>

                <a-tooltip
                  v-if="
                    Math.round(allContractData.paymentMethods.total) !=
                    Math.round(allContractData.total)
                  "
                >
                  <template slot="title">
                    Verifique nas formas de recebimento se os valores estão
                    corretos.
                  </template>
                  <img
                    class="c-pointer"
                    src="@/assets/images/dashboard/contracts/danger.png"
                    alt="img"
                    width="15"
                  />
                </a-tooltip>

                <img
                  v-if="
                    allContractData.total != 0 &&
                    allContractData.paymentMethods.total ==
                      allContractData.total
                  "
                  src="@/assets/images/dashboard/contracts/check.png"
                  alt="img"
                  width="15"
                />

                <ContractValuesInfoList
                  class="ml-5"
                  :arr="allContractData.toReceiveList"
                />
              </div>
            </div>
          </a-col>

          <a-col class="mb-10" :span="6">
            <div class="w-box">
              <h3>A PAGAR</h3>
              <div class="value">
                {{ formatPricePtBr(allContractData.totalNet) }}

                <a-tooltip v-if="allContractData.totalNet == 0">
                  <template slot="title">
                    Preencha todos os dados financeiros.
                  </template>
                  <img
                    class="c-pointer"
                    src="@/assets/images/dashboard/contracts/danger.png"
                    alt="img"
                    width="15"
                  />
                </a-tooltip>

                <img
                  v-if="allContractData.totalNet > 0"
                  src="@/assets/images/dashboard/contracts/check.png"
                  alt="img"
                  width="15"
                />

                <ContractValuesInfoList
                  class="ml-5"
                  :arr="allContractData.netList"
                />
              </div>
            </div>
          </a-col>

          <a-col class="mb-10" :span="6">
            <div class="w-box">
              <h3>TAXAS</h3>
              <div class="value">
                {{ formatPricePtBr(allContractData.totalTaxes) }}

                <ContractValuesInfoList
                  class="ml-5"
                  :arr="allContractData.extraFeesList"
                />
              </div>
            </div>
          </a-col>

          <a-col class="mb-20" :span="6">
            <div class="w-box">
              <h3>
                <a-row type="flex" justify="space-between">
                  <a-col>LUCRO </a-col>
                  <a-col class="cgreen">
                    {{
                      formatPercentage(allContractData.totalProfitPercentage)
                    }}
                    %
                  </a-col>
                </a-row>
              </h3>
              <div class="value">
                {{ formatPricePtBr(allContractData.totalProfit) }}
                <ContractValuesInfoList
                  class="ml-5"
                  :arr="allContractData.profitList"
                />
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import ContractValuesInfoList from "@/components/contracts/lists/ContractValuesInfoList.vue";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "ContractInfosSection",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    allContractData: Object,
  },
  mixins: [formatThings],
  components: {
    ContractValuesInfoList,
  },
};
</script>

<style lang="sass" scoped>
.meta-wrapper
  .data-panels
    .w-box
      padding: 20px 15px !important
    h3
      color: #bcbec0
      font-size: 16px
      font-weight: 600
      margin-bottom: 0
    .value
      color: #59595b
      text-align: right
      font-size: 22px
      letter-spacing: -1px
      font-weight: 700
.meta
  font-size: 10px
  line-height: 8px
  font-weight: 600
  text-transform: none
  padding-top: 10px
  border-top: 1px solid #eee
  margin-top: 4px
  .user
      color: #444
      max-width: 100px
      display: inline-block
h1
    margin: 0
    padding: 0
    font-weight: 600
    letter-spacing: -2px
    line-height: 1.4
    .view
        color: #aaa
        font-size: 24px
    img
        margin-right: 5px
h2
    font-size: 14px
    font-weight: 400
    border-bottom: 1px solid #ddd
    padding: 0 0 10px 0 !important
    color: #59595b !important
</style>
