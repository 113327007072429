var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',{attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('label',{staticClass:"filled"},[_vm._v("Valor total da Transferência")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_bank_transfer_total_value`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredFieldTransfer(),
                  message: 'Preencha todos números.',
                },
                {
                  validator: _vm.verifyValue,
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_bank_transfer_total_value`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredFieldTransfer(),\n                  message: 'Preencha todos números.',\n                },\n                {\n                  validator: verifyValue,\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor da Transferência"},on:{"keyup":_vm.verifyTotal}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Data de recebimento transferência")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_bank_transfer_receipt_date`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredFieldTransfer(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_bank_transfer_receipt_date`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredFieldTransfer(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Data de recebimento","format":"DD/MM/YYYY","showToday":false,"allowClear":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1),_vm._v(" "),_c('a-row',{attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"show-label mt-20"},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_bank_transfer_bank`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredFieldTransfer(),
                  message: 'Selecione um banco.',
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_bank_transfer_bank`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredFieldTransfer(),\n                  message: 'Selecione um banco.',\n                },\n              ],\n            },\n          ]"}],attrs:{"name":"radioGroup"}},_vm._l((_vm.banksList),function(item,index){return _c('a-radio',{key:index,attrs:{"value":item.account}},[_c('strong',{staticStyle:{"font-weight":"600"}},[_vm._v("\n              "+_vm._s(item.company_name)+"\n            ")]),_vm._v(" "),_c('a-divider',{attrs:{"type":"vertical"}}),_vm._v(" "),_c('strong',{staticStyle:{"font-weight":"600"}},[_vm._v("CNPJ/PIX:")]),_vm._v("\n            "+_vm._s(item.cnpj)+"\n            "),_c('br'),_vm._v(" "),_c('div',{staticStyle:{"padding":"8px 0px 10px 28px"}},[_vm._v("\n              Agência:\n              "),_c('strong',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(item.agency))]),_vm._v(" "),_c('a-divider',{attrs:{"type":"vertical"}}),_vm._v("\n              Conta:\n              "),_c('strong',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(item.account))]),_vm._v(" "),_c('a-divider',{attrs:{"type":"vertical"}}),_vm._v("\n              "+_vm._s(item.number)+"\n              -\n              "+_vm._s(item.name)+"\n            ")],1)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }