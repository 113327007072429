var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.hotelRows),function(hotel,index){return _c('div',{key:index,staticClass:"supplier-accordeon"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"name",on:{"click":function($event){return _vm.activeHotelAccordeon(index, hotel)}}},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_vm._v("\n            "+_vm._s(_vm.tempContract[`hotel_${hotel.id}_name`]
                ? _vm.tempContract[`hotel_${hotel.id}_name`]
                : "HOTEL")+"\n          ")]),_vm._v(" "),_c('a-col',[_c('a-button',{attrs:{"type":"link"}},[_c('i',{staticClass:"fas fa-caret-down"})])],1)],1)],1),_vm._v(" "),(index == _vm.activeAccordeon)?_c('div',{staticClass:"content"},[(_vm.$root.isAdmin())?_c('a-row',{staticClass:"infos",attrs:{"gutter":10}},[_c('a-col',{attrs:{"span":24}},[_c('div',{staticClass:"line"},[_c('span',{staticClass:"label"},[_vm._v(" Produto: ")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v("\n                "+_vm._s(_vm.tempContract[`hotel_${hotel.id}_name`])+"\n              ")])])]),_vm._v(" "),_c('a-col',{staticClass:"line",attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v(" Valor Venda: ")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v("\n              "+_vm._s(_vm.formatPricePtBr(_vm.tempContract[`hotel_${hotel.id}_total_price`]))+"\n            ")])]),_vm._v(" "),_c('a-col',{staticClass:"line",attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v(" Valor Neto: ")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v("\n              "+_vm._s(_vm.tempContract[
                  `contract_finances_hotel_${hotel.id}_net_value`
                ] != undefined
                  ? _vm.formatPricePtBr(
                      _vm.tempContract[
                        `contract_finances_hotel_${hotel.id}_net_value`
                      ]
                    )
                  : "---")+"\n            ")])]),_vm._v(" "),(
              _vm.tempContract[
                `contract_finances_hotel_${hotel.id}_profit_value`
              ] != undefined
            )?_c('a-col',{staticClass:"line",attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v(" Lucratividade: ")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v("\n              "+_vm._s(_vm.tempContract[
                  `contract_finances_hotel_${hotel.id}_profit_value`
                ] != undefined
                  ? _vm.formatPricePtBr(
                      _vm.tempContract[
                        `contract_finances_hotel_${hotel.id}_profit_value`
                      ]
                    )
                  : "---")+"\n            ")])]):_vm._e(),_vm._v(" "),_c('a-col',{staticClass:"line",attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v(" Localizador: ")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v("\n              "+_vm._s(_vm.tempContract[`hotel_${hotel.id}_locator`])+"\n            ")])]),_vm._v(" "),(
              _vm.tempContract[
                `contract_finances_hotel_${hotel.id}_reservation_date`
              ] != undefined
            )?_c('a-col',{staticClass:"line",attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v(" DT Reserva: ")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v("\n              "+_vm._s(_vm.tempContract[
                  `contract_finances_hotel_${hotel.id}_reservation_date`
                ])+"\n            ")])]):_vm._e(),_vm._v(" "),(
              _vm.tempContract[
                `contract_finances_hotel_${hotel.id}_reservation_expiration_date`
              ] != undefined
            )?_c('a-col',{staticClass:"line",attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v(" DT Expiração: ")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v("\n              "+_vm._s(_vm.tempContract[
                  `contract_finances_hotel_${hotel.id}_reservation_expiration_date`
                ])+"\n            ")])]):_vm._e(),_vm._v(" "),(
              _vm.tempContract[
                `contract_finances_hotel_${hotel.id}_value_currency`
              ] != undefined
            )?_c('a-col',{staticClass:"line",attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v(" Moeda: ")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v("\n              "+_vm._s(_vm.tempContract[
                  `contract_finances_hotel_${hotel.id}_value_currency`
                ])+"\n            ")])]):_vm._e(),_vm._v(" "),(
              _vm.tempContract[
                `contract_finances_hotel_${hotel.id}_expiration_date`
              ] != undefined
            )?_c('a-col',{staticClass:"line",attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v(" Vencimento: ")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v("\n              "+_vm._s(_vm.tempContract[
                  `contract_finances_hotel_${hotel.id}_expiration_date`
                ])+"\n            ")])]):_vm._e(),_vm._v(" "),(
              _vm.tempContract[
                `contract_finances_hotel_${hotel.id}_payment_status`
              ] != undefined
            )?_c('a-col',{staticClass:"line",attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v("Pagamento: ")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v("\n              "+_vm._s(_vm.tempContract[
                  `contract_finances_hotel_${hotel.id}_payment_status`
                ])+"\n            ")])]):_vm._e(),_vm._v(" "),_c('a-col',{staticClass:"line",attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v("Fornecedor: ")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v("\n              "+_vm._s(_vm.tempContract[
                  `contract_finances_hotel_${hotel.id}_supplier`
                ] != undefined
                  ? _vm.tempContract[
                      `contract_finances_hotel_${hotel.id}_supplier`
                    ]
                  : "---")+"\n            ")])])],1):_vm._e(),_vm._v(" "),_c('a-row',{staticClass:"fields",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Valor total (R$) ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `hotel_${hotel.id}_total_price`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `hotel_${hotel.id}_total_price`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira"},on:{"blur":function($event){return _vm.calcProfit(hotel.id)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer travel-multiselector"},[_c('label',{class:'filled'},[_vm._v("\n                Fornecedor\n\n                "),(
                    _vm.tempContract[
                      `contract_finances_hotel_${hotel.id}_supplier_infotera`
                    ] &&
                    !_vm.tempContract[
                      `contract_finances_hotel_${hotel.id}_supplier_id`
                    ]
                  )?_c('font',{staticClass:"cblack"},[_vm._v("\n                  "+_vm._s(_vm.tempContract[
                      `contract_finances_hotel_${hotel.id}_supplier_infotera`
                    ])+"\n                ")]):_vm._e()],1),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `contract_finances_hotel_${hotel.id}_supplier_id`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `contract_finances_hotel_${hotel.id}_supplier_id`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",staticStyle:{"width":"100%","height":"30px"},attrs:{"show-search":"","allow-clear":true,"optionFilterProp":"txt","placeholder":"Selecione"},on:{"change":function($event){return _vm.onSelectSupplier(hotel.id)}}},_vm._l((_vm.productSupplier.list),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n                  "+_vm._s(item.id)+" -\n                  "+_vm._s(item.trading_name.toUpperCase())+"\n                ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Valor Neto")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `contract_finances_hotel_${hotel.id}_net_value`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `contract_finances_hotel_${hotel.id}_net_value`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira"},on:{"blur":function($event){return _vm.calcProfit(hotel.id)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),(
              _vm.form.getFieldValue(`hotel_${hotel.id}_total_price`) &&
              _vm.form.getFieldValue(
                `contract_finances_hotel_${hotel.id}_net_value`
              )
            )?_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Desconto")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `contract_finances_hotel_${hotel.id}_discount_value`,
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `contract_finances_hotel_${hotel.id}_discount_value`,\n                  {\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira"},on:{"~blur":function($event){$event.preventDefault();return _vm.discountValue(hotel.id)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1):_vm._e(),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Dia do pagamento")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                  `contract_finances_hotel_${hotel.id}_payday`,
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `contract_finances_hotel_${hotel.id}_payday`,\n                  {\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","showToday":false,"allowClear":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('h4',{staticClass:"subtitile"},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_vm._v("Taxas Extras")]),_vm._v(" "),_c('a-col',[_c('a-button',{staticClass:"add-button color",attrs:{"size":"small"},on:{"click":function($event){return _vm.addTaxRow(hotel.id)}}},[_vm._v("\n                    Adicionar\n                  ")])],1)],1)],1),_vm._v(" "),_vm._l((_vm.convertStrArr(
                _vm.tempContract[`contract_finances_hotel_${hotel.id}_taxes_rows`]
              )),function(tax,index){return _c('a-row',{key:index,attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Taxas (R$)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                      `contract_finances_hotel_${hotel.id}_tax_${tax.id}_value`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]),expression:"[\n                      `contract_finances_hotel_${hotel.id}_tax_${tax.id}_value`,\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Obrigatório',\n                          },\n                        ],\n                      },\n                    ]"}],staticClass:"travel-input",attrs:{"placeholder":"Taxas (R$)"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer travel-multiselector"},[_c('label',{class:'filled'},[_vm._v(" Tipo ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      `contract_finances_hotel_${hotel.id}_tax_${tax.id}_type`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]),expression:"[\n                      `contract_finances_hotel_${hotel.id}_tax_${tax.id}_type`,\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Obrigatório',\n                          },\n                        ],\n                      },\n                    ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"show-search":"","allow-clear":true,"optionFilterProp":"txt","placeholder":"Selecione um tipo"}},_vm._l((_vm.taxesType),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.name}},[_vm._v("\n                      "+_vm._s(item.name)+"\n                    ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":7}},[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      `contract_finances_hotel_${hotel.id}_tax_${tax.id}_add_in_total`,
                    ]),expression:"[\n                      `contract_finances_hotel_${hotel.id}_tax_${tax.id}_add_in_total`,\n                    ]"}],attrs:{"checked":_vm.tempContract[
                        `contract_finances_hotel_${hotel.id}_tax_${tax.id}_add_in_total`
                      ] == 1
                        ? true
                        : false}},[_vm._v("Somar no valor total")])],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":1}},[(
                    tax.id ==
                    _vm.convertStrArr(
                      _vm.tempContract[
                        `contract_finances_hotel_${hotel.id}_taxes_rows`
                      ]
                    ).length
                  )?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeTaxRow(hotel.id, tax.id)}}}):_vm._e()],1)],1)})],2),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('h4',{staticClass:"subtitile"},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_vm._v("Incentivos")]),_vm._v(" "),_c('a-col',[_c('a-button',{staticClass:"add-button color",attrs:{"size":"small"},on:{"click":function($event){return _vm.addIncentiveRow(hotel.id)}}},[_vm._v("\n                    Adicionar\n                  ")])],1)],1)],1),_vm._v(" "),_vm._l((_vm.convertStrArr(
                _vm.tempContract[
                  `contract_finances_hotel_${hotel.id}_incentives_rows`
                ]
              )),function(incentive,index){return _c('a-row',{key:index,attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer travel-multiselector"},[_c('label',{class:'filled'},[_vm._v(" Tipo ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_type`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]),expression:"[\n                      `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_type`,\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Obrigatório',\n                          },\n                        ],\n                      },\n                    ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"show-search":"","allow-clear":true,"optionFilterProp":"txt","placeholder":"% ou R$"}},[_c('a-select-option',{attrs:{"value":"%"}},[_vm._v(" % ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"R$"}},[_vm._v(" R$ ")])],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Valor incentivo")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                          currency: {
                            prefix: '',
                            suffix: '',
                          },
                          distractionFree: true,
                          valueAsInteger: false,
                          precision: 2,
                          autoDecimalMode: true,
                          allowNegative: false,
                        }),expression:"{\n                          currency: {\n                            prefix: '',\n                            suffix: '',\n                          },\n                          distractionFree: true,\n                          valueAsInteger: false,\n                          precision: 2,\n                          autoDecimalMode: true,\n                          allowNegative: false,\n                        }"},{name:"decorator",rawName:"v-decorator",value:([
                          `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_value`,
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]),expression:"[\n                          `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_value`,\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: 'Obrigatório',\n                              },\n                            ],\n                          },\n                        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor incentivo","disabled":_vm.form.getFieldValue(
                            `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_type`
                          )
                            ? false
                            : true},on:{"blur":function($event){return _vm.calcIncetivesPercentage(hotel.id, incentive.id)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":7}},[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_add_in_total`,
                    ]),expression:"[\n                      `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_add_in_total`,\n                    ]"}],attrs:{"checked":_vm.tempContract[
                        `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_add_in_total`
                      ] == 1
                        ? true
                        : false}},[_vm._v("Somar no valor total")])],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":3}},[(
                    incentive.id ==
                    _vm.convertStrArr(
                      _vm.tempContract[
                        `contract_finances_hotel_${hotel.id}_incentives_rows`
                      ]
                    ).length
                  )?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeIncentiveRow(hotel.id, incentive.id)}}}):_vm._e()],1)],1)})],2)],1)],1):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }