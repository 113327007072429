<template>
  <div>
    <a-row>
      <a-col v-for="(service, index) in serviceRows" :key="index" :span="24">
        <div class="product-card" @click="onClickOpenCollapse(service.id)">
          <a-row :gutter="20">
            <a-col class="id cprimary" :span="1">
              <span class="ico">
                <i class="fa fa-briefcase"></i>
                <font class="num">{{ service.id }}</font>
              </span>
            </a-col>

            <a-col class="id-name upper" :span="21">
              {{
                tempContract[`service_${service.id}_type`]
                  ? tempContract[`service_${service.id}_type`]
                  : "SERVIÇO"
              }}
            </a-col>

            <a-col class="a-right" :span="2">
              <a-button
                v-if="serviceRows.length == service.id && service.id != 1"
                size="small"
                type="danger"
                shape="circle"
                icon="delete"
                @click="removeServiceRow(service.id)"
                ghost
              >
              </a-button>
            </a-col>
          </a-row>
        </div>

        <div class="product-card-content" v-if="openCollapse == service.id">
          <ServiceCollapse
            :totalRows="serviceRows.length"
            :serviceId="service.id"
            :serviceIndex="index"
            :tempContract="tempContract"
            :form="form"
            @removeServiceRow="removeServiceRow"
          />

          <div :class="`travellers`">
            <div
              class="product-card internal"
              @click="onClickOpenInternalCollapse(`t-${service.id}`)"
            >
              <a-row>
                <a-col :span="20">
                  <a-row type="flex" justify="start">
                    <a-col class="id cprimary">
                      <span class="ico">
                        <i class="fa fa-users"></i>
                      </span>
                    </a-col>

                    <a-col class="id-name">
                      VIAJANTES
                      <font class="count f8"
                        >({{ service.travellers.length }})</font
                      >
                    </a-col>
                  </a-row></a-col
                >
                <a-col
                  v-if="openInternalCollapse == `t-${service.id}`"
                  class="a-right"
                  :span="4"
                >
                  <a-tooltip
                    placement="topRight"
                    title="Adicionar todos viajantes"
                  >
                    <a-button
                      size="small"
                      type="primary"
                      shape="circle"
                      @click="addAllTravellers(service.id, 'service')"
                    >
                      <a-icon type="usergroup-add" style="font-size: 17px" />
                    </a-button>
                  </a-tooltip>
                </a-col>
              </a-row>
            </div>

            <div
              class="product-card-content internal"
              v-if="openInternalCollapse == `t-${service.id}`"
            >
              <serviceTravellersCollapse
                :serviceId="service.id"
                :travellersRows="service.travellers"
                :travellers="travellers"
                :tempContract="tempContract"
                :form="form"
                @removeTraveller="removeTraveller"
              />

              <div class="a-center mt-20">
                <a-button
                  class="f12"
                  size="small"
                  @click="addTraveller(service.id, service.travellers.length)"
                >
                  Adicionar viajante
                </a-button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="serviceRows.length == service.id" class="a-center">
          <a-button class="cprimary mt-10" @click="addServiceRow(service.id)">
            Adicionar serviço
          </a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import ServiceCollapse from "../collapses/ServiceCollapse.vue";
import ServiceTravellersCollapse from "../collapses/ServiceTravellersCollapse.vue";

export default {
  name: "ServiceTab",
  props: {
    tempContract: Object,
    form: Object,
    travellers: Array,
  },
  components: {
    ServiceCollapse,
    ServiceTravellersCollapse,
  },
  data() {
    return {
      serviceRows: [],
      openCollapse: -1,
      openInternalCollapse: -1,
    };
  },
  beforeMount() {
    if (this.tempContract.service_rows == undefined) {
      this.tempContract.service_rows = JSON.stringify([
        {
          id: 1,
          travellers: [{ id: 1 }],
        },
      ]);
    }

    this.serviceRows =
      this.tempContract.service_rows != undefined
        ? JSON.parse(this.tempContract.service_rows)
        : [];
  },
  methods: {
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = -1;
      } else {
        this.openCollapse = id;
      }

      this.openInternalCollapse = -1;
    },
    onClickOpenInternalCollapse(id) {
      if (id == this.openInternalCollapse) {
        this.openInternalCollapse = -1;
      } else {
        this.openInternalCollapse = id;
      }
    },
    addServiceRow(id) {
      this.serviceRows.push({
        id: id + 1,
        travellers: [{ id: 1 }],
      });
      this.tempContract.service_rows = JSON.stringify(this.serviceRows);
    },
    removeServiceRow(id) {
      for (var i in this.serviceRows) {
        if (this.serviceRows[i].id == id) {
          this.serviceRows.splice(i, 1);
          break;
        }
      }
      this.tempContract.service_rows = JSON.stringify(this.serviceRows);
    },
    addTraveller(serviceId, id) {
      this.serviceRows[serviceId - 1].travellers.push({
        id: id + 1,
      });

      this.tempContract["service_rows"] = JSON.stringify(this.serviceRows);
    },
    removeTraveller(serviceId, id) {
      for (var i in this.serviceRows[serviceId - 1].travellers) {
        if (this.serviceRows[serviceId - 1].travellers[i].id == id) {
          this.serviceRows[serviceId - 1].travellers.splice(i, 1);
          break;
        }
      }
      this.tempContract["service_rows"] = JSON.stringify(this.serviceRows);
    },
    addAllTravellers(id, type) {
      let travellers = [];

      this.travellers.forEach((traveller) => {
        if (traveller.name && traveller.lastName) {
          travellers.push({ id: traveller.id });
        }
      });

      if (travellers.length > 0) {
        if (type == "service") {
          for (let i in this.serviceRows) {
            if (this.serviceRows[i].id == id) {
              this.serviceRows[i].travellers = travellers;
              break;
            }
          }

          setTimeout(() => {
            travellers.forEach((traveller) => {
              this.form.setFieldsValue({
                [`service_${id}_traveller_${traveller.id}`]: traveller.id,
              });

              setTimeout(() => {
                this.tempContract[
                  `service_${id}_traveller_${traveller.id}_age`
                ] = this.tempContract[`traveller_${traveller.id}_age`];
              }, 20);
            });
          }, 10);

          this.tempContract.service_rows = JSON.stringify(this.serviceRows);
        }
      } else {
        ///
        this.$message.warning("Preencha os viajantes.");
      }

      setTimeout(() => {
        this.openInternalCollapse = `t-${id}`;
      }, 1);
    },
  },
};
</script>

<style lang="sass" scoped>
.product-card-content
  padding: 20px 15px 10px
  border: 1px solid #ccc
  margin-top: -14px
  border-radius: 0 0 6px 6px
  margin-bottom: 11px
  background: #fff

.product-card-content.internal
  padding: 15px

.product-card.internal
  padding:  10px 20px
.product-card
  cursor: pointer
  clear: both
  padding:  20px
  background: #fff
  border: 1px solid #ccc
  margin-bottom: 10px
  border-radius: 6px
  .id
    color: #474747
    font-weight: 600
    .ico
      position: relative
    .num
      position: absolute
      right: -13px
      top: 0
      font-size: 11px
      color: #333
  .id-name
    padding-left: 20px !important
    color: #42413f
    font-weight: 500
</style>
