<template>
  <div>
    <div
      v-for="(accommodation, index) in accommodationRows"
      :key="index"
      :class="`accommodation accommodation-${accommodation.id}`"
    >
      <div class="product-card" @click="onClickOpenCollapse(accommodation.id)">
        <a-row :gutter="20">
          <a-col class="id cprimary" :span="1">
            <span class="ico">
              <i class="fas fa-bed"></i>
              <font class="num">{{ accommodation.id }} </font>
            </span>
          </a-col>

          <a-col class="id-name" :span="21">
            {{
              tempContract[`hotel_${hotelID}_accommodation_${accommodation.id}`]
                ? tempContract[
                    `hotel_${hotelID}_accommodation_${accommodation.id}`
                  ]
                : "ACOMODAÇÃO / HÓSPEDES"
            }}
          </a-col>

          <a-col class="a-right" :span="2">
            <a-button
              v-if="
                accommodation.id == accommodationRows.length &&
                accommodation.id != 1
              "
              size="small"
              type="danger"
              shape="circle"
              icon="delete"
              @click="removeAccommodation(accommodation.id)"
              ghost
            >
            </a-button>
          </a-col>
        </a-row>
      </div>

      <div class="product-card-content" v-if="openCollapse == accommodation.id">
        <a-row :gutter="15">
          <a-col
            :span="
              $root.reponsive([
                {
                  min: 1300,
                  max: 9999,
                  span: 24,
                },
                {
                  min: 621,
                  max: 1300,
                  span: 24,
                },
                {
                  min: 0,
                  max: 620,
                  span: 24,
                },
              ])
            "
            ><a-col :span="24"> </a-col>

            <a-col :span="14">
              <a-form-item>
                <label :class="'filled'">Nome da acomodação</label>
                <a-input
                  class="travel-input"
                  placeholder="Quarto"
                  v-decorator="[
                    'hotel_' + hotelID + '_accommodation_' + accommodation.id,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item>
                <label :class="'filled'">Tipo de acomodação</label>

                <a-select
                  class="travel-input"
                  show-search
                  placeholder="Tipo"
                  v-decorator="[
                    'hotel_' +
                      hotelID +
                      '_accommodation_' +
                      accommodation.id +
                      '_type',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="(item, index) of accommodationTypes"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.name.toUpperCase() }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-col>

          <a-col :span="24">
            <div :class="`travellers`">
              <div
                class="product-card internal"
                @click="onClickOpenInternalCollapse(`t-${hotelID}`)"
              >
                <a-row>
                  <a-col :span="20">
                    <a-row type="flex" justify="start">
                      <a-col class="id cprimary">
                        <span class="ico">
                          <i class="fa fa-users"></i>
                        </span>
                      </a-col>

                      <a-col class="id-name">
                        HÓSPEDES
                        <font class="count f8"
                          >({{
                            accommodationRows[accommodation.id - 1].travellers
                              .length
                          }})</font
                        >
                      </a-col>
                    </a-row></a-col
                  >
                  <a-col class="a-right" :span="4">
                    <a-tooltip
                      placement="topRight"
                      title="Adicionar todos viajantes"
                    >
                      <a-button
                        size="small"
                        type="primary"
                        shape="circle"
                        @click="
                          addAllTravellers(hotelID, 'hotel', accommodation.id)
                        "
                      >
                        <a-icon type="usergroup-add" class="f18" />
                      </a-button>
                    </a-tooltip>
                  </a-col>
                </a-row>
              </div>

              <div
                class="product-card-content internal"
                v-if="openInternalCollapse == `t-${hotelID}`"
              >
                <HotelTravellersCollapse
                  :hotelId="hotelID"
                  :roomId="accommodation.id"
                  :travellersRows="accommodationRows[index].travellers"
                  :travellers="theTravellers"
                  :tempContract="tempContract"
                  :form="form"
                  @removeTraveller="removeTraveller"
                />

                <div class="a-center mt-20">
                  <a-button
                    class="f12"
                    size="small"
                    @click="
                      addTraveller(
                        accommodation.id,
                        accommodationRows[index].travellers.length
                      )
                    "
                  >
                    Adicionar hóspede
                  </a-button>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div
        v-if="accommodationRows.length == accommodation.id"
        class="a-center mt-20"
      >
        <a-button
          class="f12"
          size="small"
          @click="addAccommodation(accommodationRows.length)"
        >
          Adicionar acomodação
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import HotelTravellersCollapse from "../collapses/HotelTravellersCollapse";

export default {
  name: "HotelAccommodationCollapse",
  props: {
    id: Number,
    hotelID: Number,
    hotelRows: Array,
    tempContract: Object,
    form: Object,
    hotel: Object,
    theTravellers: Array,
    travellers: Array,
    accommodationRows: Array,
  },
  components: { HotelTravellersCollapse },
  data() {
    return {
      openCollapse: false,
      openInternalCollapse: false,
      accommodationTypes: [
        { name: "SINGLE", value: "SINGLE" },
        { name: "DUPLO", value: "DUPLO" },
        { name: "TRIPLO", value: "TRIPLO" },
        { name: "QUADRUPLO", value: "QUADRUPLO" },
        { name: "QUINTUPLO", value: "QUINTUPLO" },
      ],
    };
  },
  methods: {
    onClickOpenInternalCollapse(id) {
      if (id == this.openInternalCollapse) {
        this.openInternalCollapse = -1;
      } else {
        this.openInternalCollapse = id;
      }
    },
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;
        setTimeout(() => {
          this.setFormFields();
        }, 100);
      }
    },
    addAccommodation() {
      for (let i in this.hotelRows) {
        if (this.hotelRows[i].id == this.hotelID) {
          this.hotelRows[i].accommodation.push({
            id: this.hotelRows[i].accommodation.length + 1,
            travellers: [
              {
                id: 1,
              },
            ],
          });
          break;
        }
      }

      this.accommodationRows = this.hotelRows[this.hotelID - 1].room;
      this.tempContract["hotel_rows"] = JSON.stringify(this.hotelRows);

      this.$emit("updateHotelRows", this.hotelRows);
    },
    removeAccommodation(id) {
      for (var i in this.accommodationRows) {
        if (this.accommodationRows[i].id == id) {
          this.accommodationRows.splice(i, 1);
          break;
        }
      }

      setTimeout(() => {
        this.tempContract["hotel_rows"] = JSON.stringify(this.hotelRows);
        this.$emit("updateHotelRows", this.hotelRows);
      }, 20);
    },
    addTraveller(hotelID, id) {
      this.accommodationRows[hotelID - 1].travellers.push({
        id: id + 1,
      });
      setTimeout(() => {
        this.tempContract["hotel_rows"] = JSON.stringify(this.hotelRows);
        this.$emit("updateHotelRows", this.hotelRows);
      }, 100);
    },
    removeTraveller(hotelId, roomId, id) {
      for (var i in this.accommodationRows[roomId - 1].travellers) {
        if (this.accommodationRows[roomId - 1].travellers[i].id == id) {
          this.accommodationRows[roomId - 1].travellers.splice(i, 1);
          break;
        }
      }

      this.tempContract["hotel_rows"] = JSON.stringify(this.hotelRows);
      this.$emit("updateHotelRows", this.hotelRows);
    },
    setFormFields() {
      this.hotel.accommodation.forEach((room) => {
        this.form.setFieldsValue({
          [`hotel_${this.hotelID}_accommodation_${room.id}`]:
            this.tempContract[`hotel_${this.hotelID}_accommodation_${room.id}`],
          [`hotel_${this.hotelID}_accommodation_${room.id}_type`]:
            this.tempContract[
              `hotel_${this.hotelID}_accommodation_${room.id}_type`
            ],
        });

        room.travellers.forEach((traveller) => {
          this.form.setFieldsValue({
            [`hotel_${this.hotelID}_accommodation_${room.id}_traveller_${traveller.id}`]:
              this.tempContract[
                `hotel_${this.hotelID}_accommodation_${room.id}_traveller_${traveller.id}`
              ] != undefined
                ? parseInt(
                    this.tempContract[
                      `hotel_${this.hotelID}_accommodation_${room.id}_traveller_${traveller.id}`
                    ]
                  )
                : undefined,
          });
        });
      });
    },
    addAllTravellers(id, type, roomId) {
      let roomTravellers = [];

      this.travellers.forEach((traveller) => {
        if (traveller.name && traveller.lastName)
          roomTravellers.push({ id: traveller.id });
      });

      if (this.travellers.length > 0) {
        for (let i in this.accommodationRows) {
          if (this.accommodationRows[i].id == roomId) {
            this.accommodationRows[i].travellers = roomTravellers;
            break;
          }
        }

        setTimeout(() => {
          roomTravellers.forEach((traveller) => {
            this.form.setFieldsValue({
              [`hotel_${this.hotelID}_accommodation_${roomId}_traveller_${traveller.id}`]:
                traveller.id,
            });
          });
        }, 10);

        this.tempContract["hotel_rows"] = JSON.stringify(this.hotelRows);

        setTimeout(() => {
          this.openInternalCollapse = `t-${id}`;
        }, 1);
      } else {
        this.$message.warning("Preencha os viajantes.");
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.product-card-content
  padding: 30px 20px 20px
  border: 1px solid #ddd
  margin-top: -14px
  border-radius: 0 0 6px 6px
  margin-bottom: 11px
  background: #FFF
.product-card
  cursor: pointer
  clear: both
  padding: 10px 20px
  background: #fff
  border: 1px solid #ddd
  margin-bottom: 10px
  border-radius: 6px
  .id
    color: #474747
    font-weight: 600
    .ico
      position: relative
    .num
      position: absolute
      right: -13px
      top: 0
      font-size: 11px
      color: #333
  .id-name
    padding-left: 20px !important
    color: #42413f
    font-weight: 500
</style>
