<template>
  <div>
    <div
      v-for="(traveller, index) in travellersRows"
      :key="index"
      :class="`traveller traveller-${traveller.id}`"
    >
      <div class="product-card" @click="onClickOpenCollapse(traveller.id)">
        <a-row>
          <a-col :span="22">
            <a-row type="flex" justify="start" :gutter="20">
              <a-col class="id cprimary">
                <span class="ico">
                  <a-icon type="user" />
                  <font class="num">{{ traveller.id }} </font>
                </span>
              </a-col>
              <a-col class="id-name"> {{ theTraveller(traveller.id) }}</a-col>
            </a-row>
          </a-col>
          <a-col class="a-right" :span="2">
            <a-button
              v-if="traveller.id == travellersRows.length && traveller.id != 1"
              size="small"
              type="danger"
              shape="circle"
              icon="delete"
              @click="removeTraveller(hotelId, roomId, traveller.id)"
              ghost
            />
          </a-col>
        </a-row>
      </div>

      <div class="product-card-content" v-if="openCollapse == traveller.id">
        <a-row :gutter="20" class="travellers-rows">
          <a-col :span="6">
            <a-form-item class="travel-input">
              <label :class="'filled'">Hóspede </label>
              <a-select
                placeholder="Selecione"
                v-decorator="[
                  `hotel_${hotelId}_accommodation_${roomId}_traveller_${traveller.id}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(traveller, index) of travellers"
                  :key="index"
                  :value="traveller.id"
                >
                  {{ traveller.name }}
                  {{ traveller.lastName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HotelTravellersCollapse",
  props: {
    hotelId: Number,
    roomId: Number,
    tempContract: Object,
    form: Object,
    travellersRows: Array,
    travellers: Array,
  },
  data() {
    return {
      openCollapse: -1,
    };
  },
  methods: {
    theTraveller(travellerId) {
      let theTraveller = "HÓSPEDE",
        currentTraveller = this.travellers.filter((t) => {
          return (
            t.id ==
            this.tempContract[
              `hotel_${this.hotelId}_accommodation_${this.roomId}_traveller_${travellerId}`
            ]
          );
        });

      if (currentTraveller.length > 0)
        theTraveller = `${currentTraveller[0].name} ${currentTraveller[0].lastName}`;

      return theTraveller;
    },
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;

        setTimeout(() => {
          this.setHotelTravellerFields(id);
        }, 5);
      }
    },
    setHotelTravellerFields(id) {
      this.form.setFieldsValue({
        [`hotel_${this.hotelId}_accommodation_${this.roomId}_traveller_${id}`]:
          this.tempContract[
            `hotel_${this.hotelId}_accommodation_${this.roomId}_traveller_${id}`
          ] != undefined
            ? parseInt(
                this.tempContract[
                  `hotel_${this.hotelId}_accommodation_${this.roomId}_traveller_${id}`
                ]
              )
            : undefined,
      });
    },
    removeTraveller(hotelId, roomId, id) {
      this.$emit("removeTraveller", hotelId, roomId, id);
    },
  },
};
</script>

<style lang="sass" scoped>
.product-card-content
  padding: 20px 15px 0px
  border: 1px solid #ddd
  margin-top: -14px
  border-radius: 0 0 6px 6px
  margin-bottom: 11px
  background: #FFF
.product-card
  cursor: pointer
  clear: both
  padding: 10px 20px
  background: #fff
  border: 1px solid #ddd
  margin-bottom: 10px
  border-radius: 6px
  .id
    color: #474747
    font-weight: 600
    .ico
      position: relative
    .num
      position: absolute
      right: -13px
      top: 0
      font-size: 11px
      color: #333
  .id-name
    padding-left: 20px !important
    color: #42413f
    font-weight: 500
</style>
