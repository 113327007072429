<template>
  <div>
    <div v-if="contractId > 4856">
      <a-row>
        <a-col
          class="collapse-card"
          v-for="{ id } in customPaymentRows"
          :key="id"
          :span="24"
        >
          <div class="head" @click="onClickOpenCollapse(id)">
            <a-row :gutter="20">
              <a-col class="id cprimary" :span="1">
                <span class="ico">
                  <a-icon type="dollar" />
                  <font class="num">{{ id }} </font>
                </span>
              </a-col>

              <a-col class="id-name" :span="11">
                {{
                  tempContract[`payment_methods_custom_payment_name_${id}`] ??
                  "PG. PERSONALIZADO"
                }}
              </a-col>

              <a-col class="value" :span="9">
                <a-tag :color="'blue'">
                  {{
                    tempContract[
                      `payment_methods_custom_payment_total_value_${id}`
                    ] | formatPricePtBrComma
                  }}
                </a-tag>
              </a-col>

              <a-col class="a-right" :span="3">
                <a-button
                  class="ml-5"
                  v-if="id > 1 && customPaymentRows.length === id"
                  size="small"
                  type="danger"
                  shape="circle"
                  icon="delete"
                  @click="removeRow(id - 1)"
                  ghost
                >
                </a-button>
              </a-col>
            </a-row>
          </div>

          <div class="content" v-if="openCollapse === id">
            <CustomPaymentCollapse
              :form="form"
              :customPaymentId="id"
              :tempContract="tempContract"
              @updateCustomPaymentValue="updateCustomPaymentValue"
            />
          </div>
        </a-col>
      </a-row>

      <div class="a-center mb-40 mt-20">
        <a-button
          type="primary"
          @click="addNewRow(customPaymentRows.length)"
          ghost
        >
          Adicionar pagamento personalizado
        </a-button>
      </div>
    </div>

    <CustomPaymentTabV1
      v-if="contractId <= 4856"
      :form="form"
      :contract="contract"
      :tempContract="tempContract"
      :reservation_total_price="reservation_total_price"
      @verifyTotal="verifyTotal"
    />
  </div>
</template>

<script>
import { parse } from "vue-currency-input";

import formatThings from "@/mixins/general/formatThings.js";
import CustomPaymentTabV1 from "./CustomPaymentTabV1.vue";
import CustomPaymentCollapse from "../collapses/CustomPaymentCollapse.vue";

export default {
  components: { CustomPaymentTabV1, CustomPaymentCollapse },
  name: "CustomPaymentTab",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    reservation_total_price: Number,
  },
  mixins: [formatThings],
  data() {
    return {
      openCollapse: "",
      customPaymentRows: [],
      contractId: parseInt(this.contract.id),
    };
  },
  mounted() {
    if (this.tempContract["custom_payment_rows"] !== undefined)
      this.customPaymentRows = JSON.parse(
        this.tempContract["custom_payment_rows"]
      );

    this.updateCustomPaymentValue();
  },
  methods: {
    updateCustomPaymentValue() {
      let theTotal = 0;
      this.customPaymentRows.forEach((customPayment) => {
        theTotal += parse(
          this.tempContract[
            `payment_methods_custom_payment_total_value_${customPayment.id}`
          ]
        );
      });

      this.tempContract[`payment_methods_custom_payment_total_value`] =
        `${theTotal}`.replace(".", ",");

      this.$emit("updateCustomPaymentValue");
    },
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;
      }
    },
    addNewRow(id) {
      this.customPaymentRows.push({
        id: id + 1,
      });

      this.tempContract["custom_payment_rows"] = JSON.stringify(
        this.customPaymentRows
      );

      this.updateCustomPaymentValue();
    },
    removeRow(index) {
      for (var i in this.customPaymentRows) {
        if (i == index) {
          this.customPaymentRows.splice(i, 1);
          break;
        }
      }

      this.tempContract["custom_payment_rows"] = JSON.stringify(
        this.customPaymentRows
      );

      this.updateCustomPaymentValue();
    },
  },
};
</script>

<style lang="sass" scoped>
.collapse-card
  .content
    padding: 30px 20px 20px
    border: 1px solid #ccc
    margin-top: -14px
    border-radius: 0 0 6px 6px
    margin-bottom: 11px
    background: #fff
  .head
    cursor: pointer
    clear: both
    padding:  20px
    background: #fff
    border: 1px solid #ccc
    margin-bottom: 10px
    border-radius: 6px
    .id
      color: #474747
      font-weight: 600
      .ico
        position: relative
        font-size: 14px
      .num
        position: absolute
        right: -13px
        top: 0
        font-size: 11px
        color: #333
    .id-name
      padding-left: 20px !important
      color: #42413f
      font-weight: 500
      text-transform: uppercase
    .value
      font-size: 10px
      color: #42413f
      font-weight: 500
      text-transform: uppercase
</style>
