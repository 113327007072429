<template>
  <section>
    <div v-if="contractId > 4853">
      <a-row>
        <a-col
          class="collapse-card"
          v-for="{ id } in bankTransferRows"
          :key="id"
          :span="24"
        >
          <div class="head" @click="onClickOpenCollapse(id)">
            <a-row :gutter="20">
              <a-col class="id cprimary" :span="1">
                <span class="ico">
                  <a-icon type="bank" />
                  <span class="num">{{ id }} </span>
                </span>
              </a-col>

              <a-col class="id-name" :span="11">
                TRANSFERÊNCIA BANCÁRIA ({{
                  tempContract[`payment_methods_bank_transfer_bank_${id}`] ??
                  "BANCO"
                }})
              </a-col>

              <a-col class="value" :span="9">
                <a-tag :color="'blue'">
                  {{
                    tempContract[
                      `payment_methods_bank_transfer_total_value_${id}`
                    ] | formatPricePtBrComma
                  }}
                </a-tag>
              </a-col>

              <a-col class="a-right" :span="3">
                <a-button
                  class="ml-5"
                  v-if="id > 1 && bankTransferRows.length === id"
                  size="small"
                  type="danger"
                  shape="circle"
                  icon="delete"
                  @click="removeRow(id - 1)"
                  ghost
                >
                </a-button>
              </a-col>
            </a-row>
          </div>

          <div class="content" v-if="openCollapse === id">
            <BankTransferCollapse
              :form="form"
              :bankTransferId="id"
              :tempContract="tempContract"
              :reservation_total_price="reservation_total_price"
              @updateBankTransferValue="updateBankTransferValue"
            />
          </div>
        </a-col>
      </a-row>

      <div class="a-center mb-40 mt-20">
        <a-button
          type="primary"
          @click="addNewRow(bankTransferRows.length)"
          ghost
        >
          Adicionar transferência bancária
        </a-button>
      </div>
    </div>

    <BankTransferTabV1
      v-if="contractId <= 4853"
      :contract="contract"
      :tempContract="tempContract"
      :form="form"
      :reservation_total_price="reservation_total_price"
    />
  </section>
</template>

<script>
import { parse } from "vue-currency-input";
import formatThings from "@/mixins/general/formatThings.js";

import BankTransferCollapse from "../collapses/BankTransferCollapse.vue";
import BankTransferTabV1 from "./BankTransferTabV1.vue";

export default {
  components: { BankTransferTabV1, BankTransferCollapse },
  name: "BankTransferTab",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    reservation_total_price: Number,
  },
  mixins: [formatThings],
  data() {
    return {
      openCollapse: "",
      bankTransferRows: [],
      contractId: parseInt(this.contract.id),
    };
  },
  mounted() {
    if (this.tempContract["bank_transfer_rows"] !== undefined)
      this.bankTransferRows = JSON.parse(
        this.tempContract["bank_transfer_rows"]
      );

    this.updateBankTransferValue();
  },
  methods: {
    updateBankTransferValue() {
      let theTotal = 0;
      this.bankTransferRows.forEach((bankTransfer) => {
        theTotal += parse(
          this.tempContract[
            `payment_methods_bank_transfer_total_value_${bankTransfer.id}`
          ]
        );
      });

      this.tempContract[`payment_methods_bank_transfer_total_value`] =
        `${theTotal}`.replace(".", ",");

      this.$emit("updateBankTransferValue");
    },
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;
      }
    },
    addNewRow(id) {
      this.bankTransferRows.push({
        id: id + 1,
      });

      this.tempContract["bank_transfer_rows"] = JSON.stringify(
        this.bankTransferRows
      );

      this.updateBankTransferValue();
    },
    removeRow(index) {
      for (var i in this.bankTransferRows) {
        if (i == index) {
          this.bankTransferRows.splice(i, 1);
          break;
        }
      }

      this.tempContract["bank_transfer_rows"] = JSON.stringify(
        this.bankTransferRows
      );

      this.updateBankTransferValue();
    },
  },
};
</script>

<style lang="sass" scoped>
.collapse-card
  .content
    padding: 30px 20px 20px
    border: 1px solid #ccc
    margin-top: -14px
    border-radius: 0 0 6px 6px
    margin-bottom: 11px
    background: #fff
  .head
    cursor: pointer
    clear: both
    padding:  20px
    background: #fff
    border: 1px solid #ccc
    margin-bottom: 10px
    border-radius: 6px
    .id
      color: #474747
      font-weight: 600
      .ico
        position: relative
        font-size: 14px
      .num
        position: absolute
        right: -13px
        top: 0
        font-size: 11px
        color: #333
    .id-name
      padding-left: 20px !important
      color: #42413f
      font-weight: 500
      text-transform: uppercase
    .value
      font-size: 10px
      color: #42413f
      font-weight: 500
      text-transform: uppercase
</style>
