<template>
  <div class="transaction-actions">
    <div class="tid">
      ID da Transação Rede:
      <b>{{ tempContract[`payment_link_3ds_tid_${paymentLinkId}`] }}</b>
    </div>
    <div class="tid" v-if="![undefined, ''].includes(cancelId)">
      ID do cancelamento Rede:
      <b>{{ cancelId }}</b>
    </div>
    <div class="tid" v-if="![undefined, ''].includes(authCode)">
      ID da autorização da captura:
      <b>{{ authCode }}</b>
    </div>

    <a-button
      v-if="[undefined, ''].includes(authCode) && tid"
      class="capture mr-10"
      icon="check"
      type="primary"
      :loading="actionsLoading"
      @click="onClickCaptureRedeTransaction"
    >
      Capturar
    </a-button>
    <a-button
      v-if="[undefined, ''].includes(cancelId)"
      class="mr-10"
      icon="close"
      type="danger"
      :loading="actionsLoading"
      @click="onClickCancelRedeTransaction"
    >
      Cancelar
    </a-button>
    <a-button
      icon="close"
      type="danger"
      :loading="actionsLoading"
      @click="onClickCancelHayaTransaction"
      disabled
    >
      Cancelar no Haya
    </a-button>
  </div>
</template>

<script>
export default {
  name: "PaymentLinkTransactionActions",
  props: {
    form: Object,
    paymentLinkId: Number,
    tempContract: Object,
    contract: Object,
  },
  data() {
    return {
      tid: "",
      cancelId: undefined,
      authCode: undefined,
      actionsLoading: false,
    };
  },
  mounted() {
    this.tid = this.tempContract[`payment_link_3ds_tid_${this.paymentLinkId}`];
    this.cancelId =
      this.tempContract[
        `payment_methods_payment_link_refund_id_${this.paymentLinkId}`
      ];
    this.authCode =
      this.tempContract[
        `payment_methods_payment_link_authorization_code_${this.paymentLinkId}`
      ];
  },
  methods: {
    onClickCaptureRedeTransaction() {
      const tid =
        this.tempContract[`payment_link_3ds_tid_${this.paymentLinkId}`];

      this.actionsLoading = true;

      this.$http
        .post("/integrations/rede/capture", {
          tid,
          request: JSON.stringify({
            amount: this.formatValueToRede(
              this.tempContract[
                `payment_methods_payment_link_total_value_${this.paymentLinkId}`
              ]
            ),
          }),
        })
        .then(({ data }) => {
          this.$message.success("Transação capturada com sucesso!");
          this.authCode = data.authorizationCode;
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempContract.id,
            module: "contract",
            action: `payment-link-${this.paymentLinkId}-capture-transaction-success`,
            description: `<b class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</b> capturou a transação <b>ID ${tid}</b> com sucesso. Segue mensagem retornada:<br><br>
            Código ${data.returnCode}: <b>${data.returnMessage}</b><br>  authorizationCode: <b>${data.authorizationCode}</b>  <br><br>`,
          });

          // update contract
          this.$http.post("/contract/update-multi-meta", {
            id: this.tempContract.id,
            [`payment_methods_payment_link_authorization_code_${this.paymentLinkId}`]:
              data.authorizationCode,
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.returnMessage);
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempContract.id,
            module: "contract",
            action: `payment-link-${this.paymentLinkId}-capture-failure`,
            description: `<b class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</b> tentou capturar a transação <b>ID ${tid}</b>. Segue mensagem retornada:<br><br>
            Erro ${response.data.returnCode}: <b>${response.data.returnMessage}</b><br><br>`,
          });
        })
        .finally(() => {
          this.actionsLoading = false;
        });
    },
    onClickCancelRedeTransaction() {
      const tid =
        this.tempContract[`payment_link_3ds_tid_${this.paymentLinkId}`];

      this.actionsLoading = true;
      this.$http
        .post("/integrations/rede/cancel", {
          tid: tid,
          request: JSON.stringify({
            amount: this.formatValueToRede(
              this.tempContract[
                `payment_methods_payment_link_total_value_${this.paymentLinkId}`
              ]
            ),
          }),
        })
        .then(({ data }) => {
          this.$message.success(data.returnMessage);
          this.cancelId = data.refundId;
          this.tid = "";
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempContract.id,
            module: "contract",
            action: `payment-link-${this.paymentLinkId}-cancel-transaction-success`,
            description: `<b class="user">${
              this.$store.state.userData.first_name
            } ${
              this.$store.state.userData.last_name
            }</b> cancelou a transação <b>ID ${tid}</b> com sucesso. Segue mensagem retornada:<br><br>
            Código ${data.returnCode}: <b>${
              data.returnMessage
            }</b><br>  refundId: <b>${data.refundId}</b> <br>  cancelId: <b>${
              data.cancelId ? data.cancelId : ""
            }</b> <br><br>`,
          });

          // update contract
          this.$http.post("/contract/update-multi-meta", {
            id: this.tempContract.id,
            [`payment_methods_payment_link_refund_id_${this.paymentLinkId}`]:
              data.refundId,
            [`payment_methods_payment_link_cancel_id_${this.paymentLinkId}`]:
              data.cancelId,
            [`payment_methods_payment_link_refund_date_${this.paymentLinkId}`]:
              data.refundDateTime,
            [`payment_link_3ds_tid_${this.paymentLinkId}`]: "",
            [`payment_methods_payment_link_authorization_code_${this.paymentLinkId}`]:
              "",
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.returnMessage);
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempContract.id,
            module: "contract",
            action: `payment-link-${this.paymentLinkId}-cancel-transaction-failure`,
            description: `<b class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</b> tentou cancelar a transação <b>ID ${tid}</b>. Segue mensagem retornada:<br><br>
            Erro ${response.data.returnCode}: <b>${response.data.returnMessage}</b><br><br>`,
          });
        })
        .finally(() => {
          this.actionsLoading = false;
        });
    },
    onClickCancelHayaTransaction() {
      //
    },
    formatValueToRede(value) {
      let theValue;
      let theValuePieces = [];

      if (value.includes(",")) {
        theValuePieces = value.split(",");
        let theValueDecimals = "00";
        if (theValuePieces[1].length === 1) {
          theValueDecimals = `${theValuePieces[1]}0`;
        } else {
          theValueDecimals = `${theValuePieces[1]}`;
        }
        theValue = `${theValuePieces[0]}${theValueDecimals}`;
      } else {
        theValue = `${value}00`;
      }

      return theValue;
    },
  },
};
</script>

<style lang="sass" scoped>
.transaction-actions
  background: #eee
  margin: 0 -20px 20px
  padding: 20px
  .capture
    background: #2ecc71
    border-color: #2ecc71
  .tid
    margin: 0 0 10px
    font-size: 13px
    color: #333
</style>
