<template>
  <div>
    <aRow>
      <aCol
        class="collapse-card"
        v-for="{ id } in paymentLinkRows"
        :key="id"
        :span="24"
      >
        <div class="head" @click="onClickOpenCollapse(id)">
          <aRow :gutter="20">
            <aCol class="id cprimary" :span="1">
              <span class="ico">
                <aIcon type="link" />
                <span class="num">{{ id }} </span>
              </span>
            </aCol>
            <aCol class="id-name" :span="11"> LINK DE PAGAMENTO </aCol>
            <aCol class="value" :span="9">
              <aTag :color="'blue'">
                {{
                  tempContract[`payment_methods_payment_link_total_value_${id}`]
                    | formatPricePtBrComma
                }}
              </aTag>
            </aCol>

            <aCol class="a-right" :span="3">
              <a-tooltip>
                <template slot="title"> Logs </template>
                <span
                  class="f18 relative cblue c-pointer"
                  style="top: 2px"
                  @click="onClickOpenPaymentLinkLogsModal(id)"
                >
                  <i class="fa-solid fa-list-check" />
                </span>
              </a-tooltip>

              <aButton
                class="ml-10"
                v-if="id > 1 && paymentLinkRows.length === id"
                size="small"
                type="danger"
                shape="circle"
                icon="delete"
                @click="removeRow(id - 1)"
                ghost
              >
              </aButton>
            </aCol>
          </aRow>
        </div>

        <div class="content" v-if="openCollapse === id">
          <PaymentLinkCollapse
            :form="form"
            :contract="contract"
            :tempContract="tempContract"
            :reservation_total_price="reservation_total_price"
            :paymentLinkId="id"
            @updatePaymentLinkValue="updatePaymentLinkValue"
          />
        </div>
      </aCol>
    </aRow>

    <div class="a-center mb-40 mt-20">
      <aButton type="primary" @click="addNewRow(paymentLinkRows.length)" ghost>
        Adicionar link de pagamento
      </aButton>
    </div>

    <aModal
      :visible="openPaymentLinkLogsModal"
      :footer="false"
      @cancel="openPaymentLinkLogsModal = false"
      @ok="openPaymentLinkLogsModal = false"
      width="800px"
    >
      <template slot="title">
        <i class="fa-solid fa-list-check mr-10 cprimary" /> HISTÓRICO LINK DE
        PAGAMENTO #{{ pLinkId }}
      </template>

      <aTabs type="card" @change="callback">
        <aTabPane key="1" tab="Logs">
          <LogsTimelineV2
            v-if="openPaymentLinkLogsModal"
            module="contract"
            :id="tempContract.id"
            :actionContains="`payment-link-${pLinkId}`"
          />
        </aTabPane>
        <aTabPane
          v-if="tempContract[`payment_link_client_cep_${pLinkId}`]"
          key="2"
          tab="Endereço Fatura"
          class="f12"
        >
          Endereço da fatura do cartão de crédito:<br />
          <b>
            {{
              tempContract[`payment_link_client_location_street_${pLinkId}`]
            }},
            {{
              tempContract[
                `payment_link_client_location_neighborhood_${pLinkId}`
              ]
            }},
            {{
              tempContract[`payment_link_client_location_number_${pLinkId}`]
            }},
            {{
              tempContract[`payment_link_client_location_complement_${pLinkId}`]
                ? tempContract[
                    `payment_link_client_location_complement_${pLinkId}`
                  ] + ", "
                : ""
            }}
            {{ tempContract[`payment_link_client_location_city_${pLinkId}`] }} -
            {{ tempContract[`payment_link_client_location_state_${pLinkId}`] }}

            -
            {{ tempContract[`payment_link_client_cep_${pLinkId}`] }}
          </b>
        </aTabPane>
      </aTabs>
    </aModal>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";
import formatThings from "@/mixins/general/formatThings.js";
import PaymentLinkCollapse from "../collapses/PaymentLinkCollapse.vue";
import LogsTimelineV2 from "../../logs/LogsTimelineV2.vue";

export default {
  name: "PaymentLinkTab",
  props: {
    form: Object,
    contract: Object,
    tempContract: Object,
    reservation_total_price: Number,
  },
  components: { PaymentLinkCollapse, LogsTimelineV2 },
  mixins: [formatThings],
  data() {
    return {
      openCollapse: "",
      pLinkId: "",
      paymentLinkRows: [],
      openPaymentLinkLogsModal: false,
    };
  },
  mounted() {
    if (this.tempContract["payment_link_rows"] !== undefined)
      this.paymentLinkRows = JSON.parse(this.tempContract["payment_link_rows"]);

    this.updatePaymentLinkValue();
  },
  methods: {
    updatePaymentLinkValue() {
      let theTotal = 0;
      this.paymentLinkRows.forEach((pLink) => {
        theTotal += parse(
          this.tempContract[
            `payment_methods_payment_link_total_value_${pLink.id}`
          ]
        );
      });

      this.tempContract[`payment_methods_payment_link_total_value`] =
        `${theTotal}`.replace(".", ",");

      this.$emit("verifyTotal");
    },
    onClickOpenPaymentLinkLogsModal(id) {
      this.openPaymentLinkLogsModal = true;
      this.openCollapse = id;
      this.pLinkId = id;
    },
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;
      }
    },
    addNewRow(id) {
      this.paymentLinkRows.push({
        id: id + 1,
      });

      this.tempContract["payment_link_rows"] = JSON.stringify(
        this.paymentLinkRows
      );

      this.updatePaymentLinkValue();
    },
    removeRow(index) {
      for (let i in this.paymentLinkRows) {
        if (i == index) {
          this.paymentLinkRows.splice(i, 1);
          break;
        }
      }

      this.tempContract["payment_link_rows"] = JSON.stringify(
        this.paymentLinkRows
      );

      this.updatePaymentLinkValue();
    },
  },
};
</script>

<style lang="sass" scoped>
.collapse-card
  .content
    padding: 30px 20px 20px
    border: 1px solid #ccc
    margin-top: -14px
    border-radius: 0 0 6px 6px
    margin-bottom: 11px
    background: #fff
  .head
    cursor: pointer
    clear: both
    padding:  20px
    background: #fff
    border: 1px solid #ccc
    margin-bottom: 10px
    border-radius: 6px
    .id
      color: #474747
      font-weight: 600
      .ico
        position: relative
        font-size: 14px
        color: #ff4a00
      .num
        position: absolute
        right: -13px
        top: 0
        font-size: 11px
        color: #333
    .id-name
      padding-left: 20px !important
      color: #42413f
      font-weight: 500
      text-transform: uppercase
    .value
      font-size: 10px
      color: #42413f
      font-weight: 500
      text-transform: uppercase
</style>
