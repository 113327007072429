<template>
  <section class="haya-page contract-page page pd-20">
    <div v-if="systemLoadings.generateContractLoading" class="loading f16">
      <aIcon class="mr-5" type="loading" /> Gerando o contrato, aguarde...
    </div>

    <div class="mb-20 f12" v-if="tempContract.sale_status == 'Finalizado'">
      <aAlert type="warning" banner>
        <template #message>
          A venda deste contrato foi <b>FINALIZADA</b>, a edição dos dados não
          será mais possível.
        </template>
      </aAlert>
    </div>

    <aForm
      :form="form"
      @onFieldsChange="updateTempContract"
      @submit="submitContract"
    >
      <ContractInfosSection
        :contract="contract"
        :form="form"
        :tempContract="tempContract"
        :allContractData="allContractData"
      />

      <aRow type="flex" :gutter="15">
        <aCol class="sidebar" flex="360px">
          <aRow>
            <aCol :span="24">
              <ContractCustomerSection
                :contract="contract"
                :form="form"
                :tempContract="tempContract"
                :validateAllContractFields="validateAllContractFields"
                @onClickOpenCustomerModal="onClickOpenCustomerModal"
              />
            </aCol>

            <aCol v-if="$route.name != 'new'" class="w-box mb-10" :span="24">
              <div
                class="row border c-pointer hover"
                @click="travellers.drawer = true"
              >
                <span class="txt">
                  <aRow type="flex" justify="space-between">
                    <aCol>
                      <span class="img">
                        <img
                          src="@/assets/images/dashboard/contracts/travellers.png"
                          alt="travellers"
                        />
                      </span>
                      Passageiros</aCol
                    >
                    <aCol>
                      <a-tooltip
                        v-if="validateAllContractFields.passengersStatus.alert"
                      >
                        <template slot="title">
                          Verifique se os dados dos passageiros estão completos
                        </template>
                        <img
                          class="c-pointer"
                          src="@/assets/images/dashboard/contracts/danger.png"
                          alt="img"
                          width="15"
                        /> </a-tooltip
                    ></aCol>
                  </aRow>
                </span>
              </div>

              <div class="row border c-pointer">
                <div @click="onClickShowProducts">
                  <aRow>
                    <aCol :span="12">
                      <span class="img">
                        <img
                          src="@/assets/images/dashboard/contracts/product.png"
                          alt="product"
                        />
                      </span>
                      <span class="txt"> Produtos </span>
                    </aCol>
                    <aCol :span="12" class="a-right">
                      <a-icon type="caret-down" />
                    </aCol>
                  </aRow>
                </div>

                <aRow class="products-row mt-20" v-if="showProducts">
                  <aCol :span="24">
                    <aFormItem class="mb-0">
                      <a-radio-group
                        class="package-type"
                        v-decorator="[
                          'package_type',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Selecione',
                              },
                            ],
                          },
                        ]"
                      >
                        <a-radio
                          v-for="(type, index) in packageTypes"
                          :key="index"
                          :value="type.value"
                          :disabled="type.disabled"
                        >
                          <span class="label">
                            {{ type.label }}
                          </span>

                          <div class="description">
                            {{ type.description }}
                          </div>
                        </a-radio>
                      </a-radio-group>
                    </aFormItem>
                  </aCol>

                  <aCol :span="24">
                    <aFormItem class="services">
                      <a-checkbox-group
                        v-decorator="[
                          'contracted_services',
                          {
                            rules: [
                              {
                                required: true,
                                message: '*',
                              },
                            ],
                          },
                        ]"
                        style="width: 100%"
                        @change="contractedServicesChange"
                      >
                        <a-checkbox
                          v-for="(product, index) in products"
                          :key="index"
                          :value="product.value"
                          :disabled="product.disabled"
                        >
                          {{ product.label }}
                        </a-checkbox>
                      </a-checkbox-group>
                    </aFormItem>
                  </aCol>

                  <aCol :span="24">
                    <a-tag
                      v-for="(product, index) in formatProducts(tempContract)"
                      :key="index"
                      class="mb-10"
                      :color="product.color"
                    >
                      <div
                        style="
                          max-width: 280px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                        v-html="product.name"
                      />
                    </a-tag>
                  </aCol>

                  <aCol class="mt-20" :span="24">
                    <aFormItem class="travel-input-outer">
                      <label for :class="'filled'"> Origem do cliente </label>

                      <a-select
                        class="travel-input"
                        placeholder="Selecione"
                        optionFilterProp="txt"
                        v-decorator="[`customer_origin`]"
                        show-search
                        style="width: 100%"
                      >
                        <a-select-option
                          v-for="(item, index) of customerOriginList"
                          :key="index"
                          :value="item.name"
                          :txt="item.name"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </aFormItem>

                    <aFormItem class="none">
                      <aInput
                        v-decorator="[`company_branch`]"
                        placeholder="Filial"
                      >
                      </aInput>
                    </aFormItem>
                  </aCol>

                  <aCol
                    v-if="form.getFieldValue('customer_origin') === 'Indicação'"
                    :span="24"
                  >
                    <aFormItem class="travel-input-outer">
                      <label for :class="'filled'">
                        Cliente que fez a indicação (opcional)
                      </label>

                      <a-auto-complete
                        style="width: 79%"
                        placeholder="ID, Nome, Nome Fantasia..."
                        v-model="customerWhoReferred"
                        @search="searchCustomersWhoReferred"
                        @select="onCustomerWhoReferredSelected"
                      >
                        <template slot="dataSource">
                          <a-select-option
                            v-for="item in customersWhoReferredList.map(
                              ({
                                first_name,
                                last_name,
                                person_type,
                                trading_name,
                                id,
                              }) => ({
                                value: id,
                                text: `${id} - ${
                                  person_type == 'Pessoa Física'
                                    ? first_name.toUpperCase() +
                                      ' ' +
                                      last_name.toUpperCase()
                                    : trading_name.toUpperCase()
                                }`,
                              })
                            )"
                            :key="item.value"
                            :title="item.text"
                            :txt="item.text"
                            >{{ item.text }}</a-select-option
                          >
                        </template>
                        <a-input
                          v-decorator="[`customer_who_referred_id`]"
                          style="border-radius: 0"
                        />
                      </a-auto-complete>
                      <a-button
                        class="ml-10"
                        type="primary"
                        @click="openCreateCustomer = true"
                        shape="circle"
                        size="small"
                        icon="plus"
                      />
                    </aFormItem>

                    <aFormItem class="none">
                      <aInput
                        class="travel-input"
                        v-decorator="['customer_who_referred_name']"
                      />
                    </aFormItem>
                  </aCol>
                </aRow>
              </div>

              <div
                v-if="contractedServices.length > 0"
                class="row border hover c-pointer"
                @click="openContractFinancesModal = true"
              >
                <span class="txt">
                  <aRow type="flex" justify="space-between">
                    <aCol>
                      <span class="img">
                        <img
                          src="@/assets/images/dashboard/contracts/received.png"
                          alt="financeiro"
                        />
                      </span>
                      Financeiro</aCol
                    >
                    <aCol>
                      <a-tooltip
                        v-if="
                          validateAllContractFields.hotelStatus.financesAlert ||
                          validateAllContractFields.serviceStatus
                            .financesAlert ||
                          validateAllContractFields.flightStatus.financesAlert
                        "
                      >
                        <template slot="title">
                          Verifique se os valores estão preenchidos
                        </template>
                        <img
                          class="c-pointer"
                          src="@/assets/images/dashboard/contracts/danger.png"
                          alt="img"
                          width="15"
                        /> </a-tooltip
                    ></aCol>
                  </aRow>
                </span>
              </div>

              <div class="row border c-pointer hover" @click="showPayment()">
                <span class="txt">
                  <aRow type="flex" justify="space-between">
                    <aCol>
                      <span class="img">
                        <img
                          src="@/assets/images/dashboard/contracts/payments.png"
                          alt="recebimento"
                        />
                      </span>
                      Recebimento
                    </aCol>

                    <aCol>
                      <a-tooltip
                        v-if="
                          validateAllContractFields.paymentsStatus.alert ||
                          this.allContractData.total !=
                            this.allContractData.paymentMethods.total
                        "
                      >
                        <template slot="title">
                          Verifique se os dados dos recebimentos estão
                          preenchidos
                        </template>
                        <img
                          class="c-pointer"
                          src="@/assets/images/dashboard/contracts/danger.png"
                          alt="img"
                          width="15"
                        /> </a-tooltip
                    ></aCol>
                  </aRow>
                </span>
              </div>
              <div
                v-if="requestMiles().show"
                class="row border c-pointer hover"
                @click="openMilesRequestModal = true"
              >
                <span class="txt">
                  <aRow type="flex" justify="space-between">
                    <aCol>
                      <span class="img">
                        <img
                          src="@/assets/images/dashboard/contracts/miles-card.png"
                          alt="miles-card"
                        />
                      </span>
                      Pedidos de Milhas
                    </aCol>
                    <aCol>
                      <a-tooltip
                        v-if="
                          (checkIfAllMilesOrdersIsConcluded() &&
                            milesCardOrdersIsConcluded) == false
                        "
                      >
                        <template slot="title">
                          Verifique se os dados do pedido de milhas
                        </template>
                        <img
                          class="c-pointer"
                          src="@/assets/images/dashboard/contracts/danger.png"
                          alt="img"
                          width="15"
                        /> </a-tooltip
                    ></aCol>
                  </aRow>
                </span>
              </div>

              <div
                v-if="milesTaxesPayments().show"
                class="row border c-pointer hover"
                @click="showMilesTaxesPayments = true"
              >
                <aRow type="flex" justify="space-between">
                  <aCol>
                    <span class="img">
                      <img
                        src="@/assets/images/dashboard/contracts/taxes.png"
                        alt="taxes"
                      />
                    </span>
                    Taxas
                  </aCol>
                  <aCol>
                    <a-tooltip
                      v-if="validateAllContractFields.voucherStatus.alert"
                    >
                      <template slot="title">
                        Verifique se os valores estão preenchidos
                      </template>
                      <img
                        class="c-pointer"
                        src="@/assets/images/dashboard/contracts/danger.png"
                        alt="danger"
                        width="15"
                      /> </a-tooltip
                  ></aCol>
                </aRow>
              </div>

              <div
                v-if="$root.isAdmin()"
                class="row border hover c-pointer"
                @click="openMarketingModal = true"
              >
                <span class="img">
                  <img
                    src="@/assets/images/dashboard/contracts/marketing.png"
                    alt="marketing"
                    width="20"
                  />
                </span>
                <span class="txt"> Marketing </span>
              </div>

              <div
                class="row border hover c-pointer"
                @click="openFilesModal = true"
              >
                <span class="img">
                  <img
                    src="@/assets/images/dashboard/contracts/files.png"
                    alt="files"
                  />
                </span>
                <span class="txt"> Arquivos </span>
              </div>
              <div
                class="row no-border hover c-pointer"
                @click="showNotes = true"
              >
                <span class="img">
                  <img
                    src="@/assets/images/dashboard/contracts/note.png"
                    alt="note"
                  />
                </span>
                <span class="txt"> Anotações </span>
              </div>
            </aCol>

            <aCol :span="24">
              <ContractSideMenuSection
                :contract="contract"
                :form="form"
                :tempContract="tempContract"
                :validateAllContractFields="validateAllContractFields"
                @onClickOpenCustomerModal="onClickOpenCustomerModal"
                @validatePolicyFields="validatePolicyFields"
                @allContractUpdates="allContractUpdates"
                @updateTempContractFromChild="updateTempContractFromChild"
              />
            </aCol>

            <aCol class="mb-20 actions" :span="24">
              <aRow :gutter="20">
                <aCol v-if="$route.name != 'new'" :span="19">
                  <a-button
                    v-if="tempContract.status != 'concluded'"
                    class="button-green"
                    id="generate-contract"
                    size="large"
                    type="primary"
                    @click="openDocumentsModal = true"
                    style="width: 100%"
                    :disabled="generateContractIsBlocked"
                    :loading="systemLoadings.generateContractLoading"
                  >
                    GERAR
                  </a-button>
                  <a-button
                    v-if="tempContract.status == 'concluded'"
                    class="button-green"
                    id="generate-contract"
                    size="large"
                    type="primary"
                    style="width: 100%; pointer-events: none"
                  >
                    GERADO
                    <a-icon type="like" /> </a-button
                ></aCol>
                <aCol
                  v-if="
                    tempContract.sale_status != 'Finalizado' ||
                    $store.state.userData.id == 1
                  "
                  :span="5"
                >
                  <a-button
                    class="a-center"
                    size="large"
                    type="primary"
                    html-type="submit"
                    style="width: 100%"
                    :loading="systemLoadings.updateContractLoading"
                    :disabled="systemLoadings.updateContractLoading"
                    @click="allContractUpdates"
                  >
                    <img
                      v-if="!systemLoadings.updateContractLoading"
                      src="@/assets/images/dashboard/contracts/save.png"
                      alt="save"
                    />
                  </a-button>
                </aCol>
              </aRow>
              <aRow
                v-if="
                  tempContract.contract_email_is_sent == undefined &&
                  tempContract.status != 'concluded'
                "
                :gutter="20"
              >
                <aCol class="f12 mt-10" :span="20">
                  <a-checkbox
                    v-model="sendEmailToCustomer"
                    :disabled="$root.isAdmin() ? false : true"
                    class="f11 relative fw600"
                  >
                    Enviar e-mail para cliente ao gerar contrato
                  </a-checkbox>
                </aCol>

                <aCol class="f9" :span="20">
                  *Uma cópia será enviada para o consultor do contrato.
                </aCol>
              </aRow>
            </aCol>
          </aRow>
        </aCol>
        <aCol class="content" flex="auto">
          <a-alert
            class="miles-alert mb-20"
            v-if="requestMiles().haveMiles"
            type="error"
            :show-icon="false"
          >
            <template slot="message">
              <img
                class="ico"
                src="@/assets/images/dashboard/contracts/miles-alert.png"
                alt="miles-alert"
                width="40"
              />
              <b>Atenção!</b> Você está emitindo um bilhete com milhas!
            </template>
            <template slot="description">
              Este bilhete não permite cancelamento, remarcação e reembolso.
              Informe ao seu cliente antes de emitir o bilhete. O cliente irá
              receber esta informação no e-mail de confirmação.
            </template>
          </a-alert>

          <aRow>
            <aCol class="w-box mb-20">
              <ContractPageContent
                :contract="contract"
                :form="form"
                :activeTab="activeTab"
                :tempContract="tempContract"
                :travellers="theTravellers()"
                :contractedServices="contractedServices"
                :validateAllContractFields="validateAllContractFields"
              />
            </aCol> </aRow
        ></aCol>
      </aRow>

      <a-tooltip v-if="tempContract.the_customer" placement="left">
        <template slot="title">
          <span>Clique aqui para falar com o cliente</span>
        </template>
        <a-button
          @click="openWhatsapp(tempContract.the_customer.mobile_phone)"
          id="whatsapp"
          shape="round"
          ><i class="fab fa-whatsapp"></i
        ></a-button>
      </a-tooltip>

      <aModal
        :visible="openCustomerModal"
        :footer="false"
        @cancel="updateCustomerData"
        @ok="updateCustomerData"
        :width="640"
        :closable="$route.name == 'new' ? false : true"
      >
        <template slot="title">
          <img
            class="mr-5"
            src="@/assets/images/dashboard/contracts/customer.png"
            alt="customer"
          />
          CONTRATANTE
        </template>

        <div class="search-customers">
          <a-auto-complete
            :data-source="
              customers.list.map(
                ({ first_name, last_name, person_type, trading_name, id }) => ({
                  value: id,
                  text: `${id} - ${
                    person_type == 'Pessoa Física'
                      ? first_name.toUpperCase() + ' ' + last_name.toUpperCase()
                      : trading_name.toUpperCase()
                  }`,
                })
              )
            "
            style="width: 100%"
            placeholder="Buscar por ID, Nome, Nome Fantasia..."
            v-model="customers.filters.customerName"
            @search="searchCustomers"
            @select="onCustomerSelected"
          >
            <aInput>
              <a-icon slot="prefix" type="search" />
            </aInput>
          </a-auto-complete>
        </div>

        <a-form @submit.prevent="submitCustomer" :form="customerForm">
          <aRow
            class="box box-contractor"
            :gutter="[20, 0]"
            style="margin-top: -20px"
          >
            <CustomerForm
              v-if="openCustomerModal"
              :customer="tempContract.the_customer"
              :disableCustomerMainData="$route.name != 'new' ? true : false"
              :customerForm="customerForm"
              ref="customerForm"
            />

            <aCol
              v-if="
                $route.name == 'new' && tempContract.customer_id == undefined
              "
              class="a-center mt-20"
              :span="24"
            >
              <a-button
                icon="user"
                type="primary"
                :loading="loadingSubmitCustomer"
                html-type="submit"
              >
                Cadastrar cliente
              </a-button>
            </aCol>
          </aRow>
        </a-form>

        <aRow>
          <aCol
            v-if="$route.name == 'new' && tempContract.customer_id"
            class="a-center mt-20"
            :span="24"
          >
            <a-form
              :form="form"
              @onFieldsChange="updateTempContract"
              @submit="submitContract"
            >
              <a-button
                id="create-contract"
                class="a-center"
                size="large"
                type="primary"
                html-type="submit"
                :loading="systemLoadings.updateContractLoading"
                :disabled="systemLoadings.updateContractLoading"
              >
                CRIAR CONTRATO
              </a-button>
            </a-form>
          </aCol>
        </aRow>
      </aModal>

      <a-drawer
        placement="right"
        :closable="true"
        :width="$root.windowWidth > 960 ? '800px' : '100%'"
        :visible="openMarketingModal"
        @close="onCloseMarketingDrawer"
      >
        <template #title> MARKETING </template>
        <ContractMarketingModal
          v-if="openMarketingModal"
          :total="allContractData.total"
          :form="form"
          :contract="contract"
          :tempContract="tempContract"
        />
      </a-drawer>

      <a-drawer
        class="product-supplier-modal"
        placement="right"
        :closable="true"
        :width="$root.windowWidth > 960 ? '1024px' : '100%'"
        :visible="openContractFinancesModal"
        @close="onCloseContractFinancesModal"
      >
        <template #title> FINANCEIRO </template>

        <ContractFinancesModal
          v-if="openContractFinancesModal"
          :form="form"
          :contract="contract"
          :tempContract="tempContract"
        />
      </a-drawer>

      <aModal
        v-model="openCreateCustomer"
        :footer="false"
        @cancel="openCreateCustomer = false"
        :width="340"
      >
        <template slot="title">
          <aIcon type="user" class="mr-5 cprimary" /> CADASTRAR CLIENTE
        </template>

        <CustomerBasicForm
          v-if="openCreateCustomer"
          @onCreateCustomer="onCreateCustomer"
        />
      </aModal>

      <aModal
        class="miles-request-modal"
        v-model="openMilesRequestModal"
        :footer="false"
        :width="$root.windowWidth > 700 ? '940px' : '100%'"
        @cancel="onCloseMilesRequestModal"
      >
        <template slot="title">
          <a-icon
            type="credit-card"
            style="
              top: 3px;
              margin-right: 6px;
              font-size: 20px;
              font-size: 20px;
              color: #00aa21;
              position: relative;
            "
          />
          PEDIDOS DE MILHAS
          <span
            v-if="milesCardOrder != undefined"
            class="upper"
            style="font-size: 11px; font-weight: 600"
          >
          </span>
        </template>

        <RequestMiles
          v-if="openMilesRequestModal"
          :form="form"
          :showActions="true"
          :allowMilesRequest="requestMiles().show"
          :tempContract="tempContract"
          :milesCardOrder="milesCardOrder"
          :milesRequestList="airlinesList"
          :show="openMilesRequestModal"
          @updateTempContract="onCloseMilesRequestModal"
          @closeRequestModal="onCloseMilesRequestModal"
        />
      </aModal>

      <a-drawer
        placement="right"
        :width="$root.windowWidth > 900 ? '900px' : '100%'"
        class="travel-drawer"
        :closable="true"
        :visible="showMilesTaxesPayments"
        @close="onCloseTaxesPayment"
      >
        <template slot="title"> TAXAS </template>
        <VoucherPayments
          v-if="showMilesTaxesPayments"
          :form="form"
          :tempContract="tempContract"
          :milesTaxesPayments="milesTaxesPayments()"
          @updateFlightValue="calcFlightTotalValue(1)"
        />
      </a-drawer>

      <a-drawer
        placement="right"
        :closable="true"
        :width="$root.windowWidth > 960 ? '960px' : '100%'"
        :visible="openFilesModal"
        @close="onCloseFilesModal"
      >
        <template slot="title"> ARQUIVOS </template>

        <ContractDocumentsTab
          v-if="openFilesModal"
          :tempContract="tempContract"
        />
      </a-drawer>

      <a-drawer
        placement="right"
        :closable="true"
        :width="$root.windowWidth > 960 ? '1060px' : '100%'"
        :visible="travellers.drawer"
        @close="onCloseTravellersDrawer"
      >
        <template slot="title"> PASSAGEIROS </template>
        <Travellers
          v-if="travellers.drawer"
          :form="form"
          :customerForm="customerForm"
          :data="tempContract"
        />
      </a-drawer>

      <a-drawer
        class="travel-drawer"
        placement="right"
        width="740px"
        :closable="true"
        :visible="showNotes"
        @close="onCloseNotesDrawer"
      >
        <template slot="title"> ANOTAÇÕES </template>

        <Notes
          v-if="showNotes"
          listType="multi"
          moduleName="contract"
          :entry="contract"
          :noteReasonsCategoryid="21"
          :moduleId="`${contract.id}`"
          :multiIds="[
            {
              module: 'opportunity',
              id: contract.opportunity_id,
            },
            {
              module: 'contract',
              id: contract.id,
            },
            {
              module: 'sale',
              id: contract.sale_id,
            },
          ]"
        />
      </a-drawer>

      <a-drawer
        placement="right"
        :width="$root.windowWidth > 900 ? '900px' : '100%'"
        class="travel-drawer"
        :closable="true"
        :visible="paymentVisible"
        @close="closePaymentMethodsModal"
      >
        <template slot="title"> RECEBIMENTO </template>
        <PaymentMethodsModal
          v-if="paymentVisible"
          :contract="contract"
          :tempContract="tempContract"
          :form="form"
          :customerForm="customerForm"
          :reservation_total_price="allContractData.total"
          :travellers="theTravellers()"
          ref="verifyTotal"
          @onPriceChange="onPriceChange"
        />
      </a-drawer>

      <aModal
        :visible="openDocumentsModal"
        :closable="!systemLoadings.generateContractLoading"
        :footer="false"
        :width="800"
        @ok="updateContract('generate-contract')"
        @cancel="openDocumentsModal = false"
      >
        <template #title> FINALIZAÇÃO DO CONTRATO </template>
        <GenerateContractModal
          v-if="openDocumentsModal"
          :tempContract="tempContract"
          :form="form"
          :generateContractLoading="systemLoadings.generateContractLoading"
          ref="GenerateContractModal"
          @onClickGenerateContract="onClickGenerateContract"
          @emitUpdateContract="updateContract('generate-contract')"
        />
      </aModal>
    </aForm>

    <aButton
      v-if="$store.state.userData.id == 1"
      id="live-debug-inline"
      class="dev-btn mb-20 none"
      @click="devLogs"
      ><a-icon type="code-sandbox" />
    </aButton>
  </section>
</template>

<script>
import _ from "lodash";
import formatThings from "@/mixins/general/formatThings";
import contractMixins from "@/components/contracts/mixins/contractMixins.js";
import customerMixins from "@/components/customers/mixins/customerMixins.js";
import contractData from "@/components/contracts/mixins/contractData.js";

import contractFieldsValidation from "@/components/contracts/mixins/contractFieldsValidation.js";
import contractEmailData from "@/components/contracts/mixins/contractEmailData.js";

import ContractDocumentsTab from "@/components/contracts/tabs/ContractDocumentsTab.vue";
import ContractMarketingModal from "@/components/contracts/modals/ContractMarketingModal.vue";
import PaymentMethodsModal from "@/components/contracts/modals/PaymentMethodsModal.vue";
import Travellers from "@/components/contracts/modals/TravellersModal.vue";
import ContractFinancesModal from "@/components/contracts/modals/ContractFinancesModal.vue";
import RequestMiles from "@/components/contracts/modals/RequestMilesModal.vue";
import VoucherPayments from "@/components/contracts/modals/VoucherPaymentsModal.vue";

import ContractPageContent from "@/components/contracts/sections/ContractPageContent.vue";
import ContractInfosSection from "@/components/contracts/sections/ContractInfosSection.vue";
import ContractCustomerSection from "@/components/contracts/sections/ContractCustomerSection.vue";
import ContractSideMenuSection from "@/components/contracts/sections/ContractSideMenuSection.vue";

import Notes from "@/components/general/Notes.vue";
import CustomerForm from "@/components/customers/forms/CustomerForm.vue";
import CustomerBasicForm from "@/components/customers/forms/CustomerBasicForm.vue";
import GenerateContractModal from "@/components/contracts/modals/GenerateContractModal.vue";

export default {
  name: "ContractPage",
  components: {
    ContractPageContent,
    ContractFinancesModal,
    ContractInfosSection,
    ContractCustomerSection,
    ContractSideMenuSection,
    ContractMarketingModal,
    ContractDocumentsTab,
    Notes,
    Travellers,
    RequestMiles,
    CustomerForm,
    PaymentMethodsModal,
    VoucherPayments,
    GenerateContractModal,
    CustomerBasicForm,
  },
  mixins: [
    contractData,
    formatThings,
    customerMixins,
    contractFieldsValidation,
    contractMixins,
    contractEmailData,
  ],
  data() {
    return {
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempContract,
      }),
      customerForm: this.$form.createForm(this),
      tempContract: {},
      contract: {},
      activeTab: "",
      requestMilesData: {},
      sendEmailToCustomer: true,
      airlinesList: [],
      openFilesModal: false,
      contractedServices: [],
      customerOriginList: [],
      showMilesTaxesPayments: false,
      openCustomerModal: false,
      openContractFinancesModal: false,
      openMarketingModal: false,
      showNotes: false,
      showProducts: false,
      searchTerm: "",
      loading: false,
      openCreateCustomer: false,
      customerWhoReferred: "",
      customersWhoReferredList: [],
      openDocumentsModal: false,
      openMilesRequestModal: false,
      loadingSubmitCustomer: false,
      generateContractIsBlocked: true,
      verifyTotal: false,
      paymentVisible: false,
      systemLoadings: {
        generateContractLoading: false,
        updateContractLoading: false,
      },
      travellers: {
        drawer: false,
      },
    };
  },

  beforeUpdate() {
    this.activeTab = this.tempContract.active_tab;
  },
  beforeMount() {
    this.$http
      .get(`/category-entry/list?page=1&category_id=24&per_page=300`)
      .then(({ data }) => {
        this.customerOriginList = data.data;
      });
  },
  mounted() {
    setInterval(() => {
      this.allContractUpdates();
    }, 10000);

    setTimeout(() => {
      if (this.tempContract.status != "concluded")
        this.generateMilesFinancialData();

      if (this.tempContract.sale_id == 0) this.getSaleByContractId();
    }, 2000);
  },
  computed: {
    routeTab() {
      return this.$route.name == "new"
        ? {
            title: `Novo Contrato`,
            tips: "",
          }
        : {
            title: `Contrato ${this.contract.id ? this.contract.id : ""}`,
            tips: "",
          };
    },
  },
  methods: {
    updateTempContract(props, fields) {
      let field = Object.keys(fields)[0],
        value = Object.values(fields)[0];

      this.tempContract[field] = value;

      if (Array.isArray(value))
        this.tempContract[field] = JSON.stringify(value);

      this.tempContract["reservation_total_price"] = this.allContractData.total;
      this.tempContract["value"] = this.allContractData.total;
      this.tempContract = { ...this.tempContract };
    },
    updateTempContractFromChild(newContract) {
      this.tempContract = newContract;
      this.contractedServices = newContract.contracted_services
        ? JSON.parse(newContract.contracted_services)
        : [];
    },
    allContractUpdates() {
      /* This updates all contract data like: Email, Validations and Pricing Data */
      setTimeout(() => {
        this.contractEmail();
        this.validateFields();
        this.contractPricingData(this.tempContract);
        this.allowGenerateContract();
      }, 100);
    },
    searchCustomers() {
      setTimeout(() => {
        this.getCustomers();
      }, 500);
    },
    searchCustomersWhoReferred: _.debounce(function () {
      setTimeout(() => {
        this.$http
          .get(
            `/customer/list?page=1&per_page=150&customer_name=${this.customerWhoReferred}&have_cpf=true&order=desc&order-by=created`
          )
          .then(({ data }) => {
            this.customersWhoReferredList = data.data;
          });
      }, 1000);
    }, 600),
    onCustomerWhoReferredSelected(customerId, obj) {
      this.form.setFieldsValue({
        [`customer_who_referred_id`]: customerId,
        [`customer_who_referred_name`]: `${obj.data.attrs.txt}`,
      });

      this.tempContract.customer_who_referred_name = `${obj.data.attrs.txt}`;
    },
    onCreateCustomer(data) {
      this.form.setFieldsValue({
        [`customer_who_referred_id`]: data.id,
        [`customer_who_referred_name`]: `${data.id} - ${data.first_name} ${data.last_name}`,
      });

      this.tempContract.customer_who_referred_name = `${data.id} - ${data.first_name} ${data.last_name}`;
      this.customerWhoReferred = `${data.id} - ${data.first_name} ${data.last_name}`;
      this.openCreateCustomer = false;
    },
    allowGenerateContract() {
      let contractedServices = this.tempContract["contracted_services"]
        ? JSON.parse(this.tempContract["contracted_services"])
        : [];

      if (
        contractedServices.includes("hotel") ||
        contractedServices.includes("service") ||
        contractedServices.includes("flight")
      ) {
        if (this.validateAllContractFields.allFieldsFilled == true) {
          if (
            this.allContractData.total ==
            this.allContractData.paymentMethods.total
          ) {
            if (this.requestMiles().haveMiles) {
              if (
                this.checkIfAllMilesOrdersIsConcluded() &&
                this.milesCardOrdersIsConcluded
              ) {
                this.generateContractIsBlocked = false;
              } else {
                setTimeout(() => {
                  this.generateContractIsBlocked = true;
                }, 1);
              }
            } else {
              this.generateContractIsBlocked = false;
            }
            // this.generateContractIsBlocked = false;
          } else {
            setTimeout(() => {
              this.generateContractIsBlocked = true;
            }, 1);
          }
          this.generateContractIsBlocked = false;
        } else {
          this.generateContractIsBlocked = true;
        }
      }
    },
    onClickOpenCustomerModal() {
      this.openCustomerModal = true;
    },
    onCloseContractFinancesModal() {
      this.openContractFinancesModal = false;
      this.allContractUpdates();
    },
    onClickShowProducts() {
      if (this.showProducts) {
        this.showProducts = false;
      } else {
        this.showProducts = true;
        setTimeout(() => {
          this.form.setFieldsValue({
            [`package_type`]: this.tempContract.package_type
              ? this.tempContract.package_type
              : undefined,
            [`customer_origin`]: this.tempContract.customer_origin
              ? this.tempContract.customer_origin
              : undefined,
            [`customer_who_referred_id`]: this.tempContract
              .customer_who_referred_id
              ? this.tempContract.customer_who_referred_id
              : undefined,
            [`contracted_services`]: this.tempContract.contracted_services
              ? JSON.parse(this.tempContract.contracted_services)
              : undefined,
          });

          this.customerWhoReferred =
            this.tempContract.customer_who_referred_name;
        }, 100);
      }

      this.allContractUpdates;
    },
    generateMilesFinancialData() {
      setTimeout(() => {
        if (this.tempContract[`flight_sections_rows`] != undefined) {
          JSON.parse(this.tempContract[`flight_sections_rows`]).forEach(
            (flight) => {
              let allSuppliers = "",
                allMilesOrdersIds = "",
                totalProfit = 0,
                totalSale = 0,
                totalMiles = 0;

              if (flight.id) {
                this.$http
                  .get(
                    `/miles-card-order/list?page=1&per_page=20&status=Concluído&contract_id=${this.tempContract.id}&flight_id=${flight.id}&order=desc&order-by=created`
                  )
                  .then(({ data }) => {
                    data.data.forEach((milesOrder) => {
                      allSuppliers += `${milesOrder.miles_card.supplier.first_name} ${milesOrder.miles_card.supplier.last_name} (ID ${milesOrder.miles_card.supplier.id}), `;
                      allMilesOrdersIds += `${milesOrder.id}, `;

                      totalProfit += parseFloat(milesOrder.raw.profit);
                      totalSale += parseFloat(milesOrder.raw.sale);
                      totalMiles += parseInt(milesOrder.raw.miles_quantity);

                      this.tempContract[
                        `contract_finances_flight_${milesOrder.raw.flight_id}_miles`
                      ] = totalMiles;

                      this.tempContract[
                        `contract_finances_flight_${milesOrder.raw.flight_id}_sale_value`
                      ] = totalSale;

                      totalProfit;

                      this.tempContract[
                        `contract_finances_flight_${milesOrder.raw.flight_id}_suppliers`
                      ] = allSuppliers.slice(0, -2);

                      this.tempContract[
                        `contract_finances_flight_${milesOrder.raw.flight_id}_miles_order_ids`
                      ] = allMilesOrdersIds.slice(0, -2);

                      this.form.setFieldsValue({
                        [`contract_finances_flight_${milesOrder.raw.flight_id}_miles_order_ids`]:
                          allMilesOrdersIds.slice(0, -2),
                      });
                    });
                  });
              }
            }
          );
        }

        setTimeout(() => {
          this.updateTempContract;
        }, 200);
      }, 2000);
    },
    onCloseTaxesPayment() {
      this.showMilesTaxesPayments = false;
      this.generateMilesFinancialData();
      this.allContractUpdates();
    },
    onCloseMilesRequestModal() {
      this.openMilesRequestModal = false;
      this.generateMilesFinancialData();
      this.allContractUpdates();
    },
    onCloseFilesModal() {
      this.openFilesModal = false;
      this.allContractUpdates();
    },
    onCloseTravellersDrawer() {
      this.travellers.drawer = false;
      this.submitContract;
      this.allContractUpdates();
    },
    onCloseNotesDrawer() {
      this.showNotes = false;
      this.allContractUpdates();
    },
    onCloseMarketingDrawer() {
      this.openMarketingModal = false;
      this.allContractUpdates();
    },
    closePaymentMethodsModal() {
      this.paymentVisible = false;
      this.allContractUpdates();
    },
    submitCustomer() {
      this.customerForm.validateFields((err, customer) => {
        if (!err) {
          this.loadingSubmitCustomer = true;
          customer.action = "create-customer";
          customer.avatar = "";
          customer.status = 1;
          customer.user_id = this.$store.state.userData.id;
          customer.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          customer = { ...customer, ...this.tempContract.the_customer };

          this.$http
            .post("/customer/create", customer)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.tempContract.customer_id = data.id;

              this.tempContract.the_customer = {
                ...this.tempContract.the_customer,
                ...customer,
              };
              setTimeout(() => {
                this.createContract();
              }, 50);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingSubmitCustomer = false;
            });
        }
      });
    },
    openWhatsapp(number) {
      let theNumber = number
        .replace(" ", "")
        .replace(" ", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "");
      window.open(
        `https://api.whatsapp.com/send?phone=55${theNumber}&text=`,
        "_blank"
      );

      this.$http.post("/log/create", {
        user_id: this.$store.state.userData.id,
        module_id: this.tempContract.id,
        module: "contract",
        action: "open-whatsapp",
        description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} clicou no botão <b>Whatsapp</b> para contato com o cliente.`,
      });
    },
    validatePolicyFields(data) {
      this.policy.list = data;
    },
    requestMiles() {
      let flag = false,
        airlineFlag = false,
        totalMiles = 0,
        haveMiles = false;

      if (this.tempContract.flight_sections_rows != undefined) {
        JSON.parse(this.tempContract.flight_sections_rows).forEach((flight) => {
          if (this.tempContract[`flight_${flight.id}_type`] == "Milhas") {
            haveMiles = true;

            flight.airlines.forEach((airline) => {
              let airlineTravellers = [];

              if (
                (this.tempContract[
                  `flight_${flight.id}_airline_${airline.id}_travellers`
                ] &&
                  this.tempContract[
                    `flight_${flight.id}_airline_${airline.id}_miles_program`
                  ]) ||
                (this.form.getFieldValue(
                  `flight_${flight.id}_airline_${airline.id}_miles_program`
                ) &&
                  this.form.getFieldValue(
                    `flight_${flight.id}_airline_${airline.id}_travellers`
                  ))
              ) {
                airlineTravellers = JSON.parse(
                  this.tempContract[
                    `flight_${flight.id}_airline_${airline.id}_travellers`
                  ]
                );

                if (airlineTravellers.length > 0) airlineFlag = true;
              } else {
                airlineFlag = false;
              }
            });

            // FLIGHT TRAVELLERS ROWS
            flight.travellers.forEach((traveller) => {
              if (
                (this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}_miles`
                ] &&
                  this.tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_value`
                  ] &&
                  this.tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_taxes`
                  ] &&
                  this.tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_taxes`
                  ] != "0,00" &&
                  this.tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                  ]) ||
                (this.form.getFieldValue(
                  `flight_${flight.id}_traveller_${traveller.id}_miles`
                ) &&
                  this.form.getFieldValue(
                    `flight_${flight.id}_traveller_${traveller.id}_value`
                  ) &&
                  this.form.getFieldValue(
                    `flight_${flight.id}_traveller_${traveller.id}_taxes`
                  ) &&
                  this.form.getFieldValue(
                    `flight_${flight.id}_traveller_${traveller.id}_taxes`
                  ) != "0,00" &&
                  this.form.getFieldValue(
                    `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                  ))
              ) {
                totalMiles += this.convertToInt(
                  this.tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_miles`
                  ]
                );
                flag = true;
              } else {
                totalMiles += 0;
                flag = false;
              }
            });
          }
        });
      }

      this.tempContract.request_miles_status = flag;

      this.requestMilesData = {
        show: flag && airlineFlag,
        miles: totalMiles,
        haveMiles,
      };

      return { show: flag && airlineFlag, miles: totalMiles, haveMiles };
    },
    showPayment() {
      this.paymentVisible = true;
    },
    onPriceChange(total) {
      setTimeout(() => {
        this.verifyTotal = total;
      }, 100);
    },
    updateCustomerData() {
      if (this.$route.name == "new") {
        this.openCustomerModal = true;
      } else {
        this.customerForm.validateFields(
          (customerFormErrors, customerFormVals) => {
            if (!customerFormErrors) {
              this.tempContract.the_customer = {
                ...this.tempContract.the_customer,
                ...customerFormVals,
              };
              this.customers.filters.searchTerm = undefined;
              this.openCustomerModal = false;
            } else {
              this.$message.warning("Alguns campos não foram preenchidos.");
            }
          }
        );

        this.allContractUpdates();
      }
    },
    milesTaxesPayments() {
      let flag = false,
        totalTaxes = parseFloat(0),
        totalTaxesWithCurrency = parseFloat(0),
        currency = "BRL";

      if (this.tempContract["flight_sections_rows"] != undefined) {
        JSON.parse(this.tempContract["flight_sections_rows"]).forEach(
          (flight) => {
            if (this.tempContract[`flight_${flight.id}_type`] == "Milhas") {
              flight.travellers.forEach((traveller) => {
                let taxCurrency =
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                    ],
                  taxes =
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes`
                    ];

                if (taxCurrency != undefined) currency = taxCurrency;

                if (this.tempContract[`flight_${flight.id}_type`] == "Milhas") {
                  if (currency == "BRL") {
                    if (taxes) {
                      taxes = taxes.replace(",", ".");

                      if (isNaN(taxes)) {
                        totalTaxesWithCurrency += parseFloat(0);
                      } else {
                        totalTaxesWithCurrency += parseFloat(taxes);
                      }
                    } else {
                      totalTaxesWithCurrency += parseFloat(0);
                    }
                  } else {
                    if (taxes) {
                      taxes = taxes.replace(",", ".");
                      if (isNaN(taxes)) {
                        totalTaxesWithCurrency += parseFloat(0);
                      } else {
                        totalTaxesWithCurrency += parseFloat(taxes);
                      }
                    } else {
                      totalTaxesWithCurrency += parseFloat(0);
                    }
                  }
                } else {
                  if (taxes) {
                    taxes = taxes.replace(",", ".");
                    if (isNaN(taxes)) {
                      totalTaxes += parseFloat(0);
                    } else {
                      totalTaxes += parseFloat(taxes);
                    }
                  } else {
                    totalTaxes += parseFloat(0);
                  }
                }

                if (taxCurrency && taxes && taxCurrency != "" && taxes != "")
                  flag = true;
              });
            }
          }
        );
      }

      return {
        show: flag,
        totalTaxes: totalTaxes,
        totalTaxesWithCurrency,
        currency: currency,
      };
    },
    theTravellers() {
      let travellers = [],
        travellersList = [];

      if (this.tempContract[`travellers_rows`] != undefined)
        travellers = JSON.parse(this.tempContract[`travellers_rows`]);

      travellers.forEach((traveller) => {
        let name = "",
          lastName = "";

        if (
          this.tempContract[`traveller_${traveller.id}_first_name`] !=
            undefined &&
          this.tempContract[`traveller_${traveller.id}_last_name`] != undefined
        ) {
          name = this.tempContract[`traveller_${traveller.id}_first_name`];
          lastName = this.tempContract[`traveller_${traveller.id}_last_name`];
        }

        if (name != undefined && lastName != undefined)
          travellersList.push({
            id: traveller.id,
            name: name.toUpperCase(),
            lastName: lastName.toUpperCase(),
          });
      });

      return travellersList;
    },
    devLogs() {
      console.log("devLogs: Contrato", this.generateContractIsBlocked);
      console.log("devLogs: allContractData", this.allContractData);
      console.log("devLogs: Validate Fields", this.generateContractIsBlocked);
      console.log(
        "devLogs: validateAllContractFields",
        JSON.stringify(this.validateAllContractFields)
      );
      console.log(
        "devLogs: tempContract",
        JSON.stringify(this.tempContract, null, 4)
      );
      console.log(this.activeSection);
      console.log(this.validateContractData);
    },
  },
};
</script>

<style lang="sass">
.product-supplier-modal
  .ant-tabs-bar
    margin-bottom: 5px !important
  .ant-tabs-tab
    color: #aaa !important
    filter: grayscale(1)
    padding: 6px 16px
  .ant-tabs-tab-active
    filter: grayscale(0) !important
    color: #777 !important
  .ant-tabs-nav-wrap
    padding: 0 25px
  .ant-drawer-header
    border: 0 !important
  .ant-drawer-body
    padding: 0 !important

.miles-alert
  position: relative
  padding: 10px 10px 10px 60px !important
  background: #f3c7cc
  border-color: #f57383
  .ico
    position: absolute
    top: 8px
    left: 10px
  .ant-alert-message
    font-size: 14px
    font-weight: 500
    margin: 0 0 0px
    color: #df213a
  .ant-alert-description
    font-size: 11px
    margin: 0
    font-weight: 500
    line-height: 1.4
    color: #df213a

.contract-page
  background: #f1f1f2
  .tab-content
    h2.title
      display: block
      border-bottom: 1px solid #efefef
      font-size: 14px
      font-weight: 600
      padding: 5px 8px
      margin-bottom: 20px !important
      letter-spacing: -0.5px
    h3.title
      display: block
      border-bottom: 1px solid #efefef
      font-size: 14px
      font-weight: 600
      padding: 5px 8px
      margin-bottom: 20px !important
    .ant-form-item
        height: 54px !important
        margin-bottom: 0 !important
    label
      position: absolute
      z-index: 4
      font-size: 10px
      font-weight: 500
      color: #ccc
      transition: .3s
      opacity: 0
      top: 13px
      left: 0
      line-height: 1
      overflow: hidden
      text-overflow: ellipsis
      width: 100%
      white-space: nowrap
    label.filled
      top: -16px
      opacity: 1
    .add-button
      font-size: 11px
      font-weight: 600
      color: #bbb
      border-color: #ddd
      padding: 2px 5px !important
      line-height: 4px
      height: 22px
      top: -3px
      position: relative
    .add-button.color
      border-color: #be4178
      color: #be4178
    .remove-button
      font-size: 11px
      font-weight: 600
      color: red
      border-color: red
      padding: 2px 5px !important
      line-height: 4px
      height: 20px
      top: -3px
      position: relative
</style>

<style lang="sass" scoped>
.loading
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(0, 0, 0, 0.4901960784)
  z-index: 99999
  color: #FFF
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  align-content: center
  flex-wrap: wrap

@media (min-width: 0px) and (max-width: 1500px)
.contract-page
  .meta-wrapper
    .data-panels
      img
        width: 15px
      h3
        font-size: 12px !important
      .value
        font-size: 14px !important

.button-green
  background: #2ecc71
  border-color: #2ecc71
  font-weight: 600
  & [disabled]
    pointer-events: none
    background: #f6f6f6
    opacity: 0.7
    border-color: #bbb !important

.contract-page
  .content
    flex: 1 1 !important
  .package-type
    .ant-radio-wrapper
      color: #444
      font-weight: 600
      font-size: 12px
      display: block  !important
      .description
        font-weight: 400
        color: #aaa

  .meta-wrapper
    .data-panels
      .w-box
        padding: 20px 15px !important
      h3
        color: #bcbec0
        font-size: 16px
        font-weight: 600
        margin-bottom: 0
      .value
        color: #59595b
        text-align: right
        font-size: 22px
        letter-spacing: -1px
        font-weight: 700
  .meta
    font-size: 10px
    line-height: 8px
    font-weight: 600
    text-transform: none
    padding-top: 10px
    border-top: 1px solid #eee
    margin-top: 4px
    .user
        color: #444
        max-width: 100px
        display: inline-block
  .services
    font-size: 12px
    font-weight: 500
    display: inline-block
    float: left
    height: 68px
    padding: 7px 0 0
    margin: -17px 0 15px 0
    border: 1px dashed #ddd
    border-width: 1px 0
    .label
      float: left !important
      font-size: 11px
      font-weight: 600
      margin-right: 10px
    .ant-checkbox-group
      display: inline-block
      width: auto !important
    label
      float: left !important
      font-size: 12px
      font-weight: 500
      margin: 5px !important
  .actions
      button
          padding-top: 15px
          padding-bottom: 15px
          height: 59px
          font-size: 20px
  .customer-box
      .row
          padding-left: 0
          padding-right: 0

  .button-green
    background: #2ecc71
    border-color: #2ecc71
    font-weight: 600
  & [disabled]
    pointer-events: none
    background: #f6f6f6
    opacity: 0.7
    border-color: #bbb !important

  .row
      padding: 5px 10px
      font-size: 13px
      font-weight: 500
      width: 314px
      transition: .3s
      max-width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      .ant-tag
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          display: inline-block
          max-width: 100% !important
          float: left
          text-transform: uppercase
          font-size: 10px
          font-weight: 600
          margin-bottom: 6px !important
      &.hover:hover
          background: rgba(0,0,0,0.02)
          color: #ff8000
          .img
              -webkit-filter: grayscale(0)
              -webkit-filter: grayscale(0%)
      &.border
          border-bottom: 1px solid #d6d6d6
          padding: 10px 5px
      &.no-border
          border: 0
          padding: 10px  5px
      &.bold
          font-weight: 600
      .img
          -webkit-filter: grayscale(1)
          -webkit-filter: grayscale(100%)
          width: 24px
          display: inline-block
          text-align: center

  h1
      margin: 0
      padding: 0
      font-weight: 600
      letter-spacing: -2px
      .view
          color: #aaa
          font-size: 24px
      img
          margin-right: 5px
  h2
      font-size: 14px
      font-weight: 400
      border-bottom: 1px solid #ddd
      padding: 0 0 10px 0 !important
      color: #59595b !important

.search-customers
  background: #eee !important
  padding: 10px 20px
  background: #eee
  margin: -24px -24px 20px
</style>
