<template>
  <div>
    <div
      v-for="(section, index) in sectionsRows"
      :key="index"
      :class="`section section-${section.id}`"
    >
      <div class="product-card" @click="onClickOpenCollapse(section.id)">
        <aRow>
          <aCol :span="22">
            <aRow type="flex" justify="start" :gutter="20">
              <aCol class="id cprimary">
                <span class="ico">
                  <i class="fas fa-plane-departure"></i>
                  <span class="num">{{ section.id }} </span>
                </span>
              </aCol>
              <aCol class="id-name"
                ><div v-html="theSection(section.id)"></div
              ></aCol>
            </aRow>
          </aCol>
          <aCol class="a-right" :span="2">
            <a-button
              v-if="section.id == sectionsRows.length && section.id != 1"
              size="small"
              type="danger"
              shape="circle"
              icon="delete"
              @click="removeSection(flightSectionId, section.id)"
              ghost
            />
          </aCol>
        </aRow>
      </div>

      <div class="product-card-content" v-if="openCollapse == section.id">
        <aRow :gutter="[20, 0]">
          <aCol :span="24">
            <a-form-item class="section-type">
              <a-radio-group
                :options="[
                  { label: 'Ida', value: 'Ida' },
                  { label: 'Volta', value: 'Volta' },
                  {
                    label: 'Conexão',
                    value: 'Conexão',
                  },
                ]"
                v-decorator="[
                  `flight_${flightSectionId}_section_${section.id}_type`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </aCol>

          <aCol :span="4">
            <a-form-item>
              <label :class="'filled'">Origem</label>
              <a-auto-complete
                placeholder="Insira"
                option-label-prop="value"
                :dropdownMenuStyle="{ width: '300px' }"
                v-decorator="[
                  `flight_${flightSectionId}_section_${section.id}_origin`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                allowClear
                @change="searchOriginAiports"
              >
                <template slot="dataSource">
                  <a-select-option
                    class="dropdown-airports"
                    v-for="(airport, index) in originAirportsList"
                    :key="index"
                    :value="airport.name"
                  >
                    <div style="width: 500px">
                      {{ airport.name }}
                      <a-divider type="vertical" />
                      {{ airport.meta.airport_name }}
                    </div>
                  </a-select-option>
                </template>
                <a-input
                  maxLength="3"
                  class="travel-input"
                  placeholder="Origem"
                >
                  <a-icon
                    slot="prefix"
                    :type="loadingOriginAirports ? 'loading' : 'field-svg'"
                  />
                </a-input>
              </a-auto-complete>
            </a-form-item>

            <a-form-item>
              <label :class="'filled'">Destino</label>

              <a-auto-complete
                placeholder="Insira"
                option-label-prop="value"
                :dropdownMenuStyle="{ width: '300px' }"
                v-decorator="[
                  `flight_${flightSectionId}_section_${section.id}_destination`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                allowClear
                @change="searchDestinationAiports"
              >
                <template slot="dataSource">
                  <a-select-option
                    class="dropdown-airports"
                    v-for="(airport, index) in destinationAirportsList"
                    :key="index"
                    :value="airport.name"
                  >
                    {{ airport.name }}
                    <a-divider type="vertical" />
                    {{ airport.meta.airport_name }}
                  </a-select-option>
                </template>
                <a-input
                  maxLength="3"
                  class="travel-input"
                  placeholder="Destino"
                >
                  <a-icon
                    slot="prefix"
                    :type="loadingDestinationAirports ? 'loading' : 'field-svg'"
                  />
                </a-input>
              </a-auto-complete>
            </a-form-item>
          </aCol>

          <aCol :span="8">
            <aRow :gutter="10">
              <aCol :span="14">
                <a-form-item>
                  <label :class="'filled'">Data de embarque</label>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Insira"
                    format="DD/MM/YYYY"
                    valueFormat="YYYY-MM-DD"
                    :showToday="false"
                    v-mask="'##/##/####'"
                    @change="calcDuration(section.id)"
                    v-decorator="[
                      `flight_${flightSectionId}_section_${section.id}_departure_date`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </aCol>
              <aCol :span="10">
                <a-form-item>
                  <label :class="'filled'">Horário embarque</label>
                  <a-input
                    class="travel-input"
                    placeholder="Insira"
                    @change="calcDuration(section.id)"
                    v-mask="'##:##'"
                    v-decorator="[
                      `flight_${flightSectionId}_section_${section.id}_departure_time`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-input>
                </a-form-item>
              </aCol>
            </aRow>

            <aRow :gutter="10">
              <aCol :span="14">
                <a-form-item>
                  <label class="filled">Data de desembarque</label>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Insira"
                    format="DD/MM/YYYY"
                    valueFormat="YYYY-MM-DD"
                    :showToday="false"
                    v-mask="'##/##/####'"
                    @change="calcDuration(section.id)"
                    v-decorator="[
                      `flight_${flightSectionId}_section_${section.id}_arrival_date`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </aCol>
              <aCol :span="10">
                <a-form-item>
                  <label :class="'filled'">Horário desembarque</label>
                  <a-input
                    class="travel-input"
                    placeholder="Insira"
                    @change="calcDuration(section.id)"
                    v-mask="'##:##'"
                    v-decorator="[
                      `flight_${flightSectionId}_section_${section.id}_arrival_time`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-input>
                </a-form-item>
              </aCol>
            </aRow>
          </aCol>

          <aCol :span="5">
            <a-form-item>
              <label :class="'filled'">Classe</label>

              <a-select
                class="travel-input"
                show-search
                placeholder="Selecione"
                v-decorator="[
                  `flight_${flightSectionId}_section_${section.id}_class`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of flightClasses"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name.toUpperCase() }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="travel-input">
              <label :class="'filled'">Duração do vôo</label>
              <a-input
                class="readonly"
                placeholder="Insira"
                v-mask="'##:##'"
                v-decorator="[
                  `flight_${flightSectionId}_section_${section.id}_duration`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Modifique as datas',
                      },
                    ],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </aCol>
          <aCol :span="7">
            <a-form-item>
              <label :class="'filled'">Companhia aérea</label>

              <a-select
                class="travel-input"
                style="width: 100%"
                placeholder="Selecione"
                show-search
                :allow-clear="true"
                v-decorator="[
                  `flight_${flightSectionId}_section_${section.id}_airline`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="airline in airlinesList"
                  :key="airline.id"
                  :value="airline.name"
                >
                  <img v-if="airline.logo" :src="airline.logo" width="13" />
                  {{ airline.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <aRow :gutter="10">
              <aCol :span="12">
                <a-form-item>
                  <label :class="'filled'">Nº do vôo</label>
                  <a-input
                    type="number"
                    class="travel-input"
                    placeholder="Insira"
                    v-decorator="[
                      `flight_${flightSectionId}_section_${section.id}_number`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-input>
                </a-form-item>
              </aCol>
            </aRow>
          </aCol>
        </aRow>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { format, intervalToDuration } from "date-fns";
import formatThings from "@/mixins/general/formatThings";

moment.locale(); // pt-br
moment.utc(-3);
moment().format("LT");

export default {
  name: "FlightSectionSectionsCollapse",
  props: {
    flightSectionId: Number,
    tempContract: Object,
    form: Object,
    sectionsRows: Array,
  },
  mixins: [formatThings],
  data() {
    return {
      openCollapse: -1,
      loadingOriginAirports: false,
      loadingDestinationAirports: false,
      originAirportsList: [],
      destiantionAirportsList: [],
      airlinesList: [],
      flightClasses: [
        { name: "ECONÔMICA", value: "ECONÔMICA" },
        { name: "ECONÔMICA PREMIUM", value: "ECONÔMICA PREMIUM" },
        { name: "EXECUTIVA", value: "EXECUTIVA" },
        { name: "FIRST CLASS", value: "FIRST CLASS" },
      ],
    };
  },
  mounted() {
    this.getAirlines();
  },
  methods: {
    getAirlines() {
      this.$http
        .get(`/airline/list?page=1&per_page=200&order=ascend&order-by=name`)
        .then(({ data }) => {
          this.airlinesList = data.data;
        });
    },
    calcDuration(id) {
      setTimeout(() => {
        let departureDate =
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_departure_date`
          ];

        if (typeof departureDate == "string") {
          departureDate = departureDate.split("T")[0];
        }

        if (typeof departureDate == "object") {
          departureDate = format(departureDate._d, "yyyy-MM-dd");
        }

        let departureTime =
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_departure_time`
          ];

        let arrivalDate =
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_arrival_date`
          ];

        if (typeof arrivalDate == "string") {
          arrivalDate = arrivalDate.split("T")[0];
        }

        if (typeof arrivalDate == "object") {
          arrivalDate = format(arrivalDate._d, "yyyy-MM-dd");
        }

        let arrivalTime =
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_arrival_time`
          ];

        if (departureDate && departureTime && arrivalDate && arrivalTime) {
          let interval = intervalToDuration({
            start: new Date(departureDate + " " + departureTime),
            end: new Date(arrivalDate + " " + arrivalTime),
          });

          this.form.setFieldsValue({
            [`flight_${this.flightSectionId}_section_${id}_duration`]: `${this.formatDateTimeZeros(
              interval.hours
            )}:${this.formatDateTimeZeros(interval.minutes)}`,
          });
        }
      }, 20);
    },
    getOriginAiports(airport) {
      this.loadingOriginAirports = true;
      this.$http
        .get(
          `/category-entry/list?page=1&category_id=30&per_page=100&s=${airport}`
        )
        .then(({ data }) => {
          this.originAirportsList = data.data;
        })
        .finally(() => (this.loadingOriginAirports = false));
    },
    searchOriginAiports: _.debounce(function (e) {
      if (e?.length > 1) this.getOriginAiports(e);
    }, 600),
    getDestinationAiports(airport) {
      this.loadingDestinationAirports = true;
      this.$http
        .get(
          `/category-entry/list?page=1&category_id=30&per_page=100&s=${airport}`
        )
        .then(({ data }) => {
          this.destinationAirportsList = data.data;
        })
        .finally(() => (this.loadingDestinationAirports = false));
    },
    searchDestinationAiports: _.debounce(function (e) {
      if (e?.length > 1) this.getDestinationAiports(e);
    }, 600),
    theSection(sectionId) {
      let flightType =
        this.tempContract[
          `flight_${this.flightSectionId}_section_${sectionId}_type`
        ] != undefined
          ? "(" +
            this.tempContract[
              `flight_${this.flightSectionId}_section_${sectionId}_type`
            ] +
            ")"
          : "";
      let theSection = `${
        this.tempContract[
          `flight_${this.flightSectionId}_section_${sectionId}_origin`
        ] != undefined
          ? this.tempContract[
              `flight_${this.flightSectionId}_section_${sectionId}_origin`
            ]
          : "ORIGEM"
      } ⇒ ${
        this.tempContract[
          `flight_${this.flightSectionId}_section_${sectionId}_destination`
        ] != undefined
          ? this.tempContract[
              `flight_${this.flightSectionId}_section_${sectionId}_destination`
            ]
          : "DESTINO"
      } <font class="f8">${flightType}</font>`;
      return theSection;
    },
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;

        setTimeout(() => {
          this.setFlightSectionFields(id);
        }, 5);
      }
    },
    setFlightSectionFields(id) {
      this.form.setFieldsValue({
        [`flight_${this.flightSectionId}_section_${id}_type`]:
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_type`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_section_${id}_type`
              ]
            : undefined,

        [`flight_${this.flightSectionId}_section_${id}_origin`]:
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_origin`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_section_${id}_origin`
              ]
            : undefined,

        [`flight_${this.flightSectionId}_section_${id}_destination`]:
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_destination`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_section_${id}_destination`
              ]
            : undefined,

        [`flight_${this.flightSectionId}_section_${id}_departure_date`]:
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_departure_date`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_section_${id}_departure_date`
              ]
            : undefined,

        [`flight_${this.flightSectionId}_section_${id}_arrival_date`]:
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_arrival_date`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_section_${id}_arrival_date`
              ]
            : undefined,

        [`flight_${this.flightSectionId}_section_${id}_departure_time`]:
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_departure_time`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_section_${id}_departure_time`
              ]
            : undefined,

        [`flight_${this.flightSectionId}_section_${id}_arrival_time`]:
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_arrival_time`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_section_${id}_arrival_time`
              ]
            : undefined,

        [`flight_${this.flightSectionId}_section_${id}_class`]:
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_class`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_section_${id}_class`
              ]
            : undefined,

        [`flight_${this.flightSectionId}_section_${id}_duration`]:
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_duration`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_section_${id}_duration`
              ]
            : undefined,

        [`flight_${this.flightSectionId}_section_${id}_airline`]:
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_airline`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_section_${id}_airline`
              ]
            : undefined,

        [`flight_${this.flightSectionId}_section_${id}_number`]:
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_number`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_section_${id}_number`
              ]
            : undefined,

        [`flight_${this.flightSectionId}_section_${id}_stops`]:
          this.tempContract[
            `flight_${this.flightSectionId}_section_${id}_stops`
          ] != undefined
            ? this.tempContract[
                `flight_${this.flightSectionId}_section_${id}_stops`
              ]
            : undefined,
      });
    },
    removeSection(flightSectionId, id) {
      this.$emit("removeSection", flightSectionId, id);
    },
  },
};
</script>

<style lang="css">
.section-type label {
  position: relative !important;
  opacity: 1 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #333 !important;
  top: -10px !important;
  left: unset !important;
  width: unset !important;
}
</style>

<style lang="sass" scoped>
.product-card-content
  padding: 20px 15px 0px
  border: 1px solid #ddd
  margin-top: -14px
  border-radius: 0 0 6px 6px
  margin-bottom: 11px
  background: #FFF
  .section-type
    max-height: 40px !important
.product-card
  cursor: pointer
  clear: both
  padding: 10px 20px
  background: #fff
  border: 1px solid #ddd
  margin-bottom: 10px
  border-radius: 6px
  .id
    color: #474747
    font-weight: 600
    .ico
      position: relative
    .num
      position: absolute
      right: -13px
      top: 0
      font-size: 11px
      color: #333
  .id-name
    padding-left: 20px !important
    color: #42413f
    font-weight: 500
    .f10
      color: #ccc
</style>
