<template>
  <div class="tabs">
    <a-row :gutter="10">
      <a-col
        v-if="paymentMethods.includes('billing-ticket')"
        @click="portalActiveTab('billing-ticket')"
        :span="6"
      >
        <div class="tab" :class="activeTab == 'billing-ticket' ? 'active' : ''">
          <div>Boleto</div>
          <a-tag class="ml-10" color="blue">
            {{
              tempContract[`payment_methods_billing_ticket_total_value`]
                | formatPricePtBrComma
            }}
          </a-tag>
        </div>
      </a-col>

      <a-col
        v-if="paymentMethods.includes('payment-card')"
        @click="portalActiveTab('payment-card')"
        :span="6"
      >
        <div class="tab" :class="activeTab == 'payment-card' ? 'active' : ''">
          <div>Carta de Crédito</div>

          <a-tag class="ml-10" color="blue">
            R$
            {{ paymentCardTotal }}
          </a-tag>
        </div>
      </a-col>

      <a-col
        v-if="paymentMethods.includes('credit-card')"
        @click="portalActiveTab('credit-card')"
        :span="6"
      >
        <div class="tab" :class="activeTab == 'credit-card' ? 'active' : ''">
          <div>Cartão de Crédito</div>
          <a-tag class="ml-10" color="blue">
            R$
            {{ creditCardTotal }}
          </a-tag>
        </div>
      </a-col>

      <a-col
        v-if="paymentMethods.includes('custom-payment')"
        @click="portalActiveTab('custom-payment')"
        :span="6"
      >
        <div class="tab" :class="activeTab == 'custom-payment' ? 'active' : ''">
          <div>Personalizado</div>

          <a-tag class="ml-10" color="blue">
            {{
              this.tempContract[`payment_methods_custom_payment_total_value`]
                | formatPricePtBrComma
            }}
          </a-tag>
        </div>
      </a-col>

      <a-col
        v-if="paymentMethods.includes('bank-transfer')"
        @click="portalActiveTab('bank-transfer')"
        :span="6"
      >
        <div class="tab" :class="activeTab == 'bank-transfer' ? 'active' : ''">
          <div>Transferência bancária</div>

          <a-tag class="ml-10" color="blue">
            {{
              tempContract[`payment_methods_bank_transfer_total_value`]
                | formatPricePtBrComma
            }}
          </a-tag>
        </div>
      </a-col>

      <a-col
        v-if="paymentMethods.includes('payment-link')"
        @click="portalActiveTab('payment-link')"
        :span="6"
      >
        <div
          class="tab payment-link"
          :class="activeTab == 'payment-link' ? 'active' : ''"
        >
          <div>Link de Pagamento</div>
          <a-tag class="ml-10" color="blue"> R$ {{ paymentLinkTotal }} </a-tag>
        </div>
      </a-col>

      <a-col
        v-if="paymentMethods.includes('third-party')"
        @click="portalActiveTab('third-party')"
        :span="6"
      >
        <div class="tab" :class="activeTab == 'third-party' ? 'active' : ''">
          <div>Terceiros</div>
          <a-tag class="ml-10" color="purple">
            {{ third_party_payment_rows.length }}
          </a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";

export default {
  name: "ContractPaymentMethodsValuesPanel",
  props: {
    paymentMethods: Array,
    activeTab: String,
    tempContract: Object,
    third_party_payment_rows: Array,
    paymentCardTotal: String,
    creditCardTotal: String,
    paymentLinkTotal: String,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    portalActiveTab(active) {
      this.$emit("portalActiveTab", active);
    },
  },
};
</script>

<style lang="sass" scoped></style>
