<template>
  <section class="payments">
    <a-form-item class="options payments-options">
      <a-checkbox-group
        v-decorator="[
          'payment_methods',
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]"
        @change="onPaymentChange"
      >
        <a-checkbox
          v-for="(method, index) in paymentMethodsOptions"
          :key="index"
          class="mr-20"
          :value="method.value"
          :disabled="method.show"
        >
          {{ method.label }}
        </a-checkbox>
      </a-checkbox-group>
    </a-form-item>

    <aRow class="status-bar">
      <aCol :span="24">
        <strong>VALOR TOTAL </strong>
        <a-tag
          class="ml-10"
          :color="
            totalValue.status == false && updatePaymentMethods.length > 0
              ? 'red'
              : 'green'
          "
        >
          R$ {{ reservation_total_price | formatPrice }}
        </a-tag>

        <span
          class="equality"
          style="
            font-size: 19px;
            margin-bottom: 0;
            position: relative;
            top: 4px;
            font-weight: 500;
            color: rgb(76, 56, 141);
          "
        >
          <span v-if="totalValue.status">
            <a-icon type="swap" />
          </span>
          <span v-if="!totalValue.status">
            <a-icon type="swap-right" />
          </span>
        </span>

        <a-tag class="ml-10" color="blue">
          R$ {{ totalValue.total | formatPrice }}
        </a-tag>
      </aCol>
    </aRow>

    <a-alert
      class="mb-20"
      type="error"
      v-if="totalValue == false && updatePaymentMethods.length > 0"
      style="font-size: 11px"
      message="Os valores somados não se equivalem ao total. Verique os valores preenchidos."
      banner
    />

    <div v-if="paymentMethods != undefined">
      <div
        v-if="paymentMethods.length > 0"
        class="portal-tabs horizontal payment-tabs"
      >
        <ContractPaymentMethodsValuesPanel
          :paymentMethods="paymentMethods"
          :activeTab="activeTab"
          :tempContract="tempContract"
          :third_party_payment_rows="third_party_payment_rows"
          :paymentCardTotal="paymentCardTotal()"
          :creditCardTotal="creditCardTotal()"
          :paymentLinkTotal="paymentLinkTotal()"
          @portalActiveTab="portalActiveTab"
        />

        <div class="tabs-content">
          <BillingTicketTab
            v-if="activeTab == 'billing-ticket'"
            :contract="contract"
            :tempContract="tempContract"
            :form="form"
            :reservation_total_price="reservation_total_price"
            @verifyTotal="verifyTotal"
          />

          <CreditCardTab
            v-if="activeTab == 'credit-card'"
            :contract="contract"
            :tempContract="tempContract"
            :form="form"
            :customerForm="customerForm"
            :travellers="travellers"
            @creditCardTotal="creditCardTotal"
            @verifyTotal="verifyTotal"
          />

          <PaymentCardTab
            v-if="
              (contractId > 2786 || this.contractId == 2294) &&
              activeTab == 'payment-card'
            "
            :contract="contract"
            :tempContract="tempContract"
            :form="form"
            :reservation_total_price="reservation_total_price"
            :customerForm="customerForm"
            @creditCardTotal="creditCardTotal"
            @verifyTotal="verifyTotal"
          />

          <PaymentCardCollapse
            v-if="contractId <= 2786 && activeTab == 'payment-card'"
            :contract="contract"
            :tempContract="tempContract"
            :form="form"
            :reservation_total_price="reservation_total_price"
            :customerForm="customerForm"
            @creditCardTotal="creditCardTotal"
            @verifyTotal="verifyTotal"
          />

          <div v-if="activeTab == 'payment-link'" class="payment-link">
            <PaymentLinkTab
              :form="form"
              :contract="contract"
              :tempContract="tempContract"
              :reservation_total_price="reservation_total_price"
              @verifyTotal="verifyTotal"
            />
          </div>

          <CustomPaymentTab
            v-if="activeTab == 'custom-payment'"
            :form="form"
            :contract="contract"
            :tempContract="tempContract"
            :reservation_total_price="reservation_total_price"
            @updateCustomPaymentValue="verifyTotal"
          />

          <BankTransferTab
            v-if="activeTab == 'bank-transfer'"
            :form="form"
            :contract="contract"
            :tempContract="tempContract"
            :reservation_total_price="reservation_total_price"
            @updateBankTransferValue="verifyTotal"
          />

          <div v-if="activeTab == 'third-party'">
            <div class="third-party-list">
              <a-form-item style="display: none">
                <a-input
                  class="travel-input"
                  v-decorator="[
                    `third_party_payment_rows`,
                    {
                      initialValue: JSON.stringify(third_party_payment_rows),
                    },
                  ]"
                >
                </a-input>
              </a-form-item>

              <ClientThirdPartyForm
                :contract="contract"
                :tempContract="tempContract"
                :form="form"
                v-for="person in third_party_payment_rows"
                :clientId="person.id"
                :totalThirdParty="third_party_payment_rows.length"
                :key="person.id"
                @removeThirdParty="removeThirdParty"
                @addThirdParty="addThirdParty"
              />
            </div>
            <div class="a-center mb-40 mt-20">
              <a-button
                type="primary"
                @click="addThirdParty(third_party_payment_rows.length)"
              >
                Adicionar terceiro
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import contractPaymentMethodsMixins from "@/components/contracts/mixins/contractPaymentMethodsMixins";

import PaymentCardCollapse from "../collapses/PaymentCardCollapse.vue";
import ClientThirdPartyForm from "../collapses/ClientThirdPartyForm.vue";

import PaymentLinkTab from "../tabs/PaymentLinkTab.vue";
import CreditCardTab from "../tabs/CreditCardTab.vue";
import PaymentCardTab from "../tabs/PaymentCardTab.vue";
import BillingTicketTab from "../tabs/BillingTicketTab.vue";
import CustomPaymentTab from "../tabs/CustomPaymentTab.vue";
import BankTransferTab from "../tabs/BankTransferTab.vue";
import ContractPaymentMethodsValuesPanel from "../sections/ContractPaymentMethodsValuesPanel.vue";

export default {
  name: "PaymentMethodsModal",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    customerForm: Object,
    reservation_total_price: Number,
    verify_total: Boolean,
    travellers: Array,
  },
  components: {
    ClientThirdPartyForm,
    PaymentLinkTab,
    CreditCardTab,
    PaymentCardTab,
    PaymentCardCollapse,
    BillingTicketTab,
    CustomPaymentTab,
    BankTransferTab,
    ContractPaymentMethodsValuesPanel,
  },
  mixins: [formatThings, contractPaymentMethodsMixins],
  data() {
    return {
      paymentMethods: undefined,
      activeTab: null,
      updatePaymentMethods: [],
      third_party_payment_rows: [],
      contractId: parseInt(this.contract.id),
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.tempContract["payment_methods"] != undefined) {
        this.paymentMethods = JSON.parse(this.tempContract["payment_methods"]);
        this.form.setFieldsValue({
          payment_methods: JSON.parse(this.tempContract["payment_methods"]),
        });
      }
    }, 20);

    this.third_party_payment_rows = this.tempContract[
      "third_party_payment_rows"
    ]
      ? JSON.parse(this.tempContract["third_party_payment_rows"])
      : [];

    setTimeout(() => {
      this.verifyTotal();

      if (this.paymentMethods != undefined) {
        if (this.paymentMethods.includes("billing-ticket")) {
          this.activeTab = "billing-ticket";
        } else {
          if (this.paymentMethods.includes("payment-card")) {
            this.activeTab = "payment-card";
          } else {
            if (this.paymentMethods.includes("credit-card")) {
              this.activeTab = "credit-card";
            } else {
              if (this.paymentMethods.includes("payment-link")) {
                this.activeTab = "payment-link";
              } else {
                if (this.paymentMethods.includes("custom-payment")) {
                  this.activeTab = "custom-payment";
                } else {
                  if (this.paymentMethods.includes("bank-transfer")) {
                    this.activeTab = "bank-transfer";
                  } else {
                    if (this.paymentMethods.includes("third-party")) {
                      this.activeTab = "third-party";
                    }
                  }
                }
              }
            }
          }
        }
      }

      this.updatePaymentMethods =
        this.paymentMethods != undefined ? this.paymentMethods : [];

      this.verifyTotal();
    }, 500);
  },
  methods: {},
};
</script>
