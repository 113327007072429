var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',{attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Valor total da carta de crédito")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_payment_card_total_value`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredFieldPaymentCard(),
                  message: 'Preencha todos números.',
                },
                {
                  validator: _vm.verifyValue,
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_payment_card_total_value`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredFieldPaymentCard(),\n                  message: 'Preencha todos números.',\n                },\n                {\n                  validator: verifyValue,\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira um valor"},on:{"blur":_vm.updtPaymentCardValue}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Valor da Lucratividade")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_payment_card_total_profit_value`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredFieldPaymentCard(),
                  message: 'Preencha todos números.',
                },
                {
                  validator: !this.$root.isAdmin()
                    ? _vm.verifyPaymentCardProfitValue
                    : false,
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_payment_card_total_profit_value`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredFieldPaymentCard(),\n                  message: 'Preencha todos números.',\n                },\n                {\n                  validator: !this.$root.isAdmin()\n                    ? verifyPaymentCardProfitValue\n                    : false,\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira um valor"},on:{"blur":_vm.calcConstractualFine}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Multa contratual (%)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
            currency: {
              prefix: '',
              suffix: '',
            },
            distractionFree: true,
            valueAsInteger: false,
            precision: 2,
            autoDecimalMode: false,
            allowNegative: false,
          }),expression:"{\n            currency: {\n              prefix: '',\n              suffix: '',\n            },\n            distractionFree: true,\n            valueAsInteger: false,\n            precision: 2,\n            autoDecimalMode: false,\n            allowNegative: false,\n          }"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_payment_card_contractual_fine_percentage`,
            {
              rules: [
                {
                  validator: _vm.verifyPercentage,
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_payment_card_contractual_fine_percentage`,\n            {\n              rules: [\n                {\n                  validator: verifyPercentage,\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira um valor"},on:{"blur":_vm.calcConstractualFine}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Multa (R$)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_payment_card_contractual_fine_value`,
          ]),expression:"[\n            `payment_methods_payment_card_contractual_fine_value`,\n          ]"}],staticClass:"travel-input readonly"},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Valor desconto lucratividade (R$)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_payment_card_contract_profit_discount_value`,
          ]),expression:"[\n            `payment_methods_payment_card_contract_profit_discount_value`,\n          ]"}],staticClass:"travel-input readonly"},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('label',{class:'filled'},[_vm._v("Número do voyage")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_payment_card_voyage_id`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredFieldPaymentCard(),
                  message: 'Obrigatório.',
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_payment_card_voyage_id`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredFieldPaymentCard(),\n                  message: 'Obrigatório.',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"type":"number","placeholder":"Insira um valor"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }