<template>
  <aFormItem class="hf-wrapper hf-input-wrapper hf-typed-date">
    <label v-if="label">{{ label }}</label>
    <div class="description" v-if="description">{{ description }}</div>
    <aInput
      class="hf-input"
      :type="type"
      :class="fieldClass"
      :placeholder="placeholder || 'Digite: ○○/○○/○○○○'"
      v-mask="'##/##/####'"
      v-decorator="[
        fieldKey,
        {
          initialValue,
          rules: validationRules,
        },
      ]"
      @blur="onBlur"
      @change="onChange"
    >
      <template #prefix>
        <a-icon type="field-svg" />
      </template>
      <template #suffix>
        <a-date-picker v-model="datepickerValue" @change="onChangeDatePicker">
          <aTooltip>
            <template #title> Digite a data no campo </template>
            <a-icon class="calendar c-pointer" type="calendar" />
          </aTooltip>
        </a-date-picker>
      </template>
    </aInput>
  </aFormItem>
</template>

<script>
import { format } from "date-fns";
export default {
  name: "HayaTypeDateField",
  inheritAttrs: false,
  props: {
    label: String,
    type: String,
    description: String,
    fieldKey: String,
    placeholder: String,
    fieldClass: String,
    requiredMessage: String,
    required: Boolean,
    initialValue: String,
    form: Object,
    preIcon: Boolean,
    rules: Array,
    fieldToTransferValue: String,
  },
  data() {
    return {
      validationRules: [],
      datepickerValue: undefined,
    };
  },
  beforeMount() {
    if (this.required) {
      this.validationRules.push({
        required: true,
        message: this.requiredMessage ? this.requiredMessage : "Obrigatório",
      });
    }

    this.validationRules.push({
      len: 10,
      message: "Escreva todos números da data",
    });

    if (this.rules)
      this.validationRules = this.validationRules.concat(this.rules);
  },
  methods: {
    onChange(e) {
      this.$emit("onChangeTextField", e);
    },
    onChangeDatePicker(e) {
      this.$emit(
        "onChangeDatePicker",
        format(e._d, "dd/MM/yyyy"),
        format(e._d, "yyyy-MM-dd"),
        e._d
      );

      setTimeout(() => {
        this.form.setFieldsValue({
          [this.fieldKey]: format(e._d, "dd/MM/yyyy"),
        });
        this.form.setFieldsValue({
          [this.fieldToTransferValue]: format(e._d, "yyyy-MM-dd"),
        });
      }, 100);
    },
    onBlur(e) {
      let typedDate = e.target.value;

      if (typedDate.length === 10) {
        let typedDatePieces = typedDate.split("/");
        let theDay = typedDatePieces[0];
        let theMonth = typedDatePieces[1];
        let theYear = typedDatePieces[2];

        console.log(
          "typedDatePieces",
          theYear,
          theMonth,
          theDay,
          parseInt(theDay),
          `${theYear}-${theMonth}-${theDay}`
        );

        this.form.setFieldsValue({
          [`${this.fieldToTransferValue}`]: `${theYear}-${theMonth}-${theDay}`,
        });

        if (parseInt(theDay) > 31) {
          this.form.setFieldsValue({
            [`${this.fieldToTransferValue}`]: undefined,
          });
          this.$message.warning(`Atenção dia inválido! Valor: ${theDay}.`);
        }

        if (parseInt(theMonth) > 12) {
          this.form.setFieldsValue({
            [`${this.fieldToTransferValue}`]: undefined,
          });
          this.$message.warning(`Atenção mês inválido! Valor: ${theMonth}.`);
        }

        if (parseInt(theYear) < 1900) {
          this.form.setFieldsValue({
            [`${this.fieldToTransferValue}`]: undefined,
          });
          this.$message.warning(`Atenção ano inválido! Valor: ${theYear}.`);
        }

        this.$emit(
          "onBlurHayaTypeDateField",
          `${theYear}-${theMonth}-${theDay}`
        );
      } else {
        this.form.setFieldsValue({
          [`${this.fieldToTransferValue}`]: undefined,
        });

        this.$emit("onBlurHayaTypeDateField", undefined);
      }
    },
  },
};
</script>

<style lang="sass">
.hf-input-wrapper
  .ant-form-explain
    font-size: 9px !important
    top: -4px
    position: relative
.hf-input.ant-input-affix-wrapper
  .ant-input
    border: 2px solid #d9d9d9
    border-radius: 0
    border-width: 0 0 2px 0
    padding-left: 21px
    &:focus
      border-right-width: 0
      outline: 0
      box-shadow: none

  .ant-input-prefix
    left: 0
</style>

<style lang="sass" scoped>
.hf-wrapper
  label
    position: relative !important
    opacity: 1 !important
    font-size: 10px !important
    font-weight: 500 !important
    color: #ccc !important
    line-height: 1 !important
    text-overflow: ellipsis !important
    width: 100% !important
    white-space: nowrap !important
    top: 5px !important
    margin-top: -10px !important
    margin-bottom: 0px !important
    display: block !important
  .calendar
    color: #ccc

  .hf-input.ant-input-affix-wrapper
    border: 0
  .hf-input
    border: 2px solid #d9d9d9
    border-radius: 0
    border-width: 0 0 2px 0
    padding-left: 0
    &:focus
      border-right-width: 0
      outline: 0
      box-shadow: none
  .description
    font-size: 9px
    line-height: 1.2
    margin: 8px  0 3px
    font-weight: 500
    border-left: 2px solid #ddd
    padding-left: 7px
</style>
